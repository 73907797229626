import * as React from "react";
import { Controller, Control, RegisterOptions } from "react-hook-form";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import Info from "@mui/icons-material/Info";

interface RootProps {
  name: string;
  control: Control<any>;
  label: string;
  options: Array<{
    value: string | number | boolean | Date;
    label: string;
  }>;
  required?: boolean;
  errorMessage: string | undefined;
  hint?: {
    Title?: string;
    Content: string;
  };
  vertical?: boolean;
  initialValue?: string | number | boolean;
  validation?: RegisterOptions;
}

export default function RadioInput({
  name,
  control,
  label,
  options,
  required,
  errorMessage,
  hint,
  vertical,
  initialValue,
  validation,
}: RootProps) {
  const generateRadioOptions = () => {
    return options.map((singleOption) => (
      <FormControlLabel
        key={singleOption.label}
        value={singleOption.value}
        label={singleOption.label}
        control={<Radio sx={{ mt: -1 }} />}
        sx={{ alignItems: "flex-start", my: 1 }}
      />
    ));
  };

  return (
    <FormControl fullWidth>
      <FormLabel sx={{ display: "inline-flex", alignItems: "center", gap: 1 }}>
        <Typography fontWeight={500} color={errorMessage ? "error" : "inherit"}>
          {label} {required ? "*" : "(Optional)"}
        </Typography>
        {hint && (
          <Tooltip title={<Typography>{hint.Content}</Typography>} arrow>
            <Info color="secondary" sx={{ cursor: "pointer" }} />
          </Tooltip>
        )}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={initialValue ?? ""}
        shouldUnregister
        rules={validation}
        render={({ field: { onChange, value } }) => (
          <RadioGroup row={!vertical} value={value} onChange={onChange}>
            {generateRadioOptions()}
          </RadioGroup>
        )}
      />
      <Typography color="red" fontSize={12}>
        {errorMessage}
      </Typography>
    </FormControl>
  );
}
