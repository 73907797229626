import * as React from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import ArrowBack from "@mui/icons-material/ArrowBack";

import MedicareNumberInput from "../inputs/MedicareNumberInput";
import DateInput from "../inputs/DateInput";

import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";

import { FormRootProps } from "../../../types/Enrollment.types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  Input,
  addCompleteStep,
  setInput,
} from "../../../redux/reducers/Enrollment";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";

const StyledImage = styled("img")({
  width: "90%",
  maxWidth: "555px",
  height: "auto",
});

interface FormInput {
  MedicareNumber: string;
  MedicarePartA: string;
  MedicarePartB: string;
}

export default function MedicareInformation({ handleNavigate }: FormRootProps) {
  const dispatch = useAppDispatch();
  const analytics = useRudderStackAnalytics();

  const {
    ApplicationID,
    EnrollPlan,
    Carrier,
    Input: InputtedValues,
  } = useAppSelector((state) => state.Enrollment);
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const methods = useForm<FormInput>({
    defaultValues: {
      MedicareNumber:
        (InputtedValues["medicare-information"]?.MedicareNumber as string) ??
        "",
      MedicarePartA:
        (InputtedValues["medicare-information"]?.MedicarePartA as string) ?? "",
      MedicarePartB:
        (InputtedValues["medicare-information"]?.MedicarePartB as string) ?? "",
    },
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  const { control, handleSubmit, formState } = methods;
  const { errors } = formState;

  React.useEffect(() => {
    if (!analytics || !EnrollPlan || !ApplicationID || !QuoteID) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_STARTED, {
      quote_id: QuoteID,
      application_id: ApplicationID,
      enrollment_type: "INTERNAL",
      contract_id: EnrollPlan?.ContractID,
      plan_id: EnrollPlan?.PlanID,
      segment_id: EnrollPlan?.SegmentID,
      plan_year: EnrollPlan?.Year,
      snp_type: EnrollPlan?.SnpType,
      plan_type: EnrollPlan?.PlanType,
      section_id: "medicare-information",
    });
  }, [ApplicationID, EnrollPlan, QuoteID, analytics]);

  React.useEffect(() => {
    if (!analytics || !EnrollPlan || !ApplicationID || !QuoteID) {
      return;
    }

    const { errors } = formState;
    if (errors) {
      Object.keys(errors).forEach((i) => {
        const errorMessage = errors[i as keyof FormInput]?.message;

        if (errorMessage) {
          analytics?.track(
            ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_ERROR,
            {
              quote_id: QuoteID,
              application_id: ApplicationID,
              enrollment_type: "INTERNAL",
              contract_id: EnrollPlan?.ContractID,
              plan_id: EnrollPlan?.PlanID,
              segment_id: EnrollPlan?.SegmentID,
              plan_year: EnrollPlan?.Year,
              snp_type: EnrollPlan?.SnpType,
              plan_type: EnrollPlan?.PlanType,
              section_id: "medicare-information",
              error_field: i,
              error_detail: errorMessage,
            }
          );
        }
      });
    }
  }, [formState, analytics, EnrollPlan, ApplicationID, QuoteID]);

  const onSubmit: SubmitHandler<FormInput> = (data) => {
    const inputData: Input = {
      "medicare-information": { ...data },
    };

    dispatch(setInput(inputData));
    dispatch(addCompleteStep("medicare-information"));

    analytics?.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_SUBMITTED, {
      quote_id: QuoteID,
      application_id: ApplicationID,
      enrollment_type: "INTERNAL",
      contract_id: EnrollPlan?.ContractID,
      plan_id: EnrollPlan?.PlanID,
      segment_id: EnrollPlan?.SegmentID,
      plan_year: EnrollPlan?.Year,
      snp_type: EnrollPlan?.SnpType,
      plan_type: EnrollPlan?.PlanType,
      section_id: "medicare-information",
    });

    handleNavigate("eligibility-questions");
  };

  const isHumanaPlan = React.useMemo(() => {
    return Carrier ? /humana/i.test(Carrier) : false;
  }, [Carrier]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowGap={3}>
          <Grid
            item
            md={6}
            container
            justifyContent={{ xs: "center", md: "flex-start" }}
            rowGap={1}
          >
            <Grid item xs={12} sm={7} md={10}>
              <MedicareNumberInput
                name="MedicareNumber"
                control={control}
                errorMessage={errors.MedicareNumber?.message}
                label="Medicare Number"
                required
                validation={{
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  pattern: {
                    value:
                      /^\d(?![SLOIBZ])[A-Z]\d|(?![SLOIBZ])[A-Z]\d(?![SLOIBZ])[A-Z]\d|(?![SLOIBZ])[A-Z]\d(?![SLOIBZ])[A-Z](?![SLOIBZ])[A-Z]\d\d$/,
                    message: "Enter a valid Medicare Number",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={7} md={10}>
              <DateInput
                name="MedicarePartA"
                control={control}
                label="Medicare Part-A Start Date"
                required={!isHumanaPlan}
                errorMessage={errors.MedicarePartA?.message}
                validation={{
                  required: {
                    value: !isHumanaPlan,
                    message: "This field is required",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={7} md={10}>
              <DateInput
                name="MedicarePartB"
                control={control}
                label="Medicare Part-B Start Date"
                required={!isHumanaPlan}
                errorMessage={errors.MedicarePartB?.message}
                validation={{
                  required: {
                    value: !isHumanaPlan,
                    message: "This field is required",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} container justifyContent="center">
            <StyledImage src="/images/MedicareCard.png" alt="" />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} container>
            <Grid item xs={6}>
              <Button
                variant="text"
                color="primary"
                startIcon={<ArrowBack />}
                onClick={() => handleNavigate("contact-information")}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <Button variant="contained" color="primary" type="submit">
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
