import * as React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { useGetDrxEnrollmentQuery } from "../../../redux/services/API";

import {
  Stack,
  Typography,
  useMediaQuery,
  CircularProgress,
  Button,
  Theme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Cancel from "@mui/icons-material/Cancel";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minHeight: 360,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, bgcolor: "primary.main" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <Cancel fontSize="large" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface RootProps {
  open: boolean;
  handleClose: () => void;
}

export default function DrxEnrollDialog({ open, handleClose }: RootProps) {
  const analytics = useRudderStackAnalytics();

  const { SessionID, QuoteID, Location } = useAppSelector(
    (state) => state.Quote
  );
  const EnrollPlan = useAppSelector((state) => state.Enrollment.EnrollPlan);
  const AffiliateID = useAppSelector((state) => state.Affiliate.Code);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  const { data, isFetching, isError, error } = useGetDrxEnrollmentQuery(
    {
      SessionID: SessionID ?? "",
      DrxPlanID: EnrollPlan?.DrxID ?? "",
      QuoteID: QuoteID ?? "",
      UserType: "CONSUMER",
      ContractID: EnrollPlan?.ContractID ?? "",
      PlanID: EnrollPlan?.PlanID ?? "",
      SegmentID: EnrollPlan?.SegmentID ?? "",
      PlanYear: EnrollPlan?.Year ?? 0,
      ZipCode: Location?.ZipCode ?? "",
      Fips: Location?.CountyFIPS ?? "",
      State: Location?.State ?? "",
      Affiliate: AffiliateID,
    },
    { skip: !SessionID || !EnrollPlan || !QuoteID || !Location }
  );

  const EnrollmentURL = `${data?.data.Url}?${data?.data.Parameters}`;

  const getErrorText = () => {
    //@ts-ignore
    let errorText = error?.data?.error;

    if (typeof errorText === "string") {
      return errorText;
    }

    return "Internal Server Error";
  };

  const handleContinueClick = () => {
    analytics?.track(ANALYTICS_EVENT.EXIT_LINK_CLICKED, {
      quote_id: QuoteID,
      link_url: EnrollmentURL,
      link_text: "Continue",
    });

    analytics?.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_STARTED, {
      quote_id: QuoteID,
      enrollment_type: "DRX",
      application_id: data?.data.Parameters,
      contract_id: EnrollPlan?.ContractID,
      plan_id: EnrollPlan?.PlanID,
      segment_id: EnrollPlan?.SegmentID,
      plan_year: EnrollPlan?.Year,
      snp_type: EnrollPlan?.SnpType,
      plan_type: EnrollPlan?.PlanType,
    });
  };

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="drx-enroll-dialog"
      open={open}
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
    >
      <CustomDialogTitle id="drx-enroll-dialog-title" onClose={handleClose}>
        <Typography variant="h6" fontWeight={600} color="#fff">
          Enrollment
        </Typography>
      </CustomDialogTitle>
      <Stack justifyContent="center" rowGap={4} p={2}>
        {isFetching ? (
          <CircularProgress sx={{ alignSelf: "center" }} />
        ) : isError ? (
          //@ts-ignore
          <Typography>Error: {getErrorText()} </Typography>
        ) : (
          data && (
            <>
              <Typography variant="h6" fontWeight={600}>
                External Enrollment Confirmation
              </Typography>

              <Typography fontWeight={500}>{data.data.Message}</Typography>

              <Button
                variant="outlined"
                component="a"
                href={EnrollmentURL}
                endIcon={<ArrowForward />}
                sx={{ maxWidth: "150px", alignSelf: "center" }}
                onClick={() => {
                  handleContinueClick();
                }}
              >
                Continue
              </Button>
            </>
          )
        )}
      </Stack>
    </CustomDialog>
  );
}
