export enum WEBSITE_FILTER_TYPE {
  MEDICINE = "MEDICINE",
  PHARMACY = "PHARMACY",
  PLAN = "PLAN",
  PROVIDER = "PROVIDER",
}

type Filter = {
  [key in WEBSITE_FILTER_TYPE]?: string[];
};

export interface Phone {
  PhoneNumber: string;
  PhoneNumberAvailability: {
    Days: string;
    Hours: string;
    Timezone: string;
  };
  TTY: boolean;
}

export interface ColorPalette {
  Primary: string;
  Secondary: string;
  Info: string;
}

export interface AffiliateResponse {
  DisplayName: string;
  Description: string;
  Metadata: {
    LogoURL: string;
    Phone: Phone;
    ColorPalette: ColorPalette;
  };
  Filters: Filter;
}
