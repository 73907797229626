import * as React from "react";
import { useEmailQuoteMutation } from "../../redux/services/API";
import { useAppSelector } from "../../redux/hooks";

import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Check from "@mui/icons-material/Check";

import { ANALYTICS_EVENT } from "../../config/analytics.config";

import { useRudderStackAnalytics } from "../../utils/useRudderStackAnalytics";

const StyledInput = styled(OutlinedInput)(() => ({
  borderRadius: 15,
  paddingRight: 0,
  paddingLeft: 20,
  width: "100%",
  maxWidth: "32rem",
  backgroundColor: "#fff",
}));

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  fontWeight: 600,
  padding: ".6rem",
  borderRadius: 15,
  fontSize: "1rem",
  width: "13rem",
  height: "4rem",
  marginLeft: ".5rem",

  ":hover": {
    background: theme.palette.primary.dark,
  },
}));

const emailValidator =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export default function EmailQuote() {
  const analytics = useRudderStackAnalytics();

  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);
  const AffiliateCode = useAppSelector((state) => state.Affiliate.Code);

  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(false);

  const [
    sendEmail,
    { isLoading, isSuccess, isError, error: emailQuoteError, data },
  ] = useEmailQuoteMutation({ fixedCacheKey: "quote-email" });

  React.useEffect(() => {
    if (!analytics) {
      return;
    }

    analytics.track(ANALYTICS_EVENT.FORM_STARTED, {
      quote_id: QuoteID,
      form_name: "EMAIL QUOTE",
      form_type: "QUOTE",
    });
  }, [QuoteID, analytics]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmitEmail = async () => {
    const validEmail = emailValidator.test(email);

    if (!validEmail || !QuoteID) {
      setError(true);

      analytics?.track(ANALYTICS_EVENT.FORM_ERROR, {
        quote_id: QuoteID,
        form_name: "EMAIL QUOTE",
        form_type: "QUOTE",
        error_type: "VALIDATION",
        error_name: "Email",
        error_detail: "Not a valid Email",
      });
      return;
    }

    setError(false);

    analytics?.identify(analytics.getAnonymousId(), {
      email,
    });

    analytics?.track(ANALYTICS_EVENT.QUOTE_SHARED, {
      quote_id: QuoteID,
      share_via: "EMAIL",
      recipient: email,
    });

    analytics?.track(ANALYTICS_EVENT.FORM_SUBMITTED, {
      quote_id: QuoteID,
      form_name: "EMAIL QUOTE",
      form_type: "QUOTE",
    });

    await sendEmail({ QuoteID: QuoteID ?? "", Email: email, AffiliateCode });
  };

  if (isSuccess) {
    return (
      <Typography
        component="div"
        display="flex"
        alignItems="center"
        gap={0.5}
        color="primary"
        variant="body2"
        fontWeight={500}
      >
        <Check fontSize="small" /> Successfully sent Quote to{" "}
        <b>{data?.data.email}</b>
      </Typography>
    );
  }

  const errorText =
    //@ts-ignore
    emailQuoteError?.data?.error ?? "Please enter a valid email";

  return (
    <>
      <StyledInput
        value={email}
        onChange={handleEmailChange}
        placeholder="Email"
        endAdornment={
          <StyledButton loading={isLoading} onClick={() => handleSubmitEmail()}>
            Send Email
          </StyledButton>
        }
        error={error || isError}
      />
      {(error || isError) && (
        <Typography variant="body2" color="error">
          {errorText}
        </Typography>
      )}
    </>
  );
}
