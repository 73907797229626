import * as React from "react";

import { Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { Pharmacy } from "../../../types/Pharmacy.types";

function PharmacySelect({
  info,
  handleSelect,
  index,
  selected,
  loading,
}: {
  info: Pharmacy;
  handleSelect: (index: number, selected: boolean) => void;
  index: number;
  selected: boolean;
  loading: boolean;
}) {
  return (
    <Grid
      container
      width={{ xs: 350, sm: 600 }}
      minHeight={60}
      sx={{ mx: { xs: 0.5, sm: 1 }, my: 1 }}
      rowGap={2}
    >
      <Grid item xs={6} sm={5} container alignContent="center" sx={{ px: 1 }}>
        <Typography fontWeight={500}>{info.Name}</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={5}
        container
        direction="column"
        textAlign={{ xs: "right", sm: "left" }}
      >
        <Typography variant="body2">{info.Address1}</Typography>
        <Typography variant="body2">{info.Address2}</Typography>
        <Typography variant="body2">
          {info.City}, {info.State} {info.Zip}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={2}
        container
        justifyContent="center"
        alignContent="center"
      >
        <LoadingButton
          variant="outlined"
          fullWidth
          sx={{ maxWidth: "150px", borderRadius: 15 }}
          onClick={() => handleSelect(index, selected)}
          color={selected ? "error" : "primary"}
          loading={loading}
        >
          {selected ? "REMOVE" : "SELECT"}
        </LoadingButton>
      </Grid>
    </Grid>
  );
}

export default React.memo(PharmacySelect, function (prevState, nextState) {
  return (
    prevState.selected === nextState.selected &&
    prevState.loading === nextState.loading
  );
});
