import * as React from "react";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { addCompleteStep, setInput } from "../../../redux/reducers/Enrollment";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowBack from "@mui/icons-material/ArrowBack";

import Input from "../inputs";
import {
  BasicAccordion,
  BasicAccordionDetails,
  BasicAccordionSummary,
} from "../BasicAccordion";

import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";

import {
  FieldInput,
  FormRootProps,
  Section,
} from "../../../types/Enrollment.types";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";

interface RootProps extends FormRootProps {
  pageID: string;
  sectionsData: Section[];
  nextPage: string;
  prevPage: string;
}

export default function DynamicForm({
  pageID,
  sectionsData,
  nextPage,
  prevPage,
  handleNavigate,
}: RootProps) {
  const dispatch = useAppDispatch();
  const analytics = useRudderStackAnalytics();

  const InputtedValues = useAppSelector(
    (state) => state.Enrollment.Input[pageID]
  );
  const { ApplicationID, EnrollPlan } = useAppSelector(
    (state) => state.Enrollment
  );
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    defaultValues: InputtedValues,
  });

  const { control, handleSubmit, watch, formState } = methods;
  const { errors } = formState;

  React.useEffect(() => {
    if (!analytics || !EnrollPlan || !ApplicationID || !QuoteID || !pageID) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_STARTED, {
      quote_id: QuoteID,
      application_id: ApplicationID,
      enrollment_type: "INTERNAL",
      contract_id: EnrollPlan?.ContractID,
      plan_id: EnrollPlan?.PlanID,
      segment_id: EnrollPlan?.SegmentID,
      plan_year: EnrollPlan?.Year,
      snp_type: EnrollPlan?.SnpType,
      plan_type: EnrollPlan?.PlanType,
      section_id: pageID,
    });
  }, [ApplicationID, EnrollPlan, QuoteID, analytics, pageID]);

  React.useEffect(() => {
    if (!analytics || !EnrollPlan || !ApplicationID || !QuoteID || !pageID) {
      return;
    }

    const { errors } = formState;
    if (errors) {
      Object.keys(errors).forEach((i) => {
        const errorMessage = errors[i]?.message;

        if (errorMessage) {
          analytics?.track(
            ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_ERROR,
            {
              quote_id: QuoteID,
              application_id: ApplicationID,
              enrollment_type: "INTERNAL",
              contract_id: EnrollPlan?.ContractID,
              plan_id: EnrollPlan?.PlanID,
              segment_id: EnrollPlan?.SegmentID,
              plan_year: EnrollPlan?.Year,
              snp_type: EnrollPlan?.SnpType,
              plan_type: EnrollPlan?.PlanType,
              section_id: pageID,
              error_field: i,
              error_detail: errorMessage,
            }
          );
        }
      });
    }
  }, [formState, analytics, EnrollPlan, ApplicationID, QuoteID, pageID]);

  const onSubmit: SubmitHandler<any> = (data) => {
    const inputData = {
      [pageID]: data,
    };

    dispatch(setInput(inputData));
    dispatch(addCompleteStep(pageID));

    analytics?.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_SUBMITTED, {
      quote_id: QuoteID,
      application_id: ApplicationID,
      enrollment_type: "INTERNAL",
      contract_id: EnrollPlan?.ContractID,
      plan_id: EnrollPlan?.PlanID,
      segment_id: EnrollPlan?.SegmentID,
      plan_year: EnrollPlan?.Year,
      snp_type: EnrollPlan?.SnpType,
      plan_type: EnrollPlan?.PlanType,
      section_id: pageID,
    });

    handleNavigate(nextPage);
  };

  /**
   * Create a Formatted Title
   *
   * @param section
   * @returns Formatted Title for the Section
   */
  const generateTitle = (section: Section) => {
    return (
      <Typography variant="h6" fontWeight={600}>
        {section.Title} {section.Optional && "(Optional)"}
      </Typography>
    );
  };

  /**
   * TODO: MOVE HELPER FUNCTIONS TO OWN TSX FILES
   * using useFormContext() we can access all necessary functions
   * inside separate files
   */

  /**
   * Create a form based on the "Fields" field in the Section Object
   *
   * @param section
   * @returns Styled Form with Validation
   */
  const generateForm = (section: Section) => {
    return (
      <Grid container spacing={3}>
        {section.Fields.map((field) => {
          return (
            <Grid key={`${section.id}-${field.id}`} item {...field.Width}>
              <Input
                field={field}
                control={control}
                errorMessage={
                  (errors[field.id]?.message as string) ?? undefined
                }
                initialValue={InputtedValues ? InputtedValues[field.id] : ""}
              />
              {field.Tag === "input" &&
                field.OptionHasFollowup &&
                generateFollowupForm(field)}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  /**
   * Create a Form for each Option with a Followup Input
   * for a specific value of the parent field
   *
   * @param field
   * @returns Styled Form with Validation
   */
  const generateFollowupForm = (field: FieldInput) => {
    if (!field.Options) {
      return null;
    }

    const watchFieldValue = watch(field.id);

    return field.Options.map((option) => {
      if (!option.Followup) {
        return null;
      }

      const displayForm = option.value === watchFieldValue;

      if (!displayForm) {
        return null;
      }

      return (
        <Grid
          key={`${field.id}-${option.value}-followup`}
          container
          pl={{ xs: 0, sm: 4 }}
          spacing={3}
          mt={{ xs: 1, sm: 0 }}
        >
          {option.Followup.map((followupField) => {
            return (
              <Grid
                key={`${field.id}-${option.value}-${followupField.id}`}
                item
                {...followupField.Width}
              >
                <Input
                  field={followupField}
                  control={control}
                  errorMessage={
                    (errors[followupField.id]?.message as string) ?? undefined
                  }
                  initialValue={
                    InputtedValues ? InputtedValues[followupField.id] : ""
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      );
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container gap={2}>
          {sectionsData.map((section) => {
            const title = generateTitle(section);
            const children = generateForm(section);
            return (
              <React.Fragment key={section.id}>
                {section.Accordion ? (
                  <Grid item xs={12}>
                    <BasicAccordion>
                      <BasicAccordionSummary>{title}</BasicAccordionSummary>
                      <BasicAccordionDetails>{children}</BasicAccordionDetails>
                    </BasicAccordion>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      {title}
                    </Grid>
                    <Grid item xs={12} mb={2}>
                      {children}
                    </Grid>
                  </>
                )}
              </React.Fragment>
            );
          })}

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} container>
            <Grid item xs={6}>
              <Button
                variant="text"
                color="primary"
                startIcon={<ArrowBack />}
                onClick={() => handleNavigate(prevPage)}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <Button variant="contained" color="primary" type="submit">
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
