import { US_STATE } from "./Global.types";

export enum MED_SUPP_PLANS {
  "A" = "A",
  "B" = "B",
  "C" = "C",
  "D" = "D",
  "E" = "E",
  "F" = "F",
  "G" = "G",
  "H" = "H",
  "HDF" = "HDF",
  "HDG" = "HDG",
  "I" = "I",
  "K" = "K",
  "L" = "L",
  "M" = "M",
  "N" = "N",
  "MA_CORE" = "MA_CORE",
  "MA_SUPP1" = "MA_SUPP1",
  "MNR_PREV" = "MNR_PREV",
  "MNR_PTA" = "MNR_PTA",
  "MNR_PTB" = "MNR_PTB",
  "MNR_UPRV" = "MNR_UPRV",
  "MN_BASIC" = "MN_BASIC",
  "MN_EXTB" = "MN_EXTB",
  "MN_XBAS" = "MN_XBAS",
  "MNR_EXCS" = "MNR_EXCS",
  "MN_PBCO" = "MN_PBCO",
  "MN_HDED" = "MN_HDED",
  "MN_50%" = "MN_50%",
  "MN_75%" = "MN_75%",
  "MNR_UHC1" = "MNR_UHC1",
  "WIR_A50%" = "WIR_A50%",
}

export interface MedSuppQuoteParameters {
  ZipCode: string;
  Plan: MED_SUPP_PLANS;
  Age: number;
  Gender: GENDER_OPTIONS;
  /** 0=No | 1=Yes */
  Tobacco: number;
  /** YYYY-MM-DD */
  EffectiveDate: string;
}

export interface MedSuppQuote {
  key: string;
  age: number;
  age_increases: number[];
  archive: null;
  company: string;
  company_base: Company;
  contextual_data: {
    has_eapp: boolean;
  };
  discount_category: "Multi-Insured" | "Roommate" | string;
  discounts: Discount[];
  e_app_link: string;
  /** YYYY-MM-DDTHH:MM:SS */
  effective_date: string;
  /** YYYY-MM-DDTHH:MM:SS */
  expires_date: string;
  fees: Fee[];
  gender: "M" | "F";
  has_brochure: boolean;
  has_pdf_app: boolean;
  /** YYYY-MM-DDTHH:MM:SS */
  last_modified: string;
  legacy_id: null;
  location: string;
  note: null;
  plan: MED_SUPP_PLANS;
  rate: {
    /** US Dollar in Pennies */
    annual: number;
    /** US Dollar in Pennies */
    month: number;
    /** US Dollar in Pennies */
    quarter: number;
    /** US Dollar in Pennies */
    semi_annual: number;
  };
  rate_increases: RateIncrease[];
  rate_type: string;
  rating_class: string;
  related_data: null;
  riders: Rider[];
  select: boolean;
  tobacco: boolean;
  view_type: string[];
  location_base: Location;
}

interface Company {
  key: string;
  ambest_outlook: string;
  ambest_rating: string;
  business_type: string;
  customer_complaint_ratio: null;
  customer_satisfaction_ratio: number;
  default_resources: {
    "medicare-supplement": {
      sso_e_app_link: string;
      e_app_link: string;
      get_contracted_link: string;
    };
    "medicare-advantage": {
      sso_e_app_link: string;
      e_app_link: string;
    };
    "final-expense-life": {
      sso_e_app_link: string;
      e_app_link: string;
    };
    "hospital-indemnity": {
      sso_e_app_link: string;
      e_app_link: string;
    };
    dental: {
      sso_e_app_link: string;
      e_app_link: string;
    };
  };
  established_year: number;
  /** YYYY-MM-DDTHH:MM:SS */
  last_modified: string;
  /** List of previous year med supp market data */
  med_supp_market_data: PreviousMedSuppMarketData[];
  /** Most recent med supp national market data */
  med_supp_national_market_data: MarketData;
  /** Most recent med supp state market data */
  med_supp_state_market_data: MarketData[];
  naic: string;
  name: string;
  name_full: string;
  parent_company: string;
  parent_company_base: {
    key: string;
    code: string;
    established_year: number;
    /** YYYY-MM-DDTHH:MM:SS */
    last_modified: string;
    name: string;
  };
  sp_rating: string;
  state_marketing_data: StateMarketingData[];
  type: string;
  underwriting_data: [];
}

interface Discount {
  name: "household" | string;
  rule: null;
  type: "percent" | string;
  /** Percentage in decimal */
  value: number;
}

interface Fee {
  name: "policy" | string;
  type: "fixed" | string;
  /** US Dollar */
  value: number;
}

interface Location {
  key: string;
  county: string[];
  /** YYYY-MM-DDTHH:MM:SS */
  effective_date: string;
  /** YYYY-MM-DDTHH:MM:SS */
  expires_date: string;
  /** YYYY-MM-DDTHH:MM:SS */
  last_modified: string;
  state: US_STATE;
  zip3: string[];
  zip5: string[];
}

interface MarketData {
  claims: number;
  lives: number;
  /** Percentage as decimal */
  market_share: number;
  premiums: number;
  /** null value = National Data */
  state: US_STATE | null;
}

interface PreviousMedSuppMarketData {
  med_supp_national_market_data: MarketData;
  med_supp_state_market_data: MarketData[];
  year: number;
}

interface RateIncrease {
  /** YYYY-MM-DDTHH:MM:SS */
  date: string;
  rate_increase: number;
}

interface Rider {}

interface StateMarketingData {
  /** Carrier marketing name in state */
  marketing_name: string;
  state: US_STATE;
}

/**
 * Value (Creates a table):
 *  Parent array is a new row
 *  Child array is 4 elements that correspond to the labels:
 *  "Services" | "Medicare Pays" | "This Plan Pays" | "You Pay"
 */
export interface MedSuppPlanDetails {
  /** Only populated for HDF,HDG (US Dollar without $) */
  deductible?: string;
  "Part A": {
    /** Plan Detail Title  */
    [key: string]: string[][];
    Hospitalization: string[][];
    "Skilled Nursing Facility Care": string[][];
    Blood: string[][];
  };
  "Part B": {
    /** Plan Detail Title */
    [key: string]: string[][];
    "Medical Expenses": string[][];
    Blood: string[][];
    "Clinical Laboratory Services": string[][];
  };
  "Parts A & B": {
    /** Plan Detail Title */
    [key: string]: string[][];
    "Home Health Care": string[][];
    "Durable Medical Equipment": string[][];
  };
  "Other Benefits": {
    /** Plan Detail Title */
    [key: string]: string[][];
    "Foreign Travel": string[][];
  };
}

export enum GENDER_OPTIONS {
  MALE = "M",
  FEMALE = "F",
}

export interface MedSuppQuestionAnswers {
  ZipCode: string;
  Plan: MED_SUPP_PLANS | null;
  Age: number | null;
  Gender: GENDER_OPTIONS | null;
  /** 0=No | 1=Yes */
  Tobacco: number | null;
  /** YYYY-MM-DD */
  EffectiveDate: string;
}

export interface HealthConditionCategory {
  name: string;
  conditions: HealthCondition[];
}

export interface HealthConditionOption {
  name: string;
  specifications: HealthSpecification[];
  look_back_periods: LookBackPeriod[];
  category: string;
  index: number;
}

interface HealthCondition {
  name: string;
  specifications: HealthSpecification[];
  look_back_periods: LookBackPeriod[];
}

interface HealthSpecification {
  specification_identifier: string;
  condition_specifications: ConditionSpecification[];
}

interface ConditionSpecification {
  count: number;
  identifier: string;
  op: OPERATOR | null;
  unit: string;
}

export interface LookBackPeriod {
  count: number;
  currently: boolean;
  ever: boolean;
  identifier: string;
  /** "YYYY-MM-DDTHH:mm:SS" */
  look_back_date: string;
  op: OPERATOR;
  unit: string;
}

export enum OPERATOR {
  GREATER_THAN = ">",
  LESS_THAN = "<",
  GREATER_THAN_EQUAL = ">=",
  LESS_THAN_EQUAL = "<=",
  EQUAL = "==",
}

export interface HealthConditionMedication {
  name: string;
  meta: MedicationCondition[];
}

export interface HealthConditionMedicationOption {
  name: string;
  meta: MedicationCondition[];
  index: number;
  firstLetter: string;
}

interface MedicationCondition {
  associated_condition: string;
  specifications: ConditionSpecification[];
}
