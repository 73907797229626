import * as React from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled, alpha } from "@mui/material/styles";

import Check from "@mui/icons-material/Check";

import useNavigateParams from "../../utils/useNavigateParams";

import { EnrollmentSteps } from "../../types/Enrollment.types";

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  fontSize: "1.1rem",
  fontWeight: 500,
  "&.Mui-selected": {
    backgroundColor: "transparent",
    fontWeight: 600,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
}));

interface PageProps {
  activeLink: boolean;
  link: string;
  title: string;
  handleClick: (id: string) => void;
  step: number;
  complete?: boolean;
}
function Page({
  activeLink,
  link,
  title,
  handleClick,
  step,
  complete,
}: PageProps) {
  return (
    <StyledListItem
      key={link}
      id={link}
      selected={activeLink}
      disableGutters
      onClick={() => handleClick(link)}
    >
      <ListItemIcon
        sx={{
          justifyContent: "center",
          color: activeLink ? "secondary.main" : "#c4c4c4",
        }}
      >
        {complete ? <Check /> : step}
      </ListItemIcon>
      {title}
    </StyledListItem>
  );
}

const Title = styled(Typography)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.27),
  padding: theme.spacing(1),
  fontWeight: 600,
}));

export default function EnrollmentStepsList() {
  const navigate = useNavigateParams();
  const location = useLocation();
  const pathname = location.pathname.split("/")[3];

  const completeSteps = useAppSelector(
    (state) => state.Enrollment.CompleteSteps
  );

  const handleClick = (id: string) => {
    if (EnrollmentSteps[id].step > EnrollmentSteps[pathname].step) {
      return;
    }
    navigate(`/enroll/${id}`);
  };

  return (
    <Paper sx={{ width: { md: "90%" }, maxWidth: "350px" }}>
      <Title variant="h6">Enrollment Steps:</Title>
      <List>
        {Object.keys(EnrollmentSteps).map((key) => {
          const enrollmentStep = EnrollmentSteps[key];

          if (enrollmentStep.ignoreOnStepList) return null;

          return (
            <Page
              key={`enrollment-step-${key}`}
              link={key}
              activeLink={pathname === key}
              title={enrollmentStep.title}
              handleClick={handleClick}
              step={enrollmentStep.step}
              complete={completeSteps.includes(key)}
            />
          );
        })}
      </List>
    </Paper>
  );
}
