import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useDebounce } from "use-debounce";
import { useAppSelector } from "../../../redux/hooks";
import { useAddressAutocompleteQuery } from "../../../redux/services/API";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import LocationOn from "@mui/icons-material/LocationOn";

import { AzureSearchAddressResult } from "../../../types/Global.types";

interface RootProps {
  AddressFields: {
    Address1: string;
    City: string;
    State?: string;
    ZipCode?: string;
    County?: string;
  };
}

export default function AddressAutocomplete({ AddressFields }: RootProps) {
  const { setValue } = useFormContext();
  const { ZipCode, Coordinates } =
    useAppSelector((state) => state.Quote.Location) ?? {};
  const AzureMapsKey = useAppSelector((state) => state.Utils.AzureMapsKey);

  const [error, setError] = React.useState<string>();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [selectedValue, setSelectedValue] =
    React.useState<AzureSearchAddressResult | null>(null);

  const [debounceSearchQuery] = useDebounce(searchQuery, 750);

  const { data, isLoading: loading } = useAddressAutocompleteQuery(
    {
      query: debounceSearchQuery,
      lat: Coordinates?.at(1) ?? 0,
      lon: Coordinates?.at(0) ?? 0,
      AzureMapsKey: AzureMapsKey ?? "",
    },
    {
      skip: debounceSearchQuery.length === 0,
    }
  );

  const storeAddress = (selectedValue: AzureSearchAddressResult) => {
    if (!selectedValue) {
      return;
    }

    const address1 = `${selectedValue.address.streetNumber} ${selectedValue.address.streetName}`;
    const city = selectedValue.address.municipality;
    const state = selectedValue.address.countrySubdivision;
    const zipCode = selectedValue.address.postalCode;
    const county = selectedValue.address.countrySecondarySubdivision ?? "";

    if (ZipCode && ZipCode !== zipCode) {
      setError("Zip Code does not match Quote");
      return;
    }

    setError(undefined);

    setValue(AddressFields.Address1, address1, {
      shouldDirty: true,
    });
    setValue(AddressFields.City, city, { shouldDirty: true });

    if (AddressFields.State) {
      setValue(AddressFields.State, state, { shouldDirty: true });
    }

    if (AddressFields.ZipCode) {
      setValue(AddressFields.ZipCode, zipCode, { shouldDirty: true });
    }

    if (AddressFields.County) {
      setValue(AddressFields.County, county, { shouldDirty: true });
    }
  };

  return (
    <FormControl fullWidth>
      <FormLabel
        sx={{
          display: "inline-flex",
          alignItems: "center",
          gap: 1,
          mb: 0.5,
        }}
      >
        <Typography fontWeight={500}>Search Address</Typography>
      </FormLabel>
      <Autocomplete<AzureSearchAddressResult>
        id="azure-maps-autocomplete"
        disabled={!AzureMapsKey}
        getOptionLabel={(option) => option?.address?.freeformAddress ?? ""}
        filterOptions={(x) => x}
        options={data?.results ?? []}
        autoComplete
        includeInputInList
        value={selectedValue}
        inputValue={searchQuery}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        noOptionsText="Address not found"
        onChange={(_event, newValue: AzureSearchAddressResult | null) => {
          if (!newValue) {
            return;
          }

          setSelectedValue(newValue);
          storeAddress(newValue);
        }}
        onInputChange={(_event, newInputValue) => {
          setSearchQuery(newInputValue);
        }}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="primary" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            helperText={error ?? null}
            error={error !== undefined}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <LocationOn color="secondary" />
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {option.address.freeformAddress}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </FormControl>
  );
}
