import * as React from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { format } from "date-fns";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Divider from "@mui/material/Divider";

import RadioCards from "../inputs/RadioCards";

import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";

import { FormRootProps } from "../../../types/Enrollment.types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  Input,
  addCompleteStep,
  setInput,
} from "../../../redux/reducers/Enrollment";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";

const NOW = new Date();

const DATE_OPTIONS = [
  {
    value: format(
      new Date(NOW.getFullYear(), NOW.getMonth() + 1, 1),
      "yyyy-MM-dd"
    ),
    label: format(
      new Date(NOW.getFullYear(), NOW.getMonth() + 1, 1),
      "LLLL do, yyyy"
    ),
  },
  {
    value: format(
      new Date(NOW.getFullYear(), NOW.getMonth() + 2, 1),
      "yyyy-MM-dd"
    ),
    label: format(
      new Date(NOW.getFullYear(), NOW.getMonth() + 2, 1),
      "LLLL do, yyyy"
    ),
  },
  {
    value: format(
      new Date(NOW.getFullYear(), NOW.getMonth() + 3, 1),
      "yyyy-MM-dd"
    ),
    label: format(
      new Date(NOW.getFullYear(), NOW.getMonth() + 3, 1),
      "LLLL do, yyyy"
    ),
  },
];

interface FormInput {
  EffectiveDate: string;
}

export default function ApplicationEffectiveDate({
  handleNavigate,
}: FormRootProps) {
  const dispatch = useAppDispatch();
  const analytics = useRudderStackAnalytics();

  const {
    ApplicationID,
    Carrier,
    EnrollPlan,
    Input: InputtedValues,
  } = useAppSelector((state) => state.Enrollment);
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const methods = useForm<FormInput>({
    defaultValues: {
      EffectiveDate:
        (InputtedValues?.["application-effective-date"]
          ?.EffectiveDate as string) ?? "",
    },
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  const { control, handleSubmit, formState } = methods;
  const { errors } = formState;

  React.useEffect(() => {
    if (!analytics || !EnrollPlan || !ApplicationID || !QuoteID) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_STARTED, {
      quote_id: QuoteID,
      application_id: ApplicationID,
      enrollment_type: "INTERNAL",
      contract_id: EnrollPlan?.ContractID,
      plan_id: EnrollPlan?.PlanID,
      segment_id: EnrollPlan?.SegmentID,
      plan_year: EnrollPlan?.Year,
      snp_type: EnrollPlan?.SnpType,
      plan_type: EnrollPlan?.PlanType,
      section_id: "application-effective-date",
    });
  }, [ApplicationID, EnrollPlan, QuoteID, analytics]);

  React.useEffect(() => {
    if (!analytics || !EnrollPlan || !ApplicationID || !QuoteID) {
      return;
    }
    const { errors } = formState;
    if (errors) {
      Object.keys(errors).forEach((i) => {
        const errorMessage = errors[i as keyof FormInput]?.message;

        if (errorMessage) {
          analytics?.track(
            ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_ERROR,
            {
              quote_id: QuoteID,
              application_id: ApplicationID,
              enrollment_type: "INTERNAL",
              contract_id: EnrollPlan?.ContractID,
              plan_id: EnrollPlan?.PlanID,
              segment_id: EnrollPlan?.SegmentID,
              plan_year: EnrollPlan?.Year,
              snp_type: EnrollPlan?.SnpType,
              plan_type: EnrollPlan?.PlanType,
              section_id: "application-effective-date",
              error_field: i,
              error_detail: errorMessage,
            }
          );
        }
      });
    }
  }, [formState, analytics, EnrollPlan, ApplicationID, QuoteID]);

  const effectiveDateOptions = React.useMemo(() => {
    if (InputtedValues && InputtedValues["eligibility-questions"]) {
      const enrollmentPeriod =
        InputtedValues["eligibility-questions"]["EnrollmentPeriod"];

      if (enrollmentPeriod && enrollmentPeriod === "AEP") {
        return [
          {
            value: format(new Date(NOW.getFullYear() + 1, 0, 1), "yyyy-MM-dd"),
            label: format(
              new Date(NOW.getFullYear() + 1, 0, 1),
              "LLLL do, yyyy"
            ),
          },
        ];
      }
    }

    return DATE_OPTIONS;
  }, [InputtedValues]);

  const onSubmit: SubmitHandler<FormInput> = (data) => {
    const inputData: Input = {
      "application-effective-date": {
        EffectiveDate: data.EffectiveDate
          ? data.EffectiveDate
          : effectiveDateOptions[0].value,
      },
    };

    dispatch(setInput(inputData));
    dispatch(addCompleteStep("application-effective-date"));

    analytics?.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_SUBMITTED, {
      quote_id: QuoteID,
      application_id: ApplicationID,
      enrollment_type: "INTERNAL",
      contract_id: EnrollPlan?.ContractID,
      plan_id: EnrollPlan?.PlanID,
      segment_id: EnrollPlan?.SegmentID,
      plan_year: EnrollPlan?.Year,
      snp_type: EnrollPlan?.SnpType,
      plan_type: EnrollPlan?.PlanType,
      section_id: "application-effective-date",
    });

    handleNavigate("signature");
  };

  const isHumanaPlan = React.useMemo(() => {
    return Carrier ? /humana/i.test(Carrier) : false;
  }, [Carrier]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container gap={3}>
          <Grid item xs={12} container gap={3}>
            <RadioCards
              name="EffectiveDate"
              control={control}
              label="When would you like your insurance coverage to start?"
              required={!isHumanaPlan}
              options={effectiveDateOptions}
              errorMessage={errors.EffectiveDate?.message}
              cardHeight={"auto"}
              validation={{
                required: {
                  value: !isHumanaPlan,
                  message: "This field is required",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <b>NOTE: </b>Effective dates are based on the enrollment period
              you're using to enroll and the Centers for Medicare & Medicaid
              Services' regulations. Unless you are new to Medicare or are
              eligible for a Special Election Period (SEP), your effective date
              will be January 1. {Carrier} cannot guarantee the effective date
              you've requested will be honored.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} container>
            <Grid item xs={6}>
              <Button
                variant="text"
                color="primary"
                startIcon={<ArrowBack />}
                onClick={() => handleNavigate("review")}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <Button variant="contained" color="primary" type="submit">
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
