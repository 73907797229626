import * as React from "react";
import { useDebounce } from "use-debounce";
import { useFormContext } from "react-hook-form";

import { useSearchMedicinesQuery } from "../../../redux/services/API";
import { useAppSelector } from "../../../redux/hooks";

import {
  Stack,
  Divider,
  Grid,
  Button,
  useMediaQuery,
  Dialog,
  Typography,
  Skeleton,
  DialogTitle,
  IconButton,
  Theme,
  List,
  ListItemIcon,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Cancel, Close, Search } from "@mui/icons-material";

import SearchField from "../../shared/SearchField";
import MedicineSelect from "../../UserInformation/Medicines/MedicineSelect";

import { Medicine } from "../../../types/Medicine.types";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, bgcolor: "primary.main" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <Cancel fontSize="large" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface RootProps {
  id: string;
  label: string;
  required: boolean;
  errorMessage?: string;
}

export default function MedicineFinder({
  id,
  label,
  required,
  errorMessage,
}: RootProps) {
  const { setValue, register, getValues } = useFormContext();

  const QuoteMedicines = useAppSelector((state) => state.Quote.Medicines);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  const [medicineSearch, setMedicineSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  // const [selectedMedicines, setSelectedMedicines] = React.useState<string[]>(
  //   []
  // );

  const selectedMedicines = (getValues(id) as string) ?? "";
  const splitSelectedMedicines = selectedMedicines
    ? selectedMedicines.split(",")
    : [];

  const [debouncedSearchQuery] = useDebounce(medicineSearch, 750);

  register(id, {
    value: selectedMedicines,
    required: {
      value: required,
      message: "Please provide your medications",
    },
  });

  // React.useEffect(() => {
  //   const initialValue = getValues(id);
  //   if (initialValue) {
  //     setSelectedMedicines(initialValue.split(","));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // React.useEffect(() => {
  //   if (selectedMedicines) {
  //     setValue(id, selectedMedicines.join(","), {
  //       shouldDirty: true,
  //       shouldTouch: true,
  //       shouldValidate: true,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedMedicines]);

  const { currentData: data, isFetching } = useSearchMedicinesQuery(
    {
      search: debouncedSearchQuery,
      page: 1,
    },
    {
      skip: debouncedSearchQuery.length === 0,
    }
  );

  const initialMedicines: Medicine[] = React.useMemo(() => {
    if (!QuoteMedicines || QuoteMedicines.length === 0) {
      return [];
    }

    return QuoteMedicines.map((medicine) => {
      return {
        DrugID: medicine.DrugID,
        DrugName: medicine.DrugName,
        DrugType: medicine.DrugType,
        DrugTypeID: medicine.DrugTypeID,
        ChemicalName: medicine.ChemicalName,
        DrugTypeNDA: "",
        SearchMatchType: 0,
        ReferenceNDC: medicine.NDC,
      } as Medicine;
    });
  }, [QuoteMedicines]);

  const handleMedicineSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setMedicineSearch(value);
  };

  const handleOpenClick = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleMedicineSelect = (
    index: number,
    initialMeds: boolean,
    isSelected: boolean
  ) => {
    const medicine = initialMeds ? initialMedicines[index] : data?.data[index];

    if (!medicine) {
      throw Error("No medicine found");
    }

    if (isSelected) {
      const selectMedicineIndex = splitSelectedMedicines.findIndex(
        (i) => i === medicine.DrugName
      );

      handleRemoveMedicine(selectMedicineIndex);

      return;
    }

    // setSelectedMedicines((prev) => [...prev, medicine.DrugName]);

    const temp = [...splitSelectedMedicines];
    temp.push(medicine.DrugName);

    setValue(id, temp.join(","), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const handleRemoveMedicine = (index: number) => {
    if (index === -1) {
      return;
    }

    const temp = [...splitSelectedMedicines];
    temp.splice(index, 1);

    // setSelectedMedicines(temp);

    setValue(id, temp.join(","), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  return (
    <>
      <Grid container p={1} rowGap={2}>
        <Grid item xs={12} container direction="column">
          <Typography fontWeight={500} color="#6a6a6a">
            {label} {required ? "*" : "(Optional)"}
          </Typography>
          {errorMessage !== undefined && (
            <Typography pl={2} variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleOpenClick}
            endIcon={<Search />}
          >
            Find Medicines
          </Button>
        </Grid>

        <Grid item xs={12} container gap={1} pl={{ xs: 0.5, sm: 4 }}>
          <List
            subheader={
              <Typography
                fontWeight={600}
                color={errorMessage !== undefined ? "error" : "black"}
              >
                Selected Medicines (Click medicine below to remove):{" "}
                {splitSelectedMedicines.length === 0 && (
                  <>
                    <br />
                    <Typography>No Medicines Selected</Typography>
                  </>
                )}
              </Typography>
            }
          >
            {splitSelectedMedicines.map((i, index) => {
              return (
                <React.Fragment key={`${id}-medicine-${i}`}>
                  <ListItemButton onClick={() => handleRemoveMedicine(index)}>
                    <ListItemIcon>
                      <Close color="error" />
                    </ListItemIcon>
                    <ListItemText primary={i} />
                  </ListItemButton>
                  {index < splitSelectedMedicines.length - 1 && (
                    <Divider variant="inset" component="li" />
                  )}
                </React.Fragment>
              );
            })}
          </List>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
        fullScreen={isMobile}
      >
        <CustomDialogTitle
          id="medicine-finder-dialog-title"
          onClose={handleDialogClose}
        >
          <Typography variant="h5" color="#fff" fontWeight={600}>
            Medicine Finder
          </Typography>
        </CustomDialogTitle>

        <Grid container rowGap={2} p={3} justifyContent="center">
          <Grid item xs={12}>
            <SearchField
              placeholder="Search by Medicine's Name"
              handleChange={handleMedicineSearch}
              value={medicineSearch}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack divider={<Divider flexItem />} alignItems="center">
              {debouncedSearchQuery.length === 0 ? (
                initialMedicines.map((medicine, index) => {
                  const isSelected =
                    splitSelectedMedicines.findIndex(
                      (i) => i === medicine.DrugName
                    ) > -1;

                  return (
                    <MedicineSelect
                      key={medicine.DrugID}
                      name={medicine.DrugName}
                      handleClick={() =>
                        handleMedicineSelect(index, true, isSelected)
                      }
                      hasGeneric={false}
                      isGeneric={false}
                      isSelected={isSelected}
                    />
                  );
                })
              ) : isFetching ? (
                <Skeleton
                  variant="rectangular"
                  height={400}
                  sx={{ width: { xs: 350, md: 600 } }}
                />
              ) : data && data.data?.length > 0 ? (
                data.data.map((medicine, index) => {
                  const isSelected =
                    splitSelectedMedicines.findIndex(
                      (i) => i === medicine.DrugName
                    ) > -1;
                  return (
                    <MedicineSelect
                      key={medicine.DrugID}
                      name={medicine.DrugName}
                      hasGeneric={false}
                      isGeneric={false}
                      handleClick={() =>
                        handleMedicineSelect(index, false, isSelected)
                      }
                      isSelected={isSelected}
                    />
                  );
                })
              ) : (
                <Typography variant="h6" m={1}>
                  No Medicines Found
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
