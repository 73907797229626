import * as React from "react";

import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Stack,
  styled,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import Close from "@mui/icons-material/Close";

import { ComparePlan } from "../../types/Plan.types";

const DRX_LOGO_URL = process.env.REACT_APP_DRX_LOGO_URL;

const Background = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100vw",
  height: 152,
  backgroundColor: alpha(theme.palette.secondary.main, 0.75),
  zIndex: 99,

  [theme.breakpoints.only("xl")]: {
    marginLeft: "calc(-100vw / 2 + 1536px / 2)",
    marginRight: "calc(-100vw / 2 + 1536px / 2)",
  },
}));

interface RootProps {
  comparePlans: ComparePlan[];
  openCompareDialog: () => void;
  clearPlans: () => void;
  handleCompare: (plan: ComparePlan, selected: boolean) => void;
}

function DesktopBar({
  comparePlans,
  openCompareDialog,
  clearPlans,
  handleCompare,
}: RootProps) {
  const handleRemove = (plan: ComparePlan) => {
    handleCompare(plan, true);
  };

  return (
    <Background>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        justifyContent="center"
        alignItems="center"
        margin={2}
      >
        {[0, 1].map((index) => {
          return (
            <Paper
              key={`compare-${index}`}
              sx={{
                width: 250,
                height: 125,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                position: "relative",
                p: 0.5,
                gap: 0.5,
              }}
            >
              {comparePlans[index] && (
                <Typography
                  sx={{ position: "absolute", top: 5, left: 10 }}
                  variant="body2"
                  fontWeight={600}
                  color="primary"
                >
                  {comparePlans[index].PlanYear}
                </Typography>
              )}
              <IconButton
                title="Remove Compare Plan"
                sx={{ position: "absolute", top: 0, right: 0 }}
                onClick={() => handleRemove(comparePlans[index])}
              >
                <Close color="error" />
              </IconButton>
              {comparePlans[index] && (
                <img
                  src={`${DRX_LOGO_URL}/${comparePlans[index].LogoURL}`}
                  alt=""
                  width="150px"
                  height="75px"
                  style={{ objectFit: "contain" }}
                />
              )}
              <Typography variant="body2">
                {comparePlans[index]
                  ? comparePlans[index].PlanName
                  : "Select Plan to Compare"}
              </Typography>
            </Paper>
          );
        })}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => openCompareDialog()}
            disabled={comparePlans.length < 2}
          >
            Compare
          </Button>

          <Button variant="outlined" color="error" onClick={() => clearPlans()}>
            Clear
          </Button>
        </Box>
      </Stack>
    </Background>
  );
}

function MobileBar({
  comparePlans,
  openCompareDialog,
  handleCompare,
}: RootProps) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleRemove = (plan: ComparePlan) => {
    handleCompare(plan, true);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100vw",
        zIndex: 100,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: alpha(theme.palette.secondary.main, 0.75),
        p: 1,
      }}
    >
      <IconButton onClick={handleClick}>
        {open ? (
          <ExpandMore fontSize="large" />
        ) : (
          <ExpandLess fontSize="large" />
        )}
      </IconButton>
      <Collapse in={open} timeout="auto" unmountOnExit sx={{ mb: 1 }}>
        {[0, 1].map((index) => {
          return (
            <Paper
              key={`compare-${index}`}
              sx={{
                width: 275,
                height: 80,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                mb: 0.5,
                position: "relative",
                pt: 1,
                px: 0.5,
              }}
            >
              {comparePlans[index] && (
                <Typography
                  sx={{ position: "absolute", top: 5, left: 10 }}
                  variant="body2"
                  fontWeight={600}
                  color="primary"
                >
                  {comparePlans[index].PlanYear}
                </Typography>
              )}
              <IconButton
                title="Remove Compare Plan"
                sx={{ position: "absolute", top: 0, right: 0 }}
                onClick={() => handleRemove(comparePlans[index])}
              >
                <Close color="error" />
              </IconButton>
              <Typography variant="body2">
                {comparePlans[index]
                  ? comparePlans[index].PlanName
                  : "Select Plan to Compare"}
              </Typography>
            </Paper>
          );
        })}
      </Collapse>
      <Button
        variant="contained"
        sx={{ width: "90%", maxWidth: "275px", fontSize: "1.2rem" }}
        onClick={() => openCompareDialog()}
      >
        Compare {comparePlans.length} Plan{comparePlans.length > 1 && "s"}
      </Button>
    </Box>
  );
}

export default function CompareBar(props: RootProps) {
  return (
    <>
      <Box display={{ xs: "none", md: "flex" }}>
        <DesktopBar {...props} />
      </Box>
      <Box display={{ xs: "flex", md: "none" }}>
        <MobileBar {...props} />
      </Box>
    </>
  );
}
