import * as React from "react";
import { useMatch } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setActiveStep } from "../../redux/reducers/Quote";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import {
  AppBar,
  IconButton,
  Box,
  useScrollTrigger,
  Toolbar,
  Stepper,
  StepLabel,
  Step,
  Button,
  Tooltip,
  Skeleton,
  useTheme,
  Link,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import CancelIcon from "@mui/icons-material/Cancel";
import FactCheck from "@mui/icons-material/FactCheck";
import Schedule from "@mui/icons-material/Schedule";
import Info from "@mui/icons-material/Info";
import AddLink from "@mui/icons-material/AddLink";
import Email from "@mui/icons-material/Email";

//@ts-ignore
import { ReactComponent as HelpSVG } from "../../assets/help.svg";
//@ts-ignore
import { ReactComponent as SkipSVG } from "../../assets/skip.svg";
//@ts-ignore
import { ReactComponent as IdSVG } from "../../assets/id.svg";

import { ColorlibConnector, ColorlibStepIcon } from "./CustomStepper";
import EnrollmentStepsList from "../Enroll/EnrollmentStepsList";
import EmailQuote from "./EmailQuote";

import { ALL_QUOTE_STEPPER_INFORMATION, STEPS } from "../../types/Global.types";
import { Phone } from "../../types/Affiliate.types";

import { ANALYTICS_EVENT } from "../../config/analytics.config";

import formatPhoneNumber from "../../utils/formatPhoneNumber";
import copyTextToClipboard from "../../utils/copyTextToClipBoard";
import { useRudderStackAnalytics } from "../../utils/useRudderStackAnalytics";
import useNavigateParams from "../../utils/useNavigateParams";

interface RootProps {
  Logo: string;
  Phone: Phone | null;
  LoadingData: boolean;
  AffiliateCode: string;
}

function DesktopHeader({ Logo, Phone, LoadingData, AffiliateCode }: RootProps) {
  const navigate = useNavigateParams();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const analytics = useRudderStackAnalytics();

  const isEnrollmentPages = useMatch("/:affiliateId/enroll/*");
  const isInputPages = useMatch("/:affiliateId/input/*");

  const isDoctorPage = useMatch("/:affiliateId/input/doctors");
  const isMedicinePage = useMatch("/:affiliateId/input/medicines");
  const isPharmacyPage = useMatch("/:affiliateId/input/pharmacy");

  const [openHelp, setOpenHelp] = React.useState(false);
  const [isQuoteIDCopied, setIsQuoteIDCopied] = React.useState(false);
  const [openEmailQuote, setOpenEmailQuote] = React.useState(false);

  const ApplicationID = useAppSelector(
    (state) => state.Enrollment.ApplicationID
  );
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const URL = `https://${window.location.hostname}/${AffiliateCode}?quote=${QuoteID}`;

  const handleCopyClick = () => {
    copyTextToClipboard(URL)
      .then(() => {
        setIsQuoteIDCopied(true);
        setTimeout(() => {
          setIsQuoteIDCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.error(err);
      });

    analytics?.track(ANALYTICS_EVENT.QUOTE_URL_COPIED, {
      quote_id: QuoteID,
      link_url: URL,
    });
  };

  const handleHelpClick = () => {
    setOpenHelp(true);

    analytics?.track(ANALYTICS_EVENT.HEADER_MENU_OPEN, {
      quote_id: QuoteID,
    });
  };

  const handleHelpClose = () => {
    setOpenHelp(false);
    setOpenEmailQuote(false);
  };

  const handleSkipToPlansClick = () => {
    navigate("/input/compare");
    dispatch(setActiveStep(3));

    analytics?.track(ANALYTICS_EVENT.URL_BUTTON_CLICKED, {
      quote_id: QuoteID,
      link_text: "Skip to Plans",
      link_url: "/input/compare",
    });
  };

  const handleEmailQuoteClick = () => {
    setOpenEmailQuote((prev) => !prev);
  };

  const showSkipPlans = isDoctorPage || isMedicinePage || isPharmacyPage;

  return (
    <>
      <Grid
        container
        justifyContent="center"
        sx={{
          borderBottom: "4px solid",
          borderColor: "secondary.main",
        }}
      >
        <Grid
          container
          maxWidth="xl"
          spacing={1}
          px={8}
          py={2}
          alignItems="center"
        >
          <Grid item xs={12} md={3}>
            {LoadingData ? (
              <Skeleton variant="rectangular" width={320} height={75} />
            ) : (
              <img
                src={Logo ? Logo : ""}
                alt=""
                width={320}
                height={75}
                style={{ objectFit: "contain", objectPosition: "left" }}
              />
            )}
          </Grid>

          <Grid
            item
            xs={12}
            md={9}
            container
            direction={isEnrollmentPages ? "column" : "row"}
            justifyContent="flex-end"
            alignContent={isEnrollmentPages ? "flex-end" : "center"}
            columnGap={2}
          >
            {showSkipPlans && (
              <Button
                variant="text"
                startIcon={
                  <SkipSVG
                    width="42px"
                    style={{ fill: theme.palette.primary.main }}
                  />
                }
                onClick={handleSkipToPlansClick}
              >
                <Typography fontWeight={600}>Skip to Plans</Typography>
              </Button>
            )}

            <Button
              variant="text"
              startIcon={
                <HelpSVG
                  width="42px"
                  style={{ fill: theme.palette.primary.main }}
                />
              }
              onClick={handleHelpClick}
            >
              <Typography fontWeight={600}>Need Help?</Typography>
            </Button>

            {isEnrollmentPages && (
              <Box display="flex" gap={1} alignItems="center">
                <Typography variant="h6" fontWeight={500}>
                  Application ID:
                </Typography>
                <Typography variant="h6" fontWeight={600}>
                  {ApplicationID}
                </Typography>
                <Tooltip
                  title={
                    <Typography>
                      This is the unique identifier for your Enrollment. This
                      will allow you to track your enrollment status after
                      submission or to get help by one of our Licensed Insurance
                      Agents.
                    </Typography>
                  }
                >
                  <Info color="secondary" />
                </Tooltip>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={openHelp}
        onClose={handleHelpClose}
        PaperProps={{
          sx: {
            width: "38vw",
            maxWidth: "600px",
            borderRadius: "30px 0 0 30px",
          },
        }}
      >
        <Box p={1.5} sx={{ bgcolor: "primary.main" }}>
          <IconButton onClick={handleHelpClose}>
            <CancelIcon fontSize="large" htmlColor="#fff" />
          </IconButton>
        </Box>

        <Grid container py={6} px={4} gap={1} bgcolor="#fff">
          <Grid item xs={12} textAlign="center">
            <Typography variant="h3" fontWeight={600}>
              Need Help?
            </Typography>
          </Grid>

          {isInputPages && (
            <>
              <Grid item xs={12} container mt={4}>
                <Grid item xs={2} container justifyContent="center">
                  <IdSVG
                    width="64px"
                    style={{ fill: theme.palette.secondary.main }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Box display="flex" gap={0.5} alignItems="center">
                    <Typography variant="h4" fontWeight={500}>
                      {QuoteID}
                    </Typography>
                    <IconButton
                      title={isQuoteIDCopied ? "Copied" : "Copy Link"}
                      onClick={() => handleCopyClick()}
                    >
                      <AddLink color="primary" fontSize="large" />
                    </IconButton>
                    <IconButton
                      title="Email Quote ID"
                      onClick={() => handleEmailQuoteClick()}
                    >
                      <Email color="primary" fontSize="large" />
                    </IconButton>
                  </Box>
                  <Typography fontWeight={500} pl={0.5} mt={-1}>
                    Quote ID
                  </Typography>
                </Grid>
              </Grid>
              {openEmailQuote && (
                <Grid item xs={12}>
                  <EmailQuote />
                </Grid>
              )}
              <Typography>
                Provide this unique identifier to one of Licensed Insurance
                Agents. This will allow you to save your progress and continue
                later. Also, one of our Licensed Insurance Agents can use this
                to help you through our input process.
              </Typography>
            </>
          )}

          <Grid item xs={12} container mt={4}>
            <Grid item xs={2} container justifyContent="center">
              <HelpSVG
                width="64px"
                style={{ fill: theme.palette.secondary.main }}
              />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h4" fontWeight={500}>
                {formatPhoneNumber(Phone?.PhoneNumber ?? "")}{" "}
                {Phone?.TTY && "(TTY 711)"}
              </Typography>
              <Typography fontWeight={500} pl={0.5}>
                Call us{" "}
                {Phone &&
                  `${Phone?.PhoneNumberAvailability.Days} ${Phone?.PhoneNumberAvailability.Hours} ${Phone?.PhoneNumberAvailability.Timezone}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Our Licensed Insurance Agents are waiting to help you with any
              Medicare Plan Questions and Concerns.
            </Typography>
          </Grid>

          <Grid item xs={12} container mt={4} alignItems="center">
            <Grid item xs={2} container justifyContent="center">
              <Schedule sx={{ fontSize: "64px" }} color="secondary" />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h4" fontWeight={500}>
                Appointment
              </Typography>

              <Button
                variant="text"
                href={`https://appointments.yourvirtualenrollment.com/team/yve/30-minute-consultation?Affiliate=${AffiliateCode}&QuoteID=${QuoteID}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  analytics?.track(ANALYTICS_EVENT.EXIT_LINK_CLICKED, {
                    quote_id: QuoteID,
                    link_url: `https://appointments.yourvirtualenrollment.com/team/yve/30-minute-consultation?Affiliate=${AffiliateCode}&QuoteID=${QuoteID}`,
                    link_text: "Click here to Schedule Appointment",
                  });
                }}
              >
                Click here to Schedule Appointment
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Talk to a Licensed Insurance Agent at a particular date and time
              to help with your Enrollment and Questions.
            </Typography>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}

function MobileHeader({ Logo, Phone, LoadingData }: RootProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const analytics = useRudderStackAnalytics();

  const isInputPages = useMatch("/:affiliateId/input/*");
  const isEnrollmentPages = useMatch("/:affiliateId/enroll/*");
  const isPlanDetails = useMatch(
    "/:affiliateId/input/compare/plan-details/:id"
  );
  const isConfirm = useMatch("/:affiliateId/input/confirm");

  const showMenuButton = !isPlanDetails && !isConfirm;

  const ApplicationID = useAppSelector(
    (state) => state.Enrollment.ApplicationID
  );
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const [navOpen, setNavOpen] = React.useState<boolean>(false);
  const trigger = useScrollTrigger({ disableHysteresis: true });

  const activeStep = useAppSelector((state) => state.Quote.ActiveStep);

  const handleOpenNavMenu = () => {
    setNavOpen(true);
  };

  const handleCloseNavMenu = () => {
    setNavOpen(false);
  };

  const handleQuoteStepClick = (step: STEPS) => {
    dispatch(setActiveStep(step));
  };

  return (
    <>
      <AppBar
        sx={{
          background: "#fff",
          borderBottom: "4px solid",
          borderColor: "secondary.main",
          height: 74,
        }}
        elevation={trigger ? 4 : 0}
      >
        <Toolbar sx={{ px: 3, py: 1 }}>
          <Grid container>
            <Grid item xs={8} container alignItems="center">
              {LoadingData ? (
                <Skeleton variant="rectangular" width={200} height={50} />
              ) : (
                <img
                  src={Logo ? Logo : ""}
                  alt=""
                  width={200}
                  height={50}
                  style={{ objectFit: "contain", objectPosition: "left" }}
                />
              )}
            </Grid>

            {showMenuButton && (
              <Grid
                item
                xs={4}
                container
                alignContent="center"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  onClick={handleOpenNavMenu}
                  aria-label="open-navigation-menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                >
                  Menu
                </Button>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="right"
        open={navOpen}
        onClose={handleCloseNavMenu}
        onClick={handleCloseNavMenu}
        PaperProps={{
          sx: {
            width: "19rem",
            borderRadius: "30px 0 0 30px",
          },
        }}
      >
        <Box p={1.5} sx={{ bgcolor: "primary.main" }}>
          <IconButton onClick={handleCloseNavMenu}>
            <CancelIcon fontSize="large" htmlColor="#fff" />
          </IconButton>
        </Box>

        <Grid container sx={{ bgcolor: "#fff" }} py={1} px={3} gap={1}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            textAlign="center"
          >
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              <Button
                variant="text"
                startIcon={
                  <HelpSVG
                    width="42px"
                    style={{ fill: theme.palette.primary.main }}
                  />
                }
                href={`tel:${Phone?.PhoneNumber}`}
              >
                <Typography fontWeight={600} color="primary">
                  Need Help?
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="16px" fontWeight={600}>
                <Link
                  id="link-call-phone-number"
                  href={`tel:${Phone?.PhoneNumber}`}
                  color="secondary"
                  onClick={() => {
                    analytics?.track(ANALYTICS_EVENT.PHONE_NUMBER_CLICKED, {
                      quote_id: QuoteID,
                      section_id: "MOBILE_MENU",
                      link_url: `tel:${Phone?.PhoneNumber}`,
                    });
                  }}
                >
                  {formatPhoneNumber(Phone?.PhoneNumber ?? "")}
                </Link>
                <br />
                {Phone?.TTY && "TTY 711"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="16px">
                Licensed Insurance Agents are Available
                <br />
                {Phone &&
                  `${Phone?.PhoneNumberAvailability.Days} ${Phone?.PhoneNumberAvailability.Hours} ${Phone?.PhoneNumberAvailability.Timezone}`}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider flexItem />
          </Grid>

          {isInputPages && (
            <>
              <Grid item xs={12}>
                <Stepper
                  orientation="vertical"
                  activeStep={activeStep}
                  connector={<ColorlibConnector />}
                  sx={{ width: "100%" }}
                >
                  {ALL_QUOTE_STEPPER_INFORMATION.map((step) => (
                    <Step
                      key={step.label}
                      onClick={() => handleQuoteStepClick(step.id)}
                    >
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {step.label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs={12}>
                <Divider flexItem />
              </Grid>
              <Grid
                item
                xs={12}
                container
                rowGap={1}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <Typography fontWeight={600}>
                    What's on the Quote Icon?
                  </Typography>
                </Grid>
                <Grid item xs={4} container justifyContent="center">
                  <Box
                    sx={{
                      width: 64,
                      height: 64,
                      borderRadius: "50%",
                      bgcolor: "secondary.main",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FactCheck htmlColor="#fff" fontSize="large" />
                  </Box>
                </Grid>
                <Grid item xs={7}>
                  <Typography fontWeight={500} mb={-2}>
                    Your Added:
                  </Typography>
                  <ul>
                    <li>Doctors</li>
                    <li>Medicines</li>
                    <li>Pharmacy</li>
                    <li>Plans</li>
                  </ul>
                </Grid>
              </Grid>
            </>
          )}

          {isEnrollmentPages && (
            <Grid item xs={12} container sx={{ bgcolor: "#fff" }} gap={1}>
              <Grid item xs={12} p={1} textAlign="center">
                <Typography variant="h6" fontWeight={600}>
                  Application ID:
                </Typography>
                <Typography variant="h6">{ApplicationID}</Typography>
              </Grid>
              <Grid item xs={12}>
                <EnrollmentStepsList />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Drawer>
    </>
  );
}

export default function Header() {
  const { Logo, Phone, LoadingData, Code } = useAppSelector(
    (state) => state.Affiliate
  );

  return (
    <>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          backgroundColor: "#fff",
        }}
      >
        <DesktopHeader
          Logo={Logo ?? ""}
          Phone={Phone}
          LoadingData={LoadingData}
          AffiliateCode={Code}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          height: 74,
          backgroundColor: "#fff",
        }}
      >
        <MobileHeader
          Logo={Logo ?? ""}
          Phone={Phone}
          LoadingData={LoadingData}
          AffiliateCode={Code}
        />
      </Box>
    </>
  );
}
