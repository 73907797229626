import * as React from "react";
import { useAppSelector } from "../../../redux/hooks";
import {
  useDeleteMedicineMutation,
  useSearchMedicinesQuery,
} from "../../../redux/services/API";
import { useDebounce } from "use-debounce";

import {
  Grid,
  Stack,
  Typography,
  Divider,
  Paper,
  alpha,
  Box,
  Skeleton,
} from "@mui/material";
import Cancel from "@mui/icons-material/Cancel";

import MedicineSelect from "./MedicineSelect";
import SearchField from "../../shared/SearchField";
import AddMedicineDialog from "./AddMedicineDialog";

import { ANALYTICS_EVENT } from "../../../config/analytics.config";

import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";

import { DrugTypeIDs, SelectedMedicine } from "../../../types/Medicine.types";

export default function Medicines() {
  const {
    Medicines: selectedMedicines,
    SessionID,
    QuoteID,
  } = useAppSelector((state) => state.Quote);

  const analytics = useRudderStackAnalytics();

  const [medicine, setMedicine] = React.useState<number>();
  const [medicineSearch, setMedicineSearch] = React.useState("");

  const [debouncedSearchQuery] = useDebounce(medicineSearch, 750);

  const { currentData: data, isFetching } = useSearchMedicinesQuery({
    search: debouncedSearchQuery,
    page: 1,
  });

  React.useEffect(() => {
    if (!isFetching) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.SEARCH_STARTED, {
      quote_id: QuoteID,
      search_type: "MEDICINE",
      search_keyword: debouncedSearchQuery,
      search_filters: [],
      search_sorts: [{ type: "RANK", value: "desc" }],
      search_meta: [
        {
          type: "page",
          value: 1,
        },
        { type: "per", value: 6 },
      ],
    });
  }, [isFetching]);

  React.useEffect(() => {
    if (!data) {
      return;
    }
    analytics?.track(ANALYTICS_EVENT.SEARCH_RESULTS_RETURNED, {
      quote_id: QuoteID,
      search_type: "MEDICINE",
      search_keyword: debouncedSearchQuery,
      search_filters: [],
      search_sorts: [{ type: "RANK", value: "desc" }],
      search_meta: [
        {
          type: "page",
          value: 1,
        },
        { type: "per", value: 6 },
      ],
      search_result_count: data.data.length,
    });
  }, [data]);

  const [
    removeMedicine,
    { isLoading: isDeletingMedicine, reset: resetRemoveMedicine },
  ] = useDeleteMedicineMutation();

  const handleRemoveMedicine = (medicine: SelectedMedicine) => {
    removeMedicine({
      SessionID: SessionID ?? "",
      DosageRecordID: medicine.DosageRecordID,
    });

    resetRemoveMedicine();

    analytics?.track(ANALYTICS_EVENT.QUOTE_MEDICINE_REMOVED, {
      quote_id: QuoteID,
      medicine_ndc: medicine.NDC,
      medicine_name: medicine.DrugName,
      medicine_dosage: medicine.LabelName,
      medicine_quantity: medicine.UserQuantity,
      medicine_day_supply: medicine.DaysOfSupply,
      // medicine_generic: false,
    });
  };

  const handleMedicineSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setMedicineSearch(value);
  };

  const handleMedicineSelect = (index: number) => {
    if (!data) {
      return;
    }

    setMedicine(index);

    analytics?.track(ANALYTICS_EVENT.SEARCH_RESULT_CLICKED, {
      quote_id: QuoteID,
      search_type: "MEDICINE",
      search_keyword: debouncedSearchQuery,
      search_filters: [],
      search_sorts: [{ type: "RANK", value: "desc" }],
      search_meta: [
        {
          type: "page",
          value: 1,
        },
        { type: "per", value: 6 },
      ],
      search_result_count: data?.data.length,
      search_result_position_clicked: index,
      search_result_clicked_id: data.data[index].DrugName,
    });
  };

  return (
    <>
      <Grid container rowGap={2} pb={{ xs: 4, md: 2 }}>
        <Grid
          item
          xs={12}
          md={8}
          lg={7}
          xl={6}
          container
          justifyContent="center"
        >
          <Paper
            sx={{
              width: "100%",
              maxWidth: "750px",
              borderRadius: { xs: 0, sm: 5 },
              px: { xs: 1, sm: 2 },
              py: 2,
            }}
          >
            <Grid container direction="column" gap={2}>
              <Grid item>
                <SearchField
                  placeholder="Search By Medicine's Name"
                  handleChange={handleMedicineSearch}
                  value={medicineSearch}
                />
              </Grid>

              <Grid item>
                <Stack
                  direction="column"
                  divider={<Divider flexItem />}
                  alignItems="center"
                >
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    alignSelf="flex-start"
                  >
                    Search Results
                  </Typography>
                  {isFetching ? (
                    <Skeleton
                      variant="rectangular"
                      height={400}
                      sx={{ width: { xs: 350, md: 600 } }}
                    />
                  ) : (
                    data &&
                    data.data.map((medicine, index) => {
                      return (
                        <MedicineSelect
                          key={medicine.DrugID}
                          name={medicine.DrugName}
                          hasGeneric={medicine.GenericDrugID !== undefined}
                          isGeneric={
                            medicine.DrugTypeID === DrugTypeIDs.GENERIC
                          }
                          handleClick={() => handleMedicineSelect(index)}
                        />
                      );
                    })
                  )}
                  {/* <Pagination
                    variant="outlined"
                    color="secondary"
                    sx={{ mt: 2 }}
                    onChange={handleChangePage}
                  /> */}
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          lg={5}
          xl={6}
          container
          justifyContent="center"
        >
          <Paper
            sx={{
              borderRadius: { xs: 0, sm: 5 },
              p: 2,
              width: { xs: "100%", sm: "95%" },
              maxWidth: "560px",
              height: "300px",
              overflowY: "auto",
            }}
          >
            <Stack divider={<Divider flexItem />} alignItems="center">
              <Box alignSelf="flex-start">
                <Typography variant="h5" fontWeight={600}>
                  Your Medications
                </Typography>

                {/* <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: 0.1,
                  }}
                >
                  <Typography>View Medicines in</Typography>
                  <Button
                    variant="text"
                    color="primary"
                    endIcon={<FactCheck />}
                  >
                    Your Quote
                  </Button>
                </Box> */}
              </Box>

              {isDeletingMedicine ? (
                <Skeleton
                  variant="rectangular"
                  height={150}
                  sx={{ width: "100%" }}
                />
              ) : (
                selectedMedicines &&
                selectedMedicines.map((medicine) => {
                  return (
                    <Box
                      key={`selected-${medicine.DosageID}`}
                      sx={{
                        width: "100%",
                        p: 1,
                        cursor: "pointer",

                        "&:hover": {
                          backgroundColor: (theme) =>
                            alpha(theme.palette.error.main, 0.1),
                        },
                      }}
                      onClick={() => {
                        if (isDeletingMedicine) {
                          return;
                        }
                        handleRemoveMedicine(medicine);
                      }}
                    >
                      <Grid container gap={1} alignItems="center">
                        <Grid item xs={12} md={8}>
                          <Typography sx={{ textTransform: "capitalize" }}>
                            {medicine.LabelName}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={3}
                          container
                          justifyContent="flex-end"
                          alignItems="center"
                          gap={1}
                        >
                          <Typography color="error">Remove</Typography>
                          <Cancel fontSize="small" color="error" />
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })
              )}
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      {data && medicine !== undefined && (
        <AddMedicineDialog
          open={medicine !== undefined}
          handleClose={() => setMedicine(undefined)}
          medicine={data.data[medicine]}
        />
      )}
    </>
  );
}
