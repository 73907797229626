import * as React from "react";

import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { Cancel } from "@mui/icons-material";

import { SelectedMedicine } from "../../../types/Medicine.types";

const Cell = styled(TableCell)<{ border_r?: number }>(
  ({ border_r, theme }) => ({
    borderRight: border_r ? "2px solid #efefef" : "none",
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  })
);

function convertTierToName(tier: number) {
  switch (tier) {
    case 1:
      return "Preferred Generic";

    case 2:
      return "Generic";

    case 3:
      return "Preferred Brand";

    case 4:
      return "NonPreferred Brand";

    case 5:
      return "Speciality";

    default:
      return "Not Covered";
  }
}

interface RootProps {
  name: string;
  tier: number;
  PA: string;
  ST: string;
  QL: string;
  drugCost: number | null;
  medicineInfo?: SelectedMedicine;
  costPhase?: string;
}

export default function DrugRow({
  name,
  tier,
  PA,
  ST,
  QL,
  drugCost,
  medicineInfo,
  costPhase,
}: RootProps) {
  const isDrugCovered = tier > 0;
  return (
    <TableRow>
      <Cell border_r={1} width="60%">
        <Typography
          ml={1}
          fontWeight={500}
          sx={{ textTransform: "capitalize" }}
        >
          {name}{" "}
          {medicineInfo &&
            `(${medicineInfo.UserQuantity} per ${medicineInfo.DaysOfSupply} days) (${medicineInfo.DrugType})`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
            gap: 1,
            flexWrap: "wrap",
            mt: 1,
          }}
        >
          {isDrugCovered ? (
            <>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Tooltip
                  enterTouchDelay={0.1}
                  title={
                    <React.Fragment>
                      <Typography fontWeight={600}>Drug Tier</Typography>
                      <Typography variant="body2">
                        {convertTierToName(tier)}
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <Chip
                    label="Tier"
                    color="primary"
                    sx={{ color: "#fff", fontWeight: 600 }}
                  />
                </Tooltip>
                <Typography>{tier}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Tooltip
                  enterTouchDelay={0.1}
                  title={
                    <React.Fragment>
                      <Typography fontWeight={600}>
                        Prior Authorization
                      </Typography>
                      <Typography variant="body2">
                        Requires permission from your doctor before certain
                        prescriptions can be filled.
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <Chip
                    label="PA"
                    color="primary"
                    sx={{ color: "#fff", fontWeight: 600 }}
                  />
                </Tooltip>
                <Typography>{PA}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Tooltip
                  enterTouchDelay={0.1}
                  title={
                    <React.Fragment>
                      <Typography fontWeight={600}>Quantity Limits</Typography>
                      <Typography variant="body2">
                        Some plans restrict the amount of a drug that will be
                        covered for a single copay or within a predefined period
                        of time.
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <Chip
                    label="QL"
                    color="primary"
                    sx={{ color: "#fff", fontWeight: 600 }}
                  />
                </Tooltip>
                <Typography>{QL}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Tooltip
                  enterTouchDelay={0.1}
                  title={
                    <React.Fragment>
                      <Typography fontWeight={600}>Step Therapy</Typography>
                      <Typography variant="body2">
                        Some plans require that you try safe, effective,
                        lower-cost drugs before the plan covers a more costly
                        drug.
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <Chip
                    label="ST"
                    color="error"
                    sx={{ color: "#fff", fontWeight: 600 }}
                  />
                </Tooltip>
                <Typography>{ST}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Tooltip
                  enterTouchDelay={0.1}
                  title={
                    <React.Fragment>
                      <Typography fontWeight={600}>Cost Phase</Typography>
                      <Typography variant="body2">
                        Drug Cost Phase of coverage
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <Chip
                    label="Phase"
                    color="secondary"
                    sx={{ color: "#fff", fontWeight: 600 }}
                  />
                </Tooltip>
                <Typography>{costPhase ?? "-"}</Typography>
              </Box>
            </>
          ) : (
            <Box ml={1} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Cancel color="error" />
              <Typography fontWeight={500}> Not Covered</Typography>
            </Box>
          )}
        </Box>
      </Cell>

      <Cell width="40%">
        <Typography textAlign="center">
          {drugCost !== null ? `$${drugCost.toFixed(2)}` : "-"}
        </Typography>
      </Cell>
    </TableRow>
  );
}
