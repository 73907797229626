import { createListenerMiddleware } from "@reduxjs/toolkit";

import {
  addDoctor,
  addMedicine,
  addPlan,
  removeDoctor,
  removeMedicine,
  removePlan,
  setPharmacy,
  setTooltipText,
} from "../reducers/Quote";

export const listenerMiddleware = createListenerMiddleware();

let timer: ReturnType<typeof setTimeout> | null;

listenerMiddleware.startListening({
  actionCreator: addDoctor,
  effect: (_action, listenerApi) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    listenerApi.dispatch(setTooltipText("Added Doctor"));

    timer = setTimeout(() => {
      listenerApi.dispatch(setTooltipText(null));
    }, 3000);
  },
});

listenerMiddleware.startListening({
  actionCreator: removeDoctor,
  effect: (_action, listenerApi) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    listenerApi.dispatch(setTooltipText("Removed Doctor"));

    timer = setTimeout(() => {
      listenerApi.dispatch(setTooltipText(null));
    }, 3000);
  },
});

listenerMiddleware.startListening({
  actionCreator: addMedicine,
  effect: (_action, listenerApi) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    listenerApi.dispatch(setTooltipText("Added Medicine"));

    timer = setTimeout(() => {
      listenerApi.dispatch(setTooltipText(null));
    }, 3000);
  },
});

listenerMiddleware.startListening({
  actionCreator: removeMedicine,
  effect: (_action, listenerApi) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    listenerApi.dispatch(setTooltipText("Removed Medicine"));

    timer = setTimeout(() => {
      listenerApi.dispatch(setTooltipText(null));
    }, 3000);
  },
});

listenerMiddleware.startListening({
  actionCreator: setPharmacy,
  effect: (_action, listenerApi) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    listenerApi.dispatch(setTooltipText("Added Pharmacy"));

    timer = setTimeout(() => {
      listenerApi.dispatch(setTooltipText(null));
    }, 3000);
  },
});

listenerMiddleware.startListening({
  actionCreator: addPlan,
  effect: (_action, listenerApi) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    listenerApi.dispatch(setTooltipText("Added Plan"));

    timer = setTimeout(() => {
      listenerApi.dispatch(setTooltipText(null));
    }, 3000);
  },
});

listenerMiddleware.startListening({
  actionCreator: removePlan,
  effect: (_action, listenerApi) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    listenerApi.dispatch(setTooltipText("Removed Plan"));

    timer = setTimeout(() => {
      listenerApi.dispatch(setTooltipText(null));
    }, 3000);
  },
});
