import * as React from "react";

import { Box, Paper, styled, Divider, alpha } from "@mui/material";

interface BackgroundProps {
  maxwidth?: string | number;
}
const Background = styled(Paper)<BackgroundProps>(({ maxwidth }) => ({
  width: "100%",
  maxWidth: maxwidth,
  height: 90,
  borderRadius: 4,
  overflow: "hidden",

  display: "flex",
  justifyContent: "stretch",
  alignItems: "stretch",
}));

interface TabProps {
  active: boolean;
  subtitle?: string;
}
const Tab = styled(Box)<TabProps>(({ theme, active, subtitle }) => ({
  height: 90,
  flex: "2 1 auto",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  fontSize: 14,
  fontWeight: active ? 600 : 500,
  cursor: "pointer",
  textAlign: "center",

  position: "relative",

  "&::before": {
    content: `'${subtitle ?? ""}'`,
    position: "absolute",
    top: 0,
    left: "50%",
    marginLeft: "-60px",
    background: active
      ? theme.palette.primary.main
      : alpha(theme.palette.primary.main, 0.15),
    width: subtitle ? "120px" : "0px",
    height: subtitle ? "21px" : "0px",
    borderRadius: "0px 0px 10px 10px",
    color: active ? "#fff" : theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 12,
    transition: "color 0.3s, background 0.3s",
  },

  "&::after": {
    content: "''",
    position: "absolute",
    bottom: 0,
    left: 0,
    background: theme.palette.primary.main,
    width: "100%",
    height: active ? "8px" : "0px",
    transition: "height 0.3s",
  },
}));

interface RootProps {
  currentValue: number;
  handleChange: (newValue: any) => void;
  tabs: Array<{
    title: string;
    subtitle?: string;
    value: string | number;
    id: string;
  }>;
  maxWidth?: string | number;
  elevation?: number;
}
export default function Tabs({
  tabs,
  currentValue,
  handleChange,
  maxWidth,
  elevation,
}: RootProps) {
  return (
    <Background elevation={elevation ?? 0} maxwidth={maxWidth}>
      {tabs.map((tab, index) => {
        return (
          <React.Fragment key={tab.id}>
            <Tab
              active={currentValue === tab.value}
              subtitle={tab.subtitle}
              onClick={() => {
                handleChange(tab.value);
              }}
            >
              {tab.title}
            </Tab>
            {index + 1 < tabs.length && (
              <Divider
                orientation="vertical"
                style={{ height: 50, alignSelf: "center" }}
              />
            )}
          </React.Fragment>
        );
      })}
    </Background>
  );
}
