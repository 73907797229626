import * as React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { useGetPlanByIDQuery } from "../../../redux/services/API";

import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Slide, { SlideProps } from "@mui/material/Slide";
import Skeleton from "@mui/material/Skeleton";

import Cancel from "@mui/icons-material/Cancel";

import MapdComparison from "./Comparisons/Mapd.Comparison";
import MaComparison from "./Comparisons/Ma.Comparison";
import PdpComparison from "./Comparisons/Pdp.Comparison";
import MedSuppComparison from "./Comparisons/MedSupp.Comparison";

import { ANALYTICS_EVENT } from "../../../config/analytics.config";

import { ComparePlan, Plan, PlanType } from "../../../types/Plan.types";

import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";

const Transition = React.forwardRef(function Transition(
  props: SlideProps & {
    children?: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      {...props}
      ref={ref}
      direction="up"
      mountOnEnter
      unmountOnExit
      exit
    />
  );
});

interface RootProps {
  open: boolean;
  handleClose: () => void;
  comparePlans: ComparePlan[];
  handleDrugCosts: (plan: Plan) => void;
  handleNavigate: (location: "doctors" | "medicines") => void;
}

export default function PlanComparison({
  open,
  handleClose,
  comparePlans,
  handleDrugCosts,
  handleNavigate,
}: RootProps) {
  const analytics = useRudderStackAnalytics();

  const { SessionID, Location, QuoteID } = useAppSelector(
    (state) => state.Quote
  );
  const DoctorNetworks = useAppSelector((state) => state.Plan.DoctorNetworks);

  const {
    data: planOneData,
    isFetching: isFetchingPlanOne,
    isError: isErrorPlanOne,
  } = useGetPlanByIDQuery(
    {
      SessionID: SessionID ?? "",
      PlanID: comparePlans[0].ID ?? "",
      Zip: Location?.ZipCode ?? "",
      Fips: Location?.CountyFIPS ?? "",
    },
    {
      skip: !comparePlans[0] || !SessionID,
    }
  );

  const {
    data: planTwoData,
    isFetching: isFetchingPlanTwo,
    isError: isErrorPlanTwo,
  } = useGetPlanByIDQuery(
    {
      SessionID: SessionID ?? "",
      PlanID: comparePlans[1].ID ?? "",
      Zip: Location?.ZipCode ?? "",
      Fips: Location?.CountyFIPS ?? "",
    },
    {
      skip: !comparePlans[1] || !SessionID,
    }
  );

  const planOne = planOneData?.data;
  const planTwo = planTwoData?.data;

  const handleEditDoctors = () => {
    handleNavigate("doctors");
  };

  const handleEditDrugs = () => {
    handleNavigate("medicines");
  };

  const handleShowDrugCosts = (plan: Plan) => {
    handleDrugCosts(plan);
    handleDialogClose();
  };

  const generateComparison = (planType: PlanType) => {
    if (!planOne || !planTwo) {
      return <></>;
    }

    analytics?.track(ANALYTICS_EVENT.PLAN_COMPARE_DIALOG_OPENED, {
      quote_id: QuoteID,
      numPlans: 2,
      plans: [
        {
          contract_id: planOne.ContractID,
          plan_id: planOne.PlanID,
          segment_id: planOne.SegmentID,
          plan_year: planOne.PlanYear,
          carrier_id: planOne.CarrierName,
          snp_type: planOne.SNPType,
          plan_type: planOne.PlanType,
        },
        {
          contract_id: planTwo.ContractID,
          plan_id: planTwo.PlanID,
          segment_id: planTwo.SegmentID,
          plan_year: planTwo.PlanYear,
          carrier_id: planTwo.CarrierName,
          snp_type: planTwo.SNPType,
          plan_type: planTwo.PlanType,
        },
      ],
    });

    switch (planType) {
      case PlanType.MAPD:
        return (
          <MapdComparison
            planOne={planOne}
            planTwo={planTwo}
            doctors={DoctorNetworks}
            handleShowDrugCosts={handleShowDrugCosts}
            handleEditDoctors={handleEditDoctors}
            handleEditDrugs={handleEditDrugs}
          />
        );

      case PlanType.MA:
        return (
          <MaComparison
            planOne={planOne}
            planTwo={planTwo}
            doctors={DoctorNetworks}
            handleEditDoctors={handleEditDoctors}
          />
        );

      case PlanType.PDP:
        return (
          <PdpComparison
            planOne={planOne}
            planTwo={planTwo}
            handleShowDrugCosts={handleShowDrugCosts}
            handleEditDrugs={handleEditDrugs}
          />
        );

      case PlanType.MEDSUPP:
        return <MedSuppComparison planOne={planOne} planTwo={planTwo} />;

      default:
        return <></>;
    }
  };

  const handleDialogClose = () => {
    analytics?.track(ANALYTICS_EVENT.PLAN_COMPARE_DIALOG_CLOSED, {
      quote_id: QuoteID,
      numPlans: 2,
      plans: [
        {
          contract_id: planOne?.ContractID,
          plan_id: planOne?.PlanID,
          segment_id: planOne?.SegmentID,
          plan_year: planOne?.PlanYear,
          carrier_id: planOne?.CarrierName,
          snp_type: planOne?.SNPType,
          plan_type: planOne?.PlanType,
        },
        {
          contract_id: planTwo?.ContractID,
          plan_id: planTwo?.PlanID,
          segment_id: planTwo?.SegmentID,
          plan_year: planTwo?.PlanYear,
          carrier_id: planTwo?.CarrierName,
          snp_type: planTwo?.SNPType,
          plan_type: planTwo?.PlanType,
        },
      ],
    });

    handleClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => handleDialogClose()}
      scroll="paper"
      maxWidth="md"
      PaperProps={{
        sx: {
          backgroundColor: "#f4f7fa",
          display: "flex",
          alignItems: "center",
          py: 5,
        },
      }}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          title="Close"
          onClick={() => handleDialogClose()}
          sx={{
            position: "absolute",
            right: (theme) => theme.spacing(2),
            top: (theme) => theme.spacing(2),
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Cancel fontSize="large" color="error" />
        </IconButton>
      </DialogTitle>

      <Paper
        sx={{
          border: "2px solid #efefef",
          borderRadius: 5,
          px: { xs: 1, md: 2 },
          py: 2,
          width: { xs: "100%", sm: "90%" },
          maxWidth: "md",
        }}
      >
        <Grid container direction="column" rowGap={4}>
          {isFetchingPlanOne || isFetchingPlanTwo ? (
            <Grid item container justifyContent="center">
              <Skeleton
                variant="rectangular"
                height={600}
                sx={{ width: { xs: 300, sm: 500, md: 600 } }}
              />
            </Grid>
          ) : isErrorPlanOne || isErrorPlanTwo ? (
            <Typography>Error: Fetching Plans</Typography>
          ) : !planOne || !planTwo ? (
            <Typography>Error: Plans not Found</Typography>
          ) : (
            generateComparison(planOne.PlanType)
          )}
        </Grid>
      </Paper>
    </Dialog>
  );
}
