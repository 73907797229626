import * as React from "react";
import { useAppSelector } from "../../../redux/hooks";
import {
  useDeletePharmacyMutation,
  useSavePharmacyMutation,
  useSearchPharmaciesQuery,
} from "../../../redux/services/API";
import { useDebounce } from "use-debounce";

import {
  Divider,
  Grid,
  Pagination,
  Skeleton,
  Stack,
  Typography,
  Paper,
  Avatar,
  alpha,
  Box,
  Chip,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import CheckCircle from "@mui/icons-material/CheckCircle";

import SearchField from "../../shared/SearchField";
import PharmacySelect from "./PharmacySelect";
import Map, { MarkersInfo } from "../../shared/Map";

import { ANALYTICS_EVENT } from "../../../config/analytics.config";

import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";

const PHARMACY_FILTERS = [
  {
    id: "CVS",
    title: "CVS Pharmacy",
    icon: "/images/cvs-heart.ico",
  },
  {
    id: "Walgreens",
    title: "Walgreens",
    icon: "/images/walgreens-w.ico",
  },
];

const RADIUS_OPTIONS = [2, 3, 4, 5, 10, 20];

export default function Pharmacy() {
  const {
    Location: location,
    Pharmacy: selectedPharmacy,
    SessionID,
    QuoteID,
  } = useAppSelector((state) => state.Quote);

  const analytics = useRudderStackAnalytics();

  const [page, setCurrentPage] = React.useState(1);
  const [total, setTotal] = React.useState<number>();
  const [radius, setRadius] = React.useState(5);
  const [markers, setMarkers] = React.useState<MarkersInfo>({
    PageNum: 0,
    Markers: [],
  });
  const [pharmacySearch, setPharmacySearch] = React.useState("");
  const [filters, setFilters] = React.useState<string[]>([]);

  const [debouncedSearchQuery] = useDebounce(pharmacySearch, 750);

  const {
    currentData: data,
    isLoading,
    isFetching,
  } = useSearchPharmaciesQuery(
    {
      zipCode: location?.ZipCode ?? "",
      radiusInMiles: radius,
      page,
      search: debouncedSearchQuery,
    },
    {
      skip: location === null,
    }
  );
  const [
    savePharmacy,
    { reset: resetSavePharmacy, isLoading: isSavingPharmacy },
  ] = useSavePharmacyMutation();
  const [
    deletePharmacy,
    { reset: resetDeletePharmacy, isLoading: isDeletingPharmacy },
  ] = useDeletePharmacyMutation();

  React.useEffect(() => {
    if (!data) {
      return;
    }
    if (page === 1) {
      setTotal(data?.meta.totalPages);
    }

    const newMarkersInfo: MarkersInfo = {
      PageNum: page,
      Markers: [],
    };
    for (let pharmacy of data?.data) {
      const coordinates = [pharmacy.Longitude, pharmacy.Latitude];
      newMarkersInfo.Markers.push({
        Coordinates: coordinates,
        Name: pharmacy.Name,
        Address: `${pharmacy.Address1}${
          pharmacy.Address2 ? ` ${pharmacy.Address2}` : ""
        }, ${pharmacy.City}, ${pharmacy.State} ${pharmacy.Zip}`,
      });
    }
    setMarkers(newMarkersInfo);
  }, [data, page]);

  React.useEffect(() => {
    if (!isFetching) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.SEARCH_STARTED, {
      quote_id: QuoteID,
      search_type: "PHARMACY",
      search_keyword: debouncedSearchQuery,
      search_filters: [
        {
          type: "Radius",
          value: radius,
        },
      ],
      search_sorts: [{ type: "RANK", value: "desc" }],
      search_meta: [
        {
          type: "page",
          value: page,
        },
        { type: "per", value: 5 },
      ],
    });
  }, [isFetching]);

  React.useEffect(() => {
    if (!data) {
      return;
    }
    analytics?.track(ANALYTICS_EVENT.SEARCH_RESULTS_RETURNED, {
      quote_id: QuoteID,
      search_type: "PHARMACY",
      search_keyword: debouncedSearchQuery,
      search_filters: [
        {
          type: "Radius",
          value: radius,
        },
      ],
      search_sorts: [{ type: "RANK", value: "desc" }],
      search_meta: [
        {
          type: "page",
          value: page,
        },
        { type: "per", value: 5 },
      ],
      search_result_count: data.meta.numRecords,
    });
  }, [data]);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearchQuery]);

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  const handleRadius = (event: SelectChangeEvent<number>) => {
    setRadius(event.target.value as number);
  };

  const handleSelectPharmacy = (index: number, selected: boolean) => {
    if (!data) {
      return;
    }

    const pharmacy = data.data[index];

    analytics?.track(ANALYTICS_EVENT.SEARCH_RESULT_CLICKED, {
      quote_id: QuoteID,
      search_type: "PHARMACY",
      search_keyword: debouncedSearchQuery,
      search_filters: [
        {
          type: "Radius",
          value: radius,
        },
      ],
      search_sorts: [{ type: "RANK", value: "desc" }],
      search_meta: [
        {
          type: "page",
          value: page,
        },
        { type: "per", value: 5 },
      ],
      search_result_count: data?.meta.numRecords,
      search_result_position_clicked:
        (data.meta.page - 1) * data.meta.per + index,
      search_result_clicked_id: pharmacy.PharmacyNPI,
    });

    if (selected) {
      deletePharmacy({
        SessionID: SessionID ?? "",
        PharmacyRecordID: selectedPharmacy?.PharmacyRecordID
          ? selectedPharmacy.PharmacyRecordID
          : -10,
      });
      resetDeletePharmacy();

      analytics?.track(ANALYTICS_EVENT.QUOTE_PHARMACY_REMOVED, {
        quote_id: QuoteID,
        pharmacy_name: selectedPharmacy?.Name,
        pharmacy_npi: pharmacy.PharmacyNPI,
        pharmacy_id: selectedPharmacy?.PharmacyID,
        pharmacy_address: selectedPharmacy?.Address1,
        pharmacy_address_2: selectedPharmacy?.Address2,
        pharmacy_city: selectedPharmacy?.City,
        pharmacy_state: selectedPharmacy?.State,
        pharmacy_zip_code: selectedPharmacy?.Zip,
      });
      return;
    }

    savePharmacy({
      SessionID: SessionID ?? "",
      PharmacyID: pharmacy.PharmacyID,
      IsMailOrder: false,
    });
    resetSavePharmacy();

    analytics?.track(ANALYTICS_EVENT.QUOTE_PHARMACY_ADDED, {
      quote_id: QuoteID,
      pharmacy_name: pharmacy.Name,
      pharmacy_npi: pharmacy.PharmacyNPI,
      pharmacy_id: pharmacy.PharmacyID,
      pharmacy_address: pharmacy.Address1,
      pharmacy_address_2: pharmacy.Address2,
      pharmacy_city: pharmacy.City,
      pharmacy_state: pharmacy.State,
      pharmacy_zip_code: pharmacy.Zip,
    });
  };

  const handlePharmacySearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setPharmacySearch(value);
  };

  const handleFilterClick = (id: string) => {
    if (filters.includes(id)) {
      return;
    }
    setPharmacySearch(id);
    setCurrentPage(1);
    //TODO: Add Multi Search for Filter By Pharmacy
    setFilters([id]);
  };

  const handleFilterRemove = (id: string) => {
    setPharmacySearch("");
    setCurrentPage(1);

    const newFilters = filters.filter((i) => i !== id);
    setFilters(newFilters);
  };

  return (
    <Grid container rowGap={2} pb={{ xs: 4, md: 2 }}>
      <Grid item xs={12} height={300}>
        <Map
          handleRadius={handleRadius}
          radius={radius}
          markers={markers}
          radiusOptions={RADIUS_OPTIONS}
          initialCoordinates={location?.Coordinates}
          zipCode={location?.ZipCode ?? ""}
        />
      </Grid>

      <Grid item xs={12} md={8} lg={7} xl={6} container justifyContent="center">
        <Paper
          sx={{
            width: "100%",
            maxWidth: "750px",
            borderRadius: { xs: 0, sm: 5 },
            px: { xs: 1, sm: 2 },
            py: 2,
          }}
        >
          <Grid container direction="column" gap={2}>
            <Grid item>
              <SearchField
                placeholder="Search by Pharmacy's Name"
                value={pharmacySearch}
                handleChange={handlePharmacySearch}
              />
            </Grid>

            <Grid item container gap={1}>
              <Typography fontWeight={500} sx={{ textDecoration: "underline" }}>
                Filters:
              </Typography>
              {filters.map((filter) => {
                return (
                  <Chip
                    key={`filter-active-${filter}`}
                    color="secondary"
                    variant="outlined"
                    label={filter}
                    clickable
                    onDelete={() => handleFilterRemove(filter)}
                    sx={{
                      fontWeight: 500,
                      "& .MuiChip-deleteIcon": {
                        color: "red",
                        "&:hover": {
                          color: "red",
                          filter: "brightness(.9)",
                        },
                      },
                    }}
                  />
                );
              })}
            </Grid>

            <Grid item>
              <Stack divider={<Divider flexItem />} alignItems="center">
                <Typography
                  variant="h5"
                  fontWeight={600}
                  sx={{ alignSelf: "flex-start" }}
                >
                  Search Results
                </Typography>
                {!isFetching &&
                  data?.data.map((pharmacy, index) => {
                    return (
                      <PharmacySelect
                        key={pharmacy.PharmacyID}
                        info={pharmacy}
                        index={index}
                        handleSelect={handleSelectPharmacy}
                        selected={
                          selectedPharmacy?.PharmacyID === pharmacy.PharmacyID
                        }
                        loading={isSavingPharmacy || isDeletingPharmacy}
                      />
                    );
                  })}

                {isFetching &&
                  [0, 1, 2, 3, 4, 5].map((loading) => {
                    return (
                      <Skeleton
                        key={`loading-pharmacy-${loading}`}
                        variant="rectangular"
                        sx={{ width: { xs: 350, sm: 600 }, my: 0.5 }}
                        height={60}
                      />
                    );
                  })}

                {isLoading ? (
                  <div
                    style={{
                      display: "inline-flex",
                      columnGap: 3,
                      alignSelf: "center",
                      margin: ".5rem",
                    }}
                  >
                    <Skeleton variant="circular" width={32} height={32} />
                    <Skeleton variant="circular" width={32} height={32} />
                    <Skeleton variant="circular" width={32} height={32} />
                    <Skeleton variant="circular" width={32} height={32} />
                  </div>
                ) : (
                  total && (
                    <Pagination
                      page={page}
                      count={total}
                      variant="outlined"
                      color="secondary"
                      sx={{ alignSelf: "center", mt: 2 }}
                      onChange={handleChangePage}
                    />
                  )
                )}
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4} lg={5} xl={6} container justifyContent="center">
        <Paper
          sx={{
            borderRadius: { xs: 0, sm: 5 },
            p: 2,
            width: { xs: "100%", sm: "95%" },
            maxWidth: "560px",
            height: "300px",
          }}
        >
          <Stack divider={<Divider flexItem />} alignItems="center">
            <Typography
              variant="h5"
              fontWeight={600}
              mb={1}
              sx={{ alignSelf: "flex-start" }}
            >
              Filter by Pharmacy
            </Typography>
            {PHARMACY_FILTERS.map((pharmacyFilter) => {
              return (
                <Box
                  key={`filter-${pharmacyFilter.id}`}
                  sx={{
                    width: "100%",
                    py: 1,
                    px: 0.5,
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: (theme) =>
                        alpha(theme.palette.secondary.main, 0.12),
                    },
                  }}
                  onClick={() => handleFilterClick(pharmacyFilter.id)}
                >
                  <Grid container gap={1} alignItems="center">
                    <Grid item xs={2} md={2}>
                      <Avatar
                        src={pharmacyFilter.icon}
                        alt="P"
                        sx={{ border: "2px solid #cfcfcf", p: 0.5 }}
                      />
                    </Grid>
                    <Grid item xs={8} md={7}>
                      <Typography>{pharmacyFilter.title}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      md={2}
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                      gap={1}
                    >
                      {filters.includes(pharmacyFilter.id) && (
                        <CheckCircle fontSize="large" color="primary" />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
}
