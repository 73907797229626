import {
  MedSuppPlanDetails,
  MedSuppQuote,
  MED_SUPP_PLANS,
} from "../types/MedSupp.types";
import CostFromString from "./CostFromString";
import { formatUSD } from "./formatUSD";

export function getPartADeductible(
  details: MedSuppPlanDetails,
  planName: MED_SUPP_PLANS
) {
  if (planName === MED_SUPP_PLANS.HDF || planName === MED_SUPP_PLANS.HDG) {
    return formatUSD(Number(details.deductible));
  }

  return CostFromString(details["Part A"]["Hospitalization"][0][3]);
}

export function getPartBDeductible(details: MedSuppPlanDetails) {
  return CostFromString(details["Part B"]["Medical Expenses"][0][3]);
}

export function getOtherCoverage(details: MedSuppPlanDetails) {
  return details["Part B"]["Medical Expenses"][1][3];
}

export function calculateMarketShare(plan: MedSuppQuote) {
  const state = plan.location_base.state;
  const data = plan.company_base.med_supp_state_market_data.find(
    (marketData) => marketData.state === state
  );

  if (!data) {
    return "N/A";
  }

  return `${(data.market_share * 100.0).toFixed(1)}%`;
}

export function generateMedSuppPlanName(plan: MED_SUPP_PLANS) {
  switch (plan) {
    case MED_SUPP_PLANS.HDF:
      return `High Deductible Plan F`;

    case MED_SUPP_PLANS.HDG:
      return `High Deductible Plan G`;

    default:
      return `Plan ${plan}`;
  }
}
