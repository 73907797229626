/**
 * Pharmacy Object sent from API
 */
export interface PharmacyInfo {
  /** Unique ID of Specific Pharmacy at Address */
  _id: string;

  /** Unique NPI of Pharmacy */
  NPI: string;

  /** Pharmacy Name */
  OrgName: string;

  Address: {
    Address1: string;
    Address2?: string;
    City: string;
    State: string;
    ZipCode: string;
    PhoneNumber: string;
  };
}

/**
 * Data sent over to the API to get the Pharmacy
 */
export interface PharmacyBody {
  /** Coordinates of the User's Zip Code */
  Coordinates: Array<number>;

  /** Number of Entries per Page */
  Size: number;

  /** Number of Page being fetched */
  PageNum: number;

  /** Radius around the Coordinates to look for Pharmacy */
  Radius: number;

  /** (optional) Search Pharmacy by OrgName */
  SearchQuery?: string;
}

/**
 * Data sent back from the API to get the Pharmacy
 */
export type PharmacyResponse = {
  /** Pharmacies in the User's Area */
  data: PharmacyInfo[];

  /** Request was successful */
  success: boolean;

  /** Total Pages of Pharmacy (only returned when querying for PageNum: 1) */
  TotalPages?: number;
};

export interface Pharmacy {
  PharmacyID: string;
  PharmacyNPI: string;
  Name: string;
  Address1: string;
  Address2: string;
  City: string;
  State: string;
  Zip: string;
  Distance: number;
  PharmacyPhone: string;
  Latitude: number;
  Longitude: number;
  Chain: string;
  IsSpecialty: boolean;
  PharmacyServices: {
    Has24hrService: boolean;
    HasCompounding: boolean;
    HasDelivery: boolean;
    HasDriveup: boolean;
    HasDurableEquipment: boolean;
    HasEPrescriptions: boolean;
    HasHandicapAccess: boolean;
    IsHomeInfusion: boolean;
    IsLongTermCare: boolean;
  };
}

export interface SelectedPharmacy {
  PharmacyRecordID?: number;
  PharmacyID: string;
  IsMailOrder: boolean;
  Name: string;
  Address1: string;
  Address2: string;
  City: string;
  Zip: string;
  State: string;
  PharmacyPhone: string;
}

export interface PharmacySearchResponse {
  data: Pharmacy[];
  meta: {
    page: number;
    per: number;
    totalPages: number;
    numRecords: number;
  };
}

export enum PHARMACY_TYPE {
  RETAIL = 0,
  MAILORDER = 1,
}
