import * as React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import { styled, alpha } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { Plan, PlanType } from "../../../types/Plan.types";
import { Divider } from "@mui/material";
import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";
import { useAppSelector } from "../../../redux/hooks";

export const SECTIONS: { [key: number]: string[] } = {
  [PlanType.MA]: [
    "plan-highlights",
    "doctor-coverage",
    "inpatient-care",
    "supplemental-benefits",
    "plan-documents",
  ],
  [PlanType.MAPD]: [
    "plan-highlights",
    "doctor-coverage",
    "inpatient-care",
    "supplemental-benefits",
    "prescription-benefits",
    "plan-documents",
  ],
  [PlanType.PDP]: [
    "plan-highlights",
    "prescription-benefits",
    "plan-documents",
  ],
};

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  fontSize: "1.1rem",
  fontWeight: 500,
  "&.Mui-selected": {
    backgroundColor: "transparent",
    fontWeight: 600,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
}));
interface SectionScrollerProps {
  active: boolean;
  sectionID: string;
  title: string;
  handleClick: (id: string) => void;
  index: number;
}
export function SectionScroller({
  active,
  sectionID,
  title,
  handleClick,
  index,
}: SectionScrollerProps) {
  return (
    <StyledListItem
      key={sectionID}
      id={sectionID}
      selected={active}
      disableGutters
      onClick={() => handleClick(sectionID)}
    >
      <ListItemIcon
        sx={{
          justifyContent: "center",
          color: active ? "secondary.main" : "#c4c4c4",
        }}
      >
        {index}
      </ListItemIcon>
      {title}
    </StyledListItem>
  );
}

export interface SubSection {
  title: string;
  subtitle?: string;
  value: string | number | undefined | null | React.ReactElement;
  icon?: React.ReactElement;
}

export function SectionTitle({
  id,
  title,
  subtitle,
  icon,
}: {
  id: string;
  title: string;
  subtitle: string;
  icon: React.ReactElement;
}) {
  return (
    <Grid item xs={12} display="inline-flex" id={`${id}-section`}>
      {icon}
      <div>
        <Typography variant="h5" fontWeight={700}>
          {title}
        </Typography>
        <Typography>{subtitle}</Typography>
      </div>
    </Grid>
  );
}

export function RegularBenefit({
  title,
  value,
}: {
  title: string;
  value: string | number | undefined;
}) {
  return (
    <Grid item container justifyContent="space-between">
      <Grid item>
        <Typography fontWeight={500}>{title}</Typography>
      </Grid>
      <Grid
        item
        sx={{
          borderBottom: "1.5px solid #efefef",
          flexGrow: 1,
          mx: 1,
        }}
      />
      <Grid item>
        <Typography fontWeight={600}>{value ?? "Not Covered"}</Typography>
      </Grid>
    </Grid>
  );
}

export function SubBenefits({
  title,
  icon,
  subsections,
}: {
  title: string;
  icon?: React.ReactElement;
  subsections: SubSection[];
}) {
  return (
    <Grid item container>
      <Grid item>
        <Typography display="inline-flex" fontWeight={600} gap={1}>
          {title}
          {icon}
        </Typography>
      </Grid>
      {subsections &&
        subsections.map((item) => {
          return (
            <Grid
              key={item.title}
              item
              container
              justifyContent="space-between"
              pl={4}
            >
              <Grid item>
                <Typography fontWeight={500}>{item.title}</Typography>
              </Grid>
              <Grid
                item
                sx={{ borderBottom: "1.5px solid #efefef", flexGrow: 1, mx: 1 }}
              />
              <Grid item display="inline-flex">
                {item.icon ? item.icon : null}
                {React.isValidElement(item.value) ? (
                  item.value
                ) : (
                  <Typography fontWeight={600}>
                    {(item.value as string) ?? "Not Covered"}
                  </Typography>
                )}
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
}

export function BenefitTable({
  sections,
}: {
  sections: Array<{
    title: string;
    subsections: SubSection[];
  }>;
}) {
  return (
    <Grid container direction="column" rowGap={2}>
      {sections &&
        sections.map((section, index) => {
          return (
            <React.Fragment key={`${section.title}`}>
              <Grid item>
                <Typography fontWeight={600} variant="h6">
                  {section.title}
                </Typography>
              </Grid>
              <Grid
                item
                container
                gap={2}
                pl={4}
                pb={2}
                sx={{
                  borderBottom:
                    index === sections.length - 1
                      ? "none"
                      : "2px solid #efefef",
                }}
              >
                {section.subsections &&
                  section.subsections.map((item) => {
                    return (
                      <React.Fragment
                        key={`${section.title}-sub-${item.title}`}
                      >
                        <Grid item sm={3} xs={12}>
                          <Typography variant="body2" fontWeight={500}>
                            {item.icon ? item.icon : null}
                            {item.title}
                          </Typography>
                          {item.subtitle && (
                            <Typography variant="body2">
                              {item.subtitle}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item sm={8} xs={12}>
                          {React.isValidElement(item.value) ? (
                            item.value
                          ) : (
                            <Typography variant="body2">
                              {(item.value as string) ?? "Not Covered"}
                            </Typography>
                          )}
                        </Grid>
                      </React.Fragment>
                    );
                  })}
              </Grid>
            </React.Fragment>
          );
        })}
    </Grid>
  );
}

interface PlanDocument {
  title: string;
  link: string;
}

export function PlanDocuments({
  documents,
  plan,
}: {
  documents: PlanDocument[];
  plan: Plan;
}) {
  const analytics = useRudderStackAnalytics();
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const handleTracking = (document: PlanDocument) => {
    analytics?.track(ANALYTICS_EVENT.EXIT_LINK_CLICKED, {
      quote_id: QuoteID,
      link_text: document.title,
      link_href: document.link,
    });

    analytics?.track(ANALYTICS_EVENT.PLAN_DOCUMENT_VIEWED, {
      quote_id: QuoteID,
      contract_id: plan.ContractID,
      plan_id: plan.PlanID,
      segment_id: plan.SegmentID,
      plan_year: plan.PlanYear,
      carrier_id: plan.CarrierName,
      snp_type: plan.SNPType,
      plan_type: plan.PlanType,
      document_url: document.link,
      document_type: "DRX",
      document_name: document.title,
    });
  };

  return (
    <>
      {documents.map((document) => {
        return (
          <Button
            key={`${document.title}`}
            href={document.link}
            target="_blank"
            rel="noopener noreferrer"
            variant="text"
            color="primary"
            startIcon={<PictureAsPdfIcon />}
            onClick={() => {
              handleTracking(document);
            }}
          >
            {document.title}
          </Button>
        );
      })}
    </>
  );
}

export function generateSubsection(
  plan: Plan,
  fields: Array<{
    id: string;
    title: string;
    type?: "InNetwork" | "OutOfNetwork";
  }>
) {
  const subsections: SubSection[] = [];

  if (!plan) {
    return subsections;
  }

  fields.forEach((field) => {
    const value = plan.PlanDetail?.[field.type ?? "InNetwork"][field.id];
    if (value) {
      const newSubsection: SubSection = {
        title: field.title,
        value,
      };
      subsections.push(newSubsection);
    }
  });

  return subsections;
}

export const DetailsAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const DetailsAccordionSummary = styled(
  (props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<KeyboardArrowDownIcon fontSize="large" />}
      {...props}
    />
  )
)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: alpha(theme.palette.primary.main, 0.25),
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: theme.spacing(1),
  },
  fontWeight: 700,
}));

export const DetailsAccordionDetails = styled(MuiAccordionDetails)(
  ({ theme }) => ({
    padding: theme.spacing(1),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  })
);

export function MedSuppBenefitTable({
  sections,
}: {
  sections: Array<{
    title: string;
    subsections: SubSection[];
  }>;
}) {
  return (
    <Grid container direction="column" rowGap={2}>
      {sections &&
        sections.map((section) => {
          return (
            <React.Fragment key={`${section.title}`}>
              <Grid item>
                <Typography fontWeight={600} variant="h6">
                  {section.title}
                </Typography>
              </Grid>
              <Grid item container gap={2} pl={4} pb={2}>
                {section.subsections &&
                  section.subsections.map((item, index) => {
                    return (
                      <React.Fragment
                        key={`${section.title}-sub-${item.title}`}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            fontWeight={500}
                            color="primary"
                          >
                            {item.icon ? item.icon : null}
                            {item.title}
                          </Typography>
                          {item.subtitle && (
                            <Typography variant="body2">
                              {item.subtitle}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          {React.isValidElement(item.value) ? (
                            item.value
                          ) : (
                            <Typography variant="body2">
                              {(item.value as string) ?? "Not Covered"}
                            </Typography>
                          )}
                        </Grid>

                        {index < section.subsections.length - 1 && (
                          <Grid item sm={8} xs={12}>
                            <Divider flexItem />
                          </Grid>
                        )}
                      </React.Fragment>
                    );
                  })}
              </Grid>
            </React.Fragment>
          );
        })}
    </Grid>
  );
}
