import * as React from "react";

import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";

import { Location } from "../../types/Location.types";

interface RootProps {
  open: boolean;
  handleCountyClick: (index: number) => void;
  countiesData: Location[];
}

export default function CountyDialog({
  open,
  handleCountyClick,
  countiesData,
}: RootProps) {
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      PaperProps={{ sx: { minHeight: 300 } }}
    >
      <IconButton
        aria-label="close"
        onClick={() => handleCountyClick(-1)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#000",
        }}
      >
        <Close fontSize="large" />
      </IconButton>
      <Grid container gap={2} p={4} justifyContent="center">
        <Grid item xs={12} textAlign={{ xs: "center", sm: "left" }}>
          <Typography variant="h5" fontWeight={600}>
            Select Your County
          </Typography>
        </Grid>

        {countiesData.map((county, index) => {
          return (
            <Grid
              key={`county-option-${county.CountyName}`}
              item
              xs={12}
              sm={10}
              container
              justifyContent="center"
              alignContent="center"
              border="2px solid #efefef"
              borderRadius={2}
              p={2}
              onClick={() => handleCountyClick(index)}
              sx={{ cursor: "pointer" }}
            >
              <Typography variant="h6" fontWeight={500}>
                {county.CountyName}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Dialog>
  );
}
