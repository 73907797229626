import * as React from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setEnrollPlan } from "../../../../redux/reducers/Enrollment";
import { setActiveStep } from "../../../../redux/reducers/Quote";
import {
  useDeletePlanMutation,
  useGetPlanByIDQuery,
  useSavePlanMutation,
} from "../../../../redux/services/API";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import ArrowBack from "@mui/icons-material/ArrowBack";

import DisclosureDialog from "../../DisclosureDialog";
import DrugCostsDialog from "../../DrugCostsDialog";
import { SECTIONS, SectionScroller } from "../DetailComponents";
import MapdDetails from "../Details/MapdDetails";
import MaDetails from "../Details/MaDetails";
import PpdDetails from "../Details/PdpDetails";

import toProperCase from "../../../../utils/toProperCase";
import { useRudderStackAnalytics } from "../../../../utils/useRudderStackAnalytics";
import useNavigateParams from "../../../../utils/useNavigateParams";

import {
  EnrollPlan,
  PlanFavorite,
  PlanType,
} from "../../../../types/Plan.types";
import { STEPS } from "../../../../types/Global.types";
import { ANALYTICS_EVENT } from "../../../../config/analytics.config";

export default function PlanDetails() {
  const navigate = useNavigateParams();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const analytics = useRudderStackAnalytics();

  const { FavoritePlans, SessionID, Location, QuoteID } = useAppSelector(
    (state) => state.Quote
  );
  const DoctorNetworks = useAppSelector((state) => state.Plan.DoctorNetworks);

  const { data, isFetching, isError } = useGetPlanByIDQuery(
    {
      SessionID: SessionID ?? "",
      PlanID: id ?? "",
      Zip: Location?.ZipCode ?? "",
      Fips: Location?.CountyFIPS ?? "",
    },
    {
      skip: !id || !SessionID,
    }
  );

  const plan = data?.data;

  const [savePlan, { reset: resetSavePlan }] = useSavePlanMutation();
  const [deletePlan, { reset: resetDeletePlan }] = useDeletePlanMutation();

  const isFavoritePlan = React.useMemo(() => {
    if (!plan) {
      return false;
    }
    return FavoritePlans.findIndex((favPlan) => plan.ID === favPlan.ID) > -1;
  }, [FavoritePlans, plan]);

  const [currentSection, setCurrentSection] = React.useState<string>();
  const [openDisclosures, setOpenDisclosures] = React.useState(false);
  const [openDrugCosts, setOpenDrugCosts] = React.useState(false);

  const trigger = useScrollTrigger({ disableHysteresis: true });

  const handleScrollerClick = (id: string) => {
    const divElement = document.getElementById(`${id}-section`);
    divElement?.scrollIntoView({ behavior: "smooth" });
  };

  const handleEnroll = () => {
    if (!plan) {
      return;
    }

    const enrollPlan: EnrollPlan = {
      PlanName: plan.PlanName,
      ContractID: plan.ContractID,
      PlanID: plan.PlanID,
      SegmentID: plan.SegmentID,
      Carrier: plan.CarrierName,
      PlanType: plan.PlanType,
      DrxID: plan.ID,
      Year: Number(plan.PlanYear?.trim()) || 0,
      LogoURL: plan.LogoURL,
      QuotedPremium: plan.MedicalPremium + plan.DrugPremium,
      PlanRating: plan.PlanRating,
      SnpType: plan.SNPType,
    };

    dispatch(setEnrollPlan(enrollPlan));

    analytics?.track(ANALYTICS_EVENT.ENROLL_NOW_CLICKED, {
      quote_id: QuoteID,
      contract_id: plan.ContractID,
      plan_id: plan.PlanID,
      segment_id: plan.SegmentID,
      plan_year: plan.PlanYear,
      carrier_id: plan.CarrierName,
      snp_type: plan.SNPType,
      plan_type: plan.PlanType,
    });

    navigate("/input/confirm");
  };

  const handleDisclosureClose = () => {
    setOpenDisclosures(false);
  };

  const handleBackToPlans = () => {
    analytics?.track(ANALYTICS_EVENT.URL_BUTTON_CLICKED, {
      quote_id: QuoteID,
      link_text: "Back to Plans",
      link_url: "/input/compare",
    });

    dispatch(setActiveStep(STEPS.COMPARE));
    navigate("/input/compare");
  };

  const handleFavorite = async () => {
    if (!plan) {
      return;
    }

    if (isFavoritePlan) {
      await deletePlan({
        QuoteID: QuoteID ?? "",
        PlanID: plan.ID,
      });
      resetDeletePlan();

      analytics?.track(ANALYTICS_EVENT.QUOTE_FAVORITE_PLAN_REMOVED, {
        quote_id: QuoteID,
        contract_id: plan.ContractID,
        plan_id: plan.PlanID,
        segment_id: plan.SegmentID,
        plan_year: plan.PlanYear,
        carrier_id: plan.CarrierName,
        snp_type: plan.SNPType,
        plan_type: plan.PlanType,
      });
      return;
    }

    const newFavoritePlan: PlanFavorite = {
      ContractID: plan.ContractID,
      PlanID: plan.PlanID,
      SegmentID: plan.SegmentID,
      LogoURL: plan.LogoURL,
      PlanName: plan.PlanName,
      ID: plan.ID,
      PlanType: plan.PlanType,
      MedicalPremium: plan.MedicalPremium,
      DrugPremium: plan.DrugPremium,
      MarketingName: plan.MarketingName,
      CarrierName: plan.CarrierName,
      PlanYear: plan.PlanYear,
      PlanSubType: plan.PlanSubType,
      QuotedPlanPremium: plan.AnnualCalculatedPlanPremium,
      SnpType: plan.SNPType,
    };
    await savePlan({ QuoteID: QuoteID ?? "", Plan: newFavoritePlan });
    resetSavePlan();

    analytics?.track(ANALYTICS_EVENT.QUOTE_FAVORITE_PLAN_ADDED, {
      quote_id: QuoteID,
      contract_id: plan.ContractID,
      plan_id: plan.PlanID,
      segment_id: plan.SegmentID,
      plan_year: plan.PlanYear,
      carrier_id: plan.CarrierName,
      snp_type: plan.SNPType,
      plan_type: plan.PlanType,
    });
  };

  const handleScrollChange = (sectionId: string, _endSection: boolean) => {
    setCurrentSection(sectionId);

    analytics?.track(ANALYTICS_EVENT.PLAN_DETAILS_SECTION_VIEWED, {
      quote_id: QuoteID,
      contract_id: plan?.ContractID,
      plan_id: plan?.PlanID,
      segment_id: plan?.SegmentID,
      plan_year: plan?.PlanYear,
      carrier_id: plan?.CarrierName,
      snp_type: plan?.SNPType,
      plan_type: plan?.PlanType,
      section_id: sectionId,
    });
  };

  const handleDrugCosts = () => {
    setOpenDrugCosts(true);

    analytics?.track(ANALYTICS_EVENT.PLAN_DRUG_COSTS_CLICKED, {
      quote_id: QuoteID,
      contract_id: plan?.ContractID,
      plan_id: plan?.PlanID,
      segment_id: plan?.SegmentID,
      plan_year: plan?.PlanYear,
      carrier_id: plan?.CarrierName,
      snp_type: plan?.SNPType,
      plan_type: plan?.PlanType,
    });
  };

  const handleNavigate = (location: "doctors" | "medicines") => {
    analytics?.track(ANALYTICS_EVENT.URL_BUTTON_CLICKED, {
      quote_id: QuoteID,
      link_text: `Edit ${location}`,
      link_url: `/input/${location}`,
    });

    navigate(`/input/${location}`);
    dispatch(
      setActiveStep(location === "doctors" ? STEPS.DOCTORS : STEPS.MEDICINES)
    );
  };

  const handleAccordionTracking = (id: string) => {
    analytics?.track(ANALYTICS_EVENT.URL_BUTTON_CLICKED, {
      quote_id: QuoteID,
      content_name: `Plan Details Accordion - ${id}`,
      content_url: `#${id}`,
      content_interaction: `User clicked on accordion to ${id} for MedAdv Plan`,
    });
  };

  const generateDetails = (planType: PlanType) => {
    if (!plan) {
      return;
    }

    switch (planType) {
      case PlanType.MAPD:
        return (
          <MapdDetails
            plan={plan}
            doctors={DoctorNetworks}
            isFavoritePlan={isFavoritePlan}
            handleFavoritePlan={handleFavorite}
            handleEnroll={handleEnroll}
            handleScrollChange={handleScrollChange}
            handleDrugCosts={handleDrugCosts}
            handleNavigate={handleNavigate}
            handleAccordionTracking={handleAccordionTracking}
          />
        );

      case PlanType.MA:
        return (
          <MaDetails
            plan={plan}
            doctors={DoctorNetworks}
            isFavoritePlan={isFavoritePlan}
            handleFavoritePlan={handleFavorite}
            handleEnroll={handleEnroll}
            handleScrollChange={handleScrollChange}
            handleNavigate={handleNavigate}
            handleAccordionTracking={handleAccordionTracking}
          />
        );

      case PlanType.PDP:
        return (
          <PpdDetails
            plan={plan}
            isFavoritePlan={isFavoritePlan}
            handleFavoritePlan={handleFavorite}
            handleEnroll={handleEnroll}
            handleScrollChange={handleScrollChange}
            handleDrugCosts={handleDrugCosts}
            handleNavigate={handleNavigate}
          />
        );
    }
  };

  const handleDisclosureOpen = () => {
    setOpenDisclosures(true);

    analytics?.track(ANALYTICS_EVENT.MEDICARE_DISCLAIMERS_CLICKED, {
      quote_id: QuoteID,
    });
  };

  return (
    <Grid container padding={3} rowGap={3} columnGap={10}>
      <Grid item xs={12}>
        <Button
          color="primary"
          variant="text"
          startIcon={<ArrowBack />}
          onClick={() => handleBackToPlans()}
        >
          Back to Plans
        </Button>
      </Grid>

      {isFetching ? (
        <>
          <Grid item xs={12} md={3} container>
            <Skeleton variant="rectangular" width="100%" height={400} />
          </Grid>
          <Grid item xs={12} md={8} container>
            <Skeleton variant="rectangular" width="100%" height={800} />
          </Grid>
        </>
      ) : (
        (isError || !plan) && (
          <Grid item xs={12}>
            <Typography variant="h3">
              Error: Return to Plan Compare Page
            </Typography>
          </Grid>
        )
      )}

      {plan && (
        <>
          <Grid item xs={12} md={3} container>
            <Grid
              item
              sx={{
                position: { md: "fixed" },
                top: { md: trigger ? 50 : null },
              }}
            >
              <Typography variant="h6" fontWeight={700}>
                Plan Details
              </Typography>
              <List>
                {SECTIONS[plan.PlanType]?.map((section, index) => {
                  const title = section
                    .split("-")
                    .map((text) => toProperCase(text))
                    .join(" ");

                  return (
                    <SectionScroller
                      key={section}
                      sectionID={section}
                      active={currentSection === section}
                      title={title ?? section}
                      handleClick={handleScrollerClick}
                      index={index + 1}
                    />
                  );
                })}
              </List>
            </Grid>
          </Grid>

          <Grid item xs={12} md={8}>
            {generateDetails(plan.PlanType)}
          </Grid>
        </>
      )}

      <Grid item xs={12} container direction="column" alignContent="flex-end">
        <Grid item>
          <Button
            color="secondary"
            variant="text"
            onClick={() => handleDisclosureOpen()}
          >
            View Medicare Disclaimers
          </Button>
        </Grid>
      </Grid>

      {openDisclosures && (
        <DisclosureDialog
          open={openDisclosures}
          handleClose={handleDisclosureClose}
          carriers={plan ? [plan.CarrierName] : []}
          planType={plan ? plan.PlanType : 0}
          state={Location?.State ?? ""}
        />
      )}
      {openDrugCosts && plan && (
        <DrugCostsDialog
          id={plan.ID}
          open={openDrugCosts}
          handleClose={() => setOpenDrugCosts(false)}
        />
      )}
    </Grid>
  );
}
