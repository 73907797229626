import * as React from "react";
import { Control, RegisterOptions, useWatch } from "react-hook-form";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Info from "@mui/icons-material/Info";

import AddressAutocomplete from "./AddressAutocomplete";
import TextInput from "./TextInput";
import DateInput from "./DateInput";
import SelectInput from "./SelectInput";
import RadioInput from "./RadioInput";
import CheckboxInput from "./CheckboxInput";
import RadioCards from "./RadioCards";
import EnrollmentPeriodInput from "./EnrollmentPeriodInput";
import MedicareNumberInput from "./MedicareNumberInput";
import PhoneInput from "./PhoneInput";
import DoctorFinder from "./DoctorFinder";
import Hint from "../Hint";
import MedicineFinder from "./MedicineFinder";
import SpecialistFinder from "./SpecialistFinder";

import { Field, STATES } from "../../../types/Enrollment.types";

interface RootProps {
  field: Field;
  control: Control<any>;
  errorMessage: string | undefined;
  initialValue: string | number | boolean | Date;
}

export default function Input({
  field,
  control,
  errorMessage,
  initialValue,
}: RootProps) {
  const watchValues = useWatch({
    control,
    name:
      field.Tag === "input" && field.RequiredIf ? field.RequiredIf.fields : [],
    disabled: field.Tag !== "input" || field.RequiredIf === undefined,
  });

  const isRequired = React.useMemo(() => {
    if (field.Tag !== "input") {
      return false;
    }

    const { RequiredIf, Required } = field;

    if (RequiredIf === undefined) {
      return Required;
    }

    return watchValues.some((i) => {
      if (RequiredIf.value) {
        return i === RequiredIf.value;
      }

      return i !== undefined || i !== "";
    });
  }, [field, watchValues]);

  if (field.Tag === "input") {
    let validation: RegisterOptions = {};

    /**
     * Convert Validation Object from API to react-hook-forms RegisterOptions
     */
    if (field.Validation) {
      validation = {
        min: field.Validation.min ?? undefined,
        max: field.Validation.max ?? undefined,
        minLength: field.Validation.minLength ?? undefined,
        maxLength: field.Validation.maxLength ?? undefined,
        required: isRequired
          ? {
              value: isRequired,
              message: "This field is required",
            }
          : false,
      };

      if (field.Validation.pattern) {
        if (typeof field.Validation.pattern === "string") {
          validation.pattern = new RegExp(field.Validation.pattern);
        } else {
          validation.pattern = {
            value: new RegExp(field.Validation.pattern.value),
            message: field.Validation.pattern.message,
          };
        }
      }
    }

    switch (field.Type) {
      case "text":
        return (
          <TextInput
            control={control}
            name={field.id}
            label={field.Label}
            errorMessage={errorMessage}
            required={isRequired}
            placeholder={field.Placeholder ?? ""}
            initialValue={initialValue as string}
            hint={field.Hint}
            validation={validation}
            readOnly={field.ReadOnly}
          />
        );

      case "phone":
        return (
          <PhoneInput
            control={control}
            name={field.id}
            label={field.Label}
            errorMessage={errorMessage}
            required={isRequired}
            initialValue={initialValue as string}
            hint={field.Hint}
            validation={validation}
          />
        );

      case "medicare-number":
        return (
          <MedicareNumberInput
            control={control}
            name={field.id}
            label={field.Label}
            errorMessage={errorMessage}
            required={isRequired}
            initialValue={initialValue as string}
            hint={field.Hint}
            validation={validation}
          />
        );

      case "date":
        return (
          <DateInput
            control={control}
            name={field.id}
            label={field.Label}
            errorMessage={errorMessage}
            required={isRequired}
            initialValue={initialValue as string | number}
            hint={field.Hint}
            validation={validation}
            minDate={field.Validation?.minDate}
            maxDate={field.Validation?.minDate}
          />
        );

      case "us-states":
        return (
          <SelectInput
            control={control}
            name={field.id}
            label={field.Label}
            errorMessage={errorMessage}
            required={isRequired}
            options={STATES}
            initialValue={initialValue as string | number}
            hint={field.Hint}
            validation={validation}
            readOnly={field.ReadOnly}
          />
        );

      case "radio":
        return (
          <RadioInput
            control={control}
            name={field.id}
            label={field.Label}
            errorMessage={errorMessage}
            required={isRequired}
            options={field.Options ?? []}
            initialValue={initialValue as string | number | boolean}
            vertical={field.Vertical ?? false}
            hint={field.Hint}
            validation={validation}
          />
        );

      case "radio-cards":
        return (
          <RadioCards
            control={control}
            name={field.id}
            label={field.Label}
            errorMessage={errorMessage}
            required={isRequired}
            cardHeight={200}
            options={field.Options ?? []}
            initialValue={initialValue as string | number | boolean}
            hint={field.Hint}
            validation={validation}
          />
        );

      case "enrollment-periods":
        return (
          <EnrollmentPeriodInput
            control={control}
            field={field}
            errorMessage={errorMessage}
            validation={validation}
            initialValue={initialValue as string}
          />
        );

      case "doctor-finder":
        return <DoctorFinder displaySelectedPCP={field.OptionHasFollowup} />;

      case "specialist-finder":
        return (
          <SpecialistFinder
            id={field.id}
            label={field.Label}
            errorMessage={errorMessage}
            max={2}
          />
        );

      case "medicine-finder":
        return (
          <MedicineFinder
            id={field.id}
            label={field.Label}
            required={isRequired}
            errorMessage={errorMessage}
          />
        );

      case "address-autocomplete":
        return (
          <AddressAutocomplete
            AddressFields={
              field.AddressFields ?? { Address1: "", City: "", State: "" }
            }
          />
        );

      case "checkbox":
        return (
          <CheckboxInput
            control={control}
            name={field.id}
            label={field.Label}
            errorMessage={errorMessage}
            required={isRequired}
            initialValue={initialValue as boolean}
            hint={field.Hint}
            validation={validation}
          />
        );

      case "dropdown":
        return (
          <SelectInput
            control={control}
            name={field.id}
            label={field.Label}
            errorMessage={errorMessage}
            required={isRequired}
            options={field.Options ?? []}
            initialValue={initialValue as string | number}
            hint={field.Hint}
            validation={validation}
            readOnly={field.ReadOnly}
          />
        );
    }
  }

  if (field.Tag === "text") {
    switch (field.Type) {
      case "disclosure":
        return (
          <>
            {field.Hint ? (
              <Box display="inline-flex" alignItems="center" gap={1}>
                <Typography pl={3}>{field.Content}</Typography>
                <Tooltip title={<Typography>{field.Hint.Content}</Typography>}>
                  <Info color="secondary" sx={{ cursor: "pointer" }} />
                </Tooltip>
              </Box>
            ) : (
              <Typography pl={3}>{field.Content}</Typography>
            )}
          </>
        );
      case "header":
        return (
          <>
            {field.Hint ? (
              <Hint
                hintData={{ ...field.Hint, Type: "dialog", Clickable: "icon" }}
              >
                <Typography variant="h6" fontWeight={600}>
                  {field.Content}
                </Typography>
              </Hint>
            ) : (
              <Typography variant="h6" fontWeight={600}>
                {field.Content}
              </Typography>
            )}
          </>
        );
      case "link":
        return (
          <>
            {field.Hint ? (
              <Hint
                hintData={{ ...field.Hint, Type: "dialog", Clickable: "text" }}
                textData={{
                  text: field.Content,
                  fontWeight: 600,
                  color: "secondary",
                  underline: true,
                  variant: "h6",
                }}
              />
            ) : (
              <Typography
                fontWeight={600}
                color="secondary"
                sx={{ textDecoration: "underline" }}
              >
                {field.Content}
              </Typography>
            )}
          </>
        );
    }
  }

  return <>Field Error</>;
}
