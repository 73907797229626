import * as React from "react";

import { alpha, styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";

const StyledField = styled(TextField)(({ theme }) => ({
  width: "90%",
  maxWidth: 600,
  "& .MuiOutlinedInput-root": {
    paddingLeft: 0,
    boxShadow: `${theme.palette.secondary.main} 0px 0px 6px 0px`,
    "& fieldset": {
      borderColor: alpha(theme.palette.secondary.main, 0.5),
    },
    "&:hover fieldset": {
      borderColor: alpha(theme.palette.secondary.main, 0.5),
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.secondary.main,
    },
    // "&.Mui-focused": {
    //   "& .MuiInputAdornment-root": {
    //     color: "white",
    //     backgroundColor: (theme) => theme.palette.primary.main,
    //   },
    // },
  },
}));

const StyledAdornment = styled(InputAdornment)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: "27.5px 14px",
  borderTopLeftRadius: theme.shape.borderRadius + "px",
  borderBottomLeftRadius: theme.shape.borderRadius + "px",
  color: "#fff",
}));

interface RootProps {
  placeholder: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}
export default function SearchField({
  placeholder,
  handleChange,
  value,
}: RootProps) {
  return (
    <StyledField
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <StyledAdornment position="start">
            <SearchIcon />
          </StyledAdornment>
        ),
      }}
    />
  );
}
