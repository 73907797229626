import * as React from "react";
import { useAppSelector } from "../../redux/hooks";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import getCarrierDisclosure from "../../utils/getCarrierDisclosure";
import formatPhoneNumber from "../../utils/formatPhoneNumber";

import { PlanType } from "../../types/Plan.types";
import { useRudderStackAnalytics } from "../../utils/useRudderStackAnalytics";
import { ANALYTICS_EVENT } from "../../config/analytics.config";

interface RootProps {
  open: boolean;
  handleClose: () => void;
  carriers: string[];
  planType: PlanType;
  state: string;
}
export default function DisclosureDialog({
  open,
  handleClose,
  carriers,
  planType,
  state,
}: RootProps) {
  const { Phone } = useAppSelector((state) => state.Affiliate);
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const analytics = useRudderStackAnalytics();

  React.useEffect(() => {
    if (!analytics || !QuoteID) {
      return;
    }

    analytics.track(ANALYTICS_EVENT.MEDICARE_DISCLAIMERS_DIALOG_OPENED, {
      quote_id: QuoteID,
    });

    return () => {
      analytics.track(ANALYTICS_EVENT.MEDICARE_DISCLAIMERS_DIALOG_CLOSED, {
        quote_id: QuoteID,
      });
    };
  }, [analytics, QuoteID]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleClose()}
      sx={{ maxHeight: "70vh" }}
    >
      <DialogTitle sx={{ pb: 0 }}>
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container p={2} direction="column">
          <Grid item>
            <Typography fontWeight={700}>Health Plan Disclaimers</Typography>
          </Grid>
          <Grid item>
            <ul>
              <li>
                Allowance amounts cannot be combined with other benefit
                allowances. Limitations and restrictions may apply.
              </li>

              {carriers.map((carrier) => {
                return (
                  <React.Fragment key={`disclosure-${carrier}`}>
                    {getCarrierDisclosure(carrier, planType, state)}
                  </React.Fragment>
                );
              })}
            </ul>
          </Grid>
          <Grid item>
            <Typography fontWeight={700}>Additional Disclaimers</Typography>
          </Grid>
          <Grid item>
            <ul>
              <li>
                The savings number is calculated from all of the saved sessions
                where another consumer entered medication information and their
                current plan. For each saved session, we calculate the cost of
                each plan based on the medication entered and geographical
                location. The savings number is derived by comparing the cost of
                the plan to the cheapest plan in that geographical location and
                taking the average.
              </li>
              <li>
                The retail drug cost is an estimated amount based on the
                out-of-pocket expenses you may expect to pay in a calendar year
                for medications that are not covered by an insurance plan's
                formulary on estimated retail drug price (retail drug cost is
                based on national averages for a medication and assumes
                adherence).
              </li>
              <li>
                If you need help, please call{" "}
                {formatPhoneNumber(Phone?.PhoneNumber ?? "")}{" "}
                {Phone?.TTY && "(TTY User: 711)"}{" "}
                {Phone &&
                  `${Phone?.PhoneNumberAvailability.Days} ${Phone?.PhoneNumberAvailability.Hours} ${Phone?.PhoneNumberAvailability.Timezone}`}{" "}
                for Customer Service Representatives and licensed insurance
                agents who can assist with finding information on available
                Medicare Advantage, Medicare Supplement Insurance and
                Prescription Drug Plans.
              </li>
              <li>
                For a complete list of available plans please contact
                1-800-MEDICARE (TTY users should call 1-877-486-2048), 24 hours
                a day/7 days a week or consult{" "}
                <a href="http://www.medicare.gov" target="blank">
                  www.medicare.gov
                </a>
                .
              </li>
              <li>
                You must have both Part A and B to enroll in a Medicare
                Advantage plan. Members may enroll in the plan only during
                specific times of the year. Contact the plan for more
                information.
              </li>
              <li>
                You must have Medicare Part A or Part B (or both) to join a
                Medicare Prescription Drug plan. Members may enroll in the plan
                only during specific times of the year. Contact the plan for
                more information.
              </li>
              <li>
                For plans with Part D Coverage: You may be able to get Extra
                Help to pay for your prescription drug premiums and costs. To
                see if you qualify for Extra Help, call: 1-800-MEDICARE
                (1-800-633-4227). TTY users should call 1-877-486-2048, 24 hours
                a day/ 7 days a week or consult{" "}
                <a href="http://www.medicare.gov" target="blank">
                  www.medicare.gov
                </a>
                ; the Social Security Office at 1-800-772-1213 between 7 a.m.
                and 7 p.m., Monday through Friday. TTY users should call,
                1-800-325-0778 or consult{" "}
                <a href="http://www.socialsecurity.gov" target="blank">
                  www.socialsecurity.gov
                </a>
                ; or your Medicaid Office.
              </li>
              <li>
                Every year, Medicare evaluates plans based on a 5-star rating
                system.
              </li>

              <li>
                Medicare beneficiaries can file a complaint with the Centers for
                Medicare & Medicaid Services by calling 1-800-MEDICARE 24 hours
                a day/7 days a week or using the{" "}
                <a
                  href="https://www.medicare.gov/MedicareComplaintForm/home.aspx"
                  target="blank"
                >
                  medicare.gov
                </a>{" "}
                site. Beneficiaries can{" "}
                <a
                  href="http://www.cms.gov/Medicare/CMS-Forms/CMS-Forms/CMS-Forms-Items/CMS012207.html?DLPage=1&amp;DLFilter=1696&amp;DLSort=0&amp;DLSortDir=ascending"
                  target="blank"
                >
                  appoint a representative
                </a>{" "}
                by submitting CMS Form-1696 (or equivalent written notice).
              </li>
              <li>
                Other Pharmacies, Physicians, and Providers are available in the
                network.
              </li>
              <li>
                Pharmacies, Physicians, and Providers may also contract with
                other Plan Sponsors.
              </li>
              <li>
                Out-of-network/non-contracted providers are under no obligation
                to treat Plan members, except in emergency situations. Please
                call the Plan's customer service number or see your Evidence of
                Coverage for more information, including the cost-sharing that
                applies to out-of-network services.
              </li>
              <li>
                <b>
                  Medicare Supplement insurance is available to those age 65 and
                  older enrolled in Medicare Parts A and B and, in some states,
                  to those under age 65 eligible for Medicare due to disability
                  or End-Stage Renal disease. The purpose of this communication
                  is the solicitation of insurance. Contact will be made by a
                  Licensed Insurance Agent or insurance company. Medicare
                  Supplement insurance plans are not connected with or endorsed
                  by the U.S. government or the federal Medicare program.
                </b>
              </li>
              <li>
                Under a contractual relationship between Senior Choice Plans,
                LLC and each insurance company issuing a policy offered by
                Senior Choice Plans, LLC, Senior Choice Plans, LLC earns a
                commission paid by the insurance company for each policy Senior
                Choice Plans, LLC sells. The commission rate varies by policy
                and may increase as Senior Choice Plans, LLC sells more
                policies. In some cases, Senior Choice Plans, LLC may earn bonus
                commission amounts based on criteria such as the number of
                policies sold.
              </li>
              <li>
                <b>Important Message About What You Pay for Vaccines</b> - Our
                plan covers most Part D vaccines at no cost to you, even if you
                haven't paid your deductible. Call Member Services for more
                information.
              </li>
              <li>
                <b>Important Message About What You Pay for Insulin</b> - You
                won't pay more than $35 for a one-month supply of each insulin
                product covered by our plan, no matter what cost-sharing tier
                it's on, even if you haven't paid your deductible.
              </li>
            </ul>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
