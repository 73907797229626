import * as React from "react";
import { Controller, Control, RegisterOptions } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Info from "@mui/icons-material/Info";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress } from "@mui/material";

interface RootProps {
  name: string;
  control: Control<any>;
  errorMessage: string | undefined;
  required?: boolean;
  readOnly?: boolean;
  label: string;
  options: Array<{
    label: string;
    value: string | number;
  }>;
  initialValue?: string | number;
  hint?: {
    Title?: string;
    Content: string;
  };
  validation?: RegisterOptions;
  multiselect?: boolean;
  keepRegistered?: boolean;
  loading?: boolean;
}

export default function SelectInput({
  name,
  control,
  errorMessage,
  label,
  required,
  readOnly,
  options,
  initialValue,
  hint,
  validation,
  multiselect,
  keepRegistered = false,
  loading = false,
}: RootProps) {
  return (
    <FormControl fullWidth>
      <FormLabel
        sx={{ display: "inline-flex", alignItems: "center", gap: 1, mb: 0.5 }}
      >
        <Typography fontWeight={500}>
          {label} {required ? "*" : "(Optional)"}
        </Typography>
        {hint && (
          <Tooltip title={<Typography>{hint.Content}</Typography>}>
            <Info color="secondary" sx={{ cursor: "pointer" }} />
          </Tooltip>
        )}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={initialValue ?? ""}
        rules={validation}
        shouldUnregister={!keepRegistered}
        render={({ field }) => (
          <Select
            {...field}
            multiple={multiselect}
            readOnly={readOnly}
            renderValue={(selected) =>
              options
                .filter((name) =>
                  Array.isArray(field.value)
                    ? selected.includes(name.value)
                    : selected === name.value
                )
                .map((record) => record.label)
                .join(", ")
            }
            IconComponent={
              loading ? (props) => <CircularProgress {...props} /> : undefined
            }
          >
            <MenuItem value={"" || null || undefined} />
            {options.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox
                    checked={
                      field.value !== undefined
                        ? Array.isArray(field.value)
                          ? field.value.indexOf(option.value) > -1
                          : field.value === option.value
                        : false
                    }
                  />
                  <ListItemText primary={option.label} />
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
      <Typography color="red" fontSize={12}>
        {errorMessage}
      </Typography>
    </FormControl>
  );
}
