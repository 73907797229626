import * as React from "react";
import { InView } from "react-intersection-observer";
import { format } from "date-fns";

import {
  ArrowForward,
  Article,
  Lightbulb,
  TrendingUp,
  TrendingDown,
  Healing,
  Loyalty,
  LocalHospital,
  Medication,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  alpha,
  Rating,
} from "@mui/material";

import {
  SectionTitle,
  RegularBenefit,
  BenefitTable,
  MedSuppBenefitTable,
} from "../DetailComponents";

import {
  calculateMarketShare,
  generateMedSuppPlanName,
  getPartADeductible,
  getPartBDeductible,
} from "../../../../utils/MedSuppUtils";
import { formatUSD } from "../../../../utils/formatUSD";
import toProperCase from "../../../../utils/toProperCase";

import {
  MedSuppPlanDetails,
  MedSuppQuote,
} from "../../../../types/MedSupp.types";

function generateSectionMetadata(key: string): {
  subtitle: string;
  icon?: React.ReactElement;
} {
  switch (key) {
    case "Part A":
      return {
        subtitle: "Hospital Insurance",
        icon: <LocalHospital sx={{ fontSize: "3.5rem" }} color="secondary" />,
      };

    case "Part B":
      return {
        subtitle: "Medical Insurance",
        icon: <Medication sx={{ fontSize: "3.5rem" }} color="secondary" />,
      };

    case "Parts A & B":
      return {
        subtitle: "Covered by both Part A & B",
        icon: <Healing sx={{ fontSize: "3.5rem" }} color="secondary" />,
      };

    case "Other Benefits":
      return {
        subtitle: "Additional Benefits covered by this plan",
        icon: <Loyalty sx={{ fontSize: "3.5rem" }} color="secondary" />,
      };

    default:
      return { subtitle: "" };
  }
}

interface RootProps {
  plan: MedSuppQuote;
  details: MedSuppPlanDetails;
  handleEnroll: () => void;
  handleScrollChange: (sectionId: string, endSection: boolean) => void;
}

export default function MedSuppDetails({
  plan,
  details,
  handleEnroll,
  handleScrollChange,
}: RootProps) {
  return (
    <Box
      width="100%"
      borderRadius={2}
      border="2px solid #efefef"
      padding={{ xs: 1, md: 3 }}
      sx={{ backgroundColor: "#fff" }}
    >
      <Grid container rowGap={4}>
        <Grid item xs={12} md={6} container gap={1}>
          <Grid item>
            <Typography
              textAlign="center"
              sx={(theme) => ({
                backgroundColor: alpha(theme.palette.primary.main, 0.2),
                minWidth: 125,
                py: 0.5,
                px: 1,
              })}
              fontWeight={600}
            >
              {generateMedSuppPlanName(plan.plan)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography fontWeight={700} variant="h5">
              {plan.company_base.name_full}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box display="inline-flex">
              <Typography>Customer Satisfaction:</Typography>
              {plan.company_base.customer_satisfaction_ratio > 0 ? (
                <Rating
                  value={plan.company_base.customer_satisfaction_ratio * 5.0}
                  readOnly
                  color="primary"
                  precision={0.5}
                />
              ) : (
                <Typography fontWeight={600}>N/A</Typography>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          container
          direction="column"
          alignItems="flex-end"
          justifyContent="flex-start"
          rowGap={1}
        >
          <Grid item container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              sx={{ borderRadius: 10 }}
              onClick={() => handleEnroll()}
              endIcon={<ArrowForward />}
            >
              Enroll Now
            </Button>
          </Grid>
        </Grid>

        <SectionTitle
          id="plan highlights"
          title="Plan Highlights"
          subtitle="High level details to know about your plan"
          icon={<Lightbulb sx={{ fontSize: "3.5rem" }} color="secondary" />}
        />

        <InView
          threshold={0.9}
          onChange={(inView) => {
            if (inView) handleScrollChange("plan highlights", false);
          }}
        >
          {({ ref }) => (
            <Grid
              ref={ref}
              item
              xs={12}
              md={10}
              container
              direction="column"
              pl={{ xs: 0, md: 10 }}
              rowGap={2}
            >
              <Grid item container justifyContent="space-between">
                <Grid item>
                  <Typography fontWeight={600} variant="h6">
                    Premium Per Month
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    borderBottom: "1.5px solid #efefef",
                    flexGrow: 1,
                    mx: 1,
                  }}
                />
                <Grid item>
                  <Typography fontWeight={600} variant="h6">
                    {formatUSD(plan.rate.month / 100.0)}
                  </Typography>
                </Grid>
              </Grid>

              <RegularBenefit
                title="Part A Deductible"
                value={getPartADeductible(details, plan.plan) ?? ""}
              />

              <RegularBenefit
                title="Part B Deductible"
                value={getPartBDeductible(details) ?? ""}
              />

              <RegularBenefit
                title="Market Share"
                value={calculateMarketShare(plan)}
              />
            </Grid>
          )}
        </InView>

        <SectionTitle
          id="plan premium"
          title="Plan Premium"
          subtitle="Breakdown for the monthly plan premium"
          icon={<Article sx={{ fontSize: "3.5rem" }} color="secondary" />}
        />

        <InView
          threshold={1}
          onChange={(inView) => {
            if (inView) {
              handleScrollChange("plan premium", true);
            }
          }}
        >
          {({ ref }) => (
            <Grid
              ref={ref}
              item
              xs={12}
              md={10}
              container
              direction="column"
              pl={{ xs: 0, md: 10 }}
              rowGap={2}
            >
              <Grid item container justifyContent="space-between">
                <Grid item>
                  <Typography fontWeight={600} variant="h6">
                    Premium Per Month
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    borderBottom: "1.5px solid #efefef",
                    flexGrow: 1,
                    mx: 1,
                  }}
                />
                <Grid item>
                  <Typography fontWeight={600} variant="h6">
                    {formatUSD(plan.rate.month / 100.0)}
                  </Typography>
                </Grid>
              </Grid>

              <BenefitTable
                sections={[
                  {
                    title: "Discounts Applied",
                    subsections:
                      plan.discounts && plan.discounts.length > 0
                        ? plan.discounts.map((discount) => {
                            return {
                              title: toProperCase(discount.name),
                              subtitle: `${(discount.value * 100.0).toFixed(
                                0
                              )}% OFF`,
                              value:
                                "Household discounts is offered to each individual that has anyone living in the house with you. This is fake text lol.",
                            };
                          })
                        : [
                            {
                              title: "None",
                              value: "",
                            },
                          ],
                  },
                ]}
              />

              <BenefitTable
                sections={[
                  {
                    title: "Rate Increases",
                    subsections:
                      plan.rate_increases && plan.rate_increases.length > 0
                        ? plan.rate_increases.map((rateIncrease) => {
                            return {
                              title: format(
                                new Date(rateIncrease.date),
                                "MM/dd/yyyy"
                              ),
                              value: (
                                <Box sx={{ display: "inline-flex" }}>
                                  {(rateIncrease.rate_increase * 100.0).toFixed(
                                    0
                                  )}
                                  %
                                  {rateIncrease.rate_increase > 0 ? (
                                    <TrendingUp color="success" />
                                  ) : rateIncrease.rate_increase < 0 ? (
                                    <TrendingDown color="error" />
                                  ) : null}
                                </Box>
                              ),
                            };
                          })
                        : [
                            {
                              title: "None",
                              value: "",
                            },
                          ],
                  },
                ]}
              />
            </Grid>
          )}
        </InView>

        {Object.keys(details).map((key) => {
          if (key === "deductible") {
            return null;
          }

          //@ts-ignore
          const section: { [key: string]: string[][] } = details[key];

          const sectionMetadata = generateSectionMetadata(key);

          return (
            <React.Fragment key={`section-${key}`}>
              <SectionTitle
                id={key}
                title={key}
                subtitle={sectionMetadata.subtitle}
                icon={sectionMetadata.icon ?? <></>}
              />
              <InView
                threshold={0.75}
                onChange={(inView) => {
                  if (inView) {
                    handleScrollChange(key, true);
                  }
                }}
              >
                {({ ref }) => (
                  <Grid
                    ref={ref}
                    item
                    xs={12}
                    md={10}
                    container
                    direction="column"
                    pl={{ xs: 0, md: 10 }}
                    rowGap={2}
                  >
                    <MedSuppBenefitTable
                      sections={Object.keys(section).map((detail) => {
                        const value = section[detail];
                        return {
                          title: detail,
                          subsections: value.map((row) => {
                            return {
                              title: row[0],
                              value: (
                                <Stack>
                                  <Typography variant="body2">
                                    Medicare Pays: <b>{row[1]}</b>
                                  </Typography>
                                  <Typography variant="body2">
                                    This Plan Pays: <b>{row[2]}</b>
                                  </Typography>
                                  <Typography variant="body2">
                                    You Pay: <b>{row[3]}</b>
                                  </Typography>
                                </Stack>
                              ),
                            };
                          }),
                        };
                      })}
                    />
                  </Grid>
                )}
              </InView>
            </React.Fragment>
          );
        })}
      </Grid>
    </Box>
  );
}
