import * as React from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import { alpha } from "@mui/material/styles";

import Lightbulb from "@mui/icons-material/Lightbulb";
import Person from "@mui/icons-material/Person";
import LocalHospital from "@mui/icons-material/LocalHospital";
import MedicalServices from "@mui/icons-material/MedicalServices";
import Medication from "@mui/icons-material/Medication";
import Article from "@mui/icons-material/Article";
import Edit from "@mui/icons-material/Edit";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Cancel from "@mui/icons-material/Cancel";
import ArrowForward from "@mui/icons-material/ArrowForward";

import {
  PlanDocuments,
  DetailsAccordion,
  DetailsAccordionDetails,
  DetailsAccordionSummary,
} from "../../PlanDetails/DetailComponents";
import {
  ComparisonSubBenefit,
  ComparisonTable,
  generateComparisonSection,
} from "../PlanComparisonComponents";
import StarRating from "../../../shared/StarRating";

import numberWithCommas from "../../../../utils/numberWithCommas";
import { formatUSD } from "../../../../utils/formatUSD";

import {
  newDentalDetails,
  newHearingDetails,
  newVisionDetails,
  Plan,
} from "../../../../types/Plan.types";
import { DoctorNetwork } from "../../../../types/Doctor.types";

const DRX_LOGO_URL = process.env.REACT_APP_DRX_LOGO_URL;

interface RootProps {
  planOne: Plan;
  planTwo: Plan;
  doctors: DoctorNetwork[] | null;
  handleEditDoctors: () => void;
  handleEditDrugs: () => void;
  handleShowDrugCosts: (plan: Plan) => void;
}

export default function MapdComparison({
  planOne,
  planTwo,
  doctors,
  handleEditDoctors,
  handleEditDrugs,
  handleShowDrugCosts,
}: RootProps) {
  const [type, setType] = React.useState("Retail-30");
  const [PharmacyType, DaySupply] = type.split("-");

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  return (
    <Grid container direction="column" rowGap={4}>
      <Grid item container justifyContent="space-evenly">
        <Grid
          key={`plan-${planOne.ID}`}
          item
          xs={6}
          container
          direction="column"
          pl={1}
          rowGap={1}
        >
          <Grid item>
            <Typography
              textAlign="center"
              sx={(theme) => ({
                backgroundColor: alpha(theme.palette.primary.main, 0.2),
                p: 0.5,
                width: 125,
                height: 32,
              })}
              fontWeight={600}
            >
              {planOne.PlanYear}
            </Typography>
          </Grid>
          <Grid item>
            <img
              src={`${DRX_LOGO_URL}/${planOne.LogoURL}`}
              alt=""
              width="150px"
              height="75px"
              loading="lazy"
              style={{ objectFit: "contain", objectPosition: "left" }}
            />
          </Grid>
          <Grid item>
            <Typography variant="body2" fontWeight={600}>
              {planOne.PlanName}
            </Typography>
          </Grid>
          <Grid item>
            {planOne.PlanRating ? (
              <StarRating rating={planOne.PlanRating} />
            ) : (
              <Typography variant="body2">No Star Rating</Typography>
            )}
          </Grid>
        </Grid>

        <Grid
          key={`plan-${planTwo.ID}`}
          item
          xs={6}
          container
          direction="column"
          pl={1}
          rowGap={1}
        >
          <Grid item>
            <Typography
              textAlign="center"
              sx={(theme) => ({
                backgroundColor: alpha(theme.palette.primary.main, 0.2),
                p: 0.5,
                width: 125,
                height: 32,
              })}
              fontWeight={600}
            >
              {planTwo.PlanYear}
            </Typography>
          </Grid>
          <Grid item>
            <img
              src={`${DRX_LOGO_URL}/${planTwo.LogoURL}`}
              alt=""
              width="150px"
              height="75px"
              loading="lazy"
              style={{ objectFit: "contain", objectPosition: "left" }}
            />
          </Grid>
          <Grid item>
            <Typography variant="body2" fontWeight={600}>
              {planTwo.PlanName}
            </Typography>
          </Grid>
          <Grid item>
            {planTwo.PlanRating ? (
              <StarRating rating={planTwo.PlanRating} />
            ) : (
              <Typography variant="body2">No Star Rating</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item container justifyContent="center">
        <Divider flexItem sx={{ width: "90%" }} />
      </Grid>

      <Grid item container direction="column" rowGap={2}>
        <Grid item display="inline-flex" alignItems="center">
          <Lightbulb fontSize="large" color="secondary" />
          <Typography variant="h6" fontWeight={700}>
            Plan Highlights
          </Typography>
        </Grid>

        <ComparisonSubBenefit
          title="Monthly Plan Premium"
          subsections={[
            {
              title: "",
              values: [
                {
                  value: `$${(
                    planOne.MedicalPremium + planOne.DrugPremium
                  )?.toFixed(2)}`,
                },
                {
                  value: `$${(
                    planTwo.MedicalPremium + planTwo.DrugPremium
                  )?.toFixed(2)}`,
                },
              ],
            },
          ]}
        />

        <ComparisonSubBenefit
          title="Maximum Out of Pocket"
          subsections={[
            {
              title: "In-Network",
              values: [
                {
                  value: `$${numberWithCommas(planOne.MaximumOutOfPocketCost)}`,
                },
                {
                  value: `$${numberWithCommas(planTwo.MaximumOutOfPocketCost)}`,
                },
              ],
            },
          ]}
        />
        <ComparisonSubBenefit
          title="Medical Deductible"
          subsections={[
            {
              title: "In-Network",
              values: [
                {
                  value: `$${numberWithCommas(planOne.MedicalDeductible)}`,
                },
                {
                  value: `$${numberWithCommas(planTwo.MedicalDeductible)}`,
                },
              ],
            },
          ]}
        />

        <ComparisonSubBenefit
          title="Drug Deductible"
          subsections={[
            {
              title: "Annual",
              values: [
                {
                  value: `$${numberWithCommas(planOne.DrugDeductible)}`,
                },
                {
                  value: `$${numberWithCommas(planTwo.DrugDeductible)}`,
                },
              ],
            },
          ]}
        />
      </Grid>

      <Grid item container direction="column" rowGap={2}>
        <Grid item display="inline-flex" alignItems="center">
          <Person fontSize="large" color="secondary" />
          <Typography variant="h6" fontWeight={700}>
            Doctor Coverage
          </Typography>
        </Grid>

        <ComparisonSubBenefit
          title="Primary Care Physician"
          subsections={[
            {
              title: "In-Network",
              values: [
                { value: planOne.PcpCost?.InNetwork ?? "-" },
                { value: planTwo.PcpCost?.InNetwork ?? "-" },
              ],
            },
            {
              title: "Out-Of-Network",
              values: [
                { value: planOne.PcpCost?.OutOfNetwork ?? "-" },
                { value: planTwo.PcpCost?.OutOfNetwork ?? "-" },
              ],
            },
          ]}
        />

        <ComparisonSubBenefit
          title="Specialist"
          subsections={[
            {
              title: "In-Network",
              values: [
                { value: planOne.SpecialistCost?.InNetwork ?? "-" },
                { value: planTwo.SpecialistCost?.InNetwork ?? "-" },
              ],
            },
            {
              title: "Out-Of-Network",
              values: [
                {
                  value: planOne.SpecialistCost?.OutOfNetwork ?? "-",
                },
                {
                  value: planTwo.SpecialistCost?.OutOfNetwork ?? "-",
                },
              ],
            },
          ]}
        />

        <ComparisonSubBenefit
          title="Your Inputted Doctors"
          icon={
            <Button
              variant="text"
              color="secondary"
              endIcon={<Edit />}
              onClick={() => handleEditDoctors()}
            >
              Edit
            </Button>
          }
          subsections={
            doctors && doctors.length > 0
              ? doctors.map((doctor) => {
                  const doctorInNetworkPlanOne = doctor.location.networks?.find(
                    (network) => network.id === planOne.ZelisNetwork
                  );
                  const doctorInNetworkPlanTwo = doctor.location.networks?.find(
                    (network) => network.id === planTwo.ZelisNetwork
                  );

                  return {
                    title: doctor.facilityName
                      ? doctor.facilityName
                      : `Dr. ${doctor.firstName} ${doctor.lastName}`,
                    values: [
                      {
                        value: doctorInNetworkPlanOne
                          ? "In-Network"
                          : "Out-of-Network",
                        icon: doctorInNetworkPlanOne ? (
                          <CheckCircle color="primary" />
                        ) : (
                          <Cancel color="error" />
                        ),
                      },
                      {
                        value: doctorInNetworkPlanTwo
                          ? "In-Network"
                          : "Out-of-Network",
                        icon: doctorInNetworkPlanTwo ? (
                          <CheckCircle color="primary" />
                        ) : (
                          <Cancel color="error" />
                        ),
                      },
                    ],
                  };
                })
              : [
                  {
                    title: "No Doctors Selected",
                    values: [{ value: "" }, { value: "" }],
                  },
                ]
          }
        />
      </Grid>

      <Grid item container direction="column" rowGap={2}>
        <Grid item display="inline-flex" alignItems="center">
          <LocalHospital fontSize="large" color="secondary" />
          <Typography variant="h6" fontWeight={700}>
            Inpatient Care
          </Typography>
        </Grid>

        <ComparisonTable
          sections={[
            {
              title: "Inpatient Hospital Care",
              subsections: [
                {
                  title: "In-Network",
                  values: [
                    {
                      value:
                        planOne.PlanDetail?.InNetwork["InpatientHospitalCare"],
                    },
                    {
                      value:
                        planTwo.PlanDetail?.InNetwork["InpatientHospitalCare"],
                    },
                  ],
                },
                {
                  title: "Out-of-Network",
                  values: [
                    {
                      value:
                        planOne.PlanDetail?.OutOfNetwork[
                          "InpatientHospitalCare"
                        ],
                    },
                    {
                      value:
                        planTwo.PlanDetail?.OutOfNetwork[
                          "InpatientHospitalCare"
                        ],
                    },
                  ],
                },
              ],
            },
            {
              title: "Skilled Nursing Facility (SNF)",
              subsections: [
                {
                  title: "In-Network",
                  values: [
                    { value: planOne.PlanDetail?.InNetwork["SNF"] },
                    { value: planTwo.PlanDetail?.InNetwork["SNF"] },
                  ],
                },
                {
                  title: "Out-of-Network",
                  values: [
                    {
                      value: planOne.PlanDetail?.OutOfNetwork["SNF"],
                    },
                    {
                      value: planTwo.PlanDetail?.OutOfNetwork["SNF"],
                    },
                  ],
                },
              ],
            },
            {
              title: "Inpatient Mental Healthcare",
              subsections: [
                {
                  title: "In-Network",
                  values: [
                    {
                      value:
                        planOne.PlanDetail?.InNetwork[
                          "InpatientMentalHealthCare"
                        ],
                    },
                    {
                      value:
                        planTwo.PlanDetail?.InNetwork[
                          "InpatientMentalHealthCare"
                        ],
                    },
                  ],
                },
                {
                  title: "Out-of-Network",
                  values: [
                    {
                      value:
                        planOne.PlanDetail?.OutOfNetwork[
                          "InpatientMentalHealthCare"
                        ],
                    },
                    {
                      value:
                        planOne.PlanDetail?.OutOfNetwork[
                          "InpatientMentalHealthCare"
                        ],
                    },
                  ],
                },
              ],
            },
          ]}
        />
      </Grid>

      <Grid item container direction="column" rowGap={2}>
        <Grid item display="inline-flex" alignItems="center">
          <MedicalServices fontSize="large" color="secondary" />
          <Typography variant="h6" fontWeight={700}>
            Supplemental Benefits
          </Typography>
        </Grid>
        <ComparisonSubBenefit
          title="Dental"
          subsections={[
            {
              title: "",
              values: [
                {
                  value: planOne.Ancillary?.Dental
                    ? "Included"
                    : "Not Included",
                },
                {
                  value: planTwo.Ancillary?.Dental
                    ? "Included"
                    : "Not Included",
                },
              ],
            },
          ]}
        />
        <ComparisonSubBenefit
          title="Vision"
          subsections={[
            {
              title: "",
              values: [
                {
                  value: planOne.Ancillary?.Vision
                    ? "Included"
                    : "Not Included",
                },
                {
                  value: planTwo.Ancillary?.Vision
                    ? "Included"
                    : "Not Included",
                },
              ],
            },
          ]}
        />
        <ComparisonSubBenefit
          title="Hearing"
          subsections={[
            {
              title: "",
              values: [
                {
                  value: planOne.Ancillary?.Hearing
                    ? "Included"
                    : "Not Included",
                },
                {
                  value: planTwo.Ancillary?.Hearing
                    ? "Included"
                    : "Not Included",
                },
              ],
            },
          ]}
        />
        <ComparisonSubBenefit
          title="Transportation"
          subsections={[
            {
              title: "",
              values: [
                {
                  value: planOne.Ancillary?.Transportation
                    ? "Included"
                    : "Not Included",
                },
                {
                  value: planTwo.Ancillary?.Transportation
                    ? "Included"
                    : "Not Included",
                },
              ],
            },
          ]}
        />
        <ComparisonSubBenefit
          title="Part B Premium Reduction"
          subsections={[
            {
              title: "",
              values: [
                {
                  value: planOne.Ancillary?.PartB ? "Included" : "Not Included",
                },
                {
                  value: planTwo.Ancillary?.PartB ? "Included" : "Not Included",
                },
              ],
            },
          ]}
        />

        <ComparisonSubBenefit
          title="Over-the-Counter Items"
          subsections={[
            {
              title: "",
              values: [
                {
                  value: planOne.Ancillary?.OTC ? "Included" : "Not Included",
                },
                {
                  value: planTwo.Ancillary?.OTC ? "Included" : "Not Included",
                },
              ],
            },
          ]}
        />
        <ComparisonSubBenefit
          title="Fitness Membership"
          subsections={[
            {
              title: "",
              values: [
                {
                  value: planOne.Ancillary?.Fitness
                    ? "Included"
                    : "Not Included",
                },
                {
                  value: planTwo.Ancillary?.Fitness
                    ? "Included"
                    : "Not Included",
                },
              ],
            },
          ]}
        />

        <Grid item>
          <DetailsAccordion>
            <DetailsAccordionSummary>
              Supplemental Benefit Details
            </DetailsAccordionSummary>
            <DetailsAccordionDetails>
              <ComparisonTable
                sections={[
                  {
                    title: "Dental Coverages",
                    subsections: generateComparisonSection(
                      planOne,
                      planTwo,
                      newDentalDetails
                    ),
                  },
                  {
                    title: "Hearing Services",
                    subsections: generateComparisonSection(
                      planOne,
                      planTwo,
                      newHearingDetails
                    ),
                  },
                  {
                    title: "Vision Services",
                    subsections: generateComparisonSection(
                      planOne,
                      planTwo,
                      newVisionDetails
                    ),
                  },
                  {
                    title: "Transportation",
                    subsections: [
                      {
                        title: "Benefit",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork["Transportation"],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork["Transportation"],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Part B Giveback",
                    subsections: [
                      {
                        title: "Amount",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork["PartBGiveback"] ??
                              "$0",
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork["PartBGiveback"] ??
                              "$0",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Over-the-Counter Items",
                    subsections: [
                      {
                        title: "Benefit",
                        values: [
                          {
                            value: planOne.PlanDetail?.InNetwork["OTC"],
                          },
                          {
                            value: planTwo.PlanDetail?.InNetwork["OTC"],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Fitness",
                    subsections: [
                      {
                        title: "Benefit",
                        values: [
                          {
                            value: planOne.PlanDetail?.InNetwork["Fitness"],
                          },
                          {
                            value: planTwo.PlanDetail?.InNetwork["Fitness"],
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </DetailsAccordionDetails>
          </DetailsAccordion>
        </Grid>

        <Grid item>
          <DetailsAccordion>
            <DetailsAccordionSummary>
              Outpatient Care and Services
            </DetailsAccordionSummary>
            <DetailsAccordionDetails>
              <ComparisonTable
                sections={[
                  {
                    title: "Acupuncture",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value: planOne.PlanDetail?.InNetwork["Acupuncture"],
                          },
                          {
                            value: planTwo.PlanDetail?.InNetwork["Acupuncture"],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Additional Services",
                    subsections: [
                      {
                        title: "Additional Services",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "AdditionalServices"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "AdditionalServices"
                              ],
                          },
                        ],
                      },
                      {
                        title: "Additional Telehealth",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "AdditionalTelehealth"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "AdditionalTelehealth"
                              ],
                          },
                        ],
                      },
                      {
                        title: "Meal Benefit",
                        values: [
                          {
                            value: planOne.PlanDetail?.InNetwork["Meal"],
                          },
                          {
                            value: planTwo.PlanDetail?.InNetwork["Meal"],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Ambulance",
                    subsections: [
                      {
                        title: "Air",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork["AirAmbulance"],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork["AirAmbulance"],
                          },
                        ],
                      },
                      {
                        title: "Ground",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "AmbulanceServices"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "AmbulanceServices"
                              ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Chiropractic Services",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "ChiropracticServices"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "ChiropracticServices"
                              ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Diabetes Supplies and Services",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "DiabetesSuppliesAndServices"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "DiabetesSuppliesAndServices"
                              ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title:
                      "Diagnostic Tests, Lab and Radiology Services, and X-Rays",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "DiagnosticTestsLabRadiology"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "DiagnosticTestsLabRadiology"
                              ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Doctor Office Visits",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "DoctorOfficeVisits"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "DoctorOfficeVisits"
                              ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Doctor Specialty Visit",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "DoctorSpecialtyVisits"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "DoctorSpecialtyVisits"
                              ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Durable Medical Equipment",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value: planOne.PlanDetail?.InNetwork["DME"],
                          },
                          {
                            value: planTwo.PlanDetail?.InNetwork["DME"],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Emergency Care",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value: planOne.PlanDetail?.InNetwork["Emergency"],
                          },
                          {
                            value: planTwo.PlanDetail?.InNetwork["Emergency"],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Home Health Care",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork["HomeHealthCare"],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork["HomeHealthCare"],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Outpatient Mental Health Care",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "OutpatientMentalHealthCare"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "OutpatientMentalHealthCare"
                              ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Outpatient Prescription Drugs",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "ChemotherapyRadiationDrugs"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "ChemotherapyRadiationDrugs"
                              ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Outpatient Rehabilitation Services",
                    subsections: [
                      {
                        title: "Cardiac",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "CardiacRehabilitationServices"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "CardiacRehabilitationServices"
                              ],
                          },
                        ],
                      },
                      {
                        title: "Pulmonary",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "PulmonaryRehabilitationServices"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "PulmonaryRehabilitationServices"
                              ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Outpatient Services/Surgery",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "OutpatientServicesSurgery"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "OutpatientServicesSurgery"
                              ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Outpatient Substance Abuse",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "OutpatientSubstanceAbuse"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "OutpatientSubstanceAbuse"
                              ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Podiatry Services",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork["PodiatryServices"],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork["PodiatryServices"],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title:
                      "Preventive Services and Wellness/Education Programs",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "PreventativeServices"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "PreventativeServices"
                              ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Prosthetic Devices",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork[
                                "ProstheticDevices"
                              ],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork[
                                "ProstheticDevices"
                              ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Renal Dialysis",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value:
                              planOne.PlanDetail?.InNetwork["RenalDialysis"],
                          },
                          {
                            value:
                              planTwo.PlanDetail?.InNetwork["RenalDialysis"],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: "Urgently Needed Care",
                    subsections: [
                      {
                        title: "In-Network",
                        values: [
                          {
                            value: planOne.PlanDetail?.InNetwork["UrgentCare"],
                          },
                          {
                            value: planTwo.PlanDetail?.InNetwork["UrgentCare"],
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </DetailsAccordionDetails>
          </DetailsAccordion>
        </Grid>
      </Grid>

      <Grid item container direction="column" rowGap={2}>
        <Grid item display="inline-flex" alignItems="center">
          <Medication fontSize="large" color="secondary" />
          <Typography variant="h6" fontWeight={700}>
            Prescriptions Benefits
          </Typography>
        </Grid>

        <ComparisonSubBenefit
          title="Drug Deductible"
          subsections={[
            {
              title: "",
              values: [
                { value: `$${planOne.DrugDeductible}` },
                { value: `$${planTwo.DrugDeductible}` },
              ],
            },
          ]}
        />

        <ComparisonSubBenefit
          title="Est. Monthly Drug Costs"
          subsections={[
            {
              title: "",
              values: [
                {
                  value:
                    planOne.PlanDrugCoverage?.length > 0 &&
                    planOne.EstimatedAnnualDrugCost !== undefined
                      ? `${formatUSD(planOne.EstimatedAnnualDrugCost / 12)}`
                      : "N/A",
                },
                {
                  value:
                    planTwo.PlanDrugCoverage?.length > 0 &&
                    planTwo.EstimatedAnnualDrugCost !== undefined
                      ? `${formatUSD(planTwo.EstimatedAnnualDrugCost / 12)}`
                      : "N/A",
                },
              ],
            },
          ]}
        />

        <ComparisonSubBenefit
          title="Pharmacy Type"
          subsections={[
            {
              title: "",
              values: [
                {
                  value: planOne.PharmacyCosts[0]?.IsPreferred
                    ? "Preferred Cost-Sharing Pharmacy"
                    : "Standard Cost-Sharing Pharmacy",
                },
                {
                  value: planTwo.PharmacyCosts[0]?.IsPreferred
                    ? "Preferred Cost-Sharing Pharmacy"
                    : "Standard Cost-Sharing Pharmacy",
                },
              ],
            },
          ]}
        />

        <ComparisonSubBenefit
          title="Your Drug List"
          icon={
            <Button
              variant="text"
              color="secondary"
              endIcon={<Edit />}
              onClick={() => handleEditDrugs()}
            >
              Edit
            </Button>
          }
          subsections={
            planOne.PlanDrugCoverage && planOne.PlanDrugCoverage.length > 0
              ? planOne.PlanDrugCoverage.map((drugCoverage) => {
                  const drugCoveredPlanOne = drugCoverage.TierNumber > 0;
                  const drugCoveragePlanTwo = planTwo.PlanDrugCoverage.find(
                    (drug) => drug.NDC === drugCoverage.NDC
                  );

                  const drugCoveredPlanTwo = drugCoveragePlanTwo
                    ? drugCoveragePlanTwo.TierNumber > 0
                    : false;

                  return {
                    title: drugCoverage.LabelName.toUpperCase(),
                    values: [
                      {
                        value: drugCoveredPlanOne ? "Covered" : "Not Covered",
                        icon: drugCoveredPlanOne ? (
                          <CheckCircle color="primary" />
                        ) : (
                          <Cancel color="error" />
                        ),
                      },
                      {
                        value: drugCoveredPlanTwo ? "Covered" : "Not Covered",
                        icon: drugCoveredPlanTwo ? (
                          <CheckCircle color="primary" />
                        ) : (
                          <Cancel color="error" />
                        ),
                      },
                    ],
                  };
                })
              : [
                  {
                    title: "No Drugs Selected",
                    values: [{ value: "" }, { value: "" }],
                  },
                ]
          }
        />
        <Grid item container justifyContent="space-evenly">
          <Grid item xs={6}>
            <Button
              variant="text"
              color="secondary"
              endIcon={<ArrowForward />}
              onClick={() => handleShowDrugCosts(planOne)}
            >
              View Drug Costs
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="text"
              color="secondary"
              endIcon={<ArrowForward />}
              onClick={() => handleShowDrugCosts(planTwo)}
            >
              View Drug Costs
            </Button>
          </Grid>
        </Grid>

        <Grid item display="flex" height={40}>
          <Select value={type} onChange={handleTypeChange} variant="standard">
            <MenuItem value={"Retail-30"}>30 Day - Retail</MenuItem>
            <MenuItem value={"Retail-90"}>90 Day - Retail</MenuItem>
            <MenuItem value={"MailOrder-30"}>30 Day - Mail-Order</MenuItem>
            <MenuItem value={"MailOrder-90"}>90 Day - Mail-Order</MenuItem>
          </Select>
        </Grid>

        <ComparisonSubBenefit
          title="Drug Tier Pricing - Preferred Pharmacy"
          subsections={
            planOne.DrugTier
              ? Object.keys(
                  //@ts-ignore
                  planOne.DrugTier.Preferred[PharmacyType][DaySupply]
                ).map((key) => {
                  const valuePlanOne =
                    //@ts-ignore
                    planOne.DrugTier.Preferred[PharmacyType][DaySupply][key];

                  const valuePlanTwo =
                    //@ts-ignore
                    planTwo.DrugTier.Preferred[PharmacyType][DaySupply][key];
                  return {
                    title: `Tier ${key}`,
                    values: [{ value: valuePlanOne }, { value: valuePlanTwo }],
                  };
                })
              : []
          }
        />

        <ComparisonSubBenefit
          title="Drug Tier Pricing - Standard Pharmacy"
          subsections={
            planOne.DrugTier
              ? Object.keys(
                  //@ts-ignore
                  planOne.DrugTier.Standard[PharmacyType][DaySupply]
                ).map((key) => {
                  const valuePlanOne =
                    //@ts-ignore
                    planOne.DrugTier.Standard[PharmacyType][DaySupply][key];

                  const valuePlanTwo =
                    //@ts-ignore
                    planTwo.DrugTier.Standard[PharmacyType][DaySupply][key];
                  return {
                    title: `Tier ${key}`,
                    values: [{ value: valuePlanOne }, { value: valuePlanTwo }],
                  };
                })
              : []
          }
        />
      </Grid>

      <Grid item container rowGap={2}>
        <Grid item xs={12} display="inline-flex" alignItems="center">
          <Article fontSize="large" color="secondary" />
          <Typography variant="h6" fontWeight={700}>
            Plan Documents
          </Typography>
        </Grid>

        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          gap={1}
          pl={2}
        >
          <PlanDocuments
            plan={planOne}
            documents={
              planOne.Documents
                ? planOne.Documents.map((document) => {
                    return {
                      title: document.LinkName,
                      link: document.Url,
                    };
                  })
                : []
            }
          />
        </Grid>

        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          gap={1}
          pl={2}
        >
          <PlanDocuments
            plan={planTwo}
            documents={
              planTwo.Documents
                ? planTwo.Documents.map((document) => {
                    return {
                      title: document.LinkName,
                      link: document.Url,
                    };
                  })
                : []
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
