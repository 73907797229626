import * as React from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { useGetAzureMapsKeyQuery } from "./redux/services/API";
import { setLocation, setQuoteID } from "./redux/reducers/Quote";
import { setAffiliateCode } from "./redux/reducers/Affiliate";

import RootLayout from "./layouts/Root";

import Home from "./pages/Home";
import UserInformation from "./pages/UserInformation";
import Enroll from "./pages/Enroll";
import Licensing from "./pages/Licensing";
import Nondiscrimination from "./pages/Nondiscrimination";
import Preferences from "./pages/Preferences";

import ConfirmPlan from "./components/UserInformation/ConfirmPlan";
import Doctors from "./components/UserInformation/Doctors";
import Medicines from "./components/UserInformation/Medicines";
import Pharmacy from "./components/UserInformation/Pharmacy";
import Compare from "./components/Compare/Compare.layout";
import MedSuppPlanDetails from "./components/Compare/PlanDetails/Containers/MedSuppDetails.container";
import MedAdvPlanDetails from "./components/Compare/PlanDetails/Containers/MedAdvDetails.container";

import EnrollmentApplicationStatus from "./pages/EnrollmentApplicationStatus";

import { useRudderStackAnalytics } from "./utils/useRudderStackAnalytics";

import { DEFAULT_AFFILIATE_CODE } from "./config/global.config";

import "./styles/App.css";

export default function App() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const analytics = useRudderStackAnalytics();

  const AffiliateCode = useAppSelector((state) => state.Affiliate.Code);

  useGetAzureMapsKeyQuery();

  /** Auto scroll to top when path changes (user changes page) */
  React.useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);

    if (analytics) {
      analytics.page("Page View");
    }
  }, [analytics, pathname]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);

    const code = searchParams.get("a");
    if (code) {
      dispatch(setAffiliateCode(code));
    }

    // const npn = searchParams.get("npn");
    // if (npn) {
    //   if (pathname === "/") {
    //     dispatch(setBrokerNPN(npn));
    //     dispatch(setUserType("CALLCENTER"));
    //   }
    //   searchParams.delete("npn");
    // }

    const zipCode = searchParams.get("zip");
    if (zipCode) {
      dispatch(
        setLocation({
          CountyFIPS: "",
          CountyName: "",
          State: "",
          ZipCode: zipCode,
          Coordinates: [],
        })
      );

      searchParams.delete("zip");
    }

    const quoteId = searchParams.get("quote");
    if (quoteId) {
      dispatch(setQuoteID(quoteId));

      searchParams.delete("quote");
    }

    navigate(
      {
        search: searchParams.toString(),
      },
      { replace: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate replace to={AffiliateCode ?? DEFAULT_AFFILIATE_CODE} />
        }
      />

      <Route path=":affiliateId" element={<RootLayout />}>
        <Route index element={<Home />} />

        <Route path="licensing" element={<Licensing />} />
        <Route path="nondiscrimination" element={<Nondiscrimination />} />
        <Route path="preferences" element={<Preferences />} />

        <Route path="input" element={<UserInformation />}>
          <Route index element={<Doctors />} />
          <Route path="doctors" element={<Doctors />} />
          <Route path="medicines" element={<Medicines />} />
          <Route path="pharmacy" element={<Pharmacy />} />
          <Route path="compare" element={<Compare />} />
          <Route
            path="compare/plan-details/:id"
            element={<MedAdvPlanDetails />}
          />
          <Route
            path="compare/medSupp/:medSuppKey"
            element={<MedSuppPlanDetails />}
          />
          <Route path="confirm" element={<ConfirmPlan />} />
        </Route>

        <Route path="enroll/*" element={<Enroll />} />
        <Route
          path="enroll/status/:id"
          element={<EnrollmentApplicationStatus />}
        />
      </Route>
      <Route path="*" element={<h1>404: Page Not Found</h1>} />
    </Routes>
  );
}
