import * as React from "react";
import { useOutletContext } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { resetAllPlanFilters, setPlanTypeTab } from "../../redux/reducers/Plan";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material";

import Tabs from "../shared/Tabs";
import DisclosureDialog from "./DisclosureDialog";
import MedSuppContainer from "./Containers/MedSupp.container";
import MedAdvContainer from "./Containers/MedAdv.container";
import MedSuppFilters from "./Filters/MedSupp.filters";
import MedAdvFilters from "./Filters/MedAdv.filters";

import { useRudderStackAnalytics } from "../../utils/useRudderStackAnalytics";

import { PlanTypeTabs, PlanType } from "../../types/Plan.types";
import { ANALYTICS_EVENT } from "../../config/analytics.config";

export const PLAN_TABS_INFO = [
  {
    title: "Medicare Advantage",
    subtitle: "+ Drug Coverage",
    value: PlanTypeTabs.MAPD,
    id: "MAPD",
    drxID: PlanType.MAPD,
    urlPath: "mapd",
  },
  {
    title: "Medicare Advantage",
    subtitle: "- Drug Coverage",
    value: PlanTypeTabs.MA,
    id: "MA",
    drxID: PlanType.MA,
    urlPath: "ma",
  },
  {
    title: "Medicare Supplement",
    value: PlanTypeTabs.SUPP,
    id: "SUPP",
    drxID: PlanType.MEDSUPP,
    urlPath: "medsupp",
  },
  {
    title: "Medicare Part D",
    value: PlanTypeTabs.PDP,
    id: "PDP",
    drxID: PlanType.PDP,
    urlPath: "pdp",
  },
];

export default function CompareLayout() {
  const dispatch = useAppDispatch();
  const analytics = useRudderStackAnalytics();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const { Location: location, QuoteID } = useAppSelector(
    (state) => state.Quote
  );

  const { PlanFilters, PlanTypeTab, PlanYear } = useAppSelector(
    (state) => state.Plan
  );
  const { Carriers } = PlanFilters;

  const [openDisclosures, setOpenDisclosures] = React.useState(false);
  const [isPending, startTransition] = React.useTransition();

  const handleTabChange = (newValue: PlanTypeTabs) => {
    const planTabInfo = PLAN_TABS_INFO[newValue];

    dispatch(setPlanTypeTab(newValue));

    startTransition(() => {
      dispatch(
        resetAllPlanFilters(`${PlanYear}-${planTabInfo.drxID.toString()}`)
      );
    });
  };

  const handleSelectTabChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number;
    const planTabInfo = PLAN_TABS_INFO[value];

    dispatch(setPlanTypeTab(value));

    startTransition(() => {
      dispatch(
        resetAllPlanFilters(`${PlanYear}-${planTabInfo.drxID.toString()}`)
      );
    });
  };

  const handleDisclosureOpen = () => {
    setOpenDisclosures(true);

    analytics?.track(ANALYTICS_EVENT.MEDICARE_DISCLAIMERS_CLICKED, {
      quote_id: QuoteID,
    });
  };

  const handleDisclosureClose = () => {
    setOpenDisclosures(false);
  };

  return (
    <>
      <Grid container pb={2} px={1} rowGap={4}>
        <Grid
          item
          xs={12}
          sm={5}
          md={3}
          lg={4}
          container
          justifyContent={{ xs: "center", sm: "flex-start" }}
          alignContent="flex-start"
        >
          {PlanTypeTab !== PlanTypeTabs.SUPP ? (
            <MedAdvFilters
              currentPlanType={PLAN_TABS_INFO[PlanTypeTab].drxID}
              startTransition={startTransition}
            />
          ) : (
            <MedSuppFilters startTransition={startTransition} />
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sm={7}
          md={9}
          lg={8}
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          rowGap={3}
        >
          {isMobile ? (
            <Select<number>
              value={PlanTypeTab}
              onChange={handleSelectTabChange}
              sx={{ width: { xs: "95%", sm: "80%" } }}
            >
              {PLAN_TABS_INFO.map((tabInfo) => {
                return (
                  <MenuItem key={tabInfo.id} value={tabInfo.value}>
                    {tabInfo.title} {tabInfo.subtitle}
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            <Tabs
              currentValue={PlanTypeTab}
              handleChange={handleTabChange}
              tabs={PLAN_TABS_INFO}
              maxWidth={720}
              elevation={1}
            />
          )}

          {PlanTypeTab === PlanTypeTabs.SUPP ? (
            <MedSuppContainer isPending={isPending} />
          ) : (
            <MedAdvContainer isPending={isPending} />
          )}
        </Grid>

        <Grid item xs={12} sx={{ m: 3 }}>
          <Divider />
        </Grid>

        <Grid item xs={12} container direction="column">
          <Grid item>
            <Button
              color="secondary"
              variant="text"
              onClick={() => handleDisclosureOpen()}
            >
              View Medicare Disclaimers
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {openDisclosures && (
        <DisclosureDialog
          open={openDisclosures}
          handleClose={handleDisclosureClose}
          carriers={Object.keys(Carriers)}
          planType={PLAN_TABS_INFO[PlanTypeTab].drxID}
          state={location?.State ?? ""}
        />
      )}
    </>
  );
}

type ContextType = {
  isPending: boolean;
  startTransition: React.TransitionStartFunction;
};
export function useCompareTransition() {
  return useOutletContext<ContextType>();
}
