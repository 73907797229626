import * as React from "react";
import { createRoot } from "react-dom/client";

import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { AnalyticsWrapper } from "./utils/useRudderStackAnalytics";

import App from "./App";

import "./styles/index.css";
import "azure-maps-control/dist/atlas.min.css";

import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <AnalyticsWrapper>
              <App />
            </AnalyticsWrapper>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <HelmetProvider>
//       <Provider store={store}>
//         <PersistGate loading={null} persistor={persistor}>
//           <BrowserRouter>
//             <ThemeProvider theme={theme}>
//               <CssBaseline />
//               <App />
//             </ThemeProvider>
//           </BrowserRouter>
//         </PersistGate>
//       </Provider>
//     </HelmetProvider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );
