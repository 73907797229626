import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { useCallback } from "react";

interface Options {
  query?: URLSearchParams;
  replace?: boolean;
}

export default function useNavigateParams() {
  const navigate = useNavigate();
  const AffiliateCode = useAppSelector((state) => state.Affiliate.Code);

  return useCallback(
    (pathname: string, options?: Options) => {
      const path = {
        pathname: pathname.startsWith("/")
          ? `/${AffiliateCode}${pathname}`
          : pathname,
        search: options?.query?.toString() ?? window.location.search,
        replace: options?.replace !== undefined ? options.replace : false,
      };
      navigate(path);
    },
    [AffiliateCode, navigate]
  );
}
