import * as React from "react";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import {
  useGetHealthConditionMedicationsQuery,
  useGetHealthConditionsQuery,
  useGetQuoteAttributesQuery,
} from "../../../redux/services/API";
import { useAppSelector } from "../../../redux/hooks";

import {
  Button,
  Typography,
  CircularProgress,
  Grid,
  Autocomplete,
  TextField,
  lighten,
  darken,
  FormLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Close from "@mui/icons-material/Close";
import Add from "@mui/icons-material/Add";

import { US_STATE } from "../../../types/Global.types";
import {
  HealthConditionMedicationOption,
  HealthConditionOption,
  LookBackPeriod,
  OPERATOR,
} from "../../../types/MedSupp.types";
import { QuoteAttribute } from "../../../types/Quote.types";
import { MED_SUPP_FORM_STEP } from ".";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";

function generateLookbackPeriodLabel(period: LookBackPeriod) {
  if (period.ever) {
    return "Ever";
  }

  if (period.currently) {
    return "Currently";
  }

  let label: string = "";

  switch (period.op) {
    case OPERATOR.GREATER_THAN:
      label += "Greater than ";
      break;

    case OPERATOR.GREATER_THAN_EQUAL:
      label += "Greater than or Equal to ";
      break;

    case OPERATOR.LESS_THAN:
      label += "Less than ";
      break;

    case OPERATOR.LESS_THAN_EQUAL:
      label += "Less than or Equal to ";
      break;

    case OPERATOR.EQUAL:
    default:
      break;
  }

  label += `${Math.abs(period.count)} ${period.unit}${
    Math.abs(period.count) > 0 && "s"
  } ago`;

  return label;
}

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.secondary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.secondary.light, 0.85)
      : darken(theme.palette.secondary.main, 0.8),
  fontWeight: 600,
}));

const GroupItems = styled("ul")({
  padding: 0,
});

interface SelectedHealthCondition {
  Name: string;
  Category: string;
  LookbackPeriod: string;
}

interface SelectedMedication {
  Name: string;
  AssociatedCondition: string;
}

interface FormInput {
  HealthConditions: SelectedHealthCondition[];
  Medications: SelectedMedication[];
}

interface RootProps {
  handleNextStep: (currentStep: MED_SUPP_FORM_STEP) => void;
  submitRef: React.MutableRefObject<any>;
  saveAnswers: MutationTrigger<any>;
}
export default function HealthConditions({
  handleNextStep,
  submitRef,
  saveAnswers,
}: RootProps) {
  const analytics = useRudderStackAnalytics();
  const { Location, QuoteID } = useAppSelector((state) => state.Quote);

  const methods = useForm<FormInput>({
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });
  const { control, handleSubmit } = methods;

  const {
    fields: healthConditions,
    append: addHealthCondition,
    remove: removeHealthCondition,
    replace: loadHealthConditions,
  } = useFieldArray({
    control,
    name: "HealthConditions",
  });

  const {
    fields: medications,
    append: addMedication,
    remove: removeMedication,
    replace: loadMedications,
  } = useFieldArray({
    control,
    name: "Medications",
  });

  const [
    healthConditionLookbackPeriodIndex,
    setHealthConditionLookbackPeriodIndex,
  ] = React.useState<number>(-1);
  const [selectedHealthConditionIndex, setSelectedHealthConditionIndex] =
    React.useState<number>();

  const [
    medicationAssociatedConditionIndex,
    setMedicationAssociatedConditionIndex,
  ] = React.useState<number>(-1);
  const [selectedMedicationIndex, setSelectedMedicationIndex] =
    React.useState<number>();

  const { data: healthConditionsData, isFetching: isLoadingHealthConditions } =
    useGetHealthConditionsQuery(Location?.State as US_STATE, {
      skip: !Location,
    });

  const { data: medicationsData, isFetching: isLoadingMedications } =
    useGetHealthConditionMedicationsQuery(Location?.State as US_STATE, {
      skip: !Location,
    });

  const { data: quoteAttributes, isFetching: isLoadingQuoteAttributes } =
    useGetQuoteAttributesQuery(
      {
        QuoteID: QuoteID ?? "",
        Fields: ["HealthConditions", "Medications"],
      },
      {
        skip: !QuoteID,
      }
    );

  const healthConditionOptions = React.useMemo(() => {
    if (!healthConditionsData || !healthConditionsData.data) {
      return [];
    }

    const options: HealthConditionOption[] = [];
    let index = 0;

    healthConditionsData.data.forEach((category) => {
      category.conditions.forEach((condition) => {
        options.push({
          ...condition,
          category: category.name,
          index,
        });
        index++;
      });
    });

    return options;
  }, [healthConditionsData]);

  const healthConditionsLookbackPeriodOptions = React.useMemo(() => {
    if (!healthConditionOptions || selectedHealthConditionIndex === undefined) {
      return [];
    }

    const healthCondition =
      healthConditionOptions[selectedHealthConditionIndex];

    if (!healthCondition) {
      return [];
    }

    return healthCondition.look_back_periods.map((period) => {
      return {
        label: generateLookbackPeriodLabel(period),
        value: period.identifier,
      };
    });
  }, [healthConditionOptions, selectedHealthConditionIndex]);

  const medicationOptions: HealthConditionMedicationOption[] =
    React.useMemo(() => {
      if (!medicationsData || !medicationsData.data) {
        return [];
      }

      return medicationsData.data.map((i, index) => {
        return {
          ...i,
          index,
          firstLetter: i.name.toUpperCase().charAt(0),
        };
      });
    }, [medicationsData]);

  const medicationAssociatedConditionsOptions = React.useMemo(() => {
    if (
      !medicationsData ||
      !medicationsData.data ||
      selectedMedicationIndex === undefined
    ) {
      return [];
    }

    const medication = medicationsData.data[selectedMedicationIndex];

    if (!medication) {
      return [];
    }

    return medication.meta.map((i) => {
      return {
        label: i.associated_condition,
        value: i.associated_condition,
      };
    });
  }, [medicationsData, selectedMedicationIndex]);

  React.useEffect(() => {
    if (quoteAttributes?.data) {
      if (quoteAttributes.data["HealthConditions"]) {
        try {
          const parsedHealthConditions = JSON.parse(
            quoteAttributes.data["HealthConditions"]
          ) as SelectedHealthCondition[];

          loadHealthConditions(parsedHealthConditions);
        } catch (e) {
          console.error(e);
        }
      }

      if (quoteAttributes.data["Medications"]) {
        try {
          const parsedMedications = JSON.parse(
            quoteAttributes.data["Medications"]
          ) as SelectedMedication[];

          loadMedications(parsedMedications);
        } catch (e) {
          console.error(e);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteAttributes]);

  React.useEffect(() => {
    if (!analytics || !QuoteID) {
      return;
    }

    analytics.track(ANALYTICS_EVENT.FORM_STARTED, {
      quote_id: QuoteID,
      form_name: "Health Conditions",
      form_type: "MED_SUPP",
    });
  }, [QuoteID, analytics]);

  const onSubmitHandler: SubmitHandler<FormInput> = async (formData) => {
    if (!Location || !QuoteID) {
      return;
    }

    try {
      const parsedAttributes: QuoteAttribute[] = [
        {
          FieldID: "HealthConditions",
          Value: JSON.stringify(formData.HealthConditions),
        },
        {
          FieldID: "Medications",
          Value: JSON.stringify(formData.Medications),
        },
      ];

      saveAnswers({ QuoteID, Attributes: parsedAttributes });

      analytics?.track(ANALYTICS_EVENT.FORM_SUBMITTED, {
        quote_id: QuoteID,
        form_name: "Health Conditions",
        form_type: "MED_SUPP",
      });

      handleNextStep(MED_SUPP_FORM_STEP.HEALTH_CONDITION);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddHealthCondition = () => {
    if (
      selectedHealthConditionIndex === undefined ||
      healthConditionLookbackPeriodIndex === -1 ||
      !healthConditionOptions
    ) {
      return;
    }

    const healthCondition =
      healthConditionOptions[selectedHealthConditionIndex];

    if (!healthCondition) {
      return;
    }

    const lookbackPeriod =
      healthCondition.look_back_periods[healthConditionLookbackPeriodIndex];

    addHealthCondition({
      Name: healthCondition.name,
      Category: healthCondition.category,
      LookbackPeriod: lookbackPeriod.identifier,
    });

    setHealthConditionLookbackPeriodIndex(-1);
    setSelectedHealthConditionIndex(undefined);
  };

  const handleAddMedication = () => {
    if (
      !medicationOptions ||
      medicationAssociatedConditionIndex === -1 ||
      selectedMedicationIndex === undefined
    ) {
      return;
    }

    const medication = medicationOptions[selectedMedicationIndex];

    if (!medication) {
      return;
    }

    const associatedCondition =
      medication.meta[medicationAssociatedConditionIndex];

    if (!associatedCondition) {
      return;
    }

    addMedication({
      Name: medication.name,
      AssociatedCondition: associatedCondition.associated_condition,
    });

    setMedicationAssociatedConditionIndex(-1);
    setSelectedMedicationIndex(undefined);
  };

  if (isLoadingQuoteAttributes) {
    return (
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <CircularProgress size="3rem" />
      </Grid>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        id="medSupp-health-conditions"
      >
        <Grid item xs={12} container spacing={3}>
          <Grid item xs={12}>
            <FormLabel
              sx={{
                display: "inline-flex",
                alignItems: "center",
                gap: 1,
                mb: 0.5,
              }}
            >
              <Typography fontWeight={500}>Search Health Conditions</Typography>
            </FormLabel>
            <Autocomplete<HealthConditionOption>
              id="health-conditions-search"
              options={healthConditionOptions}
              groupBy={(option) => option.category}
              getOptionLabel={(option) => option.name ?? ""}
              loading={isLoadingHealthConditions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingHealthConditions ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  helperText="Search for your health condition"
                  // error={error !== undefined}
                />
              )}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
              onChange={(_event, newValue: HealthConditionOption | null) => {
                if (!newValue) {
                  return;
                }

                setSelectedHealthConditionIndex(newValue.index);
                setHealthConditionLookbackPeriodIndex(-1);
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormControl fullWidth>
              <InputLabel id="health-condition-lookback-label">
                Event Occurence *
              </InputLabel>
              <Select<number>
                id="HealthConditionLookbackPeriod"
                label="Event Occurence *"
                labelId="health-condition-lookback-label"
                value={healthConditionLookbackPeriodIndex}
                onChange={(event: SelectChangeEvent<number>) =>
                  setHealthConditionLookbackPeriodIndex(
                    Number(event.target.value)
                  )
                }
                fullWidth
              >
                <MenuItem value={-1}></MenuItem>
                {healthConditionsLookbackPeriodOptions.map((i, index) => {
                  return (
                    <MenuItem key={`lookback-option-${i.value}`} value={index}>
                      {i.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                How long ago was this health condition diagnosed?
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            container
            alignItems="flex-start"
            mt={{ md: 1 }}
          >
            <Button
              variant="contained"
              onClick={handleAddHealthCondition}
              startIcon={<Add />}
            >
              Add Condition
            </Button>
          </Grid>
          <Grid item xs={12} container gap={1} mb={2}>
            <Grid item xs={12}>
              <Typography fontWeight={600}>Your Health Conditions:</Typography>
            </Grid>
            {healthConditions.length > 0 ? (
              healthConditions.map((i, index) => {
                return (
                  <Grid item key={i.id}>
                    <Button
                      variant="text"
                      startIcon={<Close />}
                      onClick={() => removeHealthCondition(index)}
                    >
                      {i.Name} ({i.Category})
                    </Button>
                  </Grid>
                );
              })
            ) : (
              <Grid item>
                <Typography>None</Typography>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <FormLabel
              sx={{
                display: "inline-flex",
                alignItems: "center",
                gap: 1,
                mb: 0.5,
              }}
            >
              <Typography fontWeight={500}>Search Medications</Typography>
            </FormLabel>
            <Autocomplete<HealthConditionMedicationOption>
              id="medications-search"
              options={medicationOptions}
              getOptionLabel={(option) => option.name ?? ""}
              groupBy={(option) => option.firstLetter}
              loading={isLoadingMedications}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingMedications ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  helperText="Search for your medications that help with your health conditions"
                  // error={error !== undefined}
                />
              )}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
              onChange={(
                _event,
                newValue: HealthConditionMedicationOption | null
              ) => {
                if (!newValue) {
                  return;
                }

                setSelectedMedicationIndex(newValue.index);
                setMedicationAssociatedConditionIndex(-1);
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormControl fullWidth>
              <InputLabel id="associated-condition-label">
                Associated Condition *
              </InputLabel>
              <Select<number>
                id="MedicationAssociatedCondition"
                label="Associated Condition *"
                labelId="associated-condition-label"
                value={medicationAssociatedConditionIndex}
                onChange={(event: SelectChangeEvent<number>) => {
                  setMedicationAssociatedConditionIndex(
                    Number(event.target.value)
                  );
                }}
                fullWidth
              >
                <MenuItem value={-1}></MenuItem>
                {medicationAssociatedConditionsOptions.map((i, index) => {
                  return (
                    <MenuItem key={`lookback-option-${i.value}`} value={index}>
                      {i.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                After selecting a Medication, tell us which condition it helps
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            container
            alignItems="flex-start"
            mt={{ md: 1 }}
          >
            <Button
              variant="contained"
              onClick={handleAddMedication}
              startIcon={<Add />}
            >
              Add Medication
            </Button>
          </Grid>
          <Grid item xs={12} container gap={1}>
            <Grid item xs={12}>
              <Typography fontWeight={600}>Your Medications:</Typography>
            </Grid>
            {medications.length > 0 ? (
              medications.map((i, index) => {
                return (
                  <Grid item key={i.id}>
                    <Button
                      variant="text"
                      startIcon={<Close />}
                      onClick={() => removeMedication(index)}
                    >
                      {i.Name} ({i.AssociatedCondition})
                    </Button>
                  </Grid>
                );
              })
            ) : (
              <Grid item>
                <Typography>None</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        <button ref={submitRef} type="submit" style={{ display: "none" }} />
      </form>
    </FormProvider>
  );
}
