import * as React from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setActiveStep, setPreference } from "../redux/reducers/Quote";
import { useCreateQuoteMutation } from "../redux/services/API";
import { setPlanTypeTab } from "../redux/reducers/Plan";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import LoadingButton from "@mui/lab/LoadingButton";

//@ts-ignore
import { ReactComponent as DoctorSVG } from "../assets/doctor.svg";
//@ts-ignore
import { ReactComponent as MedicineSVG } from "../assets/medicine.svg";

import { PlanTypeTabs } from "../types/Plan.types";

import { useRudderStackAnalytics } from "../utils/useRudderStackAnalytics";
import useNavigateParams from "../utils/useNavigateParams";

import { ANALYTICS_EVENT } from "../config/analytics.config";

const CardButton = styled(Card)<{ active?: number }>(({ theme, active }) => ({
  width: 175,
  height: 175,
  borderRadius: 20,
  padding: "1rem",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  textAlign: "center",
  color: theme.palette.info.main,
  fill: theme.palette.primary.main,

  border: active ? `2px solid ${theme.palette.primary.main}` : 0,

  boxShadow:
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
  cursor: "pointer",

  "&:hover": {
    boxShadow:
      "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
  },
}));

export default function Preferences() {
  const navigate = useNavigateParams();
  const dispatch = useAppDispatch();
  const analytics = useRudderStackAnalytics();

  const { Location, BrokerNPN } = useAppSelector((state) => state.Quote);

  const AffiliateCode = useAppSelector((state) => state.Affiliate.Code);

  const [createQuote, { isLoading }] = useCreateQuoteMutation();

  const [preference, setPreferenceState] = React.useState<string>();

  const handleCardClick = (type: string) => {
    if (type === preference) {
      setPreferenceState(undefined);
      return;
    }
    setPreferenceState(type);
  };

  const handleButtonClick = async () => {
    if (!Location) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.QUOTING_PREFERENCE, {
      quote_preference: preference?.toUpperCase() ?? "SKIP_TO_PLANS",
    });

    try {
      const response = await createQuote({
        Location,
        BrokerNPN,
        Affiliate: AffiliateCode,
      }).unwrap();

      analytics?.track(ANALYTICS_EVENT.QUOTE_CREATED, {
        quote_id: response.QuoteID,
        insurance_type: "MEDICARE",
      });

      switch (preference) {
        case "medical":
          dispatch(setPlanTypeTab(PlanTypeTabs.MA));
          dispatch(setPreference(preference));
          dispatch(setActiveStep(0));
          navigate("/input/doctors");
          return;

        case "both":
          dispatch(setPreference(preference));
          dispatch(setActiveStep(0));
          navigate("/input/doctors");
          return;

        case "drug":
          dispatch(setPreference(preference));
          dispatch(setPlanTypeTab(PlanTypeTabs.PDP));
          dispatch(setActiveStep(1));
          navigate("/input/medicines");
          return;

        default:
          navigate("/input/compare");
          dispatch(setActiveStep(3));
          return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (!Location) {
      navigate("..");
    }
  }, [Location, navigate]);

  return (
    <Stack alignItems="center" mt={12} pb={6} gap={4}>
      <Typography variant="h5" fontWeight={700} textAlign="center">
        What Coverages are you Looking for?
      </Typography>

      <Stack direction="row" gap={4} flexWrap="wrap" justifyContent="center">
        <CardButton
          onClick={() => handleCardClick("medical")}
          active={preference === "medical" ? 1 : 0}
        >
          <DoctorSVG width={72} height={72} />
          <Typography variant="h6" fontWeight={700}>
            Medical Only
          </Typography>
        </CardButton>

        <CardButton
          onClick={() => handleCardClick("drug")}
          active={preference === "drug" ? 1 : 0}
        >
          <MedicineSVG width={72} height={72} />
          <Typography variant="h6" fontWeight={700}>
            Drug Only
          </Typography>
        </CardButton>

        <CardButton
          onClick={() => handleCardClick("both")}
          active={preference === "both" ? 1 : 0}
        >
          <DoctorSVG width={72} height={72} />
          <Typography variant="h6" fontWeight={700} lineHeight={1.2}>
            Medical & Drug
          </Typography>
        </CardButton>
      </Stack>
      <Divider sx={{ width: "90%", maxWidth: "700px" }} />
      <LoadingButton
        variant={preference ? "contained" : "outlined"}
        endIcon={<ArrowForward />}
        onClick={() => handleButtonClick()}
        loading={isLoading}
      >
        {preference ? "Next" : "Skip to All Plans"}
      </LoadingButton>
    </Stack>
  );
}
