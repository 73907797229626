import * as React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { useAppSelector } from "../../redux/hooks";
import { useLoadAffiliateDataMutation } from "../../redux/services/API";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import { Loader } from "../../components/shared/Loader";

import { useRudderStackAnalytics } from "../../utils/useRudderStackAnalytics";

import defaultTheme from "../../styles/theme";
import { ANALYTICS_EVENT } from "../../config/analytics.config";

export default function RootLayout() {
  const { affiliateId } = useParams();
  const analytics = useRudderStackAnalytics();
  const navigate = useNavigate();

  const Affiliate = useAppSelector((state) => state.Affiliate);

  const [
    findAffiliateMetadata,
    { isLoading: loadingAffiliate, isError: ErrorLoadingAffiliateData },
  ] = useLoadAffiliateDataMutation();

  React.useEffect(() => {
    const code = affiliateId ?? "vm-default";
    findAffiliateMetadata({ Code: code });
    analytics?.track(ANALYTICS_EVENT.AFFILIATE_LOADED, { affiliate_id: code });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (ErrorLoadingAffiliateData) {
      const path = window.location.pathname;
      const searchParams = window.location.search;

      const badAffiliateCode = path.split("/")[1];
      const newPath = path.replace(`/${badAffiliateCode}`, "/vm-default");

      findAffiliateMetadata({ Code: "vm-default" });
      analytics?.track(ANALYTICS_EVENT.AFFILIATE_ERROR, {
        affiliate_id: badAffiliateCode,
      });
      analytics?.track(ANALYTICS_EVENT.AFFILIATE_LOADED, {
        affiliate_id: "vm-default",
      });

      navigate(newPath + searchParams, { replace: true });

      return;
    }
  }, [findAffiliateMetadata, ErrorLoadingAffiliateData, analytics, navigate]);

  const theme = React.useMemo(() => {
    let colorPalette = {
      primary: { main: "" },
      secondary: { main: "" },
      info: { main: "" },
    };

    if (Affiliate.ColorPalette) {
      colorPalette.primary.main = Affiliate.ColorPalette.Primary;
      colorPalette.secondary.main = Affiliate.ColorPalette.Secondary;
      colorPalette.info.main = Affiliate.ColorPalette.Info;
    } else {
      colorPalette = defaultTheme.palette;
    }

    return createTheme({
      ...defaultTheme,
      palette: colorPalette,
    });
  }, [Affiliate.ColorPalette]);

  if (loadingAffiliate || Affiliate.LoadingData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          width: "100vw",
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: "#f4f7fa",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Header />
        <Container maxWidth="xl" disableGutters>
          <Outlet />
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

/**
 * // Another way to fetch affiliate info
  // React.useEffect(() => {
  //   if (isUninitialized) {
  //     console.log("hi");
  //     const code = affiliateId ?? "vm-default";
  //     findAffiliateMetadata({ Code: code });
  //     analytics?.track("Affiliate Loaded", { affiliate_id: code });
  //   }
  // }, [affiliateId, findAffiliateMetadata, analytics, isUninitialized]);
 */
