import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

interface RootProps {
  open: boolean;
  handleClose: () => void;
}
export default function DisclosureDialog({ open, handleClose }: RootProps) {
  const handleClick = () => {
    handleClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => handleClose()}
      sx={{ maxHeight: "70vh" }}
    >
      <DialogContent>
        <Typography variant="h5" fontWeight={600}>
          Idle Timeout
        </Typography>
        <Grid container p={2} direction="column">
          <Typography>
            You haven't been active on this web page for a while. Please click
            below to take you back to Quoting
          </Typography>
          <Button onClick={handleClick}>Click Here</Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
