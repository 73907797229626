import * as React from "react";
import ReactMarkdown from "react-markdown";

import {
  Dialog,
  Box,
  IconButton,
  styled,
  DialogContent,
  Typography,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minHeight: 360,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { id, children, onClose } = props;

  return (
    <Box id={id} sx={{ m: 0, p: 2, bgcolor: "primary.main" }}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <Cancel fontSize="large" />
        </IconButton>
      ) : null}
    </Box>
  );
};

interface RootProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  subtitle?: string;

  markdownFile: string;
}

export default function InformationDialog({
  open,
  handleClose,
  title,
  subtitle,
  markdownFile,
}: RootProps) {
  const [parsedMarkdown, setParsedMarkdown] = React.useState<string>();

  React.useEffect(() => {
    fetch(markdownFile)
      .then((data) => data.text())
      .then((text) => {
        setParsedMarkdown(text);
      });
  }, [markdownFile]);

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="information-dialog-title"
      open={open}
      maxWidth="sm"
      fullWidth
      scroll="paper"
    >
      <CustomDialogTitle id="information-dialog-title" onClose={handleClose}>
        <Typography variant="h5" fontWeight={600} color="#fff" mb={0.5}>
          {title}
        </Typography>

        {subtitle && <Typography color="#fff">{subtitle}</Typography>}
      </CustomDialogTitle>

      <DialogContent dividers sx={{ p: 2 }}>
        <ReactMarkdown
          components={{
            p: ({ children }) => <Typography>{children}</Typography>,
            h1: ({ children }) => (
              <Typography variant="h1">{children}</Typography>
            ),
            h2: ({ children }) => (
              <Typography variant="h2">{children}</Typography>
            ),
            h3: ({ children }) => (
              <Typography variant="h3">{children}</Typography>
            ),
            h4: ({ children }) => (
              <Typography variant="h4">{children}</Typography>
            ),
            h5: ({ children }) => (
              <Typography variant="h5">{children}</Typography>
            ),
            h6: ({ children }) => (
              <Typography variant="h6">{children}</Typography>
            ),
          }}
        >
          {parsedMarkdown}
        </ReactMarkdown>
      </DialogContent>
    </CustomDialog>
  );
}
