import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectedDoctor } from "../../types/Doctor.types";
import { Metadata } from "../../types/Enrollment.types";
import { EnrollPlan } from "../../types/Plan.types";

export interface InputField {
  [key: string]: string | number | boolean | Date;
}

export interface Input {
  [key: string]: InputField;
}

interface EnrollmentState {
  ApplicationID: string | null;
  EnrollPlan: EnrollPlan | null;
  Input: Input;
  CompleteSteps: string[];
  Metadata: Metadata | null;
  SubmitForm: boolean;
  SubmitFormSuccess: boolean;
  SelectedDoctor: SelectedDoctor | null;
  Carrier: string | null;
}

const initialState: EnrollmentState = {
  ApplicationID: null,
  EnrollPlan: null,
  Input: {
    "applicant-information": {},
    "contact-information": {},
    "medicare-information": {},
    "eligibility-questions": {},
    "application-effective-date": {},
    signature: {},
  },
  CompleteSteps: [],
  Metadata: null,
  SubmitForm: false,
  SubmitFormSuccess: false,
  SelectedDoctor: null,
  Carrier: null,
};

export const enrollmentSlice = createSlice({
  name: "Enrollment",
  initialState,
  reducers: {
    setApplicationID: (
      state: EnrollmentState,
      action: PayloadAction<string | null>
    ) => {
      state.ApplicationID = action.payload;
    },
    setEnrollPlan: (
      state: EnrollmentState,
      action: PayloadAction<EnrollPlan | null>
    ) => {
      state.EnrollPlan = action.payload;
    },
    setInput: (state: EnrollmentState, action: PayloadAction<Input>) => {
      const key = Object.keys(action.payload)[0];
      state.Input = {
        ...state.Input,
        [key]: {
          ...state.Input[key],
          ...action.payload[key],
        },
      };
    },
    clearEnrollmentInput: (state: EnrollmentState) => {
      state.Input = {
        "applicant-information": {},
        "contact-information": {},
        "medicare-information": {},
        "eligibility-questions": {},
        "application-effective-date": {},
        signature: {},
      };
    },
    addCompleteStep: (
      state: EnrollmentState,
      action: PayloadAction<string>
    ) => {
      state.CompleteSteps.push(action.payload);
    },
    removeCompleteStep: (
      state: EnrollmentState,
      action: PayloadAction<string>
    ) => {
      state.CompleteSteps = state.CompleteSteps.filter(
        (step) => step !== action.payload
      );
    },
    setMetadata: (
      state: EnrollmentState,
      action: PayloadAction<Metadata | null>
    ) => {
      state.Metadata = action.payload;
    },
    submitForm: (state: EnrollmentState, action: PayloadAction<boolean>) => {
      state.SubmitForm = action.payload;
    },
    submitFormSuccess(state: EnrollmentState) {
      state.SubmitFormSuccess = true;
      /**
       * Clear Input data after successful push
       */
      state.Input = {
        "applicant-information": {},
        "contact-information": {},
        "medicare-information": {},
        "eligibility-questions": {},
        "application-effective-date": {},
        signature: {},
      };
    },
    setSelectedDoctor: (
      state: EnrollmentState,
      action: PayloadAction<SelectedDoctor | null>
    ) => {
      state.SelectedDoctor = action.payload;
    },
    setCarrier: (
      state: EnrollmentState,
      action: PayloadAction<string | null>
    ) => {
      state.Carrier = action.payload;
    },
  },
});

export const {
  setApplicationID,
  setEnrollPlan,
  setInput,
  addCompleteStep,
  removeCompleteStep,
  setMetadata,
  submitForm,
  submitFormSuccess,
  setSelectedDoctor,
  setCarrier,
  clearEnrollmentInput,
} = enrollmentSlice.actions;

export default enrollmentSlice.reducer;
