import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 675,
      md: 1100,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#00a99d",
    },
    secondary: {
      main: "#29abe2",
    },
    error: {
      main: red.A400,
    },
    info: {
      main: "#283c3c",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Montserrat",
      fontWeight: 400,
    },
  },
});
theme.components = {
  MuiSelect: {
    styleOverrides: {
      icon: {
        position: "relative",
        top: 0,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 10,
        fontWeight: 500,
      },
      containedPrimary: {
        color: "#fff",
        fontWeight: 600,
      },
    },
  },
  // MuiTextField: {
  //   styleOverrides: {
  //     root: {
  //       "& .MuiOutlinedInput-root": {
  //         borderColor: "#e5e5e5",
  //         "&:hover fieldset": {
  //           borderColor: alpha(theme.palette.primary.main, 0.5),
  //         },
  //         "&.Mui-focused fieldset": {
  //           borderColor: theme.palette.primary.main,
  //         },

  //         // "&.Mui-focused fieldset": {
  //         //   borderColor: theme.palette.primary.main,
  //         // },
  //       },
  //     },
  //   },
  // },
};

export default theme;
