import { add, endOfYear, format } from "date-fns";

export default function effectiveDateOptions() {
  const TODAY = new Date();
  const options: Array<{
    label: string;
    value: string;
  }> = [];

  let dateIterator = add(TODAY.setDate(1), { months: 1 });

  while (dateIterator < endOfYear(TODAY)) {
    options.push({
      label: format(dateIterator, "MMMM yyyy"),
      value: format(dateIterator, "yyyy-M-d"),
    });

    dateIterator = add(dateIterator, { months: 1 });
  }

  return options;
}
