import * as React from "react";
import { useAppSelector } from "../../redux/hooks";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowForward from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";

import Slider, { CustomArrowProps } from "react-slick";

//@ts-ignore
import { ReactComponent as DoctorSVG } from "../../assets/doctor.svg";
//@ts-ignore
import { ReactComponent as MedicineSVG } from "../../assets/medicine.svg";
//@ts-ignore
import { ReactComponent as PharmacySVG } from "../../assets/pharmacy.svg";
//@ts-ignore
import { ReactComponent as CompareSVG } from "../../assets/compare.svg";

import formatPhoneNumber from "../../utils/formatPhoneNumber";
import { useRudderStackAnalytics } from "../../utils/useRudderStackAnalytics";

import { ANALYTICS_EVENT } from "../../config/analytics.config";

function CustomNextArrow(props: CustomArrowProps) {
  const { onClick } = props;
  return (
    <IconButton
      sx={{
        zIndex: 5,
        position: "absolute",
        right: 5,
        top: 80,
        backgroundColor: "primary.main",
        borderRadius: "50%",
        height: 48,
        width: 48,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        "&:hover": {
          backgroundColor: "primary.main",
        },
      }}
      onClick={onClick}
    >
      <ArrowForward fontSize="large" htmlColor="#fff" />
    </IconButton>
  );
}

function CustomPrevArrow(props: CustomArrowProps) {
  const { onClick } = props;
  return (
    <IconButton
      sx={{
        zIndex: 5,
        position: "absolute",
        left: 5,
        top: 80,
        backgroundColor: "primary.main",
        borderRadius: "50%",
        height: 48,
        width: 48,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        "&:hover": {
          backgroundColor: "primary.main",
        },
      }}
      onClick={onClick}
    >
      <ArrowBack fontSize="large" htmlColor="#fff" />
    </IconButton>
  );
}

const CAROUSEL_SETTINGS = {
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
  className: "carousel-max-width",
  centerMode: true,
  infinite: true,
  slidesToShow: 3,
  speed: 500,
  centerPadding: "0px",
  responsive: [
    {
      breakpoint: 660,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerPadding: "60px",
      },
    },
  ],
};

interface StepProps {
  active?: number;
}
const QuoteStep = styled(Card)<StepProps>(({ theme, active }) => ({
  minWidth: 175,
  height: 175,
  borderRadius: 20,
  padding: "1rem",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  textAlign: "center",
  color: active ? theme.palette.primary.main : "inherit",
  fill: active ? theme.palette.primary.main : "inherit",

  boxShadow: active
    ? "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)"
    : "0",
  cursor: "pointer",

  "&:hover": {
    boxShadow:
      "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
  },
}));

enum STEPS {
  DOCTORS = 0,
  MEDICINES = 1,
  PHARMACY = 2,
  COMPARE = 3,
}

export default function WhyWeAsking() {
  const analytics = useRudderStackAnalytics();

  const [activeStep, setActiveStep] = React.useState(STEPS.DOCTORS);

  const { Phone } = useAppSelector((state) => state.Affiliate);

  const handleClick = (id: number) => {
    setActiveStep(id);

    const stepLabel = STEPS[id];

    analytics?.track(ANALYTICS_EVENT.CONTENT_INTERACTION, {
      content_name: `Quote Step Information - ${stepLabel}`,
      content_url: `#step-${stepLabel}`,
      content_interaction: `User clicked card ${stepLabel} to learn more about it`,
    });
  };

  const DATA = [
    {
      title: "Select Your Doctors",
      body: "Some Medicare plans have networks that include doctors that accept that plan. Networks can vary by plan. Simply select the doctor that you see for your visits. You can search by name, location, or specialty. Once you select your doctors, we can show you which plans cover visits with those doctors.",
    },
    {
      title: "Select Your Medications",
      body: "Medicare plans that include drug coverage are required to have a list of medications that are covered by the plan. These lists of medications are called formularies. To check to ensure your medications are on the formulary for the plan we ask you to search them by name, or medication type.",
    },
    {
      title: "Select Your Pharmacy",
      body: "Medicare plans have the ability to have network pharmacies. By inputting your pharmacy, we can show the estimated price for the medication if you were to pick it up. Mail-order pharmacy options are also available for some plans.",
    },
    {
      title: "Compare Plans",
      body: `Medicare plans are offered in service areas. There can be many plans in a service area, and it can be difficult to understand which plan is best for your needs. That is why we have licensed agents available by phone at ${formatPhoneNumber(
        Phone?.PhoneNumber ?? ""
      )} ${Phone?.TTY && "(TTY User: 711)"} ${
        Phone &&
        `${Phone?.PhoneNumberAvailability.Days} ${Phone?.PhoneNumberAvailability.Hours} ${Phone?.PhoneNumberAvailability.Timezone}`
      } to answer your questions about the options in your area. If you feel comfortable with your selection simply enroll securely online.`,
    },
  ];

  return (
    <Grid container sx={{ pt: 5 }} justifyContent="center">
      <Grid item xs={12} sx={{ mb: 4 }} textAlign="center">
        <Typography variant="h4" fontWeight={600}>
          Let's Simplify This
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} xl={5} m={4} container justifyContent="center">
        <Slider
          {...CAROUSEL_SETTINGS}
          afterChange={(index) => {
            handleClick(index);
          }}
        >
          <Box display="flex !important" justifyContent="center" p={2}>
            <QuoteStep
              id="doctors"
              onClick={() => handleClick(STEPS.DOCTORS)}
              active={activeStep === STEPS.DOCTORS ? 1 : 0}
            >
              <DoctorSVG width={72} height={72} />
              <Typography variant="h6" fontWeight={600}>
                Doctors
              </Typography>
            </QuoteStep>
          </Box>

          <Box display="flex !important" justifyContent="center" p={2}>
            <QuoteStep
              id="medicines"
              onClick={() => handleClick(STEPS.MEDICINES)}
              active={activeStep === STEPS.MEDICINES ? 1 : 0}
            >
              <MedicineSVG width={72} height={72} />
              <Typography variant="h6" fontWeight={600}>
                Medicines
              </Typography>
            </QuoteStep>
          </Box>

          <Box display="flex !important" justifyContent="center" p={2}>
            <QuoteStep
              id="pharmacy"
              onClick={() => handleClick(STEPS.PHARMACY)}
              active={activeStep === STEPS.PHARMACY ? 1 : 0}
            >
              <PharmacySVG width={72} height={72} />
              <Typography variant="h6" fontWeight={600}>
                Pharmacy
              </Typography>
            </QuoteStep>
          </Box>

          <Box display="flex !important" justifyContent="center" p={2}>
            <QuoteStep
              id="compare"
              onClick={() => handleClick(STEPS.COMPARE)}
              active={activeStep === STEPS.COMPARE ? 1 : 0}
            >
              <CompareSVG width={72} height={72} />
              <Typography variant="h6" fontWeight={600}>
                Compare
              </Typography>
            </QuoteStep>
          </Box>
        </Slider>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction="column"
        alignContent="center"
        minHeight="296px"
        sx={{ backgroundColor: "primary.light", p: 5, color: "white" }}
        rowGap={2}
        textAlign={{ xs: "center", sm: "left" }}
      >
        <Typography variant="h3" fontWeight={600}>
          {DATA[activeStep].title}
        </Typography>
        <Typography variant="h5">Why are we asking?</Typography>
        <Typography width={{ xs: "100%", md: "55%" }}>
          {DATA[activeStep].body}
        </Typography>
      </Grid>
    </Grid>
  );
}
