import * as React from "react";
import { useAppSelector } from "../../redux/hooks";

import { alpha, Paper, Stack, styled, Typography } from "@mui/material";
import Slider from "react-slick";

import { ANALYTICS_EVENT } from "../../config/analytics.config";

import formatPhoneNumber from "../../utils/formatPhoneNumber";
import { useRudderStackAnalytics } from "../../utils/useRudderStackAnalytics";

const Contact = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.primary.main, 0.27),

  width: "95%",
  maxWidth: 720,
}));

const MedicareInfo = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.secondary.main, 0.27),

  width: "95%",
  maxWidth: 720,
}));

const sliderSettings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function InfoBox() {
  const analytics = useRudderStackAnalytics();
  const { Phone } = useAppSelector((state) => state.Affiliate);
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  // const handleChange = (currentSlide: number) => {
  //   analytics?.track(ANALYTICS_EVENT.CONTENT_INTERACTION, {
  //     quote_id: QuoteID,
  //     content_name: `Medicare Information - Step ${currentSlide}`,
  //     content_url: `#medicare-info-step-${currentSlide}`,
  //     content_interaction:
  //       "Carousel changed slide so user could learn more Medicare Information",
  //   });
  // };

  return (
    <>
      <Contact>
        <Stack textAlign="center">
          <Typography variant="h6" fontWeight={600}>
            Questions?
          </Typography>
          <Typography variant="h6" fontWeight={600}>
            Call{" "}
            <a
              href={`tel:${Phone?.PhoneNumber}`}
              onClick={() =>
                analytics?.track(ANALYTICS_EVENT.PHONE_NUMBER_CLICKED, {
                  quote_id: QuoteID,
                  section_id: "BODY",
                  link_url: `tel:${Phone?.PhoneNumber}`,
                })
              }
            >
              {formatPhoneNumber(Phone?.PhoneNumber ?? "")}
            </a>{" "}
            {Phone?.TTY && "(TTY User: 711)"}{" "}
          </Typography>
          <Typography>
            Talk to a Licensed Insurance Agent{" "}
            {Phone &&
              `${Phone?.PhoneNumberAvailability.Days} ${Phone?.PhoneNumberAvailability.Hours} ${Phone?.PhoneNumberAvailability.Timezone}`}
          </Typography>
        </Stack>
      </Contact>
      <MedicareInfo>
        <Stack justifyContent="center" textAlign="center">
          <Typography variant="h6" fontWeight={600}>
            Medicare Information
          </Typography>
          <Slider {...sliderSettings}>
            <div>
              <Typography>
                Medicare Advantage Plans (like an HMO or PPO) are health plans
                administered by Medicare-approved private insurance companies.
                Medicare Advantage Plans include everything covered under Part
                A, Part B, and may include other coverage like Medicare
                prescription drug coverage (Part D), sometimes for an extra
                cost.
              </Typography>
            </div>
            <div>
              <Typography>
                Some Medicare Advantage plans may offer low or $0 monthly plan
                premiums because of various factors such as federal government
                payments to the plan. However, many plans may have a deductible,
                copayments, or coinsurance amounts that you have to pay for
                covered services, such as doctor office visits and other medical
                services, before Medicare or your plan begins to pay its share
                of the costs.
              </Typography>
            </div>
            <div>
              <Typography>
                Maximum out-of-pocket costs are yearly limits on your
                out-of-pocket costs for covered medical and hospital care in a
                calendar year. After you meet the out of pocket maximum, your
                plan pays for 100% of the cost for covered services.
              </Typography>
            </div>
            <div>
              <Typography>
                The Centers for Medicare and Medicaid Services created the Star
                Rating System to measure how Medicare Advantage and Part D plans
                perform. Medicare rates plans in several categories, including
                quality of care and customer service. Ratings range from one to
                five stars, with five being the highest (excellent) and one
                being the lowest (poor). Every year, Medicare evaluates plans
                based on a 5-star rating system.
              </Typography>
            </div>
          </Slider>
        </Stack>
      </MedicareInfo>
    </>
  );
}
