import * as React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Plan } from "../../../types/Plan.types";

export interface ComparisonSubSection {
  title: string;
  values: Array<{
    value: string | number | undefined | null;
    icon?: React.ReactElement;
  }>;
}

export function ComparisonTable({
  sections,
}: {
  sections: Array<{
    title: string;
    subsections: ComparisonSubSection[];
  }>;
}) {
  return (
    <Grid container rowGap={2}>
      {sections &&
        sections.map((section, index) => {
          return (
            <React.Fragment key={`${section.title}`}>
              <Grid item xs={12}>
                <Typography
                  fontWeight={600}
                  sx={{ textDecoration: "underline" }}
                >
                  {section.title}
                </Typography>
              </Grid>

              {section.subsections &&
                section.subsections.map((subsection) => {
                  return (
                    <>
                      {subsection.values.map((value) => {
                        return (
                          <Grid
                            key={`${section.title}-sub-${subsection.title}`}
                            item
                            xs={12 / subsection.values.length}
                            container
                            gap={2}
                            pl={2}
                            pb={2}
                            sx={{
                              borderBottom:
                                index === sections.length - 1
                                  ? "none"
                                  : "2px solid #efefef",
                            }}
                          >
                            <Grid item xs={12}>
                              <Typography fontWeight={500}>
                                {subsection.title}
                              </Typography>
                            </Grid>

                            <Grid item xs={12}>
                              <Typography variant="body2">
                                {value.value ?? "Not Covered"}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </>
                  );
                })}
            </React.Fragment>
          );
        })}
    </Grid>
  );
}

export function ComparisonSubBenefit({
  title,
  icon,
  subsections,
}: {
  title: string;
  icon?: React.ReactElement;
  subsections: ComparisonSubSection[];
}) {
  return (
    <Grid container rowGap={1}>
      <Grid item xs={12} display="inline-flex" gap={1}>
        <Typography fontWeight={600} sx={{ textDecoration: "underline" }}>
          {title}
          {icon}
        </Typography>
      </Grid>
      {subsections &&
        subsections.map((item) => {
          return (
            <>
              {item.values.map((value) => {
                return (
                  <Grid
                    item
                    xs={12 / item.values.length}
                    container
                    justifyContent="space-between"
                    pl={2}
                  >
                    <Grid item>
                      <Typography fontWeight={500}>{item.title}</Typography>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        borderBottom: "1.5px solid #efefef",
                        flexGrow: 1,
                        mx: 1,
                      }}
                    />
                    <Grid item display="inline-flex">
                      {value.icon ?? null}
                      <Typography fontWeight={600}>
                        {value.value ?? "Not Covered"}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </>
          );
        })}
    </Grid>
  );
}

export function generateComparisonSection(
  planA: Plan,
  planB: Plan,
  fields: Array<{ id: string; title: string }>
) {
  const subsections: ComparisonSubSection[] = [];

  if (!planA || !planB) {
    return subsections;
  }

  fields.forEach((detail) => {
    const valuePlanA = planA.PlanDetail?.InNetwork[detail.id];
    const valuePlanB = planB.PlanDetail?.InNetwork[detail.id];
    if (valuePlanA || valuePlanB) {
      const newSubsection: ComparisonSubSection = {
        title: detail.title,
        values: [{ value: valuePlanA }, { value: valuePlanB }],
      };
      subsections.push(newSubsection);
    }
  });

  return subsections;
}
