export enum STEPS {
  DOCTORS,
  MEDICINES,
  PHARMACY,
  COMPARE,
}

export enum testSteps {
  DOCTORS = 0,
  DRUG_ONLY_MEDICINES = 0,
  OTHER_PHARMACY = 1,
  MEDICINES = 1,
  PHARMACY = 2,
  OTHER_COMPARE = 2,
  COMPARE = 3,
}

export type test = {
  [key: number]: {
    id: testSteps;
    url: string;
    label: string;
    title: string;
    step: string;
  };
};

export const testAll: test = {
  [testSteps.DOCTORS]: {
    id: testSteps.DOCTORS,
    url: "doctors",
    label: "Doctors",
    title: "Select Your Doctors",
    step: "First",
  },
  [testSteps.MEDICINES]: {
    id: testSteps.MEDICINES,
    url: "medicines",
    label: "Medicines",
    title: "Select Your Medicines",
    step: "Second",
  },
  [testSteps.PHARMACY]: {
    id: testSteps.PHARMACY,
    url: "pharmacy",
    label: "Pharmacy",
    title: "Select Your Pharmacy",
    step: "Third",
  },
  [testSteps.COMPARE]: {
    id: testSteps.COMPARE,
    url: "compare",
    label: "Compare",
    title: "Compare Plans",
    step: "Fourth",
  },
};

export const testMedical: test = {
  [testSteps.DOCTORS]: {
    id: testSteps.DOCTORS,
    url: "doctors",
    label: "Doctors",
    title: "Select Your Doctors",
    step: "First",
  },
  [testSteps.OTHER_PHARMACY]: {
    id: testSteps.OTHER_PHARMACY,
    url: "pharmacy",
    label: "Pharmacy",
    title: "Select Your Pharmacy",
    step: "Second",
  },
  [testSteps.OTHER_COMPARE]: {
    id: testSteps.OTHER_COMPARE,
    url: "compare",
    label: "Compare",
    title: "Compare Plans",
    step: "Third",
  },
};

export const testDrugOnly: test = {
  [testSteps.DRUG_ONLY_MEDICINES]: {
    id: testSteps.DRUG_ONLY_MEDICINES,
    url: "medicines",
    label: "Medicines",
    title: "Select Your Medicines",
    step: "First",
  },
  [testSteps.OTHER_PHARMACY]: {
    id: testSteps.OTHER_PHARMACY,
    url: "pharmacy",
    label: "Pharmacy",
    title: "Select Your Pharmacy",
    step: "Second",
  },
  [testSteps.OTHER_COMPARE]: {
    id: testSteps.OTHER_COMPARE,
    url: "compare",
    label: "Compare",
    title: "Compare Plans",
    step: "Third",
  },
};

export const ALL_QUOTE_STEPPER_INFORMATION = [
  {
    id: STEPS.DOCTORS,
    url: "doctors",
    label: "Doctors",
    title: "Select Your Doctors",
    step: "First",
  },
  {
    id: STEPS.MEDICINES,
    url: "medicines",
    label: "Medicines",
    title: "Select Your Medicines",
    step: "Second",
  },
  {
    id: STEPS.PHARMACY,
    url: "pharmacy",
    label: "Pharmacy",
    title: "Select Your Pharmacy",
    step: "Third",
  },
  {
    id: STEPS.COMPARE,
    url: "compare",
    label: "Compare",
    title: "Compare Plans",
    step: "Fourth",
  },
];

type id_string = { [id: string]: string };
export const CARRIER_NAMES: id_string = {
  aetna: "Aetna",
  humana: "Humana",
  uhc: "United Healthcare",
  bcbs: "Blue Cross Blue Shield",
  anthem: "Anthem",
};

export const LICENSED_STATES = [
  { name: "Texas", licenseNumber: "2292957" },
  { name: "South Dakota", licenseNumber: "10020520" },
  { name: "North Carolina", licenseNumber: "822148528" },
  { name: "Nebraska", licenseNumber: "3000686227" },
  { name: "Indiana", licenseNumber: "3330527" },
  { name: "Wisconsin", licenseNumber: "3001176516" },
  { name: "Tennessee", licenseNumber: "2414958" },
  { name: "South Carolina", licenseNumber: "1913396764" },
  { name: "Connecticut", licenseNumber: "2682634" },
  { name: "Georgia", licenseNumber: "200002" },
  { name: "Louisiana", licenseNumber: "841811" },
  { name: "Ohio", licenseNumber: "1188114" },
  { name: "Missouri", licenseNumber: "3000653450" },
  { name: "Arkansas", licenseNumber: "3000886991" },
  { name: "Utah", licenseNumber: "856222" },
  { name: "Mississippi", licenseNumber: "15036418" },
  { name: "New Jersey", licenseNumber: "300646839" },
  { name: "Iowa", licenseNumber: "1002314386" },
  { name: "Virginia", licenseNumber: "143660" },
  { name: "Arizona", licenseNumber: "3000334678" },
  { name: "California", licenseNumber: "0M48156" },
  { name: "Colorado", licenseNumber: "581820" },
  { name: "Illinois", licenseNumber: "3000339932" },
  { name: "Michigan", licenseNumber: "115728" },
  { name: "Minnesota", licenseNumber: "40630320" },
  { name: "Oklahoma", licenseNumber: "3000924946" },
  { name: "Pennsylvania", licenseNumber: "857045" },
  { name: "Hawaii", licenseNumber: "522266" },
  { name: "Oregon", licenseNumber: "3001058779" },
  { name: "Alabama", licenseNumber: "3000334137" },
  { name: "Kansas", licenseNumber: "822148528-0" },
  { name: "Washington", licenseNumber: "1064509" },
  { name: "Florida", licenseNumber: "L102167" },
];

export interface API_RESPONSE<T, U = {}> {
  data: T;
  meta?: U;
}

export const GOOGLE_MAPS_API_LIBRARIES = ["places"] as (
  | "drawing"
  | "geometry"
  | "localContext"
  | "places"
  | "visualization"
)[];

export interface AzureSearchAddressResponse {
  summary: {
    fuzzyLevel: number;
    geoBias: { lat: number; lon: number };
    limit?: number;
    numResults: number;
    offset?: number;
    query: string;
    queryTime: number;
    queryType: "NEARBY" | "NON_NEAR";
    totalResults: number;
  };
  results: AzureSearchAddressResult[];
}

export interface AzureSearchAddressResult {
  type:
    | "POI"
    | "Street"
    | "Geography"
    | "Point Address"
    | "Address Range"
    | "Cross Street";
  id: string;
  score: number;
  dist: number;
  matchConfidence: {
    score: number;
  };
  address: {
    streetNumber: string;
    streetName: string;
    municipalitySubdivision?: string;
    municipality: string;
    neighbourhood?: string;
    countrySecondarySubdivision: string;
    countrySubdivision: string;
    countrySubdivisionName: string;
    countrySubdivisionCode: string;
    postalCode: string;
    extendedPostalCode?: string;
    countryCode: string;
    country: string;
    countryCodeISO3?: string;
    freeformAddress: string;
    localName?: string;
  };
  position: {
    lat: number;
    lon: number;
  };
  viewport: {
    topLeftPoint: {
      lat: number;
      lon: number;
    };
    btmRightPoint: {
      lat: number;
      lon: number;
    };
  };
  entryPoints?: [
    {
      type: "main" | "minor";
      position: {
        lat: number;
        lon: number;
      };
    }
  ];
  addressRanges?: {
    rangeRight?: string;
    rangeLeft?: string;
    from: {
      lat: number;
      lon: number;
    };
    to: {
      lat: number;
      lon: number;
    };
  };
}

export enum US_STATE {
  "AL" = "AL",
  "AK" = "AK",
  "AS" = "AS",
  "AZ" = "AZ",
  "AR" = "AR",
  "CA" = "CA",
  "CO" = "CO",
  "CT" = "CT",
  "DE" = "DE",
  "DC" = "DC",
  "FM" = "FM",
  "FL" = "FL",
  "GA" = "GA",
  "GU" = "GU",
  "HI" = "HI",
  "ID" = "ID",
  "IL" = "IL",
  "IN" = "IN",
  "IA" = "IA",
  "KS" = "KS",
  "KY" = "KY",
  "LA" = "LA",
  "ME" = "ME",
  "MH" = "MH",
  "MD" = "MD",
  "MA" = "MA",
  "MI" = "MI",
  "MN" = "MN",
  "MS" = "MS",
  "MO" = "MO",
  "MT" = "MT",
  "NE" = "NE",
  "NV" = "NV",
  "NH" = "NH",
  "NJ" = "NJ",
  "NM" = "NM",
  "NY" = "NY",
  "NC" = "NC",
  "ND" = "ND",
  "MP" = "MP",
  "OH" = "OH",
  "OK" = "OK",
  "OR" = "OR",
  "PW" = "PW",
  "PA" = "PA",
  "PR" = "PR",
  "RI" = "RI",
  "SC" = "SC",
  "SD" = "SD",
  "TN" = "TN",
  "TX" = "TX",
  "UT" = "UT",
  "VT" = "VT",
  "VI" = "VI",
  "VA" = "VA",
  "WA" = "WA",
  "WV" = "WV",
  "WI" = "WI",
  "WY" = "WY",
}
