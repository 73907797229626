import * as React from "react";
import { useSaveQuoteAttributesMutation } from "../../../redux/services/API";

import {
  Button,
  Typography,
  useMediaQuery,
  Theme,
  Grid,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { LoadingButton } from "@mui/lab";
import Cancel from "@mui/icons-material/Cancel";

import BasicInformation from "./BasicInformation";
import HealthConditions from "./HealthConditions";
import { useAppSelector } from "../../../redux/hooks";
import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minHeight: 360,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { id, children, onClose } = props;

  return (
    <Box id={id} sx={{ m: 0, p: 2, bgcolor: "primary.main" }}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <Cancel fontSize="large" />
        </IconButton>
      ) : null}
    </Box>
  );
};

export enum MED_SUPP_FORM_STEP {
  BASIC_INFO = 1,
  HEALTH_CONDITION = 2,
}

interface RootProps {
  open: boolean;
  handleClose: () => void;
  handleCancel: () => void;
}

export default function MedSuppQuestionsDialog({
  open,
  handleClose,
  handleCancel,
}: RootProps) {
  const analytics = useRudderStackAnalytics();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const [formStep, setFormStep] = React.useState<MED_SUPP_FORM_STEP>(
    MED_SUPP_FORM_STEP.BASIC_INFO
  );

  const submitBasicInfo = React.useRef<HTMLButtonElement>(null);
  const submitHealthConditions = React.useRef();

  const [saveAnswers, { isLoading: isPushingAnswers }] =
    useSaveQuoteAttributesMutation();

  React.useEffect(() => {
    if (!analytics || !QuoteID) {
      return;
    }

    analytics.track(ANALYTICS_EVENT.MED_SUPP_QUESTIONS_DIALOG_OPENED, {
      quote_id: QuoteID,
    });

    return () => {
      analytics.track(ANALYTICS_EVENT.MED_SUPP_QUESTIONS_DIALOG_CLOSED, {
        quote_id: QuoteID,
      });
    };
  }, [QuoteID, analytics]);

  const handleSaveAnswers = () => {
    switch (formStep) {
      case MED_SUPP_FORM_STEP.BASIC_INFO:
        //@ts-ignore
        submitBasicInfo?.current?.click();
        break;

      case MED_SUPP_FORM_STEP.HEALTH_CONDITION:
        //@ts-ignore
        submitHealthConditions?.current?.click();
        break;

      default:
        break;
    }
  };

  const handleNextStep = (
    currentStep: MED_SUPP_FORM_STEP,
    hasHealthCondition?: boolean
  ) => {
    switch (currentStep) {
      case MED_SUPP_FORM_STEP.BASIC_INFO:
        if (hasHealthCondition) {
          setFormStep(MED_SUPP_FORM_STEP.HEALTH_CONDITION);
          break;
        }

        handleClose();
        break;

      case MED_SUPP_FORM_STEP.HEALTH_CONDITION:
        handleClose();
        break;

      default:
        handleClose();
        break;
    }
  };

  const handleBack = () => {
    setFormStep(MED_SUPP_FORM_STEP.BASIC_INFO);
  };

  return (
    <CustomDialog
      // onClose={handleClose}
      aria-labelledby="answer-med-supp-questions-dialog-title"
      open={open}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      scroll="paper"
    >
      <CustomDialogTitle
        id="answer-med-supp-questions-dialog-title"
        onClose={handleClose}
      >
        <Typography variant="h5" fontWeight={600} color="#fff" mb={0.5}>
          Your Information
        </Typography>

        <Typography color="#fff">
          Tell us about yourself so we can recommend Medicare Supplement
          Insurance Plans that may fit your needs.
        </Typography>
      </CustomDialogTitle>

      <DialogContent dividers sx={{ p: 2 }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" fontWeight={600}>
              {formStep === MED_SUPP_FORM_STEP.BASIC_INFO &&
                "Basic Information"}
              {formStep === MED_SUPP_FORM_STEP.HEALTH_CONDITION &&
                "Health Conditions"}
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant="body2" color="#6b6b6b">
              * Required Field
            </Typography>
          </Grid>

          {formStep === MED_SUPP_FORM_STEP.BASIC_INFO && (
            <BasicInformation
              handleNextStep={handleNextStep}
              submitRef={submitBasicInfo}
              saveAnswers={saveAnswers}
            />
          )}

          {formStep === MED_SUPP_FORM_STEP.HEALTH_CONDITION && (
            <HealthConditions
              handleNextStep={handleNextStep}
              submitRef={submitHealthConditions}
              saveAnswers={saveAnswers}
            />
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="flex-end" gap={1}>
          {formStep === MED_SUPP_FORM_STEP.BASIC_INFO && (
            <Button
              type="button"
              variant="text"
              onClick={() => handleCancel()}
              disabled={isPushingAnswers}
            >
              Cancel
            </Button>
          )}
          {formStep === MED_SUPP_FORM_STEP.HEALTH_CONDITION && (
            <Button
              type="button"
              variant="text"
              onClick={() => handleBack()}
              disabled={isPushingAnswers}
            >
              Back
            </Button>
          )}
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isPushingAnswers}
            onClick={handleSaveAnswers}
          >
            Save Answers
          </LoadingButton>
        </Grid>
      </DialogActions>
    </CustomDialog>
  );
}
