import * as React from "react";
import { Controller, Control, RegisterOptions } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

import Tooltip from "@mui/material/Tooltip";
import Info from "@mui/icons-material/Info";
import DropdownDatePicker from "../../shared/DropdownDatePicker";

interface RootProps {
  name: string;
  control: Control<any>;
  errorMessage: string | undefined;
  required?: boolean;
  label: string;
  initialValue?: string | number;
  hint?: {
    Title?: string;
    Content: string;
  };
  validation?: RegisterOptions;
  minDate?: string;
  maxDate?: string;
}

export default function DateInput({
  name,
  control,
  errorMessage,
  label,
  required,
  initialValue,
  hint,
  validation,
  minDate,
  maxDate,
}: RootProps) {
  return (
    <FormControl fullWidth>
      <FormLabel
        sx={{ display: "inline-flex", alignItems: "center", gap: 1, mb: 0.5 }}
      >
        <Typography fontWeight={500}>
          {label} {required ? "*" : "(Optional)"}
        </Typography>
        {hint && (
          <Tooltip title={<Typography>{hint.Content}</Typography>}>
            <Info color="secondary" sx={{ cursor: "pointer" }} />
          </Tooltip>
        )}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={initialValue ?? ""}
        shouldUnregister
        rules={{
          ...validation,
          pattern: {
            value: /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/,
            message: "Enter a valid date",
          },
        }}
        render={({ field }) => {
          return (
            <DropdownDatePicker
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              errorMessage={errorMessage}
              minDate={minDate}
              maxDate={maxDate}
            />
          );
        }}
      />
    </FormControl>
  );
}
