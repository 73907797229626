/**
 * Plan Object sent from API
 */
// export interface PlanInfo {
//   _id: string;
//   SSA: string;
//   ContractID: string;
//   PlanID: string;
//   Carrier: string;
//   Product: string;
//   PlanType: string;
//   StarRating: number;
//   PlanName: string;
//   PartB: boolean;
//   IN_PCP: number;
//   IN_Specialist: number;
//   MedicalDeductible: number;
//   MOOP: number;
//   Dental: boolean;
//   Vision: boolean;
//   Hearing: boolean;
//   OTC: boolean;
//   Transportation: boolean;
//   Fitness: boolean;
//   DrugDeductible: number;
//   DrugPremium: number;
//   FormularyID: string;
//   MedicalPremium: number;
//   SegmentID: string;
// }

// /**
//  * Plan Details Object sent from the API
//  */
// export interface PlanDetailsInfo {
//   _id: string;
//   ContractID: string;
//   PlanID: string;
//   SegmentID: string;
//   PlanName: string;
//   LimitedNetwork: string;
//   VisitorTravelerProgram: string;
//   PCP: string;
//   Specialist: string;
//   InpatientHospital: string;
//   ER: string;
//   Ambulance: string;
//   AmbulatorySurgicalCenter: string;
//   DME: string;
//   DiabeticMonitoringSupplies: string;
//   LabServices: string;
//   DiagnosticProcedures: string;
//   PreventiveBenefits: string;
//   Fitness: string;
//   Meals: string;
//   Transportation: string;
//   OTC: string;
//   DentalCoverage: string;
//   EyewearCoverage: string;
//   HearingAidCoverage: string;
//   DentalNetwork: string;
//   DentalProviderDirectoryLink: string;
//   Troop: string;
//   AdditionalGapCoverage: string;
//   SNF: string;
//   OutpatientMentalHealth: string;
//   AdditionalTelehealthServices: string;
//   HomeHealthCare: string;
//   Imaging: string;
//   DrugTierPricing: {
//     PreferredRetail30Day: {
//       PreferredGeneric: string;
//       Generic: string;
//       PreferredBrand: string;
//       NonPreferredBrand: string;
//       Speciality: string;
//     };
//     PreferredMail90Day: {
//       PreferredGeneric: string;
//       Generic: string;
//       PreferredBrand: string;
//       NonPreferredBrand: string;
//     };
//     StandardRetail30Day: {
//       PreferredGeneric: string;
//       Generic: string;
//       PreferredBrand: string;
//       NonPreferredBrand: string;
//       Speciality: string;
//     };
//     StandardMail90Day: {
//       PreferredGeneric: string;
//       Generic: string;
//       PreferredBrand: string;
//       NonPreferredBrand: string;
//     };
//   };
//   Links: Array<{
//     Type: string;
//     Name: string;
//     Link: string;
//   }>;
// }

/**
 * Sent to Plan API to find Plan in DB
 */
// export interface PlanDetailsBody {
//   ContractID: string;
//   PlanID: string;
//   SegmentID?: string;
//   Carrier: string;
// }

// export interface PlanDetailsResponse {
//   plan: PlanInfo;
//   details: PlanDetailsInfo;
// }

interface PlanDataField {
  Name: string;
  Description: string;
  Type: string;
  benefitCategoryID: number;
  BenefitId: number;
  Range: BenefitRange[];
}

interface BenefitRange {
  RangeMax: number;
  RangeMin: number;
  Amount: number;
  AmountType: number;
}

interface PlanTier {
  PharmacyType: number;
  TierInformation: Tier[];
  DaysSupply: number;
}

interface Tier {
  Description: string;
  Amount: string;
  TierNumber: number;
}

interface Document {
  Types: DocumentType[];
  Name: string;
  Url: string;
  LinkName: string;
}

interface DocumentType {
  Name: string;
}

interface PlanExtendedSection {
  HeaderType: number;
  SectionDetails: PlanExtendedDetail[];
  SectionID: number;
}

interface PlanExtendedDetail {
  Description: string;
  DetailID: number;
  FileSize: string;
  IsEnrollLink: boolean;
  isExternalLink: boolean;
  IsOutOfNetwork: boolean;
  IsPharmacyDoc: boolean;
  IsValueAddedBenefit: boolean;
  LinkName: string;
  NumberOfPages: string;
  Tags: Tag[];
  Types: DocumentType[];
  TypeID: number;
  Value: string;
  ValueTypeID: number;
}

interface Tag {
  Name: string;
  Value: string;
}

interface PlanBenefit {
  planID: string;
  benefitAmount: string;
  benefitNote: string;
}

interface TypeRider {
  PlanID: string;
  PlanName: string;
  PlanType: number;
  PlanSubType: string;
  CalculatedMedicalPremium: number;
  ApplicationFee: number;
  CalculatedMedicalPremiumRefreshDate: string;
  HasCalculatedMedicalPremium: boolean;
  ExtendedPlanDetails: PlanExtendedSection[];
  PlanTypeAbbreviation: string;
  Benefits: PlanBenefit[];
  PlanTypeDescription: string;
  CanBeMerged: boolean;
  IsMergedRider: boolean;
  IsRequiredRider: boolean;
  IsWaiverStateRider: boolean;
  MedicalPremium: number;
  MergedRiderIDs: number[];
  PartnerRiders: PartnerRider[];
}

interface PartnerRider {
  PartnerRiderID: number;
  IsMergePartner: boolean;
  MergedRiderID: number;
}

interface PlanExtraData {
  Name: string;
  Value: string;
}

interface MonthlyCost {
  MonthID: number;
  TotalMonthlyCost: number;
  CostPhases: string;
  CostDetail: MonthlyDrugCostDetail[];
}

interface MonthlyDrugCostDetail {
  FullCost: number;
  MemberCost: number;
  Phase: string;
  LabelName: string;
}

interface DrugCost {
  LabelName: string;
  Quantity: number;
  FullCost: number;
  Deductible: number;
  BeforeGap: number;
  Gap: number;
  AfterGap: number;
  DrugFootnotes: DrugFootnote[];
}

interface DrugFootnote {
  Number: number;
  Letter: string;
  Description: string;
}

interface PharmacyCost {
  PharmacyType: number;
  SpecifiedPharmacy: boolean;
  PharmacyID: string;
  IsPreferred: boolean;
  MonthlyCosts: MonthlyCost[];
  DrugCosts: DrugCost[];
  IsNetwork: boolean;
  HasCeilingPrice: boolean;
}

interface FormularyStatus {
  TierNumber: number;
  TierDescription: string;
  HasQuantityLimit: boolean;
  HasStepTherapy: boolean;
  HasPriorAuthorization: boolean;
  QuantityLimitAmount: number;
  QuantityLimitDays: number;
  QuantityLimitDescription: string;
  LabelName: string;
  NDC: string;
  LimitedAccess: boolean;
}

interface PlanFormularyTier {
  TierDescription: string;
  TierNumber: number;
  FormularyID: number;
  CoveredDosages: number;
  IsExceptionTier: boolean;
  CopayPrices: PlanFormularyCost[];
}

interface PlanFormularyCost {
  Cost: number;
  CostType: DrugCostType;
  IsMailOrder: boolean;
  IsPreferredPharmacy: boolean;
  DaysOfSupply: number;
  DefaultBenefit: boolean;
}

export enum DrugCostType {
  COPAY = 1,
  COINSRUANCE = 2,
}

interface BenefitCategoryContribution {
  BenefitCategoryName: string;
  BenefitCategoryID: number;
  Occurrences: number;
  Duration: number;
  ContributionToCost: number;
}

export interface Plan {
  ID: string;
  ContractID: string;
  PlanID: string;
  SegmentID: string;
  PlanType: number;
  PlanSubType: string;
  PlanYear: string;
  PlanName: string;
  CarrierName: string;
  PlanRating: number;
  MedicalRating: number;
  DrugRating: number;
  AnnualPlanPremium: number;
  AnnualCalculatedPlanPremium: number;
  EstimatedAnnualDrugCost: number;
  EstimatedAnnualMedicalCost: number;
  MailOrderAnnualCost: number;
  DrugDeductible: number;
  DrugPremium: number;
  MaximumOutOfPocketCost: number;
  MedicalDeductible: number;
  MedicalPremium: number;
  InitialCoverageLimit: number;
  PlanDataFields: PlanDataField[];
  PlanTiers: PlanTier[];
  PharmacyCosts: PharmacyCost[];
  Documents: Document[];
  EstimatedAnnualDrugCostPartialYear: number;
  EstimatedAnnualMailOrderDrugCostPartialYear: number;
  ModelDrugProgram: boolean;
  EstimatedAnnualMedicalCostPartialYear: number;
  AllowCostSharingReduction: boolean;
  ApplicationFee: number;
  BrandName: string;
  CatastrophicLimit: number;
  CoInsurance: number;
  CombinedDeductibleCombined: number;
  CombinedDeductibleInNetwork: number;
  CombinedDeductibleOutOfNetwork: number;
  CombinedMOOPCombined: number;
  CombinedMOOPInNetwork: number;
  CombinedMOOPOutOfNetwork: number;
  CSRLevel: number;
  EffectiveEndDate: string;
  IsPlanNetworkAvailable: boolean;
  EffectiveStartDate: string;
  ExtendedPlanDetails: PlanExtendedSection[];
  ExternalID: string;
  ExternalPlanID: string;
  ExternalSegmentID: string;
  ExtraData: PlanExtraData[];
  HasDrugCoverage: boolean;
  HasMailDrugBenefits: boolean;
  HasPreferredMailPharmacyNetwork: boolean;
  HasPreferredPharmacyNetwork: boolean;
  HasPreferredRetailPharmacyNetwork: boolean;
  IsAvailableNationwide: boolean;
  IsHSAQualifiedPlan: boolean;
  MetalID: number;
  MetalName: string;
  ModifiedDate: string;
  OrganizationName: string;
  PlanTypeAbbreviation: string;
  PlanTypeDescription: string;
  ProductName: string;
  ProviderURL: string;
  RatingArea: string;
  FormularyExternalID: string;
  FormularyVersion: string;
  PlanDrugCoverage: FormularyStatus[];
  ContributionType: number;
  CalculatedMedicalPremium: number;
  ExternalSourceDescription: string;
  ExternalSourceID: number;
  FormularyTiers: PlanFormularyTier[];
  LogoURL: string;
  PhoneMember: string;
  PhoneMemberTTY: string;
  PhoneNonMember: string;
  PhoneNonMemberTTY: string;
  Riders: TypeRider[];
  PreferenceMatch: number;
  //Providers
  NonLicensedPlan: boolean;
  SNPType: number;
  SNPDescription: string;
  IsSelectPlan: boolean;
  MarketingName: string;
  DaysCovered: string;
  BenefitAmount: string;
  EstimatedAnnualMedicalCostBenefitBreakdown: BenefitCategoryContribution[];

  // Custom Added Fields from Plan Service
  Points: number;
  Ancillary?: {
    Vision: boolean;
    Hearing: boolean;
    Dental: boolean;
    PartB: boolean;
    Transportation: boolean;
    Fitness: boolean;
    OTC: boolean;
    SNF: boolean;
    Blood: boolean;
    ForeignTravel: boolean;
    PartBExcessCharges: boolean;
  };
  PcpCost?: {
    InNetwork: string | null;
    OutOfNetwork: string | null;
  };
  SpecialistCost?: {
    InNetwork: string | null;
    OutOfNetwork: string | null;
  };
  ZelisNetwork?: string;
  DrugTier?: {
    [type: string]: {
      [type: string]: DrugTier;
    };
  };
  MonthlyDrugCosts: MonthlyDrugCost[];
  PlanDetail?: {
    InNetwork: {
      [key: string]: string;
    };
    OutOfNetwork: {
      [key: string]: string;
    };
  };
}

export interface MonthlyDrugCost {
  Month: string;
  TotalRetail?: number;
  TotalMailOrder?: number;
  CostDetail: CostDetail[];
}

interface CostDetail {
  DrugName: string;
  Tier: number;
  HasQuantityLimit: boolean;
  QuantityLimitAmount?: number;
  QuantityLimitDays?: number;
  HasPriorAuthorization: boolean;
  HasStepTherapy: boolean;
  RetailCost?: number;
  MailOrderCost?: number;
  RetailCostPhase?: string;
  MailOrderCostPhase?: string;
}

interface DrugTier {
  30: {
    [tier: string]: string;
  };
  60: {
    [tier: string]: string;
  };
  90: {
    [tier: string]: string;
  };
}

/** Reduced Plan for Favorite */
export interface PlanFavorite {
  ID: string;
  ContractID: string;
  PlanID: string;
  SegmentID: string;
  LogoURL: string;
  PlanName: string;
  PlanType: number;
  PlanYear: string;
  MedicalPremium: number;
  DrugPremium: number;
  MarketingName: string;
  CarrierName: string;
  PlanSubType: string;
  QuotedPlanPremium: number;
  SnpType: number;
}

export enum PlanType {
  PDP = 1,
  MAPD = 2,
  MA = 4,
  RIDER = 512,
  MEDSUPP = 256,
}

export interface PlanResponse {
  data: Plan[];
  meta: {
    carriers: string[];
    planSubTypes: string[];
    planFeatures: string[];
    totalPlans: number;
  };
}

export interface Dosage {
  DaysOfSupply: string;
  MetricQuantity: string;
  NDC: string;
}

export interface DosagePharmacy {
  PharmacyID: string;
  IsMailOrder: string;
  PharmacyIDType: string;
}

export interface PlanDetailsBody {
  planID: string | number;
  Dosages?: Dosage[];
  Pharmacy?: DosagePharmacy;
  Zip?: string;
  Fips?: string;
}

export interface ComparePlan {
  ID: string;
  PlanName: string;
  LogoURL: string;
  PlanYear: string;
  ContractID: string;
  PlanID: string;
  SegmentID: string;
  SNPType: number;
  CarrierName: string;
  PlanType: number;
}

export interface EnrollPlan {
  PlanName: string;
  ContractID: string;
  PlanID: string;
  SegmentID: string;
  Carrier: string;
  PlanType: PlanType;
  DrxID: string;
  Year: number;
  LogoURL: string;
  QuotedPremium: number;
  PlanRating: number;
  SnpType?: number;
}

export enum PlanSortType {
  BEST_MATCH = 1,
  CARRIER_NAME = 2,
  LOWEST_PREMIUM = 3,
}

export enum PlanTypeTabs {
  MAPD = 0,
  MA = 1,
  SUPP = 2,
  PDP = 3,
}

interface Detail {
  Label: string;
  Value: string;
}

export const DentalDetails: Detail[] = [
  {
    Label: "Dental Services",
    Value: "DentalServices",
  },
  {
    Label: "Restorative Services",
    Value: "DentalRestorativeServices",
  },
  {
    Label: "Endodontics",
    Value: "DentalEndodontics",
  },
  {
    Label: "Periodontics",
    Value: "DentalPeriodontics",
  },
  {
    Label: "Extractions",
    Value: "DentalExtractions",
  },
  {
    Label: "Prosthodontics",
    Value: "DentalProsthodontics",
  },
  {
    Label: "Comprehensive Dental NonRoutine",
    Value: "ComprehensiveDentalNonRoutine",
  },
  {
    Label: "Diagnostic Services",
    Value: "DentalDiagnosticServices",
  },
  {
    Label: "Oral Exams",
    Value: "OralExams",
  },
  {
    Label: "Dental Cleaning",
    Value: "DentalCleaning",
  },
  {
    Label: "Dental Xrays",
    Value: "DentalXrays",
  },
  {
    Label: "Dental Fluoride",
    Value: "DentalFluoride",
  },
];

export const VisionDetails: Detail[] = [
  {
    Label: "Vision Services",
    Value: "VisionServices",
  },
  {
    Label: "Medicare Covered Eyewear",
    Value: "MedicareCoveredEyewear",
  },
  {
    Label: "Routine Eye Exams",
    Value: "RoutineEyeExams",
  },
  {
    Label: "Eyeglasses",
    Value: "Eyeglasses",
  },
  {
    Label: "Contact Lenses",
    Value: "ContactLenses",
  },
];

export const HearingDetails: Detail[] = [
  {
    Label: "Hearing Services",
    Value: "HearingServices",
  },
  {
    Label: "Hearing Aids",
    Value: "HearingAids",
  },
  {
    Label: "Hearing Aid Fitting",
    Value: "HearingAidFittingEvaluation",
  },
  {
    Label: "Routine Hearing Exams",
    Value: "RoutineHearingExams",
  },
];

interface newDetail {
  id: string;
  title: string;
}

export const newDentalDetails: newDetail[] = [
  {
    title: "Dental Services",
    id: "DentalServices",
  },
  {
    title: "Restorative Services",
    id: "DentalRestorativeServices",
  },
  {
    title: "Endodontics",
    id: "DentalEndodontics",
  },
  {
    title: "Periodontics",
    id: "DentalPeriodontics",
  },
  {
    title: "Extractions",
    id: "DentalExtractions",
  },
  {
    title: "Prosthodontics",
    id: "DentalProsthodontics",
  },
  {
    title: "Comprehensive Dental NonRoutine",
    id: "ComprehensiveDentalNonRoutine",
  },
  {
    title: "Diagnostic Services",
    id: "DentalDiagnosticServices",
  },
  {
    title: "Oral Exams",
    id: "OralExams",
  },
  {
    title: "Dental Cleaning",
    id: "DentalCleaning",
  },
  {
    title: "Dental Xrays",
    id: "DentalXrays",
  },
  {
    title: "Dental Fluoride",
    id: "DentalFluoride",
  },
];

export const newVisionDetails: newDetail[] = [
  {
    title: "Vision Services",
    id: "VisionServices",
  },
  {
    title: "Medicare Covered Eyewear",
    id: "MedicareCoveredEyewear",
  },
  {
    title: "Routine Eye Exams",
    id: "RoutineEyeExams",
  },
  {
    title: "Eyeglasses",
    id: "Eyeglasses",
  },
  {
    title: "Contact Lenses",
    id: "ContactLenses",
  },
];

export const newHearingDetails: newDetail[] = [
  {
    title: "Hearing Services",
    id: "HearingServices",
  },
  {
    title: "Hearing Aids",
    id: "HearingAids",
  },
  {
    title: "Hearing Aid Fitting",
    id: "HearingAidFittingEvaluation",
  },
  {
    title: "Routine Hearing Exams",
    id: "RoutineHearingExams",
  },
];

export interface MedSuppQuestion {
  QuestionID: number;
  Name: string;
  Text: string;
  AnswerType: string;
  AnswerList?: string[];
  Tooltip?: string;
  Value?: string;
  Status?: string;
  StatusReason?: string;
}

export interface MedSupp {
  PlanType: PlanType;
  PlanSubType: string;
  PlanName: string;
  CarrierName: string;
  AnnualPlanPremium: number;
  AnnualCalculatedPlanPremium: number;
  MedicalDeductible: number;
  MedicalPremium: number;
  PlanDataFields: PlanDataField[];
  Documents: Document[];
  ModelDrugProgram: boolean;
  BrandName: string;
  IsPlanNetworkAvailable: boolean;
  ExtendedPlanDetails: PlanExtendedDetail[];
  ContributionType: number;
  CalculatedMedicalPremium: number;
  ExternalSourceDescription: string;
  ExternalSourceID: number;
  LogoURL: string;
  Riders: TypeRider[];
  NonLicensedPlan: boolean;
  IsSelectPlan: boolean;
  MarketingName: string;
}
