import * as React from "react";
import { useAppSelector } from "../../redux/hooks";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { styled, alpha } from "@mui/material/styles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import EnrollmentStepsList from "./EnrollmentStepsList";
import StarRating from "../shared/StarRating";

import formatPhoneNumber from "../../utils/formatPhoneNumber";
import { useRudderStackAnalytics } from "../../utils/useRudderStackAnalytics";
import { ANALYTICS_EVENT } from "../../config/analytics.config";

const DRX_LOGO_URL = process.env.REACT_APP_DRX_LOGO_URL;

const Title = styled(Typography)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.27),
  padding: theme.spacing(1),
  fontWeight: 600,
}));

export default function Sidebar() {
  const analytics = useRudderStackAnalytics();

  const [expanded, setExpanded] = React.useState(false);

  const { ApplicationID, EnrollPlan } = useAppSelector(
    (state) => state.Enrollment
  );
  const { Phone } = useAppSelector((state) => state.Affiliate);
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  React.useEffect(() => {
    if (!expanded || !Phone || !QuoteID || !analytics || !expanded) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.CONTENT_INTERACTION, {
      quote_id: QuoteID,
      application_id: ApplicationID,
      content_name: "Enrollment Support Number Accordion",
      content_url: `#enrollment-support-number-${Phone?.PhoneNumber}`,
      content_interaction:
        "User clicked on accordion to see a phone number for questions",
    });
  }, [ApplicationID, Phone, QuoteID, analytics, expanded]);

  return (
    <Grid container justifyContent="center" rowGap={2}>
      <Grid item xs={12} container justifyContent="center">
        <Paper sx={{ width: { md: "90%" }, maxWidth: "350px" }}>
          <Grid container rowGap={1}>
            <Grid item xs={12}>
              <Title variant="h6">Selected Plan:</Title>
            </Grid>
            <Grid item xs={12} container direction="column" gap={0.5} p={1}>
              <Grid item>
                <img
                  src={`${DRX_LOGO_URL}/${EnrollPlan?.LogoURL}`}
                  alt=""
                  width="150px"
                  loading="lazy"
                />
              </Grid>
              <Grid item>
                <Typography fontWeight={500}>{EnrollPlan?.PlanName}</Typography>
              </Grid>
              <Grid item>
                <StarRating rating={EnrollPlan?.PlanRating ?? 0} />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid
        item
        xs={12}
        display={{ xs: "none", md: "flex" }}
        container
        justifyContent="center"
      >
        <EnrollmentStepsList />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          position: "fixed",
          bottom: 0,
          zIndex: 100,
        }}
      >
        <Paper
          sx={{
            p: 1,
            backgroundColor: "secondary.light",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "secondary.main",
            },
          }}
          component="div"
          onClick={() => setExpanded((prev) => !prev)}
        >
          <Typography component={"div"} display="inline-flex" fontWeight={600}>
            Have questions before you enroll?{" "}
            {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </Typography>
          <Typography variant="body2">
            Speak with a Licensed Insurance Agent
          </Typography>
          {expanded && (
            <Typography
              component={"div"}
              display="inline-flex"
              fontSize={"1.2rem"}
            >
              <LocalPhoneIcon fontSize="large" />
              {formatPhoneNumber(Phone?.PhoneNumber ?? "")}{" "}
              {Phone?.TTY && "TTY User: 711"}{" "}
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
