import * as React from "react";
import { Controller, Control, RegisterOptions } from "react-hook-form";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import Info from "@mui/icons-material/Info";

interface RootProps {
  name: string;
  control: Control<any>;
  label: string;
  required?: boolean;
  errorMessage: string | undefined;
  hint?: {
    Title?: string;
    Content: string;
  };
  initialValue?: boolean;
  validation?: RegisterOptions;
}

export default function CheckboxInput({
  name,
  control,
  label,
  required,
  errorMessage,
  hint,
  initialValue,
  validation,
}: RootProps) {
  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        defaultValue={initialValue ?? false}
        rules={validation}
        shouldUnregister
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            value={value}
            checked={value}
            control={
              <Checkbox
                // checked={value}
                // value={value}
                onChange={onChange}
                sx={{ pointerEvents: "auto", mt: -1 }}
              />
            }
            label={
              <Box display="inline-flex" alignItems="center" gap={1}>
                <Typography>
                  {label} {required ? "*" : "(Optional)"}
                </Typography>
                {hint && (
                  <Tooltip title={<Typography>{hint.Content}</Typography>}>
                    <Info color="secondary" sx={{ cursor: "pointer" }} />
                  </Tooltip>
                )}
              </Box>
            }
            sx={{ alignItems: "flex-start", my: 1, pointerEvents: "none" }}
          />
        )}
      />
      <Typography color="red" fontSize={12}>
        {errorMessage}
      </Typography>
    </FormControl>
  );
}
