import * as React from "react";
import { useMatch } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setActiveStep } from "../../redux/reducers/Quote";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";

import { STEPS, ALL_QUOTE_STEPPER_INFORMATION } from "../../types/Global.types";
import { ColorlibConnector, ColorlibStepIcon } from "./CustomStepper";
import { ArrowForward } from "@mui/icons-material";
import ArrowBack from "@mui/icons-material/ArrowBack";

//@ts-ignore
import { ReactComponent as SkipSVG } from "../../assets/skip.svg";

import { ANALYTICS_EVENT } from "../../config/analytics.config";

import useNavigateParams from "../../utils/useNavigateParams";
import { useRudderStackAnalytics } from "../../utils/useRudderStackAnalytics";

interface RootProps {
  activeStep: number;
  handleClick: (step: STEPS) => void;
  handleBack?: () => void;
  handleNext?: () => void;
}

function DesktopStepper({ activeStep, handleClick }: RootProps) {
  return (
    <Paper
      elevation={4}
      sx={{
        width: "100%",
        height: 100,
        borderRadius: 4,
      }}
    >
      <Grid container>
        <Grid
          item
          md={4}
          container
          direction="column"
          height={100}
          sx={(theme) => ({
            backgroundRepeat: "none",
            backgroundSize: "cover",
            borderTopLeftRadius: 15,
            borderBottomLeftRadius: 15,
            backgroundImage: `url("data:image/svg+xml;utf8,<svg width='400' height='100' viewBox='0 0 400 100' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='231.049' height='100' transform='matrix(1 0 0 -1 0 99.9995)' fill='${theme.palette.primary.main.replace(
              "#",
              "%23"
            )}'/><path fill-rule='evenodd' clip-rule='evenodd' d='M333.579 0L400 0C394.507 17.5 384.518 17.5 363.017 25C321.523 39.4734 308.949 38.8248 300.82 50C293.909 59.5 302.548 73 337.102 84.5C355.674 90.6813 361.289 100 352.219 100H348.763L228.257 100V95.8333C228.257 91.6667 228.257 83.3333 228.257 75C228.257 66.6667 228.257 58.3333 228.257 50C228.257 41.6667 228.257 33.3333 228.257 25C228.257 16.6667 228.257 8.33333 228.257 4.16667V0L333.579 0Z' fill='${theme.palette.primary.main.replace(
              "#",
              "%23"
            )}'/></svg>")`,
          })}
          justifyContent="center"
        >
          <Grid item sx={{ ml: 4, color: "#fff" }}>
            <Typography variant="h6">
              {ALL_QUOTE_STEPPER_INFORMATION[activeStep].step}, Lets...
            </Typography>
            <Typography variant="h5" fontWeight={600}>
              {ALL_QUOTE_STEPPER_INFORMATION[activeStep].title}
            </Typography>
            {activeStep < ALL_QUOTE_STEPPER_INFORMATION.length - 1 && (
              <Typography>(Optional Step)</Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          md={8}
          container
          justifyContent="center"
          alignContent="center"
        >
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
            sx={{ width: "100%" }}
          >
            {ALL_QUOTE_STEPPER_INFORMATION.map((step) => (
              <Step key={step.label} onClick={() => handleClick(step.id)}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
    </Paper>
  );
}

function MobileStepper({ activeStep, handleBack, handleNext }: RootProps) {
  const navigate = useNavigateParams();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const analytics = useRudderStackAnalytics();

  const isDoctorPage = useMatch("/:affiliateId/input/doctors");
  const isMedicinePage = useMatch("/:affiliateId/input/medicines");
  const isPharmacyPage = useMatch("/:affiliateId/input/pharmacy");

  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const showSkipPlans = isDoctorPage || isMedicinePage || isPharmacyPage;

  const handleSkipToPlansClick = () => {
    analytics?.track(ANALYTICS_EVENT.URL_BUTTON_CLICKED, {
      quote_id: QuoteID,
      link_text: "Skip to Plans",
      link_url: "/input/compare",
    });

    dispatch(setActiveStep(3));
    navigate("/input/compare");
  };

  return (
    <Grid container p={1} alignItems="center" justifyContent="space-evenly">
      {handleBack !== undefined && handleNext !== undefined && (
        <Grid item xs={2}>
          <IconButton
            onClick={handleBack}
            sx={{ bgcolor: "primary.main" }}
            disabled={activeStep === 0}
          >
            <ArrowBack htmlColor="#fff" />
          </IconButton>
        </Grid>
      )}
      <Grid
        item
        xs={8}
        container
        direction="column"
        alignItems="center"
        textAlign="center"
      >
        <Typography variant="h6" fontWeight={500}>
          {ALL_QUOTE_STEPPER_INFORMATION[activeStep].step}, Lets...
        </Typography>
        <Typography variant="h6" fontWeight={600}>
          {ALL_QUOTE_STEPPER_INFORMATION[activeStep].title}
        </Typography>
        {showSkipPlans && (
          <Button
            variant="text"
            startIcon={
              <SkipSVG
                width="42px"
                style={{ fill: theme.palette.primary.main }}
              />
            }
            onClick={handleSkipToPlansClick}
          >
            <Typography fontWeight={600}>Skip to Plans</Typography>
          </Button>
        )}
      </Grid>
      {handleBack !== undefined && handleNext !== undefined && (
        <Grid item xs={2} container justifyContent="flex-end">
          <IconButton
            onClick={handleNext}
            sx={{ bgcolor: "primary.main" }}
            disabled={activeStep === 3}
          >
            <ArrowForward htmlColor="#fff" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

export default function QuoteStepper({
  activeStep,
  handleClick,
  handleBack,
  handleNext,
}: RootProps) {
  return (
    <>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
        }}
      >
        <DesktopStepper activeStep={activeStep} handleClick={handleClick} />
      </Box>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          backgroundColor: "#fff",
        }}
      >
        <MobileStepper
          activeStep={activeStep}
          handleClick={handleClick}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      </Box>
    </>
  );
}
