import * as React from "react";
import { useAppSelector } from "../../../redux/hooks";
import {
  useGetMedicineDetailsQuery,
  useSaveMedicineMutation,
} from "../../../redux/services/API";

import {
  Button,
  Stack,
  Typography,
  Select,
  MenuItem,
  TextField,
  useMediaQuery,
  CircularProgress,
  Theme,
  SelectChangeEvent,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Cancel from "@mui/icons-material/Cancel";

import toProperCase from "../../../utils/toProperCase";
import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";

import { ANALYTICS_EVENT } from "../../../config/analytics.config";

import { Medicine } from "../../../types/Medicine.types";

enum FREQUENCY {
  DAY,
  WEEK,
  MONTH,
  MONTH_2,
  MONTH_3,
  YEAR,
}

function calculateMedicineQuantities(frequency: FREQUENCY, amount: number) {
  switch (frequency) {
    case FREQUENCY.DAY:
      return {
        MetricQuantity: amount * 30,
        DaysOfSupply: 30,
      };

    case FREQUENCY.WEEK:
      return {
        MetricQuantity: amount * 4,
        DaysOfSupply: 30,
      };

    case FREQUENCY.MONTH:
      return {
        MetricQuantity: amount,
        DaysOfSupply: 30,
      };

    case FREQUENCY.MONTH_2:
      return {
        MetricQuantity: amount,
        DaysOfSupply: 60,
      };

    case FREQUENCY.MONTH_3:
      return {
        MetricQuantity: amount,
        DaysOfSupply: 90,
      };

    case FREQUENCY.YEAR:
      return {
        MetricQuantity: amount,
        DaysOfSupply: 365,
      };

    default:
      return {};
  }
}

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minHeight: 360,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { id, children, onClose } = props;

  return (
    <Box id={id} sx={{ m: 0, p: 2, bgcolor: "primary.main" }}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <Cancel fontSize="large" />
        </IconButton>
      ) : null}
    </Box>
  );
};

interface RootProps {
  open: boolean;
  handleClose: () => void;
  medicine: Medicine;
}

export default function AddMedicineDialog({
  open,
  handleClose,
  medicine,
}: RootProps) {
  const { SessionID, QuoteID } = useAppSelector((state) => state.Quote);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  const analytics = useRudderStackAnalytics();

  const [useGeneric, setUseGeneric] = React.useState("N");
  const [frequency, setFrequency] = React.useState<FREQUENCY>(FREQUENCY.DAY);
  const [amount, setAmount] = React.useState<number>(0);
  const [dosageIndex, setDosageIndex] = React.useState<number>(-1);
  const [packageIndex, setPackageIndex] = React.useState<number>(-1);

  const { data, isFetching } = useGetMedicineDetailsQuery(medicine.DrugID);
  const medicineDetails = data?.data;

  const { data: genericDrugData, isFetching: isFetchingGenericDrug } =
    useGetMedicineDetailsQuery(medicine.GenericDrugID ?? 0, {
      skip: medicine.GenericDrugID === undefined || useGeneric === "N",
    });
  const genericMedicineDetails = genericDrugData?.data;

  const [
    saveMedicine,
    {
      isLoading: isSavingMedicine,
      isSuccess: isSaveSuccess,
      reset: resetSaveMedicine,
    },
  ] = useSaveMedicineMutation();

  React.useEffect(() => {
    if (isSaveSuccess) {
      handleClose();
    }
  }, [isSaveSuccess, handleClose]);

  const handleAddMedicine = (
    NDC: string,
    DaysOfSupply: number,
    MetricQuantity: number
  ) => {
    saveMedicine({
      SessionID: SessionID ?? "",
      NDC,
      MetricQuantity: MetricQuantity.toString(),
      DaysOfSupply: DaysOfSupply.toString(),
    });
    resetSaveMedicine();
  };

  const addNewMedicine = () => {
    if (!amount || dosageIndex === -1 || !medicineDetails) {
      return;
    }

    const { DaysOfSupply, MetricQuantity } = calculateMedicineQuantities(
      frequency,
      amount
    );

    if (!DaysOfSupply || !MetricQuantity) {
      return;
    }

    if (useGeneric === "Y") {
      if (!genericMedicineDetails) {
        return;
      }
      const dosage = genericMedicineDetails.Dosages[dosageIndex];
      const dosagePackage = dosage.Packages
        ? dosage.Packages[packageIndex]
        : null;
      const NDC = dosagePackage
        ? dosagePackage.ReferenceNDC
        : dosage.ReferenceNDC;

      handleAddMedicine(NDC, DaysOfSupply, MetricQuantity);

      analytics?.track(ANALYTICS_EVENT.QUOTE_MEDICINE_ADDED, {
        quote_id: QuoteID,
        medicine_ndc: NDC,
        medicine_name: genericMedicineDetails.DrugName,
        medicine_dosage: dosage.LabelName,
        medicine_quantity: MetricQuantity,
        medicine_day_supply: DaysOfSupply,
        medicine_generic: true,
      });
    } else if (useGeneric === "N") {
      const dosage = medicineDetails.Dosages[dosageIndex];
      const dosagePackage = dosage.Packages
        ? dosage.Packages[packageIndex]
        : null;

      const NDC = dosagePackage
        ? dosagePackage.ReferenceNDC
        : dosage.ReferenceNDC;

      handleAddMedicine(NDC, DaysOfSupply, MetricQuantity);

      analytics?.track(ANALYTICS_EVENT.QUOTE_MEDICINE_ADDED, {
        quote_id: QuoteID,
        medicine_ndc: NDC,
        medicine_name: medicineDetails.DrugName,
        medicine_dosage: dosage.LabelName,
        medicine_quantity: MetricQuantity,
        medicine_day_supply: DaysOfSupply,
        medicine_generic: false,
      });
    }
  };

  const handleGenericChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUseGeneric(e.target.value);
    setDosageIndex(-1);
    setPackageIndex(-1);
  };

  const handleDosageChange = (e: SelectChangeEvent<number>) => {
    setDosageIndex(e.target.value as number);
    setPackageIndex(-1);
  };

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="add-medicine-dialog-title"
      open={open}
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography variant="h6" fontWeight={600} color="#fff">
          Add Medicine
        </Typography>
      </CustomDialogTitle>
      <Stack alignItems="center" justifyContent="center" rowGap={4} p={2}>
        {isFetching || isSavingMedicine ? (
          <CircularProgress />
        ) : !medicineDetails ? (
          <Typography>Error: Try Again</Typography>
        ) : (
          <>
            <Typography variant="h4" textAlign="center">
              {medicine.DrugName}
            </Typography>
            {medicineDetails.GenericDrugID && (
              <FormControl>
                <FormLabel
                  id="generic-version-radio-label"
                  sx={{ textAlign: "center" }}
                >
                  Would you like to use the Generic Version?
                  <br />
                  <b>{toProperCase(medicineDetails.GenericDrugName)}</b>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="generic-version-radio-label"
                  name="generic-buttons-group"
                  value={useGeneric}
                  onChange={(e) => handleGenericChange(e)}
                  sx={{ justifyContent: "center" }}
                >
                  <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                  <FormControlLabel value="N" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            )}
            <FormControl>
              <FormLabel>Which dosage do you take?</FormLabel>
              <Select<number>
                value={dosageIndex}
                onChange={handleDosageChange}
                fullWidth
              >
                <MenuItem value={-1} />

                {useGeneric === "N" ? (
                  medicineDetails.Dosages.map((dosage, index) => (
                    <MenuItem key={`dosage-${dosage.DosageID}`} value={index}>
                      {dosage.LabelName}
                    </MenuItem>
                  ))
                ) : isFetchingGenericDrug ? (
                  <CircularProgress />
                ) : (
                  genericMedicineDetails?.Dosages.map((dosage, index) => (
                    <MenuItem key={`dosage-${dosage.DosageID}`} value={index}>
                      {dosage.LabelName}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
            {dosageIndex > -1 &&
              useGeneric === "N" &&
              medicineDetails.Dosages[dosageIndex].Packages && (
                <FormControl>
                  <FormLabel>Which package?</FormLabel>
                  <Select<number>
                    value={packageIndex}
                    onChange={(e) => setPackageIndex(e.target.value as number)}
                    fullWidth
                  >
                    <MenuItem value={-1} />

                    {medicineDetails.Dosages[dosageIndex].Packages?.map(
                      (dosagePackage, index) => (
                        <MenuItem
                          key={`package-${dosagePackage.ReferenceNDC}`}
                          value={index}
                        >
                          {dosagePackage.PackageQuantity} x{" "}
                          {dosagePackage.PackageSize}{" "}
                          {dosagePackage.PackageSizeUnitOfMeasure}{" "}
                          {dosagePackage.PackageDescription}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}

            {dosageIndex > -1 &&
              useGeneric === "Y" &&
              genericMedicineDetails &&
              genericMedicineDetails.Dosages[dosageIndex].Packages && (
                <FormControl>
                  <FormLabel>Which package?</FormLabel>
                  <Select<number>
                    value={packageIndex}
                    onChange={(e) => setPackageIndex(e.target.value as number)}
                    fullWidth
                  >
                    <MenuItem value={-1} />

                    {genericMedicineDetails.Dosages[dosageIndex].Packages?.map(
                      (medicinePackage, index) => (
                        <MenuItem
                          key={`package-${medicinePackage.ReferenceNDC}`}
                          value={index}
                        >
                          {medicinePackage.PackageQuantity} x{" "}
                          {medicinePackage.PackageSize}{" "}
                          {medicinePackage.PackageSizeUnitOfMeasure}{" "}
                          {medicinePackage.PackageDescription}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}

            <FormControl>
              <FormLabel>
                {packageIndex >= 0
                  ? "How many packages do you go through?"
                  : "How many times do you take the medicine?"}
              </FormLabel>
              <Stack direction="row" columnGap={3} alignItems="center">
                <TextField
                  type="number"
                  sx={{ width: 125 }}
                  value={amount}
                  onChange={(e) => setAmount(Number(e.target.value))}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
                <Typography>{packageIndex >= 0 && "package(s) "}per</Typography>
                <Select<FREQUENCY>
                  sx={{ width: 125 }}
                  value={frequency}
                  onChange={(e) => setFrequency(e.target.value as FREQUENCY)}
                >
                  <MenuItem value={FREQUENCY.DAY}>Day</MenuItem>
                  <MenuItem value={FREQUENCY.WEEK}>Week</MenuItem>
                  <MenuItem value={FREQUENCY.MONTH}>Month</MenuItem>
                  <MenuItem value={FREQUENCY.MONTH_2}>2 Months</MenuItem>
                  <MenuItem value={FREQUENCY.MONTH_3}>3 Months</MenuItem>
                  <MenuItem value={FREQUENCY.YEAR}>Year</MenuItem>
                </Select>
              </Stack>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={() => addNewMedicine()}
            >
              Add Medicine
            </Button>
          </>
        )}
      </Stack>
    </CustomDialog>
  );
}
