import * as React from "react";

import { Button, Grid, Typography } from "@mui/material";

interface RootProps {
  name: string;
  hasGeneric: boolean;
  isGeneric: boolean;
  handleClick: () => void;
  isSelected?: boolean;
}
export default function MedicineSelect({
  name,
  hasGeneric,
  isGeneric,
  handleClick,
  isSelected,
}: RootProps) {
  return (
    <Grid
      container
      width={{ xs: 350, sm: 600 }}
      minHeight={60}
      sx={{ mx: { xs: 0.5, sm: 1 }, my: 1 }}
      rowGap={2}
    >
      <Grid item xs={12} sm={10} container direction="column" rowGap={1}>
        <Typography fontWeight={500} sx={{ textTransform: "capitalize" }}>
          {name}
        </Typography>
        <Typography variant="body2">
          {hasGeneric && "Generic Available"}
          {isGeneric && "Generic Version"}
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sm={2}
        container
        justifyContent="center"
        alignContent="center"
      >
        <Button
          variant="outlined"
          color={isSelected ? "error" : "primary"}
          fullWidth
          sx={{ maxWidth: "150px", borderRadius: 15 }}
          onClick={() => handleClick()}
        >
          {isSelected ? "Remove" : "Select"}
        </Button>
      </Grid>
    </Grid>
  );
}
