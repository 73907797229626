import * as React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Rating,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Check, InfoOutlined } from "@mui/icons-material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Close from "@mui/icons-material/Close";

import { formatUSD } from "../../../utils/formatUSD";
import toProperCase from "../../../utils/toProperCase";
import {
  generateMedSuppPlanName,
  calculateMarketShare,
  getPartADeductible,
  getPartBDeductible,
  getOtherCoverage,
} from "../../../utils/MedSuppUtils";

import {
  MED_SUPP_PLANS,
  MedSuppPlanDetails,
  MedSuppQuote,
} from "../../../types/MedSupp.types";

const InlineBox = styled("div")`
  display: inline-flex;
`;

interface RootProps {
  id: string;
  info: MedSuppQuote;
  details: MedSuppPlanDetails;
  handleDetails: (plan: MedSuppQuote) => void;
  handleInfoClick: (id: string) => void;
}

function DesktopCard({
  info,
  details,
  handleDetails,
  handleInfoClick,
}: RootProps) {
  return (
    <Card sx={{ width: 720 }}>
      <Grid container direction="column">
        <Grid item container minHeight={140}>
          <Grid item xs={6} sx={{ py: 0.5, pl: 3, pr: 1 }} container rowGap={1}>
            <Grid item>
              <Typography
                sx={(theme) => ({
                  backgroundColor: alpha(theme.palette.primary.main, 0.2),
                  py: 0.5,
                  px: 1,
                  minWidth: 120,
                  height: 32,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                })}
                fontWeight={600}
              >
                {generateMedSuppPlanName(info.plan)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography fontWeight={600}>
                {info.company_base.name_full}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                Market Share: <b>{calculateMarketShare(info)}</b>
              </Typography>

              <InlineBox>
                <Typography>Customer Satisfaction:</Typography>
                {info.company_base.customer_satisfaction_ratio > 0 ? (
                  <Rating
                    value={info.company_base.customer_satisfaction_ratio * 5.0}
                    readOnly
                    color="primary"
                    precision={0.5}
                  />
                ) : (
                  <Typography fontWeight={600}>N/A</Typography>
                )}
              </InlineBox>
            </Grid>
          </Grid>

          <Grid item xs={6} container>
            <Grid
              item
              xs={12}
              container
              paddingX={3}
              paddingY={2}
              textAlign="center"
              sx={(theme) => ({
                bgcolor: alpha(theme.palette.secondary.main, 0.15),
              })}
            >
              <Grid
                item
                xs={6}
                container
                rowGap={2}
                alignContent="center"
                justifyContent="center"
              >
                <Typography variant="h4" fontWeight={700}>
                  {formatUSD(info.rate.month / 100.0)}
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h6" fontWeight={500}>
                  PER MONTH
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              paddingX={3}
              paddingY={2}
              bgcolor="#EAEAEA"
            >
              <Grid item xs={12}>
                <InlineBox>
                  <Typography fontWeight={600} mr={0.5}>
                    Discounts Applied:
                  </Typography>
                  <IconButton
                    onClick={() => handleInfoClick("DISCOUNT")}
                    sx={{ p: 0 }}
                  >
                    <InfoOutlined color="primary" />
                  </IconButton>
                </InlineBox>
              </Grid>
              <Stack
                direction="row"
                flexWrap="wrap"
                columnGap={1}
                rowGap={0.5}
                alignContent="center"
              >
                {info.discounts?.length > 0 ? (
                  info.discounts.map((discount) => {
                    return (
                      <InlineBox key={discount.name}>
                        <Check
                          titleAccess="Discount Applied"
                          color="primary"
                          fontSize="small"
                        />
                        <Typography>
                          {toProperCase(discount.name)} (
                          {(discount.value * 100.0).toFixed(0)}% OFF)
                        </Typography>
                      </InlineBox>
                    );
                  })
                ) : (
                  <InlineBox>
                    <Close color="error" fontSize="small" />
                    <Typography>None</Typography>
                  </InlineBox>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ px: 3, py: 1 }} minHeight={80} container>
          <Grid item xs={6} container direction="column">
            <Grid item>
              <Typography variant="h5" fontWeight={600}>
                Company:
              </Typography>
            </Grid>

            <Grid item>
              <Typography>
                Ambest Rating: <b>{info.company_base.ambest_rating}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Ambest Outlook: <b>{info.company_base.ambest_outlook}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Established Year: <b>{info.company_base.established_year}</b>
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={6} container direction="column">
            <Grid item>
              <Typography variant="h5" fontWeight={600}>
                You Pay:
              </Typography>
            </Grid>

            <Grid item>
              <Typography>
                Part A Deductible:{" "}
                <b>{getPartADeductible(details, info.plan)}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Part B Deductible:<b>{getPartBDeductible(details)}</b>
              </Typography>
            </Grid>
            {info.plan === MED_SUPP_PLANS.N && (
              <Grid item>
                <Typography>
                  Other Coverage: <b>{getOtherCoverage(details)}</b>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item mx={2}>
          <Divider variant="fullWidth" />
        </Grid>

        <Grid
          item
          container
          px={3}
          py={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          {/* <Grid item md={4}>
            <Button
              variant="contained"
              color="inherit"
              startIcon={
                favorite ? (
                  <Star color="secondary" />
                ) : (
                  <StarTwoToneIcon color="secondary" />
                )
              }
              sx={{
                backgroundColor: "#cfcfcf",
                borderRadius: 15,
                "&:hover": {
                  backgroundColor: "#cfcfcf",
                  filter: "brightness(0.9)",
                },
              }}
              onClick={() => handleFavorite(info, favorite)}
            >
              {favorite && "Added "} Favorite
            </Button>
          </Grid> */}

          <Grid item md={4} container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              startIcon={<ManageSearchIcon htmlColor="#fff" />}
              sx={{ borderRadius: 15 }}
              onClick={() => handleDetails(info)}
            >
              Plan Details
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

function MobileCard({
  info,
  details,
  handleDetails,
  handleInfoClick,
}: RootProps) {
  return (
    <Card sx={{ width: 350, pb: 2 }}>
      <Grid container direction="column" rowGap={1}>
        <Grid
          item
          p={1}
          textAlign="center"
          sx={(theme) => ({
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
          })}
        >
          <Typography fontWeight={600}>
            {generateMedSuppPlanName(info.plan)}
          </Typography>
        </Grid>

        <Grid item container alignItems="center" p={1} rowGap={1}>
          <Grid item xs={12}>
            <Typography fontWeight={600} fontSize="1rem">
              {info.company_base.name_full}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          container
          justifyContent="space-around"
          p={1}
          textAlign="center"
          sx={(theme) => ({
            bgcolor: alpha(theme.palette.secondary.main, 0.15),
          })}
        >
          <Grid item>
            <Typography fontWeight={600}>You Pay:</Typography>
          </Grid>
          <Grid item>
            <Typography fontWeight={600}>
              {getPartADeductible(details, info.plan)}
            </Typography>
            <Typography variant="body2">Part A</Typography>
          </Grid>
          <Grid item>
            <Typography fontWeight={600}>
              {getPartBDeductible(details)}
            </Typography>
            <Typography variant="body2">Part B</Typography>
          </Grid>
        </Grid>

        <Grid item>
          <Accordion
            elevation={0}
            disableGutters
            defaultExpanded={info.discounts?.length > 0}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography fontWeight={600}>
                {formatUSD(info.rate.month / 100.0)} PER MONTH
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InlineBox>
                <Typography fontWeight={600}>Discounts:</Typography>
                <IconButton
                  onClick={() => handleInfoClick("DISCOUNT")}
                  sx={{ p: 0 }}
                >
                  <InfoOutlined color="primary" />
                </IconButton>
              </InlineBox>

              <Stack direction="row" flexWrap="wrap" columnGap={1} rowGap={0.5}>
                {info.discounts.map((discount) => {
                  return (
                    <InlineBox key={discount.name}>
                      <Check
                        titleAccess="Discount Applied"
                        color="primary"
                        fontSize="small"
                      />
                      <Typography>
                        {toProperCase(discount.name)} (
                        {(discount.value * 100.0).toFixed(0)}% OFF)
                      </Typography>
                    </InlineBox>
                  );
                })}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item container justifyContent="center">
          <Divider flexItem sx={{ width: "95%" }} />
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ borderRadius: 0 }}
            onClick={() => handleDetails(info)}
          >
            Plan Details
          </Button>
        </Grid>

        {/* <Grid
          item
          container
          justifyContent="space-evenly"
          alignItems="center"
          mt={1}
        >
           <Button
            variant="contained"
            color="inherit"
            startIcon={
              favorite ? (
                <Star color="secondary" />
              ) : (
                <StarTwoToneIcon color="secondary" />
              )
            }
            sx={{
              backgroundColor: "#cfcfcf",
              borderRadius: 15,
              "&:hover": {
                backgroundColor: "#cfcfcf",
                filter: "brightness(0.9)",
              },
            }}
            onClick={() => handleFavorite(info, favorite)}
          >
            {favorite && "Added "} Favorite
          </Button>
          <Button
            variant="contained"
            color="inherit"
            startIcon={
              compare ? (
                <CheckBox color="secondary" />
              ) : (
                <CheckBoxOutlineBlank color="secondary" />
              )
            }
            sx={{
              backgroundColor: "#cfcfcf",
              borderRadius: 15,
              "&:hover": {
                backgroundColor: "#cfcfcf",
                filter: "brightness(0.9)",
              },
            }}
            onClick={() => handleCompare(info, compare)}
          >
            Compare
          </Button> 
        </Grid> */}
      </Grid>
    </Card>
  );
}

function MedSuppCard(props: RootProps) {
  return (
    <>
      <Box display={{ xs: "none", md: "flex" }}>
        <DesktopCard {...props} />
      </Box>
      <Box display={{ xs: "flex", md: "none" }}>
        <MobileCard {...props} />
      </Box>
    </>
  );
}

// export default React.memo(MedSuppCard, function (prev, next) {
//   return prev.favorite === next.favorite && prev.compare === next.compare;
// });

export default MedSuppCard;
