import * as React from "react";
import { useAppSelector } from "../../../redux/hooks";

import { Typography, useMediaQuery, Theme, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Cancel from "@mui/icons-material/Cancel";
import formatPhoneNumber from "../../../utils/formatPhoneNumber";
import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minHeight: 360,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, bgcolor: "primary.main" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <Cancel fontSize="large" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface RootProps {
  open: boolean;
  handleClose: () => void;
  planName: string;
}

export default function CallAgentDialog({
  open,
  handleClose,
  planName,
}: RootProps) {
  const analytics = useRudderStackAnalytics();

  const Phone = useAppSelector((state) => state.Affiliate.Phone);
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  React.useEffect(() => {
    if (!analytics || !QuoteID || !Phone?.PhoneNumber) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.CALL_AGENT_DIALOG_OPENED, {
      quote_id: QuoteID,
      plan_name: planName,
      phone_number: Phone?.PhoneNumber,
    });

    return () => {
      analytics?.track(ANALYTICS_EVENT.CALL_AGENT_DIALOG_CLOSED, {
        quote_id: QuoteID,
        plan_name: planName,
        phone_number: Phone?.PhoneNumber,
      });
    };
  }, [analytics, QuoteID, planName, Phone?.PhoneNumber]);

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="snp-dialog"
      open={open}
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
    >
      <CustomDialogTitle id="snp-dialog-title" onClose={handleClose}>
        <Typography variant="h6" fontWeight={600} color="#fff">
          Enrollment
        </Typography>
      </CustomDialogTitle>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
        p={3}
      >
        <Grid item>
          <Typography variant="h6" fontWeight={500}>
            Please call one of our Licensed Insurance Agents to enroll into
          </Typography>
        </Grid>
        <Grid item mb={3}>
          <Typography
            variant="h6"
            fontWeight={600}
            sx={{ textDecoration: "underline" }}
          >
            {planName}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            align="center"
            fontWeight={600}
            color="secondary"
          >
            {formatPhoneNumber(Phone?.PhoneNumber ?? "")}
          </Typography>
        </Grid>
        <Grid item mb={2}>
          <Typography variant="h5" align="center" fontWeight={600}>
            {Phone?.TTY && "TTY 711"}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" align="center">
            {Phone &&
              `${Phone?.PhoneNumberAvailability.Days} ${Phone?.PhoneNumberAvailability.Hours} ${Phone?.PhoneNumberAvailability.Timezone}`}
            <br /> for a Licensed Insurance Agent
          </Typography>
        </Grid>
      </Grid>
    </CustomDialog>
  );
}
