import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Phone,
  ColorPalette,
  AffiliateResponse,
  WEBSITE_FILTER_TYPE,
} from "../../types/Affiliate.types";

import { DEFAULT_AFFILIATE_CODE } from "../../config/global.config";

interface AffiliateState {
  Code: string;
  DisplayName: string;
  Logo: string;
  Phone: Phone | null;
  ColorPalette: ColorPalette | null;
  LoadingData: boolean;
  Filters: {
    [key in WEBSITE_FILTER_TYPE]?: string[];
  };
}

const initialState: AffiliateState = {
  Code: DEFAULT_AFFILIATE_CODE,
  DisplayName: "VirtualMedicare",
  Logo: "/images/LogoWithDisclosure.png",
  Phone: null,
  ColorPalette: null,
  LoadingData: true,
  Filters: {},
};

export const affiliateSlice = createSlice({
  name: "Affiliate",
  initialState,
  reducers: {
    setAffiliateCode: (
      state: AffiliateState,
      action: PayloadAction<string>
    ) => {
      state.Code = action.payload;
    },
    setDisplayName: (state: AffiliateState, action: PayloadAction<string>) => {
      state.DisplayName = action.payload;
    },
    setLogo: (state: AffiliateState, action: PayloadAction<string>) => {
      state.Logo = action.payload;
    },
    setPhone: (state: AffiliateState, action: PayloadAction<Phone>) => {
      state.Phone = action.payload;
    },
    setColorPalette: (
      state: AffiliateState,
      action: PayloadAction<ColorPalette>
    ) => {
      state.ColorPalette = action.payload;
    },
    setLoadingData: (state: AffiliateState, action: PayloadAction<boolean>) => {
      state.LoadingData = action.payload;
    },
    setAffiliateData: (
      state: AffiliateState,
      action: PayloadAction<AffiliateResponse>
    ) => {
      state.DisplayName = action.payload.DisplayName;
      state.Logo = action.payload.Metadata.LogoURL;
      state.Phone = action.payload.Metadata.Phone;
      state.ColorPalette = action.payload.Metadata.ColorPalette;
      state.Filters = action.payload.Filters;
    },
  },
});

export const {
  setAffiliateCode,
  setDisplayName,
  setLogo,
  setPhone,
  setColorPalette,
  setLoadingData,
  setAffiliateData,
} = affiliateSlice.actions;

export default affiliateSlice.reducer;
