import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  useGetMedSuppPlanNamesQuery,
  useGetQuoteAttributesQuery,
} from "../../../redux/services/API";
import { setMedSuppQuestionAnswers } from "../../../redux/reducers/Quote";

import { DevTool } from "@hookform/devtools";

import { CircularProgress, Grid } from "@mui/material";

import RadioInput from "../../Enroll/inputs/RadioInput";
import TextInput from "../../Enroll/inputs/TextInput";
import SelectInput from "../../Enroll/inputs/SelectInput";

import effectiveDateOptions from "../../../utils/effectiveDateOptions";
import { generateMedSuppPlanName } from "../../../utils/MedSuppUtils";

import { US_STATE } from "../../../types/Global.types";
import { QuoteAttribute } from "../../../types/Quote.types";
import {
  GENDER_OPTIONS,
  MED_SUPP_PLANS,
  MedSuppQuestionAnswers,
} from "../../../types/MedSupp.types";

import { MED_SUPP_FORM_STEP } from ".";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";

const AGE_OPTIONS = [
  { label: "65", value: 65 },
  { label: "66", value: 66 },
  { label: "67", value: 67 },
  { label: "68", value: 68 },
  { label: "69", value: 69 },
  { label: "70", value: 70 },
  { label: "71", value: 71 },
  { label: "72", value: 72 },
  { label: "73", value: 73 },
  { label: "74", value: 74 },
  { label: "75", value: 75 },
  { label: "76", value: 76 },
  { label: "77", value: 77 },
  { label: "78", value: 78 },
  { label: "79", value: 79 },
  { label: "80", value: 80 },
  { label: "81", value: 81 },
  { label: "82", value: 82 },
  { label: "83", value: 83 },
  { label: "84", value: 84 },
  { label: "85", value: 85 },
  { label: "86", value: 86 },
  { label: "87", value: 87 },
  { label: "88", value: 88 },
  { label: "89", value: 89 },
  { label: "90", value: 90 },
  { label: "91", value: 91 },
  { label: "92", value: 92 },
  { label: "93", value: 93 },
  { label: "94", value: 94 },
  { label: "95", value: 95 },
  { label: "96", value: 96 },
  { label: "97", value: 97 },
  { label: "98", value: 98 },
  { label: "99", value: 99 },
  { label: "100", value: 100 },
];

const EFFECTIVE_DATE_OPTIONS = effectiveDateOptions();

interface FormInput {
  ZipCode: string;
  Age: number;
  Gender: string;
  TobaccoUse: number;
  PlanName: string;
  EffectiveDate: string;
  HasHealthCondition: string;
}

interface RootProps {
  handleNextStep: (
    currentStep: MED_SUPP_FORM_STEP,
    hasHealthCondition: boolean
  ) => void;
  submitRef: React.RefObject<HTMLButtonElement>;
  saveAnswers: MutationTrigger<any>;
}

export default function BasicInformation({
  handleNextStep,
  submitRef,
  saveAnswers,
}: RootProps) {
  const dispatch = useAppDispatch();
  const analytics = useRudderStackAnalytics();

  const { Location, MedSuppQuestionAnswers, QuoteID } = useAppSelector(
    (state) => state.Quote
  );

  const { data: quoteAttributes, isFetching: isLoadingQuoteAttributes } =
    useGetQuoteAttributesQuery(
      {
        QuoteID: QuoteID ?? "",
        Fields: ["Age", "Gender", "TobaccoUse", "HasHealthCondition"],
      },
      {
        skip: !QuoteID,
      }
    );

  const methods = useForm<FormInput>({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    defaultValues: {
      ZipCode: Location?.ZipCode,
      EffectiveDate:
        MedSuppQuestionAnswers.EffectiveDate ?? EFFECTIVE_DATE_OPTIONS[0].value,
      PlanName: MedSuppQuestionAnswers.Plan ?? undefined,
    },
  });
  const { control, handleSubmit, formState, setValue, getValues } = methods;
  const { errors, isDirty } = formState;

  const { data: planNamesData, isFetching: isLoadingPlanNames } =
    useGetMedSuppPlanNamesQuery(
      {
        ZipCode: Location?.ZipCode ?? "",
        State: (Location?.State as US_STATE) ?? "",
      },
      { skip: !Location }
    );

  const planNames = React.useMemo(() => {
    if (!planNamesData) {
      return [];
    }

    return planNamesData.data.map((name) => {
      return {
        label: generateMedSuppPlanName(name),
        value: name,
      };
    });
  }, [planNamesData]);

  React.useEffect(() => {
    if (quoteAttributes?.data) {
      if (quoteAttributes.data["Age"]) {
        setValue("Age", Number(quoteAttributes.data["Age"]));
      }

      if (quoteAttributes.data["Gender"]) {
        setValue("Gender", quoteAttributes.data["Gender"]);
      }

      if (quoteAttributes.data["TobaccoUse"]) {
        setValue(
          "TobaccoUse",
          quoteAttributes.data["TobaccoUse"] === "YES" ? 1 : 0
        );
      }

      if (quoteAttributes.data["HasHealthCondition"]) {
        setValue(
          "HasHealthCondition",
          quoteAttributes.data["HasHealthCondition"]
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteAttributes]);

  React.useEffect(() => {
    if (!formState.isDirty || !QuoteID || !analytics) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.FORM_STARTED, {
      quote_id: QuoteID,
      form_name: "Basic Information",
      form_type: "MED_SUPP",
    });
  }, [QuoteID, analytics, formState]);

  React.useEffect(() => {
    const { errors } = formState;
    if (!errors || !QuoteID || !analytics) {
      return;
    }
    if (errors) {
      Object.keys(errors).forEach((i) => {
        const errorMessage = errors[i as keyof FormInput]?.message;

        if (errorMessage) {
          analytics.track(ANALYTICS_EVENT.FORM_ERROR, {
            quote_id: QuoteID,
            form_name: "Basic Information",
            form_type: "MED_SUPP",
            error_type: "VALIDATION",
            error_name: i,
            error_details: errorMessage,
          });
        }
      });
    }
  }, [QuoteID, analytics, formState]);

  const onSubmitHandler: SubmitHandler<FormInput> = async (formData) => {
    if (!Location || !QuoteID) {
      return;
    }

    const parsedAnswers: MedSuppQuestionAnswers = {
      ZipCode: formData.ZipCode,
      Plan: formData.PlanName as MED_SUPP_PLANS,
      Age: formData.Age,
      Gender: formData.Gender as GENDER_OPTIONS,
      Tobacco: formData.TobaccoUse,
      EffectiveDate: formData.EffectiveDate,
    };

    analytics?.identify(analytics.getAnonymousId(), {
      age: parsedAnswers.Age,
      gender: parsedAnswers.Gender,
      zip: parsedAnswers.ZipCode,
    });

    try {
      dispatch(setMedSuppQuestionAnswers(parsedAnswers));

      if (isDirty) {
        const parsedAttributes: QuoteAttribute[] = [
          {
            FieldID: "Age",
            Value: formData.Age.toString(),
          },
          {
            FieldID: "Gender",
            Value: formData.Gender,
          },
          {
            FieldID: "TobaccoUse",
            Value: Number(formData.TobaccoUse) === 1 ? "YES" : "NO",
          },
          {
            FieldID: "HasHealthCondition",
            Value: formData.HasHealthCondition,
          },
        ];

        saveAnswers({ QuoteID, Attributes: parsedAttributes });
      }

      analytics?.track(ANALYTICS_EVENT.FORM_SUBMITTED, {
        quote_id: QuoteID,
        form_name: "Basic Information",
        form_type: "MED_SUPP",
      });

      const hasHealthCondition = getValues("HasHealthCondition");
      handleNextStep(1, hasHealthCondition === "YES");
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoadingQuoteAttributes || isLoadingPlanNames) {
    return (
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <CircularProgress size="3rem" />
      </Grid>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitHandler)} id="medSupp-basic-info">
        <Grid item xs={12} container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextInput
              name="ZipCode"
              control={control}
              required
              readOnly
              errorMessage={(errors["ZipCode"]?.message as string) ?? undefined}
              label="Zip Code"
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              name="Age"
              control={control}
              errorMessage={(errors["Age"]?.message as string) ?? undefined}
              label="Age"
              options={AGE_OPTIONS}
              required
              validation={{
                required: {
                  value: true,
                  message: "This field is required",
                },
              }}
              keepRegistered
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RadioInput
              name="Gender"
              control={control}
              errorMessage={(errors["Gender"]?.message as string) ?? undefined}
              label="Gender"
              options={[
                { label: "Male", value: "M" },
                { label: "Female", value: "F" },
              ]}
              required
              validation={{
                required: {
                  value: true,
                  message: "This field is required",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RadioInput
              name="TobaccoUse"
              control={control}
              errorMessage={
                (errors["TobaccoUse"]?.message as string) ?? undefined
              }
              label="Do you use Tobacco?"
              options={[
                { label: "Yes", value: 1 },
                { label: "No", value: 0 },
              ]}
              required
              validation={{
                required: {
                  value: true,
                  message: "This field is required",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              name="PlanName"
              control={control}
              errorMessage={
                (errors["PlanName"]?.message as string) ?? undefined
              }
              label={"Plan Name"}
              options={planNames}
              required
              validation={{
                required: {
                  value: true,
                  message: "This field is required",
                },
              }}
              keepRegistered
              loading={isLoadingPlanNames}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              name="EffectiveDate"
              control={control}
              errorMessage={
                (errors["EffectiveDate"]?.message as string) ?? undefined
              }
              label={"Effective Date"}
              options={EFFECTIVE_DATE_OPTIONS}
              required
              validation={{
                required: {
                  value: true,
                  message: "This field is required",
                },
              }}
              keepRegistered
            />
          </Grid>

          <Grid item xs={12}>
            <RadioInput
              name="HasHealthCondition"
              control={control}
              errorMessage={
                (errors["HasHealthCondition"]?.message as string) ?? undefined
              }
              label="Do you have any health conditions?"
              options={[
                { label: "Yes", value: "YES" },
                { label: "No", value: "NO" },
              ]}
              required
              validation={{
                required: {
                  value: true,
                  message: "This field is required",
                },
              }}
            />
          </Grid>
          <button ref={submitRef} type="submit" style={{ display: "none" }} />
        </Grid>
        <DevTool control={control} />
      </form>
    </FormProvider>
  );
}
