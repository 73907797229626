import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useGetEnrollmentFormDataQuery } from "../../../redux/services/API";
import { addCompleteStep } from "../../../redux/reducers/Enrollment";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";

import {
  ETHNICITY_OPTIONS_CONVERT,
  FormRootProps,
  RACE_OPTIONS_CONVERT,
  SpecialistInformation,
} from "../../../types/Enrollment.types";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";

const StyledPaper = styled(Paper)(({ theme }) => ({
  border: "2px solid #e5e5e5",
  width: "100%",
  maxWidth: 800,
  padding: theme.spacing(3),
  borderRadius: 15,
  position: "relative",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

function parseValue(
  value: any,
  options?: Array<{ label: string; value: string }>
) {
  switch (typeof value) {
    case "boolean":
      return value ? "Yes" : "No";

    case "string":
      if (!value) {
        return "Not Answered";
      }

      if (options) {
        const label = options.find((option) => option.value === value)?.label;
        return label ?? value;
      }

      if (value === "Y") {
        return "Yes";
      }

      if (value === "N") {
        return "No";
      }

      return value;

    case "object":
      if (Array.isArray(value)) {
        return value.join(", ");
      }

      return JSON.stringify(value);

    case "undefined":
      return "Not Answered";

    default:
      return value;
  }
}

export default function ApplicationReview({ handleNavigate }: FormRootProps) {
  const dispatch = useAppDispatch();
  const analytics = useRudderStackAnalytics();

  const {
    ApplicationID,
    EnrollPlan,
    Input: InputtedValues,
  } = useAppSelector((state) => state.Enrollment);
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const { data } = useGetEnrollmentFormDataQuery(
    {
      Year: EnrollPlan?.Year ?? 0,
      ContractID: EnrollPlan?.ContractID ?? "",
      PlanID: EnrollPlan?.PlanID ?? "",
      SegmentID: EnrollPlan?.SegmentID ?? "",
      UserType: "CONSUMER",
      SnpType: EnrollPlan?.SnpType,
    },
    {
      skip: !EnrollPlan,
    }
  );

  React.useEffect(() => {
    if (!analytics || !EnrollPlan || !ApplicationID || !QuoteID) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_STARTED, {
      quote_id: QuoteID,
      application_id: ApplicationID,
      enrollment_type: "INTERNAL",
      contract_id: EnrollPlan?.ContractID,
      plan_id: EnrollPlan?.PlanID,
      segment_id: EnrollPlan?.SegmentID,
      plan_year: EnrollPlan?.Year,
      snp_type: EnrollPlan?.SnpType,
      plan_type: EnrollPlan?.PlanType,
      section_id: "review",
    });
  }, [ApplicationID, EnrollPlan, QuoteID, analytics]);

  const formData = data?.data.form;

  if (!formData) {
    return <></>;
  }

  const ContactInformation = formData.Pages[0];
  const EligibilityQuestions = formData.Pages[1];

  const handleEditClick = (path: string) => {
    handleNavigate(path);
  };

  const handleNext = () => {
    dispatch(addCompleteStep("review"));

    analytics?.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_SUBMITTED, {
      quote_id: QuoteID,
      application_id: ApplicationID,
      enrollment_type: "INTERNAL",
      contract_id: EnrollPlan?.ContractID,
      plan_id: EnrollPlan?.PlanID,
      segment_id: EnrollPlan?.SegmentID,
      plan_year: EnrollPlan?.Year,
      snp_type: EnrollPlan?.SnpType,
      plan_type: EnrollPlan?.PlanType,
      section_id: "review",
    });

    handleNavigate("application-effective-date");
  };

  return (
    <Grid container justifyContent="center" rowGap={3}>
      <Grid item xs={12} container justifyContent="center">
        <StyledPaper elevation={0}>
          <StyledIconButton
            onClick={() => handleEditClick("applicant-information")}
          >
            <EditIcon color="info" fontSize="large" />
          </StyledIconButton>
          <Stack rowGap={2}>
            <Typography variant="h6" fontWeight={600} mr={3}>
              Applicant Information
            </Typography>
            <Typography>
              Title:{" "}
              {(InputtedValues["applicant-information"].Title as string) ??
                "Not Answered"}
            </Typography>
            <Typography>
              First Name:{" "}
              {(InputtedValues["applicant-information"].FirstName as string) ??
                "Not Answered"}
            </Typography>
            <Typography>
              Middle Name:{" "}
              {(InputtedValues["applicant-information"].MiddleName as string) ??
                "Not Answered"}
            </Typography>
            <Typography>
              Last Name:{" "}
              {(InputtedValues["applicant-information"].LastName as string) ??
                "Not Answered"}
            </Typography>
            <Typography>
              Sex:{" "}
              {(InputtedValues["applicant-information"].Sex as string) ??
                "Not Answered"}
            </Typography>
            <Typography>
              Date of Birth:{" "}
              {(InputtedValues["applicant-information"].Birthday as string) ??
                "Not Answered"}
            </Typography>

            <Typography>
              Race:{" "}
              {InputtedValues["applicant-information"].Race
                ? (
                    InputtedValues["applicant-information"]
                      .Race as unknown as string[]
                  )
                    .map((i) => RACE_OPTIONS_CONVERT[i])
                    .join(", ")
                : "Not Answered"}
            </Typography>

            <Typography>
              Ethnicity:{" "}
              {InputtedValues["applicant-information"].Ethnicity
                ? (
                    InputtedValues["applicant-information"]
                      .Ethnicity as unknown as string[]
                  )
                    .map((i) => ETHNICITY_OPTIONS_CONVERT[i])
                    .join(", ")
                : "Not Answered"}
            </Typography>
          </Stack>
        </StyledPaper>
      </Grid>

      <Grid item xs={12} container justifyContent="center">
        <StyledPaper elevation={0}>
          <StyledIconButton
            onClick={() => handleEditClick("contact-information")}
          >
            <EditIcon color="info" fontSize="large" />
          </StyledIconButton>
          <Stack rowGap={2}>
            <Typography variant="h6" fontWeight={600} mr={3}>
              Contact Information
            </Typography>
            {ContactInformation &&
              ContactInformation.Sections.map((section) => {
                return (
                  <React.Fragment key={`${section.id}-review`}>
                    <Typography sx={{ textDecoration: "underline" }}>
                      {section.Title}
                    </Typography>
                    {section.Fields.map((field) => {
                      if (field.Tag !== "input") {
                        return null;
                      }

                      if (field.Type === "address-autocomplete") {
                        return null;
                      }

                      const parsedFieldValue = parseValue(
                        InputtedValues["contact-information"][field.id],
                        field.Options
                      );

                      return (
                        <React.Fragment
                          key={`${section.id}-review-${field.id}`}
                        >
                          <Typography pl={3}>
                            {field.Label}: {parsedFieldValue}
                          </Typography>
                          {field.OptionHasFollowup &&
                            field.Options &&
                            field.Options.map((option) => {
                              if (!option.Followup) {
                                return null;
                              }

                              if (
                                InputtedValues["contact-information"][
                                  field.id
                                ] !== option.value
                              ) {
                                return null;
                              }

                              return (
                                <React.Fragment
                                  key={`${section.id}-review-${option.value}`}
                                >
                                  {option.Followup.map((followupField) => {
                                    if (followupField.Tag !== "input")
                                      return null;

                                    const parsedFollowupValue = parseValue(
                                      InputtedValues["contact-information"][
                                        followupField.id
                                      ],
                                      followupField.Options
                                    );
                                    return (
                                      <Typography
                                        key={`${section.id}-review-${option.value}-${followupField.id}`}
                                        pl={6}
                                      >
                                        {followupField.Label}:{" "}
                                        {parsedFollowupValue}
                                      </Typography>
                                    );
                                  })}
                                </React.Fragment>
                              );
                            })}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              })}
          </Stack>
        </StyledPaper>
      </Grid>

      <Grid item xs={12} container justifyContent="center">
        <StyledPaper elevation={0}>
          <StyledIconButton
            onClick={() => handleEditClick("medicare-information")}
          >
            <EditIcon color="info" fontSize="large" />
          </StyledIconButton>
          <Stack rowGap={2}>
            <Typography variant="h6" fontWeight={600} mr={3}>
              Medicare Information
            </Typography>
            <Typography>
              Medicare Number:{" "}
              {(InputtedValues["medicare-information"]
                .MedicareNumber as string) ?? "Not Answered"}
            </Typography>
            <Typography>
              Medicare Part-A Date:{" "}
              {(InputtedValues["medicare-information"]
                .MedicarePartA as string) ?? "Not Answered"}
            </Typography>
            <Typography>
              Medicare Part-B Date:{" "}
              {(InputtedValues["medicare-information"]
                .MedicarePartB as string) ?? "Not Answered"}
            </Typography>
          </Stack>
        </StyledPaper>
      </Grid>

      <Grid item xs={12} container justifyContent="center">
        <StyledPaper elevation={0}>
          <StyledIconButton
            onClick={() => handleEditClick("eligibility-questions")}
          >
            <EditIcon color="info" fontSize="large" />
          </StyledIconButton>
          <Stack rowGap={2}>
            <Typography variant="h6" fontWeight={600} mr={3}>
              Eligibility Questions
            </Typography>
            {EligibilityQuestions &&
              EligibilityQuestions.Sections.map((section) => {
                return (
                  <React.Fragment key={`${section.id}-review`}>
                    <Typography sx={{ textDecoration: "underline" }}>
                      {section.Title}
                    </Typography>
                    {section.Fields.map((field) => {
                      if (field.Tag !== "input") {
                        return null;
                      }

                      if (field.Type === "doctor-finder") {
                        /**
                         * Humana Review Page Doctor Finder
                         * Since there is no input boxes, I added an option with followup fields
                         * that display the Physician Info
                         */
                        if (!field.OptionHasFollowup) {
                          return null;
                        }
                        if (
                          field.Options === undefined ||
                          field.Options.length === 0
                        ) {
                          return null;
                        }

                        return field.Options[0].Followup?.map(
                          (followupField) => {
                            if (followupField.Tag !== "input") {
                              return null;
                            }
                            let parsedFollowupValue = parseValue(
                              InputtedValues["eligibility-questions"][
                                followupField.id
                              ]
                            );

                            if (typeof parsedFollowupValue === "string") {
                              parsedFollowupValue = parsedFollowupValue.replace(
                                /;/g,
                                " "
                              );
                            }
                            return (
                              <Typography
                                key={`${section.id}-review-doctor-finder-${followupField.id}`}
                                pl={6}
                              >
                                {followupField.Label}: {parsedFollowupValue}
                              </Typography>
                            );
                          }
                        );
                      }

                      const parsedFieldValue = parseValue(
                        InputtedValues["eligibility-questions"][field.id],
                        field.Options
                      );

                      if (field.Type === "specialist-finder") {
                        let specialists: SpecialistInformation[] = [];
                        try {
                          specialists = JSON.parse(parsedFieldValue);
                        } catch (error) {}

                        return (
                          <React.Fragment
                            key={`${section.id}-review-${field.id}`}
                          >
                            <Typography pl={3}>{field.Label}</Typography>

                            {specialists.length === 0 && (
                              <Typography pl={3}>
                                No Specialists Selected
                              </Typography>
                            )}

                            {specialists.map((specialist) => {
                              return (
                                <Box
                                  key={`${section.id}-review-${field.id}-${specialist.Name}`}
                                  pl={3}
                                >
                                  <Typography>
                                    Name: {specialist.Name}
                                  </Typography>
                                  <Typography>
                                    Address: {specialist.Address1}
                                    {specialist.Address2} {specialist.City},{" "}
                                    {specialist.State}
                                    {specialist.ZipCode}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </React.Fragment>
                        );
                      }

                      return (
                        <React.Fragment
                          key={`${section.id}-review-${field.id}`}
                        >
                          <Typography pl={3}>
                            {field.Label}: {parsedFieldValue}
                          </Typography>
                          {field.OptionHasFollowup &&
                            field.Options &&
                            field.Options.map((option) => {
                              if (!option.Followup) {
                                return null;
                              }

                              if (
                                InputtedValues["eligibility-questions"][
                                  field.id
                                ] !== option.value
                              ) {
                                return null;
                              }

                              return (
                                <React.Fragment
                                  key={`${section.id}-review-${option.value}`}
                                >
                                  {option.Followup.map((followupField) => {
                                    if (followupField.Tag !== "input")
                                      return null;

                                    const parsedFollowupValue = parseValue(
                                      InputtedValues["eligibility-questions"][
                                        followupField.id
                                      ],
                                      followupField.Options
                                    );
                                    return (
                                      <Typography
                                        key={`${section.id}-review-${option.value}-${followupField.id}`}
                                        pl={6}
                                      >
                                        {followupField.Label}:{" "}
                                        {parsedFollowupValue}
                                      </Typography>
                                    );
                                  })}
                                </React.Fragment>
                              );
                            })}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              })}
          </Stack>
        </StyledPaper>
      </Grid>

      <Grid item xs={12} container justifyContent="center">
        <Divider />
      </Grid>

      <Grid item xs={12} container>
        <Grid item xs={6}>
          <Button
            variant="text"
            color="primary"
            startIcon={<ArrowBack />}
            onClick={() => handleNavigate("eligibility-questions")}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleNext()}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
