import * as React from "react";
import { useAppSelector } from "../../../redux/hooks";
import {
  useDeleteDoctorMutation,
  useGetDoctorsQuery,
  useSaveDoctorMutation,
} from "../../../redux/services/API";
import { useDebounce } from "use-debounce";

import {
  alpha,
  Box,
  Chip,
  Divider,
  Grid,
  Pagination,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import Cancel from "@mui/icons-material/Cancel";

import SearchField from "../../shared/SearchField";
import DoctorSelect from "./DoctorSelect";
import Map, { MarkerDetails, MarkersInfo } from "../../shared/Map";

import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";

// import DulyMarker from "../../../assets/DulyMarker.png";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";

import { Doctor, Location, SelectedDoctor } from "../../../types/Doctor.types";
import { WEBSITE_FILTER_TYPE } from "../../../types/Affiliate.types";

export default function Doctors() {
  const {
    Location: location,
    Doctors: selectedDoctors,
    QuoteID,
  } = useAppSelector((state) => state.Quote);
  const { [WEBSITE_FILTER_TYPE.PROVIDER]: ProviderFilters } = useAppSelector(
    (state) => state.Affiliate.Filters
  );

  const analytics = useRudderStackAnalytics();

  const [page, setCurrentPage] = React.useState(1);
  const [total, setTotal] = React.useState<number>();
  const [radius, setRadius] = React.useState(10);
  const [markers, setMarkers] = React.useState<MarkersInfo>({
    PageNum: 0,
    Markers: [],
  });
  const [doctorSearch, setDoctorSearch] = React.useState("");

  const [debouncedSearchQuery] = useDebounce(doctorSearch, 750);

  const {
    currentData: data,
    isFetching,
    isUninitialized,
    isLoading,
  } = useGetDoctorsQuery(
    {
      zipCode: location?.ZipCode ?? "",
      radiusInMiles: radius,
      page,
      providerName: debouncedSearchQuery,
      coordinates: location?.Coordinates.join(",") ?? "",
      filters: ProviderFilters,
    },
    {
      skip:
        !location ||
        !location.ZipCode ||
        (debouncedSearchQuery.length < 3 && !ProviderFilters),
    }
  );

  const [saveDoctor, { reset: resetSaveDoctor }] = useSaveDoctorMutation();
  const [deleteDoctor, { reset: resetDeleteDoctor }] =
    useDeleteDoctorMutation();

  React.useEffect(() => {
    if (page === 1) {
      setTotal(data?.meta.totalPages);
    }
    if (data) {
      const newMarkersInfo: MarkersInfo = {
        PageNum: page,
        Markers: [],
      };

      for (let doctor of data.data) {
        for (let location of doctor.locations) {
          const coordinates = [location.longitude, location.latitude];
          let newMarker: MarkerDetails = {
            Coordinates: coordinates,
            Name:
              doctor.facilityName ?? `${doctor.firstName} ${doctor.lastName}`,
            Address: `${location.address}${
              location.address2 ? ` ${location.address2}` : ""
            }, ${location.city}, ${location.state} ${location.zip}`,
          };

          newMarkersInfo.Markers.push(newMarker);
        }
      }

      setMarkers(newMarkersInfo);
    } else {
      setMarkers({
        PageNum: 0,
        Markers: [],
      });
    }
  }, [data, page, location]);

  React.useEffect(() => {
    if (!isFetching) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.SEARCH_STARTED, {
      quote_id: QuoteID,
      search_type: "DOCTOR",
      search_keyword: debouncedSearchQuery,
      search_filters: [
        {
          type: "Radius",
          value: radius,
        },
      ],
      search_sorts: [{ type: "RANK", value: "desc" }],
      search_meta: [
        {
          type: "page",
          value: page,
        },
        { type: "per", value: 5 },
      ],
    });
  }, [isFetching]);

  React.useEffect(() => {
    if (!data) {
      return;
    }
    analytics?.track(ANALYTICS_EVENT.SEARCH_RESULTS_RETURNED, {
      quote_id: QuoteID,
      search_type: "DOCTOR",
      search_keyword: debouncedSearchQuery,
      search_filters: [
        {
          type: "Radius",
          value: radius,
        },
      ],
      search_sorts: [{ type: "RANK", value: "desc" }],
      search_meta: [
        {
          type: "page",
          value: page,
        },
        { type: "per", value: 5 },
      ],
      search_result_count: data?.meta.numRecords,
    });
  }, [data]);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearchQuery]);

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  const handleRadius = (event: SelectChangeEvent<number>) => {
    setRadius(event.target.value as number);
  };

  const handleSelectDoctor = (
    index: number,
    selected: boolean,
    locationIndex: number
  ) => {
    if (!data) {
      return;
    }

    const doctor = data.data[index];
    const { locations, firstName, lastName, facilityName, npi } = doctor;

    analytics?.track(ANALYTICS_EVENT.SEARCH_RESULT_CLICKED, {
      quote_id: QuoteID,
      search_type: "DOCTOR",
      search_keyword: debouncedSearchQuery,
      search_filters: [
        {
          type: "Radius",
          value: radius,
        },
      ],
      search_sorts: [{ type: "RANK", value: "desc" }],
      search_meta: [
        {
          type: "page",
          value: page,
        },
        { type: "per", value: 5 },
      ],
      search_result_count: data?.meta.numRecords,
      search_result_position_clicked:
        (data.meta.page - 1) * data.meta.per + index,
      search_result_clicked_id: doctor.npi,
    });

    if (selected) {
      handleRemoveDoctor(doctor, locations[locationIndex]);
      return;
    }

    const newDoctor: SelectedDoctor = {
      ...data.data[index],
      location: locations[locationIndex],
    };
    saveDoctor({
      QuoteID: QuoteID ?? "",
      Doctor: newDoctor,
    });
    resetSaveDoctor();

    analytics?.track(ANALYTICS_EVENT.QUOTE_DOCTOR_ADDED, {
      quote_id: QuoteID,
      doctor_name: facilityName ? facilityName : `${firstName} ${lastName}`,
      doctor_npi: npi,
      doctor_address: newDoctor.location.address,
      doctor_address_2: newDoctor.location.address2,
      doctor_city: newDoctor.location.city,
      doctor_state: newDoctor.location.state,
      doctor_zip_code: newDoctor.location.zip,
    });
  };

  const handleDoctorSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setDoctorSearch(value);
  };

  const handleRemoveDoctor = (
    doctor: Doctor | SelectedDoctor,
    location: Location
  ) => {
    const { firstName, lastName, facilityName, providerId, npi } = doctor;

    deleteDoctor({
      QuoteID: QuoteID ?? "",
      DoctorID: providerId,
    });
    resetDeleteDoctor();

    analytics?.track(ANALYTICS_EVENT.QUOTE_DOCTOR_REMOVED, {
      quote_id: QuoteID,
      doctor_name: facilityName ? facilityName : `${firstName} ${lastName}`,
      doctor_npi: npi,
      doctor_address: location.address,
      doctor_address_2: location.address2,
      doctor_city: location.city,
      doctor_state: location.state,
      doctor_zip_code: location.zip,
    });
  };

  return (
    <Grid container rowGap={2} pb={{ xs: 4, md: 2 }}>
      <Grid item xs={12} height={300}>
        <Map
          handleRadius={handleRadius}
          radius={radius}
          markers={markers}
          initialCoordinates={location?.Coordinates}
          zipCode={location?.ZipCode ?? ""}
        />
      </Grid>

      <Grid item xs={12} md={8} lg={7} xl={6} container justifyContent="center">
        <Paper
          sx={{
            width: "100%",
            maxWidth: "750px",
            borderRadius: { xs: 0, sm: 5 },
            px: { xs: 1, sm: 2 },
            py: 2,
          }}
        >
          <Grid container direction="column" gap={2}>
            <Grid item>
              <SearchField
                placeholder="Search by Doctor's Name"
                handleChange={handleDoctorSearch}
                value={doctorSearch}
              />
            </Grid>

            {ProviderFilters && ProviderFilters.length > 0 && (
              <Grid item container gap={1}>
                <Typography
                  fontWeight={500}
                  sx={{ textDecoration: "underline" }}
                >
                  Filters:
                </Typography>
                {ProviderFilters.map((filter) => {
                  const value = filter.split(":")[1];
                  return (
                    <Chip
                      key={`provider-filter-${filter}`}
                      color="secondary"
                      variant="outlined"
                      label={value}
                      clickable
                      sx={{
                        fontWeight: 500,
                        "& .MuiChip-deleteIcon": {
                          color: "red",
                          "&:hover": {
                            color: "red",
                            filter: "brightness(.9)",
                          },
                        },
                      }}
                    />
                  );
                })}
              </Grid>
            )}

            <Grid item>
              <Stack divider={<Divider flexItem />} alignItems="center">
                <Typography
                  variant="h5"
                  fontWeight={600}
                  alignSelf="flex-start"
                >
                  Search Results
                </Typography>
                {isUninitialized ? (
                  <Box
                    height={120}
                    p={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography fontWeight={600}>
                      Search above to display doctors
                    </Typography>
                  </Box>
                ) : isFetching ? (
                  [0, 1, 2, 3, 4, 5].map((loading) => {
                    return (
                      <Skeleton
                        key={`loading-doctor-${loading}`}
                        variant="rectangular"
                        sx={{ width: { xs: 350, sm: 600 }, my: 0.5 }}
                        height={60}
                      />
                    );
                  })
                ) : data && data.data.length > 0 ? (
                  data.data.map((doctor, index) => {
                    const doctorIndex = selectedDoctors.findIndex(
                      (x) => x.providerId === doctor.providerId
                    );
                    return (
                      <DoctorSelect
                        key={doctor.providerId}
                        info={doctor}
                        index={index}
                        handleSelect={handleSelectDoctor}
                        selected={doctorIndex > -1}
                        locationId={
                          doctorIndex > -1
                            ? selectedDoctors[doctorIndex].location
                                .providerLocationId
                            : undefined
                        }
                      />
                    );
                  })
                ) : (
                  <Box
                    height={100}
                    p={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography fontWeight={600}>No Doctors Found</Typography>
                  </Box>
                )}

                {isLoading ? (
                  <Box
                    sx={{
                      display: "inline-flex",
                      columnGap: 3,
                      margin: ".5rem",
                    }}
                  >
                    <Skeleton variant="circular" width={32} height={32} />
                    <Skeleton variant="circular" width={32} height={32} />
                    <Skeleton variant="circular" width={32} height={32} />
                    <Skeleton variant="circular" width={32} height={32} />
                  </Box>
                ) : (
                  total && (
                    <Pagination
                      page={page}
                      count={total}
                      variant="outlined"
                      color="secondary"
                      sx={{ mt: 2 }}
                      onChange={handleChangePage}
                    />
                  )
                )}
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4} lg={5} xl={6} container justifyContent="center">
        <Paper
          sx={{
            borderRadius: { xs: 0, sm: 5 },
            p: 2,
            width: { xs: "100%", sm: "95%" },
            maxWidth: "560px",
            height: "300px",
            overflowY: "auto",
          }}
        >
          <Stack divider={<Divider flexItem />} alignItems="center">
            <Typography
              variant="h5"
              fontWeight={600}
              mb={1}
              sx={{ alignSelf: "flex-start" }}
            >
              Your Providers
            </Typography>

            {selectedDoctors.map((doctor) => {
              return (
                <Box
                  key={`selected-${doctor.providerId}`}
                  sx={{
                    width: "100%",
                    p: 1,
                    cursor: "pointer",

                    "&:hover": {
                      backgroundColor: (theme) =>
                        alpha(theme.palette.error.main, 0.1),
                    },
                  }}
                  onClick={() => handleRemoveDoctor(doctor, doctor.location)}
                >
                  <Grid container gap={1} alignItems="center">
                    <Grid item xs={12} md={8}>
                      <Typography sx={{ textTransform: "capitalize" }}>
                        {doctor.facilityName ??
                          `${doctor.firstName} ${doctor.lastName}`}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                      gap={1}
                    >
                      <Typography color="error">Remove</Typography>
                      <Cancel fontSize="small" color="error" />
                    </Grid>
                  </Grid>
                </Box>
              );
            })}

            {/* <Box
              sx={{
                width: "100%",
                py: 1,
                px: 0.5,
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.secondary.main, 0.12),
                },
              }}
            >
              <Grid container gap={1} alignItems="center">
                <Grid item xs={2} md={2}>
                  <Avatar
                    src="/images/duly-logo.ico"
                    alt="D"
                    sx={{ border: "2px solid #cfcfcf", p: 0.5 }}
                  />
                </Grid>
                <Grid item xs={8} md={7}>
                  <Typography>Place Holder</Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={2}
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  gap={1}
                >
                  <CheckCircle fontSize="large" color="primary" />
                </Grid>
              </Grid>
            </Box> */}
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
}
