import * as React from "react";
import parse from "html-react-parser";

import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Popover,
  Typography,
  TypographyVariant,
} from "@mui/material";
import Cancel from "@mui/icons-material/Cancel";
import Info from "@mui/icons-material/Info";

import { HintData } from "../../types/Enrollment.types";
import { useRudderStackAnalytics } from "../../utils/useRudderStackAnalytics";
import { useAppSelector } from "../../redux/hooks";
import { ANALYTICS_EVENT } from "../../config/analytics.config";

interface RootProps {
  hintData: HintData;
  textData?: {
    text: string;
    variant: TypographyVariant;
    fontWeight: number;
    color: string;
    underline?: boolean;
  };
  children?: React.ReactNode;
}

export default function Hint({ hintData, textData, children }: RootProps) {
  const analytics = useRudderStackAnalytics();

  const ApplicationID = useAppSelector(
    (state) => state.Enrollment.ApplicationID
  );
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const [open, setOpen] = React.useState(false);

  const anchorEl = React.useRef();

  const handleOpen = () => {
    setOpen(true);

    analytics?.track(ANALYTICS_EVENT.CONTENT_INTERACTION, {
      quote_id: QuoteID,
      application_id: ApplicationID,
      content_name: `Hint - ${hintData.Title}`,
      content_url: `#hint-${hintData.Title}`,
      content_interaction: "User clicked on hint to learn more about field",
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const content = (
    <Grid container p={2}>
      <Typography variant="body2">{parse(hintData.Content)}</Typography>
    </Grid>
  );

  return (
    <>
      {hintData.Clickable === "icon" ? (
        <Box display="flex" alignItems="center" gap={1}>
          {children}
          <IconButton ref={anchorEl.current} onClick={handleOpen}>
            <Info color="secondary" />
          </IconButton>
        </Box>
      ) : (
        hintData.Clickable === "text" && (
          <>
            {textData && (
              <Typography
                ref={anchorEl.current}
                variant={textData.variant}
                color={textData.color}
                fontWeight={textData.fontWeight}
                sx={{
                  textDecoration: textData.underline ? "underline" : "",
                  cursor: "pointer",
                }}
                onClick={handleOpen}
              >
                {textData.text}
              </Typography>
            )}
          </>
        )
      )}
      {hintData.Type === "dialog" && (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <Box sx={{ bgcolor: "primary.main", p: 1 }}>
            <IconButton
              aria-label="close"
              title="Close"
              onClick={handleClose}
              sx={{
                color: "#fff",
              }}
            >
              <Cancel fontSize="large" />
            </IconButton>
          </Box>
          {content}
        </Dialog>
      )}
      {hintData.Type === "popover" && (
        <Popover
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl.current}
          PaperProps={{
            sx: {
              width: (theme) => theme.breakpoints.values.sm,
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {content}
        </Popover>
      )}
    </>
  );
}
