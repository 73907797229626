import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UtilsState {
  AzureMapsKey: string | null;
}

const initialState: UtilsState = {
  AzureMapsKey: null,
};

export const utilsSlice = createSlice({
  name: "Utils",
  initialState,
  reducers: {
    setAzureMapsKey: (state: UtilsState, action: PayloadAction<string>) => {
      state.AzureMapsKey = action.payload;
    },
  },
});

export const { setAzureMapsKey } = utilsSlice.actions;
export default utilsSlice.reducer;
