import Typography from "@mui/material/Typography";

export default function getCarrierAgreements(
  carrier: string,
  planType: string,
  planName?: string,
  state?: string
) {
  switch (carrier.toLowerCase()) {
    case "aetna medicare":
    case "aetna":
      if (planType === "MAPD") {
        return (
          <>
            <Typography variant="h5" fontWeight={600}>
              Important Information Statement - MA/MAPD
            </Typography>
            <Typography fontWeight={600}>
              Please read this important information.
            </Typography>
            <Typography>
              <b>
                <u>
                  If you currently have health coverage from an employer or
                  union, joining Aetna Medicare could affect your employer or
                  union health benefits. You could lose your employer or union
                  health coverage if you join Aetna Medicare.
                </u>
              </b>{" "}
              Read the communications your employer or union sends you. If you
              have questions, visit their website, or contact the office listed
              in their communications. If there isn't any information on whom to
              contact, your benefits administrator or the office that answers
              questions about your coverage can help.
            </Typography>
            <Typography fontWeight={600}>Please read and sign below</Typography>
            <Typography fontWeight={600}>
              By completing this enrollment application, I agree to the
              following:
            </Typography>
            <Typography>
              Aetna Medicare is a Medicare Advantage plan and has a contract
              with the Federal government. I will need to keep my Medicare Parts
              A and B, and continue to pay my Part B premium. I can only be in
              one Medicare Advantage plan at a time, and I understand that my
              enrollment in this plan will automatically end my enrollment in
              another Medicare health plan or prescription drug plan. It is my
              responsibility to inform you of any prescription drug coverage
              that I have or may get in the future. For MA-only plans: I
              understand that if I don't have Medicare prescription drug
              coverage, or creditable prescription drug coverage (as good as
              Medicare's), I may have to pay a late enrollment penalty if I
              enroll in Medicare prescription drug coverage in the future.
              Enrollment in this plan is generally for the entire year. Once I
              enroll, I may leave this plan or make changes only at certain
              times of the year when an enrollment period is available (Example:
              October 15 - December 7 of every year), or under certain special
              circumstances.
            </Typography>
            <Typography>
              Aetna Medicare serves a specific service area. If I move out of
              the area that Aetna Medicare serves, I need to notify the plan so
              I can disenroll and find a new plan in my new area. Once I am a
              member of Aetna Medicare, I have the right to appeal plan
              decisions about payment or services if I disagree. I will read the
              Evidence of Coverage document from Aetna Medicare when I get it to
              know which rules I must follow to get coverage with this Medicare
              Advantage plan.
            </Typography>
            <Typography>
              <b>
                <u>For HMO plans:</u>
              </b>{" "}
              I understand that beginning on the date my Aetna Medicare coverage
              begins, I must get all of my health care from Aetna Medicare
              network providers, except for emergency or urgently-needed
              services or out-of-area dialysis services.
            </Typography>
            <Typography>
              <b>
                <u>For PPO plans:</u>
              </b>{" "}
              I understand that beginning on the date my Aetna Medicare coverage
              begins, using services in-network can cost less than using
              services out-of-network, except for emergency or urgently needed
              services or out-of-area dialysis services. If medically necessary,
              Aetna Medicare provides refunds for all covered benefits, even if
              I get services out of network. Out-of-network/non-contracted
              providers are under no obligation to treat Aetna members, except
              in emergency situations. Please call our customer service number
              or see your Evidence of Coverage for more information, including
              the cost-sharing that applies to out-of-network services.
            </Typography>
            <Typography>
              Services authorized by Aetna Medicare and other services contained
              in my Aetna Medicare Evidence of Coverage document (also known as
              a member contract or subscriber agreement) will be covered.
              Without authorization,{" "}
              <b>
                <u>
                  NEITHER MEDICARE NOR AETNA MEDICARE WILL PAY FOR THE SERVICES.
                </u>
              </b>{" "}
              I understand that if I am getting assistance from a sales agent,
              broker, or other individual employed by or contracted with Aetna
              Medicare, he/she may be paid based on my enrollment in Aetna
              Medicare.
            </Typography>
            <Typography>
              I must keep both Hospital (Part A) and Medical (Part B) to stay in
              Aetna Medicare. By joining this Medicare Advantage plan, I
              acknowledge that Aetna Medicare will share my information with
              Medicare, who may use it to track my enrollment, to make payments,
              and for other purposes allowed by Federal law that authorize the
              collection of this information (see Privacy Act Statement below).
            </Typography>

            <Typography fontWeight={600}>PRIVACY ACT STATEMENT</Typography>

            <Typography>
              The Centers for Medicare & Medicaid Services (CMS) collects
              information from Medicare plans to track beneficiary enrollment in
              Medicare Advantage (MA) Plans, improve care, and for the payment
              of Medicare benefits. Sections 1851 and 1860D-1 of the Social
              Security Act and 42 CFR §§ 422.50 and 422.60 authorize the
              collection of this information. CMS may use, disclose and exchange
              enrollment data from Medicare beneficiaries as specified in the
              System of Records Notice (SORN) "Medicare Advantage Prescription
              Drug (MARx)", System No. 09-70-0588. Your response to this form is
              voluntary. However, failure to respond may affect enrollment in
              the plan.
            </Typography>

            <Typography>
              The information on this enrollment form is correct to the best of
              my knowledge. I understand that if I intentionally provide false
              information on this form, I will be disenrolled from the plan. I
              understand that people with Medicare are generally not covered
              under Medicare while out of the country, except for limited
              coverage near the U.S. Border.
            </Typography>

            <Typography>
              I understand that when my Aetna Medicare coverage begins, I must
              get all of my medical and prescription drug benefits from Aetna
              Medicare. Benefits and services provided by Aetna Medicare and
              contained in my Aetna Medicare "Evidence of Coverage" document
              (also known as a member contract or subscriber agreement) will be
              covered. Neither Medicare nor Aetna Medicare will pay for benefits
              or services that are not covered.
            </Typography>

            <Typography>
              I understand that my signature (or the signature of the person
              authorized to act on my behalf under the laws of the State where I
              live) on this application means that I have read and understand
              the contents of this application. If signed by an authorized
              individual (as described above), this signature certifies that 1)
              this person is authorized under State law to complete this
              enrollment and 2) documentation of this authority is available
              upon request from Medicare. Aetna Medicare is a HMO, PPO plan with
              a Medicare contract. Our SNPs also have contracts with State
              Medicaid programs. Enrollment in our plans depends on contract
              renewal. See Evidence of Coverage for a complete description of
              plan benefits, exclusions, limitations and conditions of coverage.
              Plan features and availability may vary by service area.
            </Typography>
            <Typography>
              Effective dates are based on the enrollment period you're using to
              enroll and the Centers for Medicare &Medicaid Services'
              regulations.{" "}
              <b>
                <u>
                  Unless you are new to Medicare or are eligible for a Special
                  Election Period (SEP), your effective date will be January 1.
                </u>
              </b>{" "}
              Aetna cannot guarantee the effective date you've requested will be
              honored.
            </Typography>

            <Typography>
              I agree that this authorization will remain in effect until I
              provide written notification terminating this service.
            </Typography>
          </>
        );
      }

      if (planType === "PDP") {
        return (
          <>
            <Typography>
              <b>If you are a member of a Medicare Advantage Plan</b> (such as
              an HMO or PPO), you may already have prescription drug coverage
              from your Medicare Advantage Plan that will meet your needs.
            </Typography>
            <Typography>
              By joining Aetna PDP, your membership in your Medicare Advantage
              Plan may end. This will affect both your doctor and hospital
              coverage as well as your prescription drug coverage. Read the
              information that your Medicare Advantage Plan sends you and if you
              have questions, contact your Medicare Advantage Plan.
            </Typography>
            <Typography>
              <b>
                If you currently have health coverage from an employer or union,
                joining Aetna PDP could affect your employer or union health
                benefits. You could lose your employer or union health coverage
                if you join Aetna PDP.
              </b>{" "}
              Read the communications your employer or union sends you. If you
              have questions, visit their website, or contact the office listed
              in their communications. If there isn't information on whom to
              contact, your benefits administrator or the office that answers
              questions about your coverage can help.
            </Typography>

            <Typography fontWeight={600}>
              By completing this enrollment application, I agree to the
              following:
            </Typography>

            <Typography>
              Aetna PDP is a Medicare drug plan and has a contract with the
              Federal government. I understand that this prescription drug
              coverage is in addition to my coverage under Medicare; therefore,
              I will need to keep my Medicare Part A or Part B coverage to stay
              in Aetna PDP. It is my responsibility to inform you of any
              prescription drug coverage that I have or may get in the future. I
              can only be enrolled in one Medicare Prescription Drug Plan at a
              time - if I am currently enrolled in a Medicare Prescription Drug
              Plan, my enrollment in Aetna PDP will end that enrollment.
              Enrollment in this plan is generally for the entire year. Once I
              enroll, I may leave this plan or make changes if an enrollment
              period is available, generally during the Annual Enrollment Period
              (October 15 - December 7), unless I qualify for certain special
              circumstances.
            </Typography>
            <Typography>
              Aetna serves a specific service area. If I move out of the area
              that Aetna serves, I need to notify the plan so I can disenroll
              and find a new plan in my new area. I understand that I must use
              network pharmacies, except in an emergency when I cannot
              reasonably use Aetna network pharmacies. Once I am a member of
              Aetna, I have the right to appeal plan decisions about payment or
              services if I disagree. I will read the Evidence of Coverage
              document from Aetna when I get it to know which rules I must
              follow to get coverage.
            </Typography>
            <Typography>
              I understand that if I leave this plan and don't have or get other
              Medicare prescription drug coverage or creditable prescription
              drug coverage (as good as Medicare's), I may have to pay a Part D
              late enrollment penalty in addition to my premium for Medicare
              prescription drug coverage in the future.
            </Typography>
            <Typography>
              I understand that if I am getting assistance from a sales agent,
              broker, or other individual employed by or contracted with Aetna,
              he or she may be paid based on my enrollment in Aetna.
            </Typography>
            <Typography>
              Counseling services may be available in my state to provide advice
              concerning Medicare supplement insurance or other Medicare
              Advantage or Prescription Drug Plan options, medical assistance
              through the state Medicaid program, and the Medicare Savings
              Program.
            </Typography>

            <Typography fontWeight={600}>Release of Information:</Typography>

            <Typography>
              By joining this Medicare Prescription Drug Plan, I acknowledge
              that Aetna PDP will release my information to Medicare, who may
              use it to track beneficiary enrollment, for payment and other
              purposes applicable to Federal statutes that authorize the
              collection of this information (see Privacy Act Statement below).
            </Typography>
            <Typography fontWeight={600}>Privacy Act Statement:</Typography>
            <Typography>
              The Centers for Medicare & Medicaid Services (CMS) collects
              information from Medicare plans to track beneficiary enrollment in
              Medicare Advantage (MA) or Prescription Drug Plans (PDP), improve
              care, and for the payment of Medicare benefits. Sections 1851 and
              1860D-1 of the Social Security Act and 42 CFR §§ 422.50, 422.60,
              423.30 and 423.32 authorize the collection of this information.
              CMS may use, disclose and exchange enrollment data from Medicare
              beneficiaries as specified in the System of Records Notice (SORN)
              "Medicare Advantage Prescription Drug (MARx)", System No. 09-
              70-0588.
            </Typography>
            <Typography>
              Your response to this form is voluntary. However, failure to
              respond may affect enrollment in the plan.
            </Typography>
            <Typography>
              The information on this enrollment form is correct to the best of
              my knowledge. I understand that if I intentionally provide false
              information on this form, I will be disenrolled from the plan.
            </Typography>
            <Typography>
              <b>
                I understand that people with Medicare are generally not covered
                under Medicare while out of the country except for limited
                coverage near the U.S. border. I understand that my signature
                (or the signature of the person authorized to act on my behalf
                under state law where I live) on this application means that I
                have read and understand the contents of this application.
              </b>{" "}
              If signed by an authorized individual (as described above), this
              signature certifies that:
            </Typography>
            <Typography>
              1) This person is authorized under state law to complete this
              enrollment, and
            </Typography>
            <Typography>
              2) Documentation of this authority is available upon request by
              Medicare.
            </Typography>
            <Typography>
              SilverScript is a Prescription Drug Plan with a Medicare contract
              marketed through Aetna Medicare. Enrollment in SilverScript
              depends on contract renewal.
            </Typography>
          </>
        );
      }
      break;

    case "humana":
    case "humana insurance company":
    case "humana benefit plan of illinois, inc.":
      return (
        <>
          {planType === "MAPD" && (
            <Typography variant="h5" fontWeight={600}>
              Important Information Statement - MA/MAPD
            </Typography>
          )}

          <Typography fontWeight={600}>
            PLEASE READ THIS IMPORTANT INFORMATION
          </Typography>
          <Typography>
            <b>
              If you currently have health coverage from an employer or union,
              joining Humana could affect your employer or union healthcare
              benefits.
            </b>{" "}
            You could lose your employer or union health coverage if you join
            Humana.
          </Typography>
          <Typography fontWeight={600}>
            By completing this enrollment form, I agree to the following:
          </Typography>
          <Typography>
            If I am enrolling in a Medicare Advantage health plan that has a
            contract with the federal government, I will need to keep my
            Medicare Parts A and B to stay in the plan. I must continue to pay
            my Medicare Part B premium. If I am enrolling in a Medicare
            prescription drug plan, I will need to keep my Medicare Parts A or B
            coverage. It is my responsibility to inform Humana of any
            prescription drug coverage that I have or may get in the future.{" "}
            <b>
              I understand that if I don't have Medicare prescription drug
              coverage, or creditable prescription drug coverage (as good as
              Medicare's), I may have to pay a late enrollment penalty if I
              enroll in Medicare prescription drug coverage in the future.
            </b>{" "}
            With few exceptions, I can only be in one Medicare Advantage health
            plan or Medicare prescription drug plan at a time. I understand that
            my enrollment in my selected plan may end my enrollment in another
            Medicare Advantage health plan or prescription drug plan. Enrollment
            in my selected plan is generally for the entire year.
          </Typography>
          <Typography>
            I understand that when my Humana coverage begins, I must get all of
            my medical and prescription drug benefits from Humana. Benefits and
            services provided by Humana and contained in my "Evidence of
            Coverage" document (also known as a member contract or subscriber
            agreement) will be covered. Neither Medicare nor Humana will pay for
            benefits or services that are not covered. I will abide by the rules
            of my Evidence of Coverage. Once I am a member of Humana, I have the
            right to appeal plan decisions about payment or services if I
            disagree.
          </Typography>
          <Typography>
            This Humana plan serves a specific service area. If I move out of
            the area that this Humana plan serves, I need to notify Humana so I
            can disenroll and find a new plan in my new area. I understand that
            Medicare beneficiaries are generally not covered under Medicare
            while out of the country, except for limited coverage near the U.S.
            border.
          </Typography>
          <Typography>
            Once Humana has received my enrollment form, I may get a
            verification letter to make sure that I understand how my plan works
            and to confirm my intent to enroll. This is not a secondary plan to
            Medicare Parts A and B. Humana pays instead of Medicare, and I will
            be responsible for the amounts that Humana doesn't cover, such as
            copayments and coinsurances. Medicare Parts A and B won't pay for my
            healthcare while I am enrolled in Humana.
          </Typography>

          <ul>
            <li>
              If you are requesting membership in a{" "}
              <b>Private Fee For Service (PFFS)</b> plan, the following
              statement applies: I understand that this plan is a Medicare
              Advantage PFFS plan which may have prescription drug coverage
              built in. Before seeing a provider, I should verify that the
              provider will accept this plan before each visit. My doctor or
              hospital isn't required to agree to accept the plan's terms and
              conditions, and thus may choose not to treat me, except for
              emergencies. I understand that my healthcare providers have the
              right to choose whether to accept a PFFS plan's payment terms and
              conditions every time I see them. I understand that if my provider
              decides not to accept PFFS, I will need to find another provider
              that will. I understand that if my PFFS plan doesn't offer
              Medicare prescription drug coverage, I may obtain coverage from
              another Medicare prescription drug plan.
            </li>
            <li>
              If you are requesting membership in a{" "}
              <b>Chronic Condition Special Needs Plan (C-SNP)</b>, the following
              statement applies: I understand this plan is a chronic condition
              special needs plan. My ability to enroll is based on physician
              verification that I have the qualifying medical condition(s).
            </li>
            <li>
              If you are requesting membership in an{" "}
              <b>Institutional Special Needs Plan (I-SNP)</b>, the following
              statement applies: I understand this plan is an institutional
              special needs plan. My ability to enroll is based on verification
              that my condition makes it likely that either the length of stay
              or the need for an institutional level of care would be at least
              90 days; or, I reside in the community and meet state requirements
              for institutional level of care.
            </li>
            <li>
              I understand that I am enrolling into a Humana Medicare Advantage
              plan or a Humana Medicare prescription drug plan and not a
              Medicare Supplement, Medigap, Medicare Select or Medicaid plan.
              The information on this enrollment form is correct to the best of
              my knowledge. I understand that if I intentionally provide false
              information on this form, I will be disenrolled from the plan.
            </li>
          </ul>

          <Typography fontWeight={600}>Release of Information:</Typography>
          <Typography>
            By joining this Medicare plan, I acknowledge that Humana will share
            my information with Medicare, who may use it to track my enrollment,
            to make payments, and for other purposes allowed by federal law that
            authorize the collection of this information (see Privacy Act
            Statement below).
          </Typography>
          <Typography fontWeight={600}>Privacy Act Statement:</Typography>
          <Typography>
            The Centers for Medicare & Medicaid Services (CMS) collects
            information from Medicare plans to track beneficiary enrollment in
            Medicare Advantage (MA) Plans, improve care, and for the payment of
            Medicare benefits. Sections 1851 and 1860D-1 of the Social Security
            Act and 42 CFR §§ 422.50 and 422.60 authorize the collection of this
            information. CMS may use, disclose and exchange enrollment data from
            Medicare beneficiaries as specified in the System of Records Notice
            (SORN) "Medicare Advantage Prescription Drug (MARx)", System No.
            09-70-0588.{" "}
            <b>
              Your response to this form is voluntary. However, failure to
              respond may affect enrollment in the plan.
            </b>
          </Typography>
          <Typography>
            I understand that my signature (or the signature of the individual
            legally authorized to act on my behalf) on this enrollment form
            means that I have read and understand the contents of this
            enrollment form. If signed by an authorized representative (as
            described above), the signature certifies that: 1) this individual
            is authorized under state law to complete this enrollment, and 2)
            documentation of this authority is available upon request by
            Medicare.
          </Typography>
          <Typography>
            I have read and understand the important information on the
            preceding pages. I have reviewed and received a copy of the Summary
            of Benefits.
          </Typography>
        </>
      );

    case "bcbs":
    case "hcsc":
    case "ghs":
    case "hcsc - blue cross blue shield il":
    case "hisc - blue cross blue shield of il":
    case "blue cross blue shield":
    case "health care service corporation":
      if (planType === "MAPD") {
        return (
          <>
            <Typography>
              If you currently have health coverage from an employer or union,
              joining Blue Cross Medicare Advantage could affect your employer
              or union health benefits. You could lose your employer or union
              health coverage if you join Blue Cross Medicare Advantage. Read
              the communications your employer or union sends you. If you have
              questions, visit their website or contact the office listed in
              their communications. If there isn't any information on whom to
              contact, your benefits administrator or the office that answers
              questions about your coverage can help.
            </Typography>
            <Typography fontWeight={600}>
              By completing this enrollment application, I agree to the
              following:
            </Typography>
            <Typography>
              Blue Cross Medicare Advantage is a Medicare Advantage plan and has
              a contract with the federal government. I will need to keep my
              Medicare Parts A and B to stay in this plan. I can be in only one
              Medicare Advantage plan at a time, and I understand that my
              enrollment in this plan will automatically end my enrollment in
              another Medicare health plan or prescription drug plan. It is my
              responsibility to inform you of any prescription drug coverage
              that I have or may get in the future. Enrollment in this plan is
              generally for the entire year. Once I enroll, I may leave this
              plan or make changes only at certain times of the year when an
              enrollment period is available, (Example: October 15 - December 7
              of every year), or under certain special circumstances.
            </Typography>
            <Typography>
              Blue Cross Medicare Advantage serves a specific service area. If I
              move out of the area that Blue Cross Medicare Advantage serves, I
              need to notify the plan so I can disenroll and find a new plan in
              my new area. Once I am a member of Blue Cross Medicare Advantage,
              I have the right to appeal plan decisions about payment or
              services if I disagree. I will read the Evidence of Coverage
              document from Blue Cross Medicare Advantage when I get it to know
              which rules I must follow to get coverage with this Medicare
              Advantage plan. I understand that people with Medicare aren't
              usually covered under Medicare while out of the country except for
              limited coverage near the U.S. border.
            </Typography>
            <Typography>
              I understand that beginning on the date Blue Cross Medicare
              Advantage coverage begins, I must get all of my medical and
              prescription drug benefits from this plan. Benefits and services
              provided by Blue Cross Medicare Advantage and contained in my Blue
              Cross Medicare Advantage "Evidence of Coverage" document will be
              covered. Neither Medicare nor Blue Cross Medicare Advantage will
              pay for benefits or services that are not covered. I understand
              that using services in-network can cost less than using services
              out-of-network, except for emergency or urgently needed services
              or out-of-area dialysis services. If medically necessary, Blue
              Cross Medicare Advantage provides refunds for all covered
              benefits, even if I get services out of network. If the service
              requires prior authorization as stated in the Evidence of Coverage
              document, neither Medicare nor Blue Cross Medicare Advantage will
              pay for the services without prior authorization.
            </Typography>
            <Typography>
              I understand that if I am getting assistance from a sales agent,
              broker, or other individual employed by or contracted with Blue
              Cross Medicare Advantage, he/she may be paid based on my
              enrollment in Blue Cross Medicare Advantage.
            </Typography>
            <Typography>
              Subscriber hereby expressly acknowledges its understanding this
              agreement constitutes a contract solely between Subscriber and
              BCBS {state}, which is an independent corporation operating under
              a license from the Association, an Association, permitting BCBS{" "}
              {state} to use the Service Marks in the State, and that BCBS{" "}
              {state} is not contracting as the agent of the Association.
              Subscriber further acknowledges and agrees that it has not entered
              into this agreement based upon representations by any person other
              than BCBS {state} and that no person, entity, or organization
              other than BCBS {state} shall be held accountable or liable to
              Subscriber for any of BCBS {state}'s obligations to Subscriber
              created under this agreement. This paragraph shall not create any
              additional obligations whatsoever on the part of BCBS {state}{" "}
              other than those obligations created under other provisions of
              this agreement.
            </Typography>
            <Typography fontWeight={600}>Release of Information:</Typography>
            <Typography>
              By joining this Medicare health plan, I acknowledge that Blue
              Cross Medicare Advantage will share my information with Medicare,
              and other plans if necessary, who may use it to track my
              enrollment, to make payments, and for other purposes allowed by
              Federal law (see Privacy Act Statement below).
            </Typography>
            <Typography>
              The information on this enrollment form is correct to the best of
              my knowledge.
            </Typography>
            <Typography>
              I understand that if I intentionally provide false information on
              this form, I will be disenrolled from the plan.
            </Typography>
            <Typography>
              I understand that my signature (or the signature of the person
              authorized to act on my behalf under the laws of the state where I
              live) on this application means that I have read and understand
              the contents of this application. If signed by an authorized
              individual (as described above), this signature certifies that 1)
              this person is authorized under state law to complete this
              enrollment and 2) documentation of this authority is available
              upon request from Medicare.
            </Typography>
            <Typography fontWeight={600}>PRIVACY ACT STATEMENT</Typography>
            <Typography>
              The Centers for Medicare & Medicaid Services (CMS) collects
              information from Medicare plans to track beneficiary enrollment in
              Medicare Advantage (MA) or Prescription Drug Plans (PDP), improve
              care, and for the payment of Medicare benefits. Sections 1851 and
              1860D-1 of the Social Security Act and 42 CFR §§ 422.50, 422.60,
              423.30 and 423.32 authorize the collection of this information.
              CMS may use, disclose and exchange enrollment data from Medicare
              beneficiaries as specified in the System of Records Notice (SORN)
              "Medicare Advantage Prescription Drug (MARx)", System No.
              09-70-0588. Your response to this form is voluntary. However,
              failure to respond may affect enrollment in the plan.
            </Typography>
          </>
        );
      }

      if (planType === "PDP") {
        return (
          <>
            <Typography>
              <b>If you are a member of a Medicare Advantage Plan</b> (like an
              HMO or PPO), you may already have prescription drug coverage from
              your Medicare Advantage Plan that will meet your needs. By joining
              Blue Cross MedicareRx, your membership in your Medicare Advantage
              Plan may end. This will affect both your doctor and hospital
              coverage as well as your prescription drug coverage. Read the
              information that your Medicare Advantage Plan sends you and if you
              have questions, contact your Medicare Advantage Plan.
            </Typography>
            <Typography>
              <b>
                If you currently have health coverage from an employer or union,
                joining Blue Cross MedicareRx could affect your employer or
                union health benefits.
              </b>{" "}
              You could lose your employer or union health coverage if you join
              Blue Cross MedicareRx. Read the communications your employer or
              union sends you. If you have questions, visit their website, or
              contact the office listed in their communications. If there isn't
              information on whom to contact, your benefits administrator or the
              office that answers questions about your coverage can help.
            </Typography>
            <Typography fontWeight={600}>
              By completing this enrollment application, I agree to the
              following:
            </Typography>
            <Typography>
              Blue Cross MedicareRx is a Medicare drug plan and has a contract
              with the federal government. I understand that this prescription
              drug coverage is in addition to my coverage under Medicare;
              therefore, I will need to keep my Medicare Part A or Part B
              coverage to stay in this plan. It is my responsibility to inform
              Blue Cross MedicareRx of any prescription drug coverage that I
              have or may get in the future. I can only be in one Medicare
              prescription drug plan at a time — if I am currently in a Medicare
              Prescription Drug Plan, my enrollment in Blue Cross MedicareRx
              will end that enrollment. Enrollment in this plan is generally for
              the entire year. Once I enroll, I may leave this plan or make
              changes if an enrollment period is available, generally during the
              Annual Enrollment Period (October 15 - December 7), unless I
              qualify for certain special circumstances.
            </Typography>
            <Typography>
              Blue Cross MedicareRx serves a specific service area. If I move
              out of the area that Blue Cross MedicareRx serves, I need to
              notify the plan so I can disenroll and find a new plan in my new
              area. I understand that I must use network pharmacies except in an
              emergency when I cannot reasonably use Blue Cross MedicareRx
              network pharmacies. Once I am a member of Blue Cross MedicareRx, I
              have the right to appeal plan decisions about payment or services
              if I disagree. I will read the Evidence of Coverage document from
              Blue Cross MedicareRx when I get it to know which rules I must
              follow to get coverage.
            </Typography>
            <Typography>
              I understand that if I leave this plan and don't have or get other
              Medicare prescription drug coverage or creditable prescription
              drug coverage (as good as Medicare's), I may have to pay a late
              enrollment penalty in addition to my premium for Medicare
              prescription drug coverage in the future.
            </Typography>
            <Typography>
              I understand that if I am getting assistance from a sales agent,
              broker, or other individual employed by or contracted with Blue
              Cross MedicareRx, he/she may be paid based on my enrollment in
              Blue Cross MedicareRx.
            </Typography>
            <Typography>
              Counseling services may be available in my state to provide advice
              concerning Medicare supplement insurance or other Medicare
              Advantage or Prescription Drug Plan options, medical assistance
              through the state Medicaid program, and the Medicare Savings
              Program. Subscriber hereby expressly acknowledges its
              understanding this agreement constitutes a contract solely between
            </Typography>
            <Typography>
              Subscriber and Blue Cross and Blue Shield of {state} (BCBS {state}
              ), which is an independent corporation operating under a license
              from the Blue Cross and Blue Shield Association, an Association of
              Independent Blue Cross and Blue Shield Plans (the "Association"),
              permitting BCBS {state} to use the Blue Cross and/or Blue Shield
              Service Marks in the State of {state}, and that BCBS {state} is
              not contracting as the agent of the Association. Subscriber
              further acknowledges and agrees that it has not entered into this
              agreement based upon representations by any person other than BCBS{" "}
              {state} and that no person, entity, or organization other than
              BCBS {state} shall be held accountable or liable to Subscriber for
              any of BCBS {state}'s obligations to Subscriber created under this
              agreement. This paragraph shall not create any additional
              obligations whatsoever on the part of BCBS {state} other than
              those obligations created under other provisions of this
              agreement.
            </Typography>
            <Typography fontWeight={600}>Release of Information:</Typography>
            <Typography>
              By joining this Medicare prescription drug plan, I acknowledge
              that Blue Cross MedicareRx will share my information with
              Medicare, and other plans if necessary, who may use it to track my
              enrollment, to make payments, and for other purposes allowed by
              Federal law (see Privacy Act Statement below). The information on
              this enrollment form is correct to the best of my knowledge. I
              understand that if I intentionally provide false information on
              this form, I will be disenrolled from the plan.
            </Typography>
            <Typography>
              I understand that my signature (or the signature of the person
              authorized to act on my behalf under state law where I live) on
              this application means that I have read and understand the
              contents of this application. If signed by an authorized
              individual (as described above), this signature certifies that: 1)
              this person is authorized under state law to complete this
              enrollment and 2) documentation of this authority is available
              upon request by Medicare.
            </Typography>
            <Typography fontWeight={600}>PRIVACY ACT STATEMENT</Typography>
            <Typography>
              The Centers for Medicare & Medicaid Services (CMS) collects
              information from Medicare plans to track beneficiary enrollment in
              Medicare Advantage (MA) or Prescription Drug Plans (PDP), improve
              care, and for the payment of Medicare benefits. Sections 1851 and
              1860D-1 of the Social Security Act and 42 CFR §§ 422.50, 422.60,
              423.30 and 423.32 authorize the collection of this information.
              CMS may use, disclose and exchange enrollment data from Medicare
              beneficiaries as specified in the System of Records Notice (SORN)
              "Medicare Advantage Prescription Drug (MARx)", System No.
              09-70-0588. Your response to this form is voluntary. However,
              failure to respond may affect enrollment in the plan.{" "}
            </Typography>
          </>
        );
      }
      break;

    case "uhc":
      if (planType === "MAPD") {
        return (
          <>
            <Typography variant="h5" fontWeight={600}>
              Important Information Statement - MA/MAPD
            </Typography>

            <Typography>
              I must keep both Hospital (Part A) and Medical (Part B) to stay
              in.
            </Typography>

            <Typography>
              By joining this Medicare Advantage Plan, I acknowledge that{" "}
              {planName} will share my information with Medicare, who may use it
              to track my enrollment, to make payments, and for other purposes
              allowed by Federal law that authorize the collection of this
              information (see Privacy Act Statement below).
            </Typography>

            <Typography>
              Your response to this form is voluntary. However, failure to
              respond may affect enrollment in the plan. The information on this
              enrollment form is correct to the best of my knowledge.
            </Typography>

            <Typography>
              I understand that if I intentionally provide false information on
              this form, I will be disenrolled from the plan. I understand that
              people with Medicare are generally not covered under Medicare
              while out of the country, except for limited coverage near the
              U.S. border. I understand that when my coverage begins, I must get
              all of my medical and prescription drug benefits from {planName}{" "}
              Benefits and services provided by {planName} and contained in my
              “Evidence of Coverage” document (also known as a member contract
              or subscriber agreement) will be covered. Neither Medicare nor{" "}
              {planName} will pay for benefits or services that are not covered.
            </Typography>

            <Typography>
              I understand that my signature (or the signature of the person
              legally authorized to act on my behalf) on this application means
              that I have read and understand the contents of this
              application.If signed by an authorized representative (as
              described above), this signature certifies that:
            </Typography>

            <ol>
              <li>
                This person is authorized under State law to complete this
                enrollment
              </li>
              <li>
                Documentation of this authority is available upon request by
                Medicare.
              </li>
            </ol>

            <Typography variant="h5" fontWeight={600}>
              PRIVACY ACT STATEMENT
            </Typography>

            <Typography>
              The Centers for Medicare & Medicaid Services (CMS) collects
              information from Medicare plans to track beneficiary enrollment in
              Medicare Advantage (MA) Plans, improve care, and for the payment
              of Medicare benefits. Sections 1851 and 1860D-1 of the Social
              Security Act and 42 CFR §§ 422.50 and 422.60 authorize the
              collection of this information. CMS may use, disclose and exchange
              enrollment data from Medicare beneficiaries as specified in the
              System of Records Notice (SORN) “Medicare Advantage Prescription
              Drug (MARx)”, System No. 09-70-0588. Your response to this form is
              voluntary. However, failure to respond may affect enrollment in
              the plan.
            </Typography>
          </>
        );
      }

      if (planType === "PDP") {
        return (
          <>
            <Typography variant="h5" fontWeight={600}>
              Important Information Statement
            </Typography>

            <ul>
              <li>I must keep Part A or Part B to stay in {planName} (PDP).</li>
              <li>
                By joining this Medicare Prescription Drug Plan, I acknowledge
                that {planName} (PDP) will release my information to Medicare,
                who may use it to track beneficiary enrollment, for payment and
                other purposes applicable to Federal statutes that authorize the
                collection of this information (see Privacy Act Statement
                below).
              </li>
              <li>
                Your response to this form is voluntary. However, failure to
                respond may affect enrollment in the plan.
              </li>
              <li>
                The information on this enrollment form is correct to the best
                of my knowledge. I understand that if I intentionally provide
                false information on this form, I will be disenrolled from the
                plan.
              </li>
              <li>
                I understand that people with Medicare are generally not covered
                under Medicare while out of the country, except for limited
                coverage near the U.S. border.
              </li>
              <li>
                I understand that people with Medicare are generally not covered
                under Medicare while out of the country, except for limited
                coverage near the U.S. border.
                <ol>
                  <li>
                    This person is authorized under State law to complete this
                    enrollment, and 2) Documentation of this authority is
                    available upon request by Medicare.
                  </li>
                </ol>
              </li>
            </ul>

            <Typography variant="h5" fontWeight={600}>
              PRIVACY ACT STATEMENT
            </Typography>

            <Typography>
              The Centers for Medicare & Medicaid Services (CMS) collects
              information from Medicare plans to track beneficiary enrollment in
              Medicare Advantage (MA) Plans, improve care, and for the payment
              of Medicare benefits. Sections 1851 and 1860D-1 of the Social
              Security Act and 42 CFR §§ 422.50 and 422.60 authorize the
              collection of this information. CMS may use, disclose and exchange
              enrollment data from Medicare beneficiaries as specified in the
              System of Records Notice (SORN) “Medicare Advantage Prescription
              Drug (MARx)”, System No. 09-70-0588. Your response to this form is
              voluntary. However, failure to respond may affect enrollment in
              the plan.
            </Typography>
          </>
        );
      }
      break;
  }

  return <></>;
}
