import * as React from "react";
import { Controller, Control, RegisterOptions } from "react-hook-form";

import {
  FormControl,
  FormLabel,
  Box,
  RadioGroup,
  Tooltip,
  Typography,
  styled,
  Radio,
} from "@mui/material";
import Info from "@mui/icons-material/Info";

const BpIcon = styled(Box)(({ theme }) => ({
  width: 250,
  borderRadius: 15,
  border: `2px solid ${theme.palette.grey[100]}`,
  padding: theme.spacing(2),
  color: "#000",
  backgroundColor: "#fff",
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.main}`,

  "input:hover ~ &": {
    // border: `2px solid ${theme.palette.primary.light}`,
  },
}));

interface RootProps {
  name: string;
  control: Control<any>;
  label: string;
  options: Array<{
    value: string | number | boolean | Date;
    label: string;
  }>;
  required?: boolean;
  errorMessage: string | undefined;
  hint?: {
    Title?: string;
    Content: string;
  };
  cardHeight: string | number;
  initialValue?: string | number | boolean;
  validation?: RegisterOptions;
}
export default function RadioCards({
  name,
  control,
  label,
  options,
  required,
  errorMessage,
  hint,
  cardHeight,
  initialValue,
  validation,
}: RootProps) {
  return (
    <FormControl fullWidth>
      <FormLabel sx={{ display: "inline-flex", alignItems: "center", gap: 1 }}>
        <Typography fontWeight={500} color={errorMessage ? "error" : "inherit"}>
          {label} {required ? "*" : "(Optional)"}
        </Typography>
        {hint && (
          <Tooltip title={<Typography>{hint.Content}</Typography>}>
            <Info color="secondary" sx={{ cursor: "pointer" }} />
          </Tooltip>
        )}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={initialValue ?? ""}
        shouldUnregister
        rules={validation}
        render={({ field: { onChange, value } }) => (
          <RadioGroup row value={value} onChange={onChange}>
            {options.map((singleOption) => (
              <Radio
                key={singleOption.label}
                value={singleOption.value}
                sx={{
                  "&:hover": {
                    bgcolor: "transparent",
                  },
                }}
                disableRipple
                color="default"
                checkedIcon={
                  <BpCheckedIcon height={cardHeight}>
                    {singleOption.label}
                  </BpCheckedIcon>
                }
                icon={<BpIcon height={cardHeight}>{singleOption.label}</BpIcon>}
              />
            ))}
          </RadioGroup>
        )}
      />
      <Typography color="red" fontSize={12}>
        {errorMessage}
      </Typography>
    </FormControl>
  );
}
