import * as React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import SEO from "../components/shared/SEO";

export default function Nondiscrimination() {
  return (
    <>
      <SEO
        title="Nondiscrimination Notice"
        description="Nondiscrimination Notice for YourVirtualEnrollment"
        name="YourVirtualEnrollment - Nondiscrimination Notice"
        type="website"
      />
      <Grid container justifyContent="center" py={8} rowGap={4}>
        <Grid item xs={12} container flexDirection="column" alignItems="center">
          <Typography variant="h3" fontWeight={600}>
            Nondiscrimination Notice
          </Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography>
            The plans we represent do not discriminate on the basis of race,
            color, national origin, age, disability, or sex. To learn more about
            a plan's nondiscrimination policy, please select the applicable
            insurance carrier below.
          </Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <ul>
            <li>
              <Link
                color="secondary.dark"
                target="_blank"
                href="https://www.bcbsil.com/medicare/pdf/2022/nondiscrimination-il-2022.pdf"
                rel="noopener noreferrer"
              >
                Blue Cross and Blue Shield of Illinois Nondiscrimination Notice
                (PDF)
              </Link>
            </li>
            <li>
              <Link
                color="secondary.dark"
                target="_blank"
                href="https://www.bcbsmt.com/medicare/pdf/2022/nondiscrimination-mt-2022.pdf"
                rel="noopener noreferrer"
              >
                Blue Cross and Blue Shield of Montana Nondiscrimination Notice
                (PDF)
              </Link>
            </li>
            <li>
              <Link
                color="secondary.dark"
                target="_blank"
                href="https://www.bcbsnm.com/medicare/pdf/2022/nondiscrimination-nm-2022.pdf"
                rel="noopener noreferrer"
              >
                Blue Cross and Blue Shield of New Mexico Nondiscrimination
                Notice (PDF)
              </Link>
            </li>
            <li>
              <Link
                color="secondary.dark"
                target="_blank"
                href="https://www.bcbsok.com/medicare/pdf/2022/nondiscrimination-ok-2022.pdf"
                rel="noopener noreferrer"
              >
                Blue Cross and Blue Shield of Oklahoma Nondiscrimination Notice
                (PDF)
              </Link>
            </li>
            <li>
              <Link
                color="secondary.dark"
                target="_blank"
                href="https://www.bcbstx.com/medicare/pdf/2022/nondiscrimination-tx-2022.pdf"
                rel="noopener noreferrer"
              >
                Blue Cross and Blue Shield of Texas Nondiscrimination Notice
                (PDF)
              </Link>
            </li>
            <li>
              <Link
                color="secondary.dark"
                target="_blank"
                href="/Humana-NDN.pdf"
                rel="noopener noreferrer"
              >
                Humana Nondiscrimination Notice (PDF)
              </Link>
            </li>
            <li>
              <Link
                color="secondary.dark"
                target="_blank"
                href="/UHC-NDN.pdf"
                rel="noopener noreferrer"
              >
                United Healthcare Nondiscrimination Notice (PDF)
              </Link>
            </li>
          </ul>
        </Grid>
      </Grid>
    </>
  );
}
