import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import Popover from "@mui/material/Popover";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import CountyDialog from "./CountyDialog";

import {
  useGetLocationInfoQuery,
  useLoadQuoteQuery,
} from "../../redux/services/API";
import { setLocation } from "../../redux/reducers/Quote";

import { Location } from "../../types/Location.types";

import { useRudderStackAnalytics } from "../../utils/useRudderStackAnalytics";
import useNavigateParams from "../../utils/useNavigateParams";

import { ANALYTICS_EVENT } from "../../config/analytics.config";

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  fontWeight: 600,
  padding: ".6rem",
  borderRadius: 15,
  fontSize: "1rem",
  width: "23.5rem",
  height: "4rem",
  marginLeft: ".5rem",

  [theme.breakpoints.only("xs")]: {
    width: "18rem",
  },
}));

const TextButton = styled(Button)({
  textDecoration: "underline",
  textUnderlineOffset: "3px",
  "&:hover": {
    textDecoration: "underline",
  },
});

const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: 15,
  paddingRight: 0,
  paddingLeft: 20,
  width: "26rem",
  maxWidth: "26rem",
  fontSize: "1.4rem",
  backgroundColor: "#fff",

  [theme.breakpoints.only("xs")]: {
    width: "20.5rem",
  },
}));

export default function InputSection() {
  const navigate = useNavigateParams();
  const dispatch = useAppDispatch();
  const { Location, QuoteID: StoredQuoteID } = useAppSelector(
    (state) => state.Quote
  );
  const analytics = useRudderStackAnalytics();

  const quoteAnchor = React.useRef(null);

  const [zipCode, setZipCode] = React.useState(Location?.ZipCode ?? "");
  const [quoteID, setQuoteID] = React.useState(StoredQuoteID ?? "");
  const [quoteAnchorEl, setQuoteAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const popoverOpen = Boolean(quoteAnchorEl);
  const id = popoverOpen ? "quote-id-popover" : undefined;

  const {
    currentData: counties,
    isSuccess: isSuccessCounties,
    isError: isErrorCounties,
    isFetching: isFetchingCounties,
  } = useGetLocationInfoQuery(zipCode, {
    skip: zipCode.length !== 5 || isNaN(Number(zipCode)),
  });

  const {
    isSuccess: isQuoteSuccess,
    isError: isQuoteError,
    isFetching: isFetchingQuote,
  } = useLoadQuoteQuery(quoteID, {
    skip: quoteID.length !== 8,
  });

  React.useEffect(() => {
    if (isQuoteSuccess) {
      analytics?.track(ANALYTICS_EVENT.QUOTE_ID_ENTERED, {
        quote_id: quoteID,
      });

      navigate("input/doctors");
    }
  }, [isQuoteSuccess, analytics, quoteID, navigate]);

  const handleCountyClick = (index: number) => {
    if (!counties) {
      return;
    }

    if (index === -1) {
      setZipCode("");
      return;
    }

    const selectedLocation = counties.data[index];

    const newLocation: Location = {
      ...selectedLocation,
      ZipCode: zipCode,
    };

    analytics?.track(ANALYTICS_EVENT.COUNTY_SELECTED, {
      zip_code: zipCode,
      county_name: selectedLocation.CountyName,
      county_fips: selectedLocation.CountyFIPS,
    });

    dispatch(setLocation(newLocation));
    navigate("preferences");
  };

  const handleQuoteIDClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setQuoteAnchorEl(event.currentTarget);

    analytics?.track(ANALYTICS_EVENT.URL_BUTTON_CLICKED, {
      link_text: "Have a Quote ID? Click Here to Input",
      link_url: "#input-quote-id",
    });
  };

  const handleQuotePopoverClose = () => {
    setQuoteAnchorEl(null);
  };

  return (
    <>
      <Grid
        container
        height="100%"
        direction="column"
        alignContent="center"
        justifyContent="center"
        rowGap={2}
        p={5}
      >
        <Grid item>
          <Typography variant="h5" fontWeight={600}>
            Medicare Can Be Difficult <br /> Let's Change That
          </Typography>
        </Grid>

        <Grid item>
          <Typography>Input your Zip Code and County to Start</Typography>
        </Grid>

        <Grid item>
          <StyledInput
            value={zipCode}
            onChange={(e) => {
              const value = e.target.value;
              setZipCode(value);

              if (value.length === 5) {
                analytics?.track(ANALYTICS_EVENT.ZIP_CODE_ENTERED, {
                  zip_code: value,
                });
              }
            }}
            inputProps={{ maxLength: 5, inputMode: "numeric" }}
            placeholder="Zip Code"
            endAdornment={
              <StyledButton
                variant="contained"
                endIcon={<ArrowForwardIcon fontSize="large" />}
                loading={isFetchingCounties}
              >
                Find Counties
              </StyledButton>
            }
            error={isErrorCounties}
          />
          {isErrorCounties && (
            <Typography color="error" fontSize="0.75rem" mt={0.25}>
              Enter a valid Zip Code
            </Typography>
          )}
        </Grid>

        <Grid item display="inline-flex" alignItems="center">
          <Typography>Have a Quote ID?</Typography>
          <TextButton
            ref={quoteAnchor}
            variant="text"
            color="secondary"
            onClick={handleQuoteIDClick}
          >
            Click Here to Input
          </TextButton>
        </Grid>

        <Popover
          id={id}
          open={popoverOpen}
          anchorEl={quoteAnchorEl}
          onClose={handleQuotePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          PaperProps={{ sx: { p: 1, borderRadius: 5 } }}
        >
          <StyledInput
            value={quoteID}
            onChange={(e) => setQuoteID(e.target.value.toUpperCase())}
            placeholder="QuoteID"
            inputProps={{ maxLength: 8 }}
            endAdornment={
              <StyledButton
                variant="contained"
                endIcon={<ArrowForwardIcon fontSize="large" />}
                loading={isFetchingQuote}
              >
                Continue
              </StyledButton>
            }
            error={isQuoteError}
          />
          {isQuoteError && (
            <Typography color="error" fontSize="0.75rem" mt={0.25}>
              Enter a valid QuoteID
            </Typography>
          )}
        </Popover>
      </Grid>

      {counties?.data && (
        <CountyDialog
          open={isSuccessCounties}
          handleCountyClick={handleCountyClick}
          countiesData={counties.data}
        />
      )}
    </>
  );
}
