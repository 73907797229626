import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useGetDoctorsInNetworkQuery } from "../../../redux/services/API";
import { setSelectedDoctor } from "../../../redux/reducers/Enrollment";
import { useDebounce } from "use-debounce";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Cancel, Search } from "@mui/icons-material";

import SearchField from "../../shared/SearchField";
import DoctorSelect from "../../UserInformation/Doctors/DoctorSelect";

import { SelectedDoctor } from "../../../types/Doctor.types";
import { WEBSITE_FILTER_TYPE } from "../../../types/Affiliate.types";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, bgcolor: "primary.main" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <Cancel fontSize="large" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface RootProps {
  displaySelectedPCP?: boolean;
}

export default function DoctorFinder({ displaySelectedPCP }: RootProps) {
  const { setValue, watch } = useFormContext();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  const { Location } = useAppSelector((state) => state.Quote);
  const { [WEBSITE_FILTER_TYPE.PROVIDER]: ProviderFilters } = useAppSelector(
    (state) => state.Affiliate.Filters
  );

  const { ContractID, PlanID, SegmentID, Year } =
    useAppSelector((state) => state.Enrollment.EnrollPlan) ?? {};
  const { CurrentPatientOfPhysician } = useAppSelector(
    (state) => state.Enrollment.Input["eligibility-questions"]
  );
  const SelectedDoctorData = useAppSelector(
    (state) => state.Enrollment.SelectedDoctor
  );

  const watchPhysicianName = watch(
    "PhysicianName",
    SelectedDoctorData
      ? SelectedDoctorData.facilityName ??
          `${SelectedDoctorData.firstName} ${SelectedDoctorData.lastName}`
      : ""
  );
  const watchPhysicianAddress = watch(
    "PhysicianAddress",
    SelectedDoctorData
      ? `${SelectedDoctorData.location.address};${
          SelectedDoctorData.location.address2 ?? ""
        };${SelectedDoctorData.location.city};${
          SelectedDoctorData.location.state
        };${SelectedDoctorData.location.zip}`
      : ""
  );
  const watchPhysicianID = watch(
    "PhysicianID",
    SelectedDoctorData ? SelectedDoctorData.enrollmentId : ""
  );

  const [open, setOpen] = React.useState(false);
  const [page, setCurrentPage] = React.useState(1);
  const [total, setTotal] = React.useState<number>();
  const [radius, setRadius] = React.useState(5);
  const [isCurrentPatient, setIsCurrentPatient] = React.useState(
    () => CurrentPatientOfPhysician === "Y"
  );

  const [doctorSearch, setDoctorSearch] = React.useState("");
  const [debouncedSearchQuery] = useDebounce(doctorSearch, 750);

  const { data, isLoading, isFetching } = useGetDoctorsInNetworkQuery(
    {
      zipCode: (Location?.ZipCode as string) ?? "",
      radiusInMiles: radius,
      page,
      network: `${Year}_${ContractID}_${PlanID}_${SegmentID}`,
      providerName: debouncedSearchQuery,
      coordinates: Location?.Coordinates?.join(",") ?? "",
      filters: ProviderFilters,
    },
    {
      skip: !open || !Location || debouncedSearchQuery.length < 3,
    }
  );

  React.useEffect(() => {
    if (SelectedDoctorData) {
      setValue(
        "PhysicianName",
        SelectedDoctorData.facilityName ??
          `${SelectedDoctorData.firstName} ${SelectedDoctorData.lastName}`
      );
      setValue(
        "PhysicianAddress",
        `${SelectedDoctorData.location.address};${
          SelectedDoctorData.location.address2 ?? ""
        };${SelectedDoctorData.location.city};${
          SelectedDoctorData.location.state
        };${SelectedDoctorData.location.zip}`
      );

      if (SelectedDoctorData.enrollmentId?.includes("_")) {
        const splitEnrollmentId = SelectedDoctorData.enrollmentId?.split(
          "_"
        ) ?? ["0", "0"];
        setValue("PhysicianID", Number(splitEnrollmentId[0]?.trim()) || 0);
        setValue(
          "PhysicianProviderID",
          Number(splitEnrollmentId[1]?.trim()) || 0
        );
      } else {
        setValue("PhysicianID", SelectedDoctorData.enrollmentId);
      }
    }
  }, [SelectedDoctorData, setValue]);

  React.useEffect(() => {
    if (page === 1) {
      setTotal(data?.meta.totalPages);
      return;
    }
  }, [page, data]);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearchQuery]);

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  const handleRadius = (event: SelectChangeEvent<number>) => {
    setRadius(event.target.value as number);
  };

  const handleSelectDoctor = (
    index: number,
    selected: boolean,
    locationIndex: number
  ) => {
    if (!data) {
      return;
    }

    if (selected) {
      dispatch(setSelectedDoctor(null));
      setValue("PhysicianID", "");
      setValue("PhysicianProviderID", "");
      setValue("PhysicianName", "");
      setValue("PhysicianAddress", "");
      setValue("CurrentPatientOfPhysician", "");
      return;
    }

    const { locations, ...otherData } = data.data[index];
    const newDoctor: SelectedDoctor = {
      ...otherData,
      location: locations[locationIndex],
      enrollmentId:
        locations[locationIndex].networks[0].providerTypes[0].specialties[0]
          .enrollmentId,
    };
    dispatch(setSelectedDoctor(newDoctor));

    setValue("CurrentPatientOfPhysician", "N");
    setIsCurrentPatient(false);
  };

  const handleOpenClick = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleCheckboxClick = () => {
    setValue("CurrentPatientOfPhysician", !isCurrentPatient ? "Y" : "N");

    setIsCurrentPatient((prev) => !prev);
  };

  const handleDoctorSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setDoctorSearch(value);
  };

  return (
    <>
      <Grid container p={3} rowGap={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleOpenClick}
            endIcon={<Search />}
          >
            Find PCP
          </Button>
        </Grid>

        {displaySelectedPCP && watchPhysicianID && (
          <Grid item xs={12}>
            <Typography fontWeight={600} sx={{ textDecoration: "underline" }}>
              Selected PCP
            </Typography>
            <Typography>Name: {watchPhysicianName}</Typography>
            <Typography>Physician ID: {watchPhysicianID}</Typography>
            <Typography>
              Address: {watchPhysicianAddress?.replace(/;/g, " ")}
            </Typography>

            <FormControlLabel
              sx={{ pointerEvents: "none" }}
              label="I am a current patient of the above physician"
              value={isCurrentPatient}
              checked={isCurrentPatient}
              control={
                <Checkbox
                  onChange={handleCheckboxClick}
                  sx={{ pointerEvents: "auto" }}
                />
              }
            />
            <Typography>
              <b>Note:</b>{" "}
              {isCurrentPatient
                ? "You will be assigned to this doctor, even if they're closed to new patients"
                : "This doctor may be accepting current patients only. If this doctor is not taking new patients we'll assign you to a different in-network doctor. You can contact the Plan at any time to change your PCP."}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
        fullScreen={isMobile}
      >
        <CustomDialogTitle
          id="pcp-finder-dialog-title"
          onClose={handleDialogClose}
        >
          <Typography variant="h5" color="#fff" fontWeight={600}>
            PCP Finder
          </Typography>
        </CustomDialogTitle>

        <Grid container rowGap={2} p={3} justifyContent="center">
          <Grid item xs={12}>
            <SearchField
              placeholder="Search by Doctor's Name"
              handleChange={handleDoctorSearch}
              value={doctorSearch}
            />
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6} sm={3}>
              <FormControl fullWidth>
                <InputLabel id="radius-label">Radius</InputLabel>
                <Select<number>
                  labelId="radius-label"
                  label="Radius"
                  fullWidth
                  onChange={handleRadius}
                  value={radius}
                >
                  <MenuItem value={2}>2 Miles</MenuItem>
                  <MenuItem value={3}>3 Miles</MenuItem>
                  <MenuItem value={4}>4 Miles</MenuItem>
                  <MenuItem value={5}>5 Miles</MenuItem>
                  <MenuItem value={10}>10 Miles</MenuItem>
                  <MenuItem value={15}>15 Miles</MenuItem>
                  <MenuItem value={20}>20 Miles</MenuItem>
                  <MenuItem value={30}>30 Miles</MenuItem>
                  <MenuItem value={40}>40 Miles</MenuItem>
                  <MenuItem value={50}>50 Miles</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid item xs={6} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="sort-by-label">Sort By</InputLabel>
                <Select labelId="sort-by-label" label="Sort By" fullWidth>
                  <MenuItem value="Best Match">Best Match</MenuItem>
                  <MenuItem value="Distance">Distance</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            {/* <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="provider-group-label">
                  Provider Group
                </InputLabel>
                <Select
                  labelId="provider-group-label"
                  label="Provider Group"
                  fullWidth
                >
                  <MenuItem value="Duly">Duly Health and Care</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
          </Grid>

          <Grid item xs={12}>
            <Stack divider={<Divider flexItem />} alignItems="center">
              <Typography variant="h5" fontWeight={600} alignSelf="flex-start">
                Search Results
              </Typography>
              {!isFetching &&
                data?.data.map((doctor, index) => {
                  const selected =
                    SelectedDoctorData?.providerId === doctor.providerId;
                  return (
                    <DoctorSelect
                      key={doctor.providerId}
                      info={doctor}
                      index={index}
                      handleSelect={handleSelectDoctor}
                      selected={selected}
                      locationId={
                        selected
                          ? SelectedDoctorData?.location.providerLocationId
                          : undefined
                      }
                    />
                  );
                })}

              {isFetching && (
                <Skeleton
                  variant="rectangular"
                  height={400}
                  sx={{ width: { xs: 350, md: 600 } }}
                />
              )}

              {isLoading ? (
                <Box
                  sx={{
                    display: "inline-flex",
                    columnGap: 3,
                    margin: ".5rem",
                  }}
                >
                  <Skeleton variant="circular" width={32} height={32} />
                  <Skeleton variant="circular" width={32} height={32} />
                  <Skeleton variant="circular" width={32} height={32} />
                  <Skeleton variant="circular" width={32} height={32} />
                </Box>
              ) : (
                data && (
                  <Pagination
                    page={page}
                    count={total}
                    variant="outlined"
                    color="secondary"
                    sx={{ mt: 2 }}
                    onChange={handleChangePage}
                  />
                )
              )}
            </Stack>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
