import * as React from "react";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { useGetApplicationStatusQuery } from "../redux/services/API";

import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import { ANALYTICS_EVENT } from "../config/analytics.config";

import useNavigateParams from "../utils/useNavigateParams";
import { useRudderStackAnalytics } from "../utils/useRudderStackAnalytics";

const DRX_LOGO_URL = process.env.REACT_APP_DRX_LOGO_URL;

const SelectedPlan = styled(Paper)(({ theme }) => ({
  width: "95%",
  maxWidth: 375,
  minHeight: 140,
  borderRadius: 15,
  padding: theme.spacing(2),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: "95%",
  maxWidth: "800px",
  padding: theme.spacing(2),
  borderRadius: 15,
}));

export default function EnrollmentApplicationStatus() {
  const analytics = useRudderStackAnalytics();
  const { id: ApplicationID } = useParams();
  const navigate = useNavigateParams();

  const { data, isLoading, isError } = useGetApplicationStatusQuery(
    ApplicationID ?? "",
    { skip: !ApplicationID }
  );

  React.useEffect(() => {
    if (!ApplicationID) {
      navigate("/");
    }
  }, [ApplicationID, navigate]);

  React.useEffect(() => {
    if (!data?.data || !ApplicationID || !analytics) {
      return;
    }

    analytics.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_STATUS_VIEWED, {
      application_id: ApplicationID,
      contract_id: data.data.Plan.ContractID,
      plan_id: data.data.Plan.PlanID,
      segment_id: data.data.Plan.SegmentID,
      plan_year: data.data.Plan.PlanYear,
      carrier_id: data.data.Carrier.MarketingName,
      plan_type: data.data.Plan.PlanType,
    });
  }, [ApplicationID, analytics, data]);

  if (isLoading) {
    return (
      <Grid container p={5} justifyContent="center" alignContent="center">
        <CircularProgress size="8rem" />
      </Grid>
    );
  }

  if (!data || !data.data || isError) {
    return (
      <Grid
        container
        p={5}
        justifyContent="center"
        alignContent="center"
        textAlign="center"
      >
        <Typography variant="h3">
          Error: ApplicationID does not exist
        </Typography>
      </Grid>
    );
  }

  const EnrollmentStatus = data.data;

  return (
    <Grid container direction="column" rowGap={4} py={4} px={2}>
      <Grid item textAlign="center">
        <Typography variant="h4" fontWeight={600}>
          Thank you for Submitting your Application!
        </Typography>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item container direction="column" alignItems="center">
        <Typography variant="h6" fontWeight={600}>
          Selected Plan:
        </Typography>
        <SelectedPlan>
          <Grid container direction="column" justifyContent="center" rowGap={1}>
            <Grid item>
              <img
                src={`${DRX_LOGO_URL}/`}
                alt=""
                width="150px"
                loading="lazy"
              />
            </Grid>
            <Grid item>
              <Typography fontWeight={500}>
                {EnrollmentStatus.Plan.PlanName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <b>${EnrollmentStatus.QuotedMonthlyPremium}</b> Monthly Plan
                Premium
              </Typography>
            </Grid>
          </Grid>
        </SelectedPlan>
      </Grid>

      <Grid item container justifyContent="center">
        <StyledPaper>
          <Grid container direction="column" rowGap={2}>
            <Grid item textAlign="center">
              <Typography variant="h5" fontWeight={600}>
                Application Information
              </Typography>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>

            <Grid item container alignItems="center" gap={1}>
              <Typography variant="h6" fontWeight={600}>
                Confirmation ID:
              </Typography>
              <Typography variant="h6">
                {EnrollmentStatus.ConfirmationID}
              </Typography>
            </Grid>

            <Grid item container alignItems="center" gap={1}>
              <Typography variant="h6" fontWeight={600}>
                Submission Time:
              </Typography>
              <Typography variant="h6">
                {EnrollmentStatus.SubmissionTime &&
                  format(
                    new Date(EnrollmentStatus.SubmissionTime),
                    "LLLL d u, h:mm a"
                  )}
              </Typography>
            </Grid>

            <Grid item container alignItems="center" gap={1}>
              <Typography variant="h6" fontWeight={600}>
                Effective Date:
              </Typography>
              <Typography variant="h6">
                {EnrollmentStatus.EffectiveDate &&
                  new Date(EnrollmentStatus.EffectiveDate).toLocaleDateString(
                    "en-US",
                    {
                      timeZone: "UTC",
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    }
                  )}
              </Typography>
            </Grid>

            <Grid item container alignItems="center" gap={1}>
              <Typography variant="h6" fontWeight={600}>
                Plan Year:
              </Typography>
              <Typography variant="h6">
                {EnrollmentStatus.Plan.PlanYear}
              </Typography>
            </Grid>

            <Grid item container alignItems="center" gap={1}>
              <Typography variant="h6" fontWeight={600}>
                Status:
              </Typography>
              <Typography variant="h6">{EnrollmentStatus.Status}</Typography>
            </Grid>

            <Grid item container alignItems="center" gap={1}>
              <Typography variant="h6" fontWeight={600}>
                Last Updated:
              </Typography>
              <Typography variant="h6">
                {EnrollmentStatus.UpdatedAt &&
                  format(
                    new Date(EnrollmentStatus.UpdatedAt),
                    "LLLL d u, h:mm a"
                  )}
              </Typography>
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>

      <Grid item container justifyContent="center">
        <StyledPaper>
          <Grid container direction="column" rowGap={2}>
            <Grid item textAlign="center">
              <Typography variant="h5" fontWeight={600}>
                Next Steps
              </Typography>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>

            <Grid item>
              <ul>
                <li>
                  Wait for a letter in 7-10 days. You don't have to do anything.
                </li>
                <li>
                  If your application is accepted by CMS,{" "}
                  {EnrollmentStatus.Carrier.MarketingName} will mail you a
                  member Medicare Advantage card and other important plan
                  information
                </li>
                <li>
                  If you chose automatic withdrawal from your benefit check,
                  this may take up to 90 days to begin. It will not cover any
                  premiums for which {EnrollmentStatus.Carrier.MarketingName}{" "}
                  already invoiced you. Please pay your premium by check until{" "}
                  {EnrollmentStatus.Carrier.MarketingName} stops sending you an
                  invoice.
                </li>
                <li>
                  If you have limited income and resources, you may qualify for
                  Medicare Extra Help. See Bottom of this Page
                </li>
                <li>
                  Please be sure to bookmark this page. You can return to this
                  page at any time to see the updated plan status.
                </li>
              </ul>
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>

      <Grid item container direction="column">
        <Grid item>
          <Typography fontWeight={600}>Medicare Extra Help</Typography>
        </Grid>
        <Grid item>
          <Typography fontWeight={500}>
            How income affects your monthly premium?
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ textIndent: 50 }}>
            People with limited incomes may qualify for Extra Help to pay for
            their prescription drug costs. If you qualify, Medicare could pay
            for 75% or more of your drug costs including monthly prescription
            drug premiums, annual deductibles and co-insurance. Those who
            qualify won't have a coverage gap or a late enrollment penalty. Many
            people are eligible for these savings and don't even know it. For
            more information about this Extra Help, contact your local Social
            Security office, or call Social Security at 1-800-772-1213. TTY
            users should call 1-800-325-0778. You can also apply for Extra Help
            online at{" "}
            <a
              href="https://www.ssa.gov/benefits/medicare/prescriptionhelp"
              target="_blank"
              rel="noreferrer"
            >
              socialsecurity.gov/prescriptionhelp
            </a>
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ textIndent: 50 }}>
            If you qualify for Extra Help with your Medicare prescription drug
            coverage costs, Medicare will pay all or part of your plan premium.
            If Medicare pays only a portion of this premium, we will bill you
            for the amount that Medicare does not cover.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
