export const formatUSD = (input: number) => {
  if (isNaN(input)) {
    return "N/A";
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    signDisplay: "never",
  });
  return formatter.format(input);
};
