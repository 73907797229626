import * as React from "react";
import { Control, RegisterOptions } from "react-hook-form";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import RadioCards from "./RadioCards";
import RadioInput from "./RadioInput";

import { FieldInput } from "../../../types/Enrollment.types";

const SeeMore = styled("span")(({ theme }) => ({
  color: theme.palette.secondary.dark,
  textDecoration: "underline",
  cursor: "pointer",
}));

interface RootProps {
  field: FieldInput;
  control: Control<any>;
  errorMessage: string | undefined;
  validation: RegisterOptions;
  initialValue: string;
}
export default function EnrollmentPeriodInput({
  field,
  control,
  errorMessage,
  validation,
  initialValue,
}: RootProps) {
  const [seeMoreOptions, setSeeMoreOptions] = React.useState(false);

  React.useEffect(() => {
    if (!field.Options || !field.LimitOptions) {
      return;
    }
    for (let i = field.LimitOptions; i < field.Options.length; i++) {
      if (field.Options[i].value === initialValue) {
        setSeeMoreOptions(true);
        return;
      }
    }
  }, [field.LimitOptions, field.Options, initialValue]);

  if (!field.Options || !field.LimitOptions) {
    return <></>;
  }

  const mainOptions = field.Options.slice(0, field.LimitOptions);
  const otherOptions = field.Options.slice(
    field.LimitOptions,
    field.Options.length
  );

  return (
    <>
      <RadioCards
        control={control}
        name={field.id}
        label={field.Label}
        errorMessage={errorMessage}
        required={field.Required}
        cardHeight={200}
        options={mainOptions ?? []}
        initialValue={initialValue}
        hint={field.Hint}
        validation={validation}
      />
      <Typography textAlign="center" my={1}>
        Can't find your Enrollment Period?{" "}
        <SeeMore onClick={() => setSeeMoreOptions(true)}>
          Click Here to See More Enrollment Periods
        </SeeMore>
      </Typography>
      {seeMoreOptions && (
        <Grid item xs={12} container direction="column">
          <Grid item>
            <Typography variant="h6" fontWeight={600}>
              Other Medicare Enrollment Periods
            </Typography>
          </Grid>
          <Grid item>
            <RadioInput
              name={field.id}
              control={control}
              label=""
              options={otherOptions}
              errorMessage={errorMessage}
              initialValue={initialValue}
              validation={validation}
              vertical
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
