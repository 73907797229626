import * as React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import Lightbulb from "@mui/icons-material/Lightbulb";
import Article from "@mui/icons-material/Article";

import { PlanDocuments } from "../../PlanDetails/DetailComponents";
import { ComparisonSubBenefit } from "../PlanComparisonComponents";

import { Plan } from "../../../../types/Plan.types";

const DRX_LOGO_URL = process.env.REACT_APP_DRX_LOGO_URL;

interface RootProps {
  planOne: Plan;
  planTwo: Plan;
}

export default function MedSuppComparison({ planOne, planTwo }: RootProps) {
  return (
    <Grid container direction="column" rowGap={4}>
      <Grid item container justifyContent="space-evenly">
        <Grid
          key={`plan-${planOne.ID}`}
          item
          xs={6}
          container
          direction="column"
          pl={1}
          rowGap={1}
        >
          <Grid item>
            <img
              src={`${DRX_LOGO_URL}/${planOne.LogoURL}`}
              alt=""
              width="150px"
              height="75px"
              loading="lazy"
              style={{ objectFit: "contain", objectPosition: "left" }}
            />
          </Grid>
          <Grid item>
            <Typography variant="body2" fontWeight={600}>
              {planOne.PlanName}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{planOne.PlanSubType}</Typography>
          </Grid>
        </Grid>

        <Grid
          key={`plan-${planTwo.ID}`}
          item
          xs={6}
          container
          direction="column"
          pl={1}
          rowGap={1}
        >
          <Grid item>
            <img
              src={`${DRX_LOGO_URL}/${planTwo.LogoURL}`}
              alt=""
              width="150px"
              height="75px"
              loading="lazy"
              style={{ objectFit: "contain", objectPosition: "left" }}
            />
          </Grid>
          <Grid item>
            <Typography variant="body2" fontWeight={600}>
              {planTwo.PlanName}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{planTwo.PlanSubType}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container justifyContent="center">
        <Divider flexItem sx={{ width: "90%" }} />
      </Grid>

      <Grid item container direction="column" rowGap={2}>
        <Grid item display="inline-flex" alignItems="center">
          <Lightbulb fontSize="large" color="secondary" />
          <Typography variant="h6" fontWeight={700}>
            Plan Highlights
          </Typography>
        </Grid>

        <ComparisonSubBenefit
          title="Monthly Plan Premium"
          subsections={[
            {
              title: "",
              values: [
                {
                  value: `$${(
                    planOne.AnnualCalculatedPlanPremium / 12.0
                  )?.toFixed(2)}`,
                },
                {
                  value: `$${(
                    planOne.AnnualCalculatedPlanPremium / 12.0
                  )?.toFixed(2)}`,
                },
              ],
            },
          ]}
        />

        {planOne.PlanDataFields.map((planOneDataField) => {
          return (
            <ComparisonSubBenefit
              title={planOneDataField.Name}
              subsections={[
                {
                  title: "",
                  values: [
                    {
                      value: planOneDataField.Description,
                    },
                    {
                      value:
                        planTwo.PlanDataFields.find(
                          (planTwoDataField) =>
                            planTwoDataField.benefitCategoryID ===
                            planOneDataField.benefitCategoryID
                        )?.Description ?? "",
                    },
                  ],
                },
              ]}
            />
          );
        })}
      </Grid>

      <Grid item container rowGap={2}>
        <Grid item xs={12} display="inline-flex" alignItems="center">
          <Article fontSize="large" color="secondary" />
          <Typography variant="h6" fontWeight={700}>
            Plan Documents
          </Typography>
        </Grid>

        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          gap={1}
          pl={2}
        >
          <PlanDocuments
            plan={planOne}
            documents={
              planOne.Documents
                ? planOne.Documents.map((document) => {
                    return {
                      title: document.LinkName,
                      link: document.Url,
                    };
                  })
                : []
            }
          />
        </Grid>

        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          gap={1}
          pl={2}
        >
          <PlanDocuments
            plan={planTwo}
            documents={
              planTwo.Documents
                ? planTwo.Documents.map((document) => {
                    return {
                      title: document.LinkName,
                      link: document.Url,
                    };
                  })
                : []
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
