import * as React from "react";
import { compareAsc } from "date-fns";

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@mui/material";

interface RootProps {
  value: string;
  onChange: (e: any) => void;
  onBlur: (e: any) => void;
  errorMessage: string | undefined;
  minDate?: string;
  maxDate?: string;
}

export default function DropdownDatePicker({
  value,
  onChange,
  errorMessage,
  minDate,
  maxDate,
}: RootProps) {
  const splitValue = value?.split("/");

  const [error, setError] = React.useState<string>();

  const [month, setMonth] = React.useState<string>(
    splitValue && splitValue[0] ? splitValue[0].padStart(2, "0") : ""
  );

  const [day, setDay] = React.useState<string>(
    splitValue && splitValue[1] ? splitValue[1].padStart(2, "0") : ""
  );

  const [year, setYear] = React.useState<string>(
    splitValue && splitValue[2] ? splitValue[2] : ""
  );

  React.useEffect(() => {
    if (!month || !year || !day) {
      return;
    }
    const updatedValue = `${month}/${day?.padStart(2, "0")}/${year}`;

    if (
      minDate &&
      compareAsc(new Date(minDate), new Date(updatedValue)) === 1
    ) {
      setError(`Date must be after ${minDate}`);
      return;
    }

    if (
      maxDate &&
      compareAsc(new Date(updatedValue), new Date(maxDate)) === 1
    ) {
      setError(`Date must be before ${maxDate}`);
      return;
    }

    setError(undefined);
    onChange(updatedValue);
  }, [month, day, year, onChange, minDate, maxDate]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="month-label">Month</InputLabel>
          <Select
            labelId="month-label"
            label="Month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            error={errorMessage !== undefined}
          >
            <MenuItem value=""></MenuItem>
            <MenuItem value="01">January</MenuItem>
            <MenuItem value="02">February</MenuItem>
            <MenuItem value="03">March</MenuItem>
            <MenuItem value="04">April</MenuItem>
            <MenuItem value="05">May</MenuItem>
            <MenuItem value="06">June</MenuItem>
            <MenuItem value="07">July</MenuItem>
            <MenuItem value="08">August</MenuItem>
            <MenuItem value="09">September</MenuItem>
            <MenuItem value="10">October</MenuItem>
            <MenuItem value="11">November</MenuItem>
            <MenuItem value="12">December</MenuItem>
          </Select>
          {errorMessage && (
            <Typography pl={2} color="error" fontSize={"0.75rem"}>
              {errorMessage}
            </Typography>
          )}
          {error && (
            <Typography pl={2} color="error" fontSize={"0.75rem"}>
              {error}
            </Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Day"
          placeholder="dd"
          onChange={(e) => setDay(e.target.value)}
          value={day}
          error={errorMessage !== undefined}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Year"
          placeholder="yyyy"
          onChange={(e) => setYear(e.target.value)}
          value={year}
          error={errorMessage !== undefined}
        />
      </Grid>
    </Grid>
  );
}
