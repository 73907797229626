import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setMedSuppQuestionAnswer } from "../../../redux/reducers/Quote";

import {
  Paper,
  Typography,
  styled,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
  Collapse,
  useMediaQuery,
  Theme,
  ListItemText,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";

import FilterList from "@mui/icons-material/FilterList";
import Close from "@mui/icons-material/Close";

import { generateMedSuppPlanName } from "../../../utils/MedSuppUtils";
import effectiveDateOptions from "../../../utils/effectiveDateOptions";

import { MED_SUPP_PLANS } from "../../../types/MedSupp.types";
import {
  removeMedSuppCarrierFilter,
  setMedSuppCarrierFilter,
  setMedSuppPremiumFilter,
} from "../../../redux/reducers/Plan";

const FilterHeader = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(6),
  cursor: "pointer",
  position: "relative",
  zIndex: 1,
  color: "#fff",

  "&::before": {
    content: "''",
    position: "absolute",
    background: theme.palette.primary.main,
    width: "100%",
    height: "53px",
    top: 0,
    zIndex: -1,
  },

  "&::after": {
    content: "''",
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundImage: `url("data:image/svg+xml;utf8,<svg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 120' preserveAspectRatio='none'><path d='M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z' class='shape-fill' fill='${theme.palette.primary.main.replace(
      "#",
      "%23"
    )}' fill-opacity='1'></path></svg>")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "38px",
    zIndex: -1,
    backgroundColor: "transparent",
  },
}));

interface RootProps {
  startTransition: React.TransitionStartFunction;
}

const EFFECTIVE_DATE_OPTIONS = effectiveDateOptions();
const MIN_DISTANCE = 60;

function valuetext(value: number) {
  return `$${value}`;
}

export default function MedSuppFilters({ startTransition }: RootProps) {
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  const { Metadata, PlanFilters } = useAppSelector(
    (state) => state.Plan.MedSupp
  );
  const { Plan, EffectiveDate } = useAppSelector(
    (state) => state.Quote.MedSuppQuestionAnswers
  );

  const { PlanNames, Carriers } = Metadata;
  const { Carriers: selectedCarriers } = PlanFilters;

  const [openFilterBox, setFilterBoxOpen] = React.useState(true);
  const [expanded, setExpanded] = React.useState<{ [id: string]: boolean }>({
    premium: true,
  });
  /** Temp Premium value until slider triggers committed event  */
  const [premium, setPremium] = React.useState([0, 1000]);

  /**
   * Filters Should be Default Closed on Mobile
   * and Default Open on Desktop and Tablet
   */
  React.useEffect(() => {
    setFilterBoxOpen(!isMobile);
  }, [isMobile]);

  const handleAccordionClick = (id: string) => {
    setExpanded({
      ...expanded,
      [id]: !expanded[id],
    });
  };

  const handlePlanNameChange = (event: SelectChangeEvent) => {
    startTransition(() => {
      dispatch(
        setMedSuppQuestionAnswer({
          id: "Plan",
          value: event.target.value,
        })
      );
    });
  };

  const handleEffectiveDateChange = (event: SelectChangeEvent) => {
    startTransition(() => {
      dispatch(
        setMedSuppQuestionAnswer({
          id: "EffectiveDate",
          value: event.target.value,
        })
      );
    });
  };

  const handleCarrierChange = (event: SelectChangeEvent<string[]>) => {
    const value =
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value;

    startTransition(() => {
      dispatch(setMedSuppCarrierFilter(value));
    });
  };

  const handleRemoveCarrier = (carrier: string) => {
    startTransition(() => {
      dispatch(removeMedSuppCarrierFilter(carrier));
    });
  };

  const handlePremium = (
    _event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < MIN_DISTANCE) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 1000 - MIN_DISTANCE);
        setPremium([clamped, clamped + MIN_DISTANCE]);
      } else {
        const clamped = Math.max(newValue[1], MIN_DISTANCE);
        setPremium([clamped - MIN_DISTANCE, clamped]);
      }
    } else {
      setPremium(newValue);
    }
  };

  const handlePremiumCommitted = (
    _event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    if (!Array.isArray(value)) {
      return;
    }

    startTransition(() => {
      dispatch(setMedSuppPremiumFilter(value));
    });

    // analytics?.track("Plan Filter Clicked", {
    //   filter_id: "MonthlyPremium",
    //   filter_value: value.join("-"),
    // });
  };

  const handleOpen = () => {
    setFilterBoxOpen((prev) => !prev);
  };

  return (
    <Paper sx={{ width: { xs: 340, sm: 380 } }}>
      <Grid container direction="column">
        <FilterHeader
          item
          container
          justifyContent="center"
          alignItems="center"
          onClick={handleOpen}
        >
          <FilterList fontSize="large" />
          <Typography variant="h5" fontWeight={600} ml={1}>
            Filter Plans
          </Typography>
        </FilterHeader>

        <Collapse in={openFilterBox} timeout="auto" unmountOnExit>
          <Grid item>
            <FormControl sx={{ m: 3, width: 275 }}>
              <InputLabel id="plan-name-label">Plan Name</InputLabel>
              <Select
                labelId="plan-name-label"
                id="plan-name-select"
                value={Plan ?? ""}
                label="Plan Name"
                onChange={handlePlanNameChange}
              >
                {PlanNames.map((name) => {
                  return (
                    <MenuItem key={name} value={name}>
                      {generateMedSuppPlanName(name as MED_SUPP_PLANS)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl sx={{ m: 3, width: 275 }}>
              <InputLabel id="effective-date-label">Effective Date</InputLabel>
              <Select
                labelId="effective-date-label"
                id="effective-date-select"
                value={EffectiveDate}
                label="EffectiveDate"
                onChange={handleEffectiveDateChange}
              >
                {EFFECTIVE_DATE_OPTIONS.map((option) => {
                  return (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl sx={{ m: 3, width: 300 }}>
              <InputLabel id="carrier-label">Carrier</InputLabel>
              <Select<string[]>
                labelId="carrier-label"
                id="carrier-select"
                multiple
                value={selectedCarriers}
                label="Carrier"
                onChange={handleCarrierChange}
                renderValue={(selected) =>
                  Carriers.filter((name) => selected.includes(name))
                    .map((record) => record)
                    .join(", ")
                }
              >
                {Carriers.map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      <Checkbox
                        checked={selectedCarriers.indexOf(option) > -1}
                      />
                      <ListItemText primary={option} />
                    </MenuItem>
                  );
                })}
              </Select>

              <List
                subheader={
                  <Typography fontWeight={600} variant="h6">
                    Selected Carriers
                  </Typography>
                }
                sx={{ mt: 1 }}
              >
                {selectedCarriers.length > 0 ? (
                  selectedCarriers.map((selectedCarrier) => {
                    return (
                      <ListItemButton
                        key={`selected-${selectedCarrier}`}
                        onClick={() => handleRemoveCarrier(selectedCarrier)}
                      >
                        <ListItemIcon>
                          <Close color="error" />
                        </ListItemIcon>
                        <ListItemText>{selectedCarrier}</ListItemText>
                      </ListItemButton>
                    );
                  })
                ) : (
                  <ListItem>None</ListItem>
                )}
              </List>
            </FormControl>
          </Grid>

          <Grid item>
            <Accordion
              id="premium"
              disableGutters
              elevation={0}
              expanded={expanded["premium"]}
              onChange={() => handleAccordionClick("premium")}
            >
              <AccordionSummary
                aria-controls="premium-content"
                id="premium-header"
              >
                <Typography fontWeight={600}>Monthly Premium</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Slider
                  sx={{ mt: 3 }}
                  getAriaLabel={() => "Range for Monthly Premium"}
                  value={premium}
                  onChange={handlePremium}
                  onChangeCommitted={handlePremiumCommitted}
                  valueLabelDisplay="on"
                  getAriaValueText={valuetext}
                  disableSwap
                  step={10}
                  max={1000}
                  valueLabelFormat={(value) => <div>${value}</div>}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Collapse>
      </Grid>
    </Paper>
  );
}
