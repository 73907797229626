import * as React from "react";

import Grid from "@mui/material/Grid";
import { alpha, styled, useTheme } from "@mui/material/styles";

import InputSection from "../components/Home/InputSection";
import WhyWeAsking from "../components/Home/WhyWeAsking";
import SEO from "../components/shared/SEO";

const BackgroundImage = styled("div")(({ theme }) => ({
  background: `linear-gradient(to right,${alpha(
    theme.palette.secondary.main,
    0.24
  )},${alpha(
    theme.palette.secondary.main,
    0.24
  )}), url("/images/HappyPeople.png")`,
  backgroundSize: "cover",
  height: "412px",
  width: "760px",
}));

export default function Home() {
  const theme = useTheme();

  return (
    <>
      <SEO
        title="Home"
        description="Enroll into a Medicare Plan in your area online"
        name="YourVirtualEnrollment - Home"
        type="website"
      />
      <Grid
        container
        sx={{
          width: "100vw",
          ml: { xl: "calc(-100vw / 2 + 1536px / 2)" },
          mr: { xl: "calc(-100vw / 2 + 1536px / 2)" },
        }}
      >
        <Grid
          item
          xs={12}
          container
          sx={{
            background: alpha(theme.palette.secondary.main, 0.18),
          }}
        >
          <Grid item xs={12} md={7}>
            <InputSection />
          </Grid>
          <Grid item xs={12} md={5} container justifyContent="flex-end">
            <BackgroundImage />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <WhyWeAsking />
        </Grid>
      </Grid>
    </>
  );
}
