import * as React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import SEO from "../components/shared/SEO";

import { LICENSED_STATES } from "../types/Global.types";

export default function Licensing() {
  return (
    <>
      <SEO
        title="Licensing"
        description="Licensing Information for YourVirtualEnrollment"
        name="YourVirtualEnrollment - Licensing"
        type="website"
      />
      <Grid container direction="column" rowGap={4} my={4} alignItems="center">
        <Grid item textAlign="center">
          <Typography variant="h3" fontWeight={600}>
            YourVirtualEnrollment Licensing Information
          </Typography>
        </Grid>

        <Grid item>
          <TableContainer component={Paper} sx={{ width: 300 }}>
            <Table aria-label="licensing table">
              <TableHead>
                <TableRow>
                  <TableCell>State</TableCell>
                  <TableCell align="right">License Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {LICENSED_STATES.map((state) => {
                  return (
                    <TableRow
                      key={state.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {state.name}
                      </TableCell>
                      <TableCell align="right">{state.licenseNumber}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
