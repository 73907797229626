import * as React from "react";

import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

interface RootProps {
  rating: number;
  mobileCenter?: boolean;
}

export default function StarRating({ rating, mobileCenter }: RootProps) {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      alignItems={{ xs: mobileCenter ? "center" : "flex-start", md: "center" }}
      gap={{ xs: 0.25, md: 1 }}
    >
      <Rating precision={0.1} value={rating} readOnly size="small" />
      <Typography variant="body2">{rating} out of 5 Stars</Typography>
    </Box>
  );
}
