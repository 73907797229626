import * as React from "react";

import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { Doctor } from "../../../types/Doctor.types";

function DoctorSelect({
  info,
  handleSelect,
  index,
  selected,
  locationId,
}: {
  info: Doctor;
  handleSelect: (
    index: number,
    selected: boolean,
    locationIndex: number
  ) => void;
  index: number;
  selected: boolean;
  locationId?: number;
}) {
  const [locationIndex, setLocationIndex] = React.useState(() => {
    if (info.locations.length === 1) {
      return 0;
    }

    if (locationId) {
      return info.locations.findIndex(
        (location) => location.providerLocationId === locationId
      );
    }

    return undefined;
  });

  const generateSpeciality = () => {
    if (!info || !info.locations) {
      return;
    }

    const specialties = info.locations[locationIndex ?? 0].specialties;

    if (!specialties) {
      return;
    }

    let specialityBuilder = "";

    for (let i = 0; i < specialties.length; i++) {
      const speciality = specialties[i];
      specialityBuilder += `${speciality.description}`;

      if (i + 1 !== specialties.length) {
        specialityBuilder += " / ";
      }
    }

    return specialityBuilder;
  };

  const generateFlags = () => {
    if (!info || !info.locations) {
      return;
    }

    const networks = info.locations[locationIndex ?? 0]?.networks;

    if (!networks || networks.length === 0) {
      return;
    }

    const flags = info.locations[locationIndex ?? 0].networks[0].flags;

    if (flags.length === 0) {
      return;
    }

    return (
      <InfoOutlined
        fontSize="small"
        color="secondary"
        titleAccess={flags.join(", ")}
        sx={{ cursor: "pointer" }}
      />
    );
  };

  return (
    <Grid
      container
      width={{ xs: 350, sm: 600 }}
      minHeight={60}
      sx={{ mx: { xs: 0.5, sm: 1 }, my: 1 }}
      rowGap={2}
    >
      <Grid
        item
        xs={6}
        sm={5}
        container
        direction="column"
        textAlign="left"
        rowGap={0.5}
      >
        <Grid item display="flex" gap={1} alignItems="center">
          <Typography fontWeight={500}>
            {info.facilityName ?? `${info.firstName} ${info.lastName}`}
          </Typography>
          {generateFlags()}
        </Grid>
        <Grid item mr={1}>
          <Typography variant="body2">{generateSpeciality()}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sm={5}
        container
        direction="column"
        textAlign={{ xs: "right", sm: "left" }}
      >
        {locationIndex !== undefined ? (
          <>
            {info.locations.length > 1 && (
              <Grid item>
                <Typography
                  variant="body2"
                  color="primary"
                  textAlign={{ xs: "right", md: "left" }}
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    setLocationIndex(undefined);
                    if (selected) {
                      handleSelect(index, selected, locationIndex);
                    }
                  }}
                >
                  Select Other Location
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Typography variant="body2">
                {info.locations[locationIndex].address}
                {", "}
                {info.locations[locationIndex].address2}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {info.locations[locationIndex].city},{" "}
                {info.locations[locationIndex].state}{" "}
                {info.locations[locationIndex].zip}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <TextField
                select
                label="Select Location"
                variant="standard"
                value={locationIndex}
                onChange={(e) => setLocationIndex(Number(e.target.value))}
                fullWidth
              >
                <MenuItem value={null || undefined} />
                {info.locations.map((location, index) => (
                  <MenuItem
                    key={location.providerLocationId}
                    value={index}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography variant="body2">
                      {location.address}
                      {location.address2 ? ` ${location.address2}` : null},{" "}
                      {location.city},{location.state} {location.zip}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={2}
        container
        justifyContent="center"
        alignContent="center"
      >
        <Button
          variant="outlined"
          fullWidth
          sx={{ maxWidth: "150px", borderRadius: 15 }}
          onClick={() => handleSelect(index, selected, locationIndex ?? 0)}
          color={selected ? "error" : "primary"}
          disabled={locationIndex === undefined}
        >
          {selected ? "REMOVE" : "ADD"}
        </Button>
      </Grid>
    </Grid>
  );
}

export default React.memo(DoctorSelect, function (prevState, nextState) {
  return prevState.selected === nextState.selected;
});
