import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";

import { setSessionID } from "../reducers/Quote";

import { API_URL } from "../../config/global.config";

export const resumeSessionFailedListener: Middleware =
  (api: MiddlewareAPI) => (next) => async (action) => {
    if (isRejectedWithValue(action)) {
      const error = action.payload.data.error;
      const { SessionID } = action.meta.arg.originalArgs;

      if (!SessionID || typeof error !== "object") {
        return next(action);
      }

      if (error?.Message === "ResumeSession failed.") {
        try {
          const response = await fetch(
            `${API_URL}/lmpp/session/${SessionID}/refresh`
          );
          const json = await response.json();

          api.dispatch(setSessionID(json.SessionID));
        } catch (error) {
          console.error(error);
        }
      }
    }

    return next(action);
  };
