import * as React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useIdleTimer } from "react-idle-timer";
import { format } from "date-fns";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

import Sidebar from "../components/Enroll/Sidebar";
import ApplicantInformation from "../components/Enroll/forms/ApplicantInformation";
import MedicareInformation from "../components/Enroll/forms/MedicareInformation";
import ApplicationEffectiveDate from "../components/Enroll/forms/ApplicationEffectiveDate";
import ApplicationSignature from "../components/Enroll/forms/ApplicationSignature";
import ApplicationReview from "../components/Enroll/forms/ApplicationReview";
import DynamicForm from "../components/Enroll/forms/DynamicForm";
import SEO from "../components/shared/SEO";
import IdleDialog from "../components/Enroll/IdleDialog";

import {
  useGetEnrollmentFormDataQuery,
  useGenerateApplicationIDQuery,
  usePostEnrollmentDataMutation,
} from "../redux/services/API";
import {
  clearEnrollmentInput,
  setApplicationID,
  setCarrier,
  setEnrollPlan,
  setMetadata,
} from "../redux/reducers/Enrollment";
import { setActiveStep } from "../redux/reducers/Quote";

import { useRudderStackAnalytics } from "../utils/useRudderStackAnalytics";
import useNavigateParams from "../utils/useNavigateParams";

import { EnrollmentSteps } from "../types/Enrollment.types";
import { STEPS } from "../types/Global.types";
import { USER_TIME_ZONE } from "../config/global.config";
import { ANALYTICS_EVENT } from "../config/analytics.config";

export default function Enroll() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigateParams();
  const PATHNAME = location.pathname.split("/")[3];

  const analytics = useRudderStackAnalytics();

  const {
    EnrollPlan,
    SubmitForm,
    Input,
    SubmitFormSuccess,
    ApplicationID,
    CompleteSteps,
  } = useAppSelector((state) => state.Enrollment);

  const { QuoteID, Location } = useAppSelector((state) => state.Quote);
  const AffiliateCode = useAppSelector((state) => state.Affiliate.Code);

  const { data, isLoading, isFetching } = useGetEnrollmentFormDataQuery(
    {
      Year: EnrollPlan?.Year ?? 0,
      ContractID: EnrollPlan?.ContractID ?? "",
      PlanID: EnrollPlan?.PlanID ?? "",
      SegmentID: EnrollPlan?.SegmentID ?? "",
      UserType: "CONSUMER",
      SnpType: EnrollPlan?.SnpType,
    },
    {
      skip: !EnrollPlan,
    }
  );

  const formData = data?.data.form;

  const {
    isFetching: isGeneratingApplicationID,
    isError: isErrorGeneratingApplicationID,
    error: generatingApplicationIDError,
  } = useGenerateApplicationIDQuery(
    {
      QuoteID: QuoteID ?? "",
      ContractID: EnrollPlan?.ContractID ?? "",
      PlanID: EnrollPlan?.PlanID ?? "",
      SegmentID: EnrollPlan?.SegmentID ?? "",
      PlanYear: EnrollPlan?.Year ?? 0,
      State: Location?.State ?? "",
      QuotedMonthlyPremium: EnrollPlan?.QuotedPremium ?? -1,
      LeadSource: AffiliateCode,
    },
    {
      skip:
        ApplicationID !== null ||
        !QuoteID ||
        !Location ||
        !EnrollPlan ||
        PATHNAME === "status",
    }
  );

  const [
    pushInputData,
    { isLoading: isPostingData, isSuccess: isPostDataSuccess },
  ] = usePostEnrollmentDataMutation();

  React.useEffect(() => {
    const handleTabClose = (event: BeforeUnloadEvent) => {
      event.preventDefault();

      return (event.returnValue =
        "Are you sure you want to exit? Your progress will not be saved");
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  React.useEffect(() => {
    if (SubmitForm && !SubmitFormSuccess) {
      /**
       * Throw error not all steps complete
       */
      // if (CompleteSteps.length !== 7) {
      //   dispatch(submitForm(false));
      //   return;
      // }

      const inputData = {
        ApplicationID,
        SubmissionTime: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS"),
        Timezone: USER_TIME_ZONE,
        // SignatureDate: format(new Date(), "yyyy-MM-dd"),
        "plan-information": {
          ...EnrollPlan,
        },
        ...Input,
      };
      pushInputData(inputData);

      analytics?.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SUBMITTED, {
        application_id: ApplicationID,
        quote_id: QuoteID,
        enrollment_type: "INTERNAL",
        contract_id: EnrollPlan?.ContractID,
        plan_id: EnrollPlan?.PlanID,
        segment_id: EnrollPlan?.SegmentID,
        plan_year: EnrollPlan?.Year,
        snp_type: EnrollPlan?.SnpType,
        plan_type: EnrollPlan?.PlanType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    SubmitForm,
    Input,
    pushInputData,
    EnrollPlan,
    SubmitFormSuccess,
    ApplicationID,
    CompleteSteps,
  ]);

  React.useEffect(() => {
    if (!analytics || !ApplicationID || !EnrollPlan || !QuoteID) {
      return;
    }

    analytics.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_STARTED, {
      application_id: ApplicationID,
      quote_id: QuoteID,
      enrollment_type: "INTERNAL",
      contract_id: EnrollPlan?.ContractID,
      plan_id: EnrollPlan?.PlanID,
      segment_id: EnrollPlan?.SegmentID,
      plan_year: EnrollPlan?.Year,
      snp_type: EnrollPlan?.SnpType,
      plan_type: EnrollPlan?.PlanType,
    });
  }, [ApplicationID, analytics, EnrollPlan, QuoteID]);

  React.useEffect(() => {
    if (!EnrollPlan) {
      navigate("/");
    }
  }, [EnrollPlan, navigate]);

  React.useEffect(() => {
    if (isPostDataSuccess || SubmitFormSuccess) {
      navigate(`/enroll/status/${ApplicationID}`, { replace: true });
    }
  }, [ApplicationID, SubmitFormSuccess, isPostDataSuccess, navigate]);

  // React.useEffect(() => {
  //   /**
  //    * Add Custom Pages to STEPS
  //    * Necessary if we want to add Custom Pages besides Contact/EQ
  //    */
  //   if (!data) {
  //     return;
  //   }

  //   data.Pages.forEach((page) => {
  //     EnrollmentSteps[page.id]
  //   });
  // }, [data]);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleCancelEnrollment = () => {
    dispatch(setEnrollPlan(null));
    dispatch(setMetadata(null));
    dispatch(setCarrier(null));
    dispatch(setActiveStep(STEPS.COMPARE));
    handleNavigate("../input/compare");
  };

  const handleIdleDialogClick = () => {
    setIdle(false);
    dispatch(clearEnrollmentInput());
    dispatch(setApplicationID(null));
    handleCancelEnrollment();
  };

  const [isIdle, setIdle] = React.useState(false);

  const onIdle = () => {
    setIdle(true);
  };

  const onActive = () => {};

  useIdleTimer({
    onIdle,
    onActive,
    timeout: 1000 * 60 * 30, // 30 min idle
  });

  if (
    isLoading ||
    isFetching ||
    !data ||
    !formData ||
    !formData.Pages ||
    isPostingData ||
    isGeneratingApplicationID
  ) {
    return (
      <Grid container p={5} justifyContent="center" alignContent="center">
        <CircularProgress size="8rem" />
      </Grid>
    );
  }

  if (isErrorGeneratingApplicationID) {
    //@ts-ignore
    const error = generatingApplicationIDError?.data?.error;
    return (
      <Grid container p={5} justifyContent="center" alignItems="center">
        <Grid item xs={12} container justifyContent="center">
          <Typography variant="h4">{error}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button onClick={() => handleCancelEnrollment()}>
            Return to Plans
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <SEO
        title="Enroll into Medicare Plan"
        description="Enroll Medicare Plans into your Quoted Plan"
        name="YourVirtualEnrollment - Enroll"
        type="website"
      />
      <Grid container paddingX={2} paddingTop={3} paddingBottom={2} rowGap={2}>
        <Grid item xs={12} md={3}>
          <Sidebar />
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper sx={{ p: 3, borderRadius: 2 }}>
            <Grid container direction="column" rowGap={2}>
              <Grid item>
                <Typography variant="h4" fontWeight={600}>
                  {EnrollmentSteps[PATHNAME].title}
                </Typography>
                <Typography>* Required Field</Typography>
              </Grid>

              <Divider flexItem />

              <Grid item justifySelf="center" marginY="auto">
                <Routes>
                  <Route
                    path="applicant-information"
                    element={
                      <ApplicantInformation handleNavigate={handleNavigate} />
                    }
                  />
                  <Route
                    path="contact-information"
                    element={
                      <DynamicForm
                        pageID={formData.Pages[0].id}
                        sectionsData={formData.Pages[0].Sections}
                        handleNavigate={handleNavigate}
                        nextPage="medicare-information"
                        prevPage="applicant-information"
                      />
                    }
                  />
                  <Route
                    path="medicare-information"
                    element={
                      <MedicareInformation handleNavigate={handleNavigate} />
                    }
                  />
                  <Route
                    path="eligibility-questions"
                    element={
                      <DynamicForm
                        pageID={formData.Pages[1].id}
                        sectionsData={formData.Pages[1].Sections}
                        handleNavigate={handleNavigate}
                        nextPage="review"
                        prevPage="medicare-information"
                      />
                    }
                  />
                  <Route
                    path="review"
                    element={
                      <ApplicationReview handleNavigate={handleNavigate} />
                    }
                  />
                  <Route
                    path="application-effective-date"
                    element={
                      <ApplicationEffectiveDate
                        handleNavigate={handleNavigate}
                      />
                    }
                  />
                  <Route path="signature" element={<ApplicationSignature />} />
                </Routes>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {isIdle && (
        <IdleDialog open={isIdle} handleClose={() => handleIdleDialogClick()} />
      )}
    </>
  );
}
