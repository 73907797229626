import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SelectedDoctor } from "../../types/Doctor.types";
import { Location } from "../../types/Location.types";
import { SelectedMedicine } from "../../types/Medicine.types";
import { SelectedPharmacy } from "../../types/Pharmacy.types";
import { PlanFavorite } from "../../types/Plan.types";
import { MedSuppQuestionAnswers } from "../../types/MedSupp.types";

interface QuoteState {
  QuoteID: string | null;
  SessionID: string | null;
  BrokerNPN: string | null;
  Location: Location | null;
  Doctors: SelectedDoctor[];
  Medicines: SelectedMedicine[];
  Pharmacy: SelectedPharmacy | null;
  FavoritePlans: PlanFavorite[];
  Preference: "medical" | "drug" | "both";
  ActiveStep: number;
  UserType: string | null;
  TooltipText: string | null;
  AnswerMedSuppQuestions: boolean;
  MedSuppQuestionAnswers: MedSuppQuestionAnswers;
}

const initialState: QuoteState = {
  QuoteID: null,
  SessionID: null,
  BrokerNPN: null,
  Location: null,
  Doctors: [],
  Medicines: [],
  Pharmacy: null,
  FavoritePlans: [],
  Preference: "both",
  ActiveStep: 0,
  UserType: "CONSUMER",
  TooltipText: null,
  AnswerMedSuppQuestions: false,
  MedSuppQuestionAnswers: {
    ZipCode: "",
    Plan: null,
    Age: null,
    Gender: null,
    Tobacco: null,
    EffectiveDate: "",
  },
};

export const quoteSlice = createSlice({
  name: "Quote",
  initialState,
  reducers: {
    setQuoteID: (state: QuoteState, action: PayloadAction<string>) => {
      state.QuoteID = action.payload;
    },
    setSessionID: (state: QuoteState, action: PayloadAction<string>) => {
      state.SessionID = action.payload;
    },
    setBrokerNPN: (state: QuoteState, action: PayloadAction<string>) => {
      state.BrokerNPN = action.payload;
    },
    setQuote(state: QuoteState, action: PayloadAction<any>) {
      state.QuoteID = action.payload.QuoteID;
      state.SessionID = action.payload.SessionID;
      state.Location = action.payload.Location;
      state.Pharmacy = action.payload.Pharmacy;
      state.Medicines = action.payload.Medicines;
      state.Doctors = action.payload.Doctors;
      state.FavoritePlans = action.payload.FavoritePlans;
      state.BrokerNPN = action.payload.BrokerNPN;
      state.UserType = action.payload.UserType;
    },
    setLocation: (state: QuoteState, action: PayloadAction<Location>) => {
      state.Location = action.payload;
    },
    setPreference: (
      state: QuoteState,
      action: PayloadAction<"medical" | "drug" | "both">
    ) => {
      state.Preference = action.payload;
    },
    addDoctor: (state: QuoteState, action: PayloadAction<SelectedDoctor>) => {
      state.Doctors.push(action.payload);
    },
    removeDoctor(state: QuoteState, action: PayloadAction<number>) {
      const index = state.Doctors.findIndex(
        (doctor) => doctor.providerId === action.payload
      );
      state.Doctors.splice(index, 1);
    },
    addMedicine: (
      state: QuoteState,
      action: PayloadAction<SelectedMedicine>
    ) => {
      state.Medicines.push(action.payload);
    },
    removeMedicine(state: QuoteState, action: PayloadAction<number>) {
      const index = state.Medicines.findIndex(
        (medicine) => medicine.DosageRecordID === action.payload
      );
      state.Medicines.splice(index, 1);
    },
    setPharmacy: (
      state: QuoteState,
      action: PayloadAction<SelectedPharmacy | null>
    ) => {
      state.Pharmacy = action.payload;
    },
    addPlan: (state: QuoteState, action: PayloadAction<PlanFavorite>) => {
      state.FavoritePlans.push(action.payload);
    },
    removePlan(state: QuoteState, action: PayloadAction<string>) {
      const index = state.FavoritePlans.findIndex(
        (plan) => plan.ID === action.payload
      );
      state.FavoritePlans.splice(index, 1);
    },
    nextStep: (state: QuoteState) => {
      state.ActiveStep += 1;
    },
    prevStep: (state: QuoteState) => {
      state.ActiveStep -= 1;
    },
    setActiveStep: (state: QuoteState, action: PayloadAction<number>) => {
      state.ActiveStep = action.payload;
    },
    setUserType: (state: QuoteState, action: PayloadAction<string>) => {
      state.UserType = action.payload;
    },
    setTooltipText: (
      state: QuoteState,
      action: PayloadAction<string | null>
    ) => {
      state.TooltipText = action.payload;
    },
    setAnswerMedSuppQuestions: (
      state: QuoteState,
      action: PayloadAction<boolean>
    ) => {
      state.AnswerMedSuppQuestions = action.payload;
    },
    setMedSuppQuestionAnswers: (
      state: QuoteState,
      action: PayloadAction<MedSuppQuestionAnswers>
    ) => {
      state.MedSuppQuestionAnswers = action.payload;
    },
    setMedSuppQuestionAnswer: (
      state: QuoteState,
      action: PayloadAction<{ id: "Plan" | "EffectiveDate"; value: any }>
    ) => {
      state.MedSuppQuestionAnswers[action.payload.id] = action.payload.value;
    },
  },
});

export const {
  setQuoteID,
  setQuote,
  setSessionID,
  setLocation,
  setPreference,
  addDoctor,
  removeDoctor,
  addMedicine,
  removeMedicine,
  setPharmacy,
  addPlan,
  removePlan,
  nextStep,
  prevStep,
  setActiveStep,
  setUserType,
  setBrokerNPN,
  setTooltipText,
  setAnswerMedSuppQuestions,
  setMedSuppQuestionAnswers,
  setMedSuppQuestionAnswer,
} = quoteSlice.actions;
export default quoteSlice.reducer;
