import * as React from "react";
import { Controller, Control, RegisterOptions } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Tooltip from "@mui/material/Tooltip";
import Info from "@mui/icons-material/Info";

interface RootProps {
  name: string;
  control: Control<any>;
  errorMessage: string | undefined;
  required?: boolean;
  label: string;
  placeholder: string;
  initialValue?: string | number;
  hint?: {
    Title?: string;
    Content: string;
  };
  validation?: RegisterOptions;
  readOnly?: boolean;
  type?: React.HTMLInputTypeAttribute | undefined;
  inputMode?:
    | "text"
    | "search"
    | "url"
    | "none"
    | "tel"
    | "email"
    | "numeric"
    | "decimal"
    | undefined;
}

export default function TextInput({
  name,
  control,
  errorMessage,
  label,
  required,
  placeholder,
  initialValue,
  hint,
  validation,
  readOnly,
  type = "text",
  inputMode = "text",
}: RootProps) {
  return (
    <FormControl fullWidth>
      <FormLabel
        sx={{ display: "inline-flex", alignItems: "center", gap: 1, mb: 0.5 }}
      >
        <Typography fontWeight={500}>
          {label} {required ? "*" : "(Optional)"}
        </Typography>
        {hint && (
          <Tooltip title={<Typography>{hint.Content}</Typography>}>
            <Info color="secondary" sx={{ cursor: "pointer" }} />
          </Tooltip>
        )}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={initialValue ?? ""}
        rules={validation}
        shouldUnregister
        render={({ field }) => (
          <TextField
            {...field}
            InputProps={{ readOnly, inputMode: inputMode }}
            placeholder={placeholder}
            error={errorMessage !== undefined}
            helperText={errorMessage !== undefined ? errorMessage : null}
            type={type}
          />
        )}
      />
    </FormControl>
  );
}
