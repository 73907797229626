export const USER_TIME_ZONE =
  Intl.DateTimeFormat().resolvedOptions().timeZone ??
  new Date().getTimezoneOffset();

export const DEFAULT_PLAN_YEAR =
  Number(process.env.REACT_APP_DEFAULT_PLAN_YEAR) || 2024;

export const NATIONAL_NUMBER_OF_CARRIERS =
  process.env.REACT_APP_NATIONAL_NUMBER_OF_CARRIERS;

export const NATIONAL_NUMBER_OF_PLANS =
  process.env.REACT_APP_NATIONAL_NUMBER_OF_PLANS;

export const DEFAULT_CMS_FILILING_ID = process.env.REACT_APP_CMS_FILING_ID;

export const API_URL = process.env.REACT_APP_API_URL;

export const ENABLE_DEV_TOOLS =
  process.env.REACT_APP_ENABLE_DEV_TOOLS === "true";

export const ENABLE_TRACKING = process.env.REACT_APP_ENABLE_TRACKING === "true";

export const PLAUSIBLE_DOMAIN =
  process.env.REACT_APP_PLAUSIBLE_DOMAIN ?? window.location.hostname;

export const AZURE_FUNCTION_KEY =
  process.env.REACT_APP_AZURE_FUNCTION_KEY ?? "";

export const RUDDERSTACK_DATA_PLANE_URL =
  process.env.REACT_APP_RUDDERSTACK_DATA_PLANE_URL ?? "";

export const RUDDERSTACK_WRITE_KEY =
  process.env.REACT_APP_RUDDERSTACK_WRITE_KEY ?? "";

export const DEFAULT_AFFILIATE_CODE =
  process.env.REACT_APP_DEFAULT_AFFILIATE_CODE ?? "vm-default";
