export enum ANALYTICS_EVENT {
  /** General */
  URL_BUTTON_CLICKED = "URL Button Clicked",
  PAGE_VIEW = "Page View",
  PHONE_NUMBER_CLICKED = "Phone Number Clicked",
  FORM_STARTED = "Form Started",
  FORM_ERROR = "Form Error",
  FORM_SUBMITTED = "Form Submitted",
  EXIT_LINK_CLICKED = "Exit Link Clicked",
  CONTENT_FILTER = "Content Filter",
  CONTENT_INTERACTION = "Content Interaction",
  SEARCH_STARTED = "Search Started",
  SEARCH_RESULTS_RETURNED = "Search Results Returned",
  SEARCH_RESULT_CLICKED = "Search Result Clicked",
  AFFILIATE_LOADED = "Affiliate Loaded",
  AFFILIATE_ERROR = "Affiliate Error",
  HEADER_MENU_OPEN = "Header Menu Open",
  ZIP_CODE_ENTERED = "Zip Code Entered",
  COUNTY_SELECTED = "County Selected",
  QUOTING_PREFERENCE = "Quoting Preference",
  MEDICARE_DISCLAIMERS_CLICKED = "Medicare Disclaimers Clicked",
  MEDICARE_DISCLAIMERS_DIALOG_OPENED = "Medicare Disclaimers Dialog Opened",
  MEDICARE_DISCLAIMERS_DIALOG_CLOSED = "Medicare Disclaimers Dialog Closed",

  /** Quote */
  QUOTE_ID_ENTERED = "Quote ID Entered",
  QUOTE_LOADED = "Quote Loaded",
  QUOTE_CREATED = "Quote Created",
  QUOTE_INFO_OPENED = "Quote Info Opened",
  QUOTE_INFO_CLOSED = "Quote Info Closed",
  QUOTE_VIEW_CLICKED = "Quote View Clicked",
  QUOTE_SHARED = "Quote Shared",
  QUOTE_URL_COPIED = "Quote URL Copied",
  QUOTE_STEP_CLICKED = "Quote Step Clicked",
  QUOTE_NEXT_STEP = "Quote Next Step",
  QUOTE_PREVIOUS_STEP = "Quote Previous Step",
  QUOTE_DOCTOR_ADDED = "Quote Doctor Added",
  QUOTE_DOCTOR_REMOVED = "Quote Doctor Removed",
  QUOTE_MEDICINE_ADDED = "Quote Medicine Added",
  QUOTE_MEDICINE_REMOVED = "Quote Medicine Removed",
  QUOTE_PHARMACY_ADDED = "Quote Pharmacy Added",
  QUOTE_PHARMACY_REMOVED = "Quote Pharmacy Removed",
  QUOTE_FAVORITE_PLAN_ADDED = "Quote Favorite Plan Added",
  QUOTE_FAVORITE_PLAN_REMOVED = "Quote Favorite Plan Removed",

  /** Med Adv */
  PLAN_COMPARE_ADDED = "Plan Compare Added",
  PLAN_COMPARE_REMOVED = "Plan Compare Removed",
  PLAN_COMPARE_CLICKED = "Plan Compare Clicked",
  PLAN_COMPARE_DIALOG_OPENED = "Plan Compare Dialog Opened",
  PLAN_COMPARE_DIALOG_CLOSED = "Plan Compare Dialog Closed",
  PLAN_COMPARE_DIALOG_SECTION_VIEWED = "Plan Compare Dialog Section Viewed",
  PLAN_DOCUMENT_VIEWED = "Plan Document Viewed",
  PLAN_DRUG_COSTS_CLICKED = "Plan Drug Costs Clicked",
  PLAN_DRUG_COSTS_DIALOG_OPENED = "Plan Drug Costs Dialog Opened",
  PLAN_DRUG_COSTS_VIEWED = "Plan Drug Costs Viewed",
  PLAN_DRUG_COSTS_DIALOG_CLOSED = "Plan Drug Costs Dialog Closed",
  PLAN_DETAILS_CLICKED = "Plan Details Clicked",
  PLAN_DETAILS_SECTION_VIEWED = "Plan Details Section Viewed",
  ENROLL_NOW_CLICKED = "Enroll Now Clicked",

  /** Med Supp */
  MED_SUPP_PLAN_DETAILS_CLICKED = "Med Supp Plan Details Clicked",
  MED_SUPP_PLAN_DETAILS_SECTION_VIEWED = "Med Supp Plan Details Section Viewed",
  MED_SUPP_ENROLL_NOW_CLICKED = "Med Supp Enroll Now Clicked",
  MED_SUPP_QUESTIONS_DIALOG_OPENED = "Med Supp Questions Dialog Opened",
  MED_SUPP_QUESTIONS_DIALOG_CLOSED = "Med Supp Questions Dialog Closed",
  MED_SUPP_QUESTIONS_CLICKED = "Med Supp Questions Clicked",
  MED_SUPP_CALL_AGENT_DIALOG_OPENED = "Med Supp Call Agent Dialog Opened",

  /** Enroll */
  CONFIRM_ENROLLMENT_CLICKED = "Confirm Enrollment Clicked",
  CALL_AGENT_DIALOG_OPENED = "Call Agent Dialog Opened",
  CALL_AGENT_DIALOG_CLOSED = "Call Agent Dialog Closed",
  ENROLLMENT_APPLICATION_STARTED = "Enrollment Application Started",
  ENROLLMENT_APPLICATION_SUBMITTED = "Enrollment Application Submitted",
  ENROLLMENT_APPLICATION_SECTION_STARTED = "Enrollment Application Section Started",
  ENROLLMENT_APPLICATION_SECTION_SUBMITTED = "Enrollment Application Section Submitted",
  ENROLLMENT_APPLICATION_SECTION_ERROR = "Enrollment Application Section Error",
  ENROLLMENT_APPLICATION_PCP_ATTESTED = "Enrollment Application PCP Attested",
  ENROLLMENT_APPLICATION_STATUS_VIEWED = "Enrollment Application Status Viewed",
}
