import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import createFilter from "redux-persist-transform-filter";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import Quote from "./reducers/Quote";
import Enrollment from "./reducers/Enrollment";
import Plan from "./reducers/Plan";
import Affiliate from "./reducers/Affiliate";
import Utils from "./reducers/Utils";

import { resumeSessionFailedListener } from "./middlewares/resumeSessionFailedListener";
import { listenerMiddleware } from "./middlewares/quoteChangesListener";

import { API } from "./services/API";

import { ENABLE_DEV_TOOLS } from "../config/global.config";

const saveAffiliateCode = createFilter("Affiliate", ["Code"]);

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["Affiliate"],
  transforms: [saveAffiliateCode],
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  Affiliate,
  Quote,
  Enrollment,
  Plan,
  Utils,
  [API.reducerPath]: API.reducer,
});

type RootReducer = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer<RootReducer>(
  persistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: ENABLE_DEV_TOOLS,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      API.middleware,
      resumeSessionFailedListener,
      listenerMiddleware.middleware
    ),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
