import * as React from "react";
import { Link as RR_Link, useMatch } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

import { styled, Theme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import {
  NATIONAL_NUMBER_OF_CARRIERS,
  NATIONAL_NUMBER_OF_PLANS,
  DEFAULT_CMS_FILILING_ID,
} from "../../config/global.config";
import { PLAN_TABS_INFO } from "../Compare/Compare.layout";

import { ANALYTICS_EVENT } from "../../config/analytics.config";

import formatPhoneNumber from "../../utils/formatPhoneNumber";
import { useRudderStackAnalytics } from "../../utils/useRudderStackAnalytics";

const Background = styled(Grid)(
  ({ theme }) => `
    background-color: ${theme.palette.info.main};
    color: rgba(255, 255, 255, 0.6);
    padding: 1.5rem;
  `
);

const NavLink = styled(Link)({
  color: "rgba(255, 255, 255, 0.6)",
  textDecoration: "none",
  display: "inline-block",
  cursor: "pointer",

  "&:after": {
    content: "''",
    width: "0px",
    height: "2px",
    display: "block",
    background: "rgba(255, 255, 255, 0.6)",
    transition: "300ms",
  },

  "&:hover::after": {
    width: "100%",
  },
}) as typeof Link;

export default function Footer() {
  const analytics = useRudderStackAnalytics();

  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const isEnrollmentPages = useMatch("/:affiliateId/enroll/*");
  const isConfirmPlanPage = useMatch("/:affiliateId/input/confirm");

  const EnrollMetadata = useAppSelector((state) => state.Enrollment.Metadata);
  const { Phone } = useAppSelector((state) => state.Affiliate);
  const { PlanMetadata, PlanTypeTab, PlanYear } = useAppSelector(
    (state) => state.Plan
  );

  const { numCarriers, numPlans } = React.useMemo(() => {
    const currentPlanMetadata =
      PlanMetadata[`${PlanYear}-${PLAN_TABS_INFO[PlanTypeTab].drxID}`];

    return {
      numCarriers:
        currentPlanMetadata?.Carriers?.length ?? NATIONAL_NUMBER_OF_CARRIERS,
      numPlans: currentPlanMetadata?.TotalPlans ?? NATIONAL_NUMBER_OF_PLANS,
    };
  }, [PlanMetadata, PlanTypeTab, PlanYear]);

  const handleClickTracking = (text: string, href: string) => {
    analytics?.track(ANALYTICS_EVENT.URL_BUTTON_CLICKED, {
      link_text: text,
      link_url: href,
    });
  };

  const handleExternalLinkTracking = (text: string, href: string) => {
    analytics?.track(ANALYTICS_EVENT.EXIT_LINK_CLICKED, {
      link_text: text,
      link_url: href,
    });
  };

  return (
    <Background container>
      <Grid
        item
        xs={12}
        sx={{ textAlign: { xs: "center", sm: "right" }, mb: { xs: 1 } }}
      >
        <Typography>
          {isEnrollmentPages || isConfirmPlanPage
            ? EnrollMetadata?.FilingNumber
            : DEFAULT_CMS_FILILING_ID}
        </Typography>
      </Grid>
      <Grid
        item
        md={1}
        container
        justifyContent="center"
        alignContent="flex-start"
      >
        <img src="/images/Logo.svg" alt="heart" width={100} height={100} />
      </Grid>

      <Grid item xs={12} sm={6} md={3} container sx={{ pl: 3, pt: 3 }}>
        <Grid item xs={6} container direction="column" spacing={0.4}>
          <Grid item sx={{ mb: 1, fontSize: "1.05rem" }}>
            <b>Links</b>
          </Grid>

          <Grid item>
            <NavLink
              target="_blank"
              href="https://app.termly.io/document/terms-of-service/7ca5ed39-7640-4b91-8b8c-99af3e3768d6"
              rel="noopener noreferrer"
              onClick={() =>
                handleExternalLinkTracking(
                  "Terms of Use",
                  "https://app.termly.io/document/terms-of-service/7ca5ed39-7640-4b91-8b8c-99af3e3768d6"
                )
              }
            >
              Terms of Use
            </NavLink>
          </Grid>
          <Grid item>
            <NavLink
              component={RR_Link}
              to="licensing"
              onClick={() => handleClickTracking("Licensing", "/licensing")}
            >
              Licensing
            </NavLink>
          </Grid>
          <Grid item>
            <NavLink
              component={RR_Link}
              to="nondiscrimination"
              onClick={() =>
                handleClickTracking("Nondiscrimination", "/nondiscrimination")
              }
            >
              Nondiscrimination
            </NavLink>
          </Grid>
          <Grid item>
            <NavLink
              target="_blank"
              href="https://app.termly.io/document/privacy-policy/f15d27e8-d66e-47d1-b5ca-5887486bb383"
              rel="noopener noreferrer"
              onClick={() =>
                handleExternalLinkTracking(
                  "Privacy Policy",
                  "https://app.termly.io/document/privacy-policy/f15d27e8-d66e-47d1-b5ca-5887486bb383"
                )
              }
            >
              Privacy Policy
            </NavLink>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={8}
        container
        direction="column"
        justifyContent="center"
        alignContent={desktop ? "flex-end" : "center"}
        sx={{ pr: desktop ? 5 : 0, pt: desktop ? 0 : 3 }}
      >
        <Grid item>
          <Typography variant="h5" align="center" fontWeight={600}>
            Call {formatPhoneNumber(Phone?.PhoneNumber ?? "")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" align="center">
            {Phone?.TTY && "TTY 711"}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" align="center">
            {Phone &&
              `${Phone?.PhoneNumberAvailability.Days} ${Phone?.PhoneNumberAvailability.Hours} ${Phone?.PhoneNumberAvailability.Timezone}`}
            <br /> for a Licensed Insurance Agent
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: 3 }}
        container
        columnGap={5}
        rowGap={2}
        justifyContent="center"
        textAlign="center"
      >
        <Grid item>
          <NavLink
            target="_blank"
            href="https://app.termly.io/notify/f15d27e8-d66e-47d1-b5ca-5887486bb383"
            rel="noopener noreferrer"
            onClick={() =>
              handleExternalLinkTracking(
                "Do Not Sell or Share My Personal information",
                "https://app.termly.io/notify/f15d27e8-d66e-47d1-b5ca-5887486bb383"
              )
            }
          >
            Do Not Sell or Share My Personal information
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            target="_blank"
            href="https://app.termly.io/notify/f15d27e8-d66e-47d1-b5ca-5887486bb383"
            rel="noopener noreferrer"
            onClick={() =>
              handleExternalLinkTracking(
                "Limit the Use Of My Sensitive Personal Information",
                "https://app.termly.io/notify/f15d27e8-d66e-47d1-b5ca-5887486bb383"
              )
            }
          >
            Limit the Use Of My Sensitive Personal Information
          </NavLink>
        </Grid>
        <Grid item>
          <NavLink
            target="_blank"
            href="https://app.termly.io/notify/f15d27e8-d66e-47d1-b5ca-5887486bb383"
            rel="noopener noreferrer"
            onClick={() =>
              handleExternalLinkTracking(
                "Data Subject Access Request (DSAR) Form",
                "https://app.termly.io/notify/f15d27e8-d66e-47d1-b5ca-5887486bb383"
              )
            }
          >
            Data Subject Access Request (DSAR) Form
          </NavLink>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <ul>
          <li>
            <Typography variant="body2">
              YourVirtualEnrollment website is operated by Senior Choice Plans
              LLC, a licensed health insurance agency doing business as
              YourVirtualEnrollment. The purpose of this site is the
              solicitation of insurance. Contact may be made by an insurance
              agent/producer or insurance company. YourVirtualEnrollment and
              Medicare supplement insurance plans are not connected with or
              endorsed by the U.S. government or the federal Medicare program.
              We offer plans from a number of insurance companies.
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              Plans are insured or covered by a Medicare Advantage organization
              are (PPO, HMO, HMO-POS PDP and SNP) plans with a Medicare contract
              and/or a Medicare-approved Part D sponsor. Enrollment in the plan
              depends on the plan's contract renewal with Medicare.
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              We do not offer every plan available in your area. Currently we
              represent {numCarriers} organizations which offer {numPlans}{" "}
              products in your area. Please contact Medicare.gov,
              1-800-MEDICARE, or your local State Health Insurance Program
              (SHIP) to get information on all of your options.
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              Not all plans offer all of these benefits. Benefits may vary by
              carrier and location. Limitations and exclusions may apply.
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              Part B Premium give-back is not available with all plans.
              Availability varies by carrier and location. Actual Part B premium
              reduction varies
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              Every year, Medicare evaluates plans based on a 5-star rating
              system.
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              Enrollment in a plan may be limited to certain times of the year
              unless you qualify for a Special Enrollment Period or you are in
              your Medicare Initial Enrollment Period.
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <Typography variant="body2" align="center">
          Copyright © YourVirtualEnrollment {new Date().getFullYear()}.
        </Typography>
      </Grid>
    </Background>
  );
}
