import * as React from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setActiveStep } from "../../../../redux/reducers/Quote";
import {
  useGetMedSuppPlanDetailsQuery,
  useGetMedSuppQuoteKeyQuery,
} from "../../../../redux/services/API";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import ArrowBack from "@mui/icons-material/ArrowBack";

import DisclosureDialog from "../../DisclosureDialog";
import { SectionScroller } from "../DetailComponents";
import MedSuppDetails from "../Details/MedSupp.details";
import CallAgentDialog from "../../../UserInformation/ConfirmPlan/CallAgentDialog";

import toProperCase from "../../../../utils/toProperCase";
import { useRudderStackAnalytics } from "../../../../utils/useRudderStackAnalytics";
import { generateMedSuppPlanName } from "../../../../utils/MedSuppUtils";
import useNavigateParams from "../../../../utils/useNavigateParams";

import { STEPS } from "../../../../types/Global.types";
import { ANALYTICS_EVENT } from "../../../../config/analytics.config";

export default function MedSuppPlanDetails() {
  const navigate = useNavigateParams();
  const dispatch = useAppDispatch();
  const { medSuppKey } = useParams();

  const analytics = useRudderStackAnalytics();
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const {
    data: planData,
    isFetching: isLoadingQuote,
    isError: quoteError,
  } = useGetMedSuppQuoteKeyQuery(medSuppKey ?? "", {
    skip: !medSuppKey,
  });

  const plan = planData?.data;

  const {
    data: planDetails,
    isFetching: isLoadingPlanDetails,
    isError: detailsError,
  } = useGetMedSuppPlanDetailsQuery(plan?.plan ?? "", {
    skip: !plan,
  });

  const sections: string[] = React.useMemo(() => {
    if (!planDetails || !planDetails.meta) {
      return [];
    }

    const sections = [
      "plan highlights",
      "plan premium",
      ...planDetails.meta.sections,
    ];

    return sections;
  }, [planDetails]);

  const [currentSection, setCurrentSection] = React.useState<string>();
  const [openDisclosures, setOpenDisclosures] = React.useState(false);
  const [openCallAgentDialog, setOpenCallAgentDialog] = React.useState(false);

  const trigger = useScrollTrigger({ disableHysteresis: true });

  const hasEnrollmentForm = React.useMemo(() => {
    if (
      !plan ||
      !plan.contextual_data ||
      plan.contextual_data.has_eapp === undefined
    ) {
      return false;
    }

    return plan.contextual_data.has_eapp;
  }, [plan]);

  const handleScrollerClick = (id: string) => {
    const divElement = document.getElementById(`${id}-section`);
    divElement?.scrollIntoView({ behavior: "smooth" });
  };

  const handleEnroll = () => {
    if (!plan) {
      return;
    }

    if (!hasEnrollmentForm) {
    }

    analytics?.track(ANALYTICS_EVENT.MED_SUPP_ENROLL_NOW_CLICKED, {
      quote_id: QuoteID,
      plan: plan?.plan,
      carrier_name: plan?.company_base.name_full,
    });

    setOpenCallAgentDialog(true);
  };

  const handleDisclosureClose = () => {
    setOpenDisclosures(false);
  };

  const handleBackToPlans = () => {
    analytics?.track(ANALYTICS_EVENT.URL_BUTTON_CLICKED, {
      quote_id: QuoteID,
      link_text: "Back to Plans",
      link_url: "/input/compare",
    });

    dispatch(setActiveStep(STEPS.COMPARE));
    navigate("/input/compare");
  };

  const handleScrollChange = (sectionId: string, _endSection: boolean) => {
    setCurrentSection(sectionId);

    analytics?.track(ANALYTICS_EVENT.MED_SUPP_PLAN_DETAILS_SECTION_VIEWED, {
      quote_id: QuoteID,
      plan: plan?.plan,
      carrier_name: plan?.company_base.name_full,
      section_id: sectionId,
    });
  };

  const handleCallAgentDialogClose = () => {
    setOpenCallAgentDialog(false);
  };

  const handleDisclosureOpen = () => {
    setOpenDisclosures(true);

    analytics?.track(ANALYTICS_EVENT.MEDICARE_DISCLAIMERS_CLICKED, {
      quote_id: QuoteID,
    });
  };

  return (
    <>
      <Grid container padding={3} rowGap={3} columnGap={10}>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="text"
            startIcon={<ArrowBack />}
            onClick={() => handleBackToPlans()}
          >
            Back to Plans
          </Button>
        </Grid>

        {isLoadingPlanDetails || isLoadingQuote ? (
          <>
            <Grid item xs={12} md={3} container>
              <Skeleton variant="rectangular" width="100%" height={400} />
            </Grid>
            <Grid item xs={12} md={8} container>
              <Skeleton variant="rectangular" width="100%" height={800} />
            </Grid>
          </>
        ) : (
          (quoteError || detailsError || !plan || !planDetails) && (
            <Grid item xs={12}>
              <Typography variant="h3">
                Error: Return to Plan Compare Page
              </Typography>
            </Grid>
          )
        )}

        {plan && planDetails && (
          <>
            <Grid item xs={12} md={3} container>
              <Grid
                item
                sx={{
                  position: { md: "fixed" },
                  top: { md: trigger ? 50 : null },
                }}
              >
                <Typography variant="h6" fontWeight={700}>
                  Plan Details
                </Typography>
                <List>
                  {sections.map((section, index) => {
                    const title = section
                      .split(" ")
                      .map((text) => toProperCase(text))
                      .join(" ");

                    return (
                      <SectionScroller
                        key={section}
                        sectionID={section}
                        active={currentSection === section}
                        title={title ?? section}
                        handleClick={handleScrollerClick}
                        index={index + 1}
                      />
                    );
                  })}
                </List>
              </Grid>
            </Grid>

            <Grid item xs={12} md={8}>
              <MedSuppDetails
                plan={plan}
                details={planDetails.data}
                handleEnroll={handleEnroll}
                handleScrollChange={handleScrollChange}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} container direction="column" alignContent="flex-end">
          <Grid item>
            <Button
              color="secondary"
              variant="text"
              onClick={() => handleDisclosureOpen()}
            >
              View Medicare Disclaimers
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {openDisclosures && (
        <DisclosureDialog
          open={openDisclosures}
          handleClose={handleDisclosureClose}
          carriers={[]}
          planType={256}
          state={plan?.location_base.state ?? ""}
        />
      )}
      {openCallAgentDialog && (
        <CallAgentDialog
          open={openCallAgentDialog}
          planName={
            plan
              ? `${plan.company_base.name_full} ${generateMedSuppPlanName(
                  plan.plan
                )}`
              : ""
          }
          handleClose={handleCallAgentDialogClose}
        />
      )}
    </>
  );
}
