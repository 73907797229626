import * as React from "react";
import { Controller, Control, RegisterOptions } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Info from "@mui/icons-material/Info";
import InputMask from "react-input-mask";

interface RootProps {
  name: string;
  control: Control<any>;
  errorMessage: string | undefined;
  required?: boolean;
  label: string;
  initialValue?: string | number;
  hint?: {
    Title?: string;
    Content: string;
  };
  validation?: RegisterOptions;
}

export default function MedicareNumberInput({
  name,
  control,
  errorMessage,
  label,
  required,
  initialValue,
  hint,
  validation,
}: RootProps) {
  return (
    <FormControl fullWidth>
      <FormLabel
        color="primary"
        sx={{ display: "inline-flex", alignItems: "center", gap: 1, mb: 0.5 }}
      >
        <Typography fontWeight={500}>
          {label} {required ? "*" : "(Optional)"}
        </Typography>
        {hint && (
          <Tooltip title={<Typography>{hint.Content}</Typography>}>
            <Info color="secondary" sx={{ cursor: "pointer" }} />
          </Tooltip>
        )}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={initialValue ?? ""}
        rules={validation}
        render={({ field }) => (
          <InputMask
            mask="****-***-****"
            value={field.value}
            alwaysShowMask
            onChange={(e) =>
              field.onChange(e.target.value.replace(/[-_]/g, "").toUpperCase())
            }
            onBlur={field.onBlur}
            ref={field.ref}
          >
            <TextField
              inputProps={{ sx: { textAlign: "center" } }}
              error={errorMessage !== undefined}
              helperText={errorMessage !== undefined ? errorMessage : null}
            />
          </InputMask>
        )}
      />
    </FormControl>
  );
}
