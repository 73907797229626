import * as React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { useGetPlanByIDQuery } from "../../../redux/services/API";

import { styled, alpha } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import Cancel from "@mui/icons-material/Cancel";

import Tabs from "../../shared/Tabs";
import DrugRow from "./DrugRow";

import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";

import { PHARMACY_TYPE } from "../../../types/Pharmacy.types";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";

const DRX_LOGO_URL = process.env.REACT_APP_DRX_LOGO_URL;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderTopLeftRadius: 15,
  borderTopRightRadius: 15,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.33),
  borderTopLeftRadius: 15,
  borderTopRightRadius: 15,
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledTable = styled(Table)({
  width: "100%",
  whiteSpace: "normal",
  wordBreak: "break-word",
  "& .MuiTableRow-root": {
    "&:last-child td, &:last-child th": { borderBottom: 0 },
  },
});

const TABS_INFO = [
  {
    title: "Retail",
    subtitle: "Pricing",
    value: PHARMACY_TYPE.RETAIL,
    id: "RETAIL",
  },
  {
    title: "Mail Order",
    subtitle: "Pricing",
    value: PHARMACY_TYPE.MAILORDER,
    id: "MAILORDER",
  },
];

interface RootProps {
  id: string;
  open: boolean;
  handleClose: () => void;
}
export default function DrugCostsDialog({ id, open, handleClose }: RootProps) {
  const analytics = useRudderStackAnalytics();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  const [pharmacyType, setPharmacyType] = React.useState<PHARMACY_TYPE>(
    PHARMACY_TYPE.RETAIL
  );

  const { Pharmacy, SessionID, Location, Medicines, QuoteID } = useAppSelector(
    (state) => state.Quote
  );

  const { data, isFetching, isError } = useGetPlanByIDQuery(
    {
      SessionID: SessionID ?? "",
      PlanID: id,
      Zip: Location?.ZipCode ?? "",
      Fips: Location?.CountyFIPS ?? "",
    },
    {
      skip: !id || !SessionID,
    }
  );

  const handleTabChange = (newValue: number) => {
    setPharmacyType(newValue);
  };

  const plan = data?.data;

  React.useEffect(() => {
    if (!plan || !analytics || !QuoteID) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.PLAN_DRUG_COSTS_DIALOG_OPENED, {
      quote_id: QuoteID,
      contract_id: plan.ContractID,
      plan_id: plan.PlanID,
      segment_id: plan.SegmentID,
      plan_year: plan.PlanYear,
      carrier_id: plan.CarrierName,
      snp_type: plan.SNPType,
      plan_type: plan.PlanType,
    });

    return () => {
      analytics?.track(ANALYTICS_EVENT.PLAN_DRUG_COSTS_DIALOG_CLOSED, {
        quote_id: QuoteID,
        contract_id: plan.ContractID,
        plan_id: plan.PlanID,
        segment_id: plan.SegmentID,
        plan_year: plan.PlanYear,
        carrier_id: plan.CarrierName,
        snp_type: plan.SNPType,
        plan_type: plan.PlanType,
      });
    };
  }, [plan, analytics, QuoteID]);

  React.useEffect(() => {
    if (!plan || !analytics || !QuoteID) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.PLAN_DRUG_COSTS_VIEWED, {
      quote_id: QuoteID,
      contract_id: plan.ContractID,
      plan_id: plan.PlanID,
      segment_id: plan.SegmentID,
      plan_year: plan.PlanYear,
      carrier_id: plan.CarrierName,
      snp_type: plan.SNPType,
      plan_type: plan.PlanType,
      pharmacy_type: TABS_INFO[pharmacyType].id,
    });
  }, [plan, pharmacyType, analytics, QuoteID]);

  const MonthlyDrugCosts = React.useMemo(() => {
    if (!plan || !plan.MonthlyDrugCosts) {
      return [];
    }

    const planYear = Number(plan?.PlanYear);

    if (planYear > new Date().getFullYear()) {
      return plan?.MonthlyDrugCosts;
    }

    return plan.MonthlyDrugCosts.slice(new Date().getMonth());
  }, [plan]);

  const showRetailCost = pharmacyType === PHARMACY_TYPE.RETAIL;

  const handleAccordionTracking = (id: string, expanded: boolean) => {
    if (!expanded) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.CONTENT_INTERACTION, {
      quote_id: QuoteID,
      content_name: `Drug Costs Information - ${id}`,
      content_url: `#drug-costs-${id}`,
      content_interaction: `User clicked on accordion to view drug costs for ${id}`,
    });
  };

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      maxWidth="md"
      open={open}
      onClose={() => handleClose()}
      scroll="paper"
    >
      {isFetching ? (
        <>
          <DialogTitle sx={{ p: 1 }}>
            <IconButton
              aria-label="close"
              onClick={() => handleClose()}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "error.main",
              }}
            >
              <Cancel fontSize="large" />
            </IconButton>
          </DialogTitle>
          <Grid container p={5} justifyContent="center">
            <CircularProgress />
          </Grid>
        </>
      ) : isError || !plan || !MonthlyDrugCosts ? (
        <>
          <DialogTitle sx={{ p: 1 }}>
            <IconButton
              aria-label="close"
              onClick={() => handleClose()}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "error.main",
              }}
            >
              <Cancel fontSize="large" />
            </IconButton>
          </DialogTitle>
          <Grid container p={5} justifyContent="center">
            <Typography>Error: Try again</Typography>
          </Grid>
        </>
      ) : (
        <>
          <DialogTitle sx={{ pb: 1 }}>
            <IconButton
              aria-label="close"
              onClick={() => handleClose()}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "error.main",
              }}
            >
              <Cancel fontSize="large" />
            </IconButton>
            <Grid
              container
              sx={{ px: { xs: 1, sm: 5 }, mt: 5, mb: 0 }}
              rowGap={2}
            >
              <Grid
                item
                xs={12}
                md={6}
                container
                direction="column"
                alignItems="flex-start"
                rowGap={2}
              >
                <Grid item>
                  <img
                    src={`${DRX_LOGO_URL}/${plan.LogoURL}`}
                    alt=""
                    width="150px"
                    loading="lazy"
                  />
                  <Typography fontWeight={600}>{plan.PlanName}</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                container
                direction="column"
                justifyContent="center"
                alignItems={{ xs: "center", sm: "flex-start", md: "flex-end" }}
              >
                <Grid item>
                  <Typography variant="h6" fontWeight={600}>
                    Retail Pharmacy:{" "}
                  </Typography>
                  <Typography>
                    {Pharmacy
                      ? Pharmacy.Name
                      : "Not Selected. Using Default Pricing for Plan"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} container justifyContent="center">
                <Tabs
                  currentValue={pharmacyType}
                  handleChange={handleTabChange}
                  tabs={TABS_INFO}
                  elevation={1}
                />
              </Grid>
            </Grid>
          </DialogTitle>

          <DialogContent>
            <Grid container sx={{ p: { xs: 1, sm: 5 } }} rowGap={3}>
              {MonthlyDrugCosts.map((monthDrugCost, index) => {
                const id = `${PHARMACY_TYPE[pharmacyType]}-${monthDrugCost.Month}`;
                return (
                  <Grid key={`${monthDrugCost.Month}-table`} item xs={12}>
                    <Accordion
                      defaultExpanded={index < 2}
                      onChange={(_event, expanded) => {
                        handleAccordionTracking(id, expanded);
                      }}
                    >
                      <AccordionSummary>
                        <Grid container alignItems="center">
                          <Grid item xs={6}>
                            <Typography variant="h5" fontWeight={600}>
                              {monthDrugCost.Month}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            container
                            direction="column"
                            alignItems="flex-end"
                          >
                            {pharmacyType === PHARMACY_TYPE.MAILORDER && (
                              <>
                                <Typography fontWeight={600}>
                                  {monthDrugCost.TotalMailOrder !== undefined &&
                                  monthDrugCost.TotalMailOrder !== null
                                    ? `$${monthDrugCost.TotalMailOrder.toFixed(
                                        2
                                      )}`
                                    : "-"}
                                </Typography>
                                <Typography>Mail-Order</Typography>
                              </>
                            )}

                            {pharmacyType === PHARMACY_TYPE.RETAIL && (
                              <>
                                <Typography fontWeight={600}>
                                  {monthDrugCost.TotalRetail !== undefined &&
                                  monthDrugCost.TotalRetail !== null
                                    ? `$${monthDrugCost.TotalRetail.toFixed(2)}`
                                    : "-"}
                                </Typography>
                                <Typography>Retail</Typography>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <TableContainer
                        component="div"
                        sx={{ border: "2px solid #efefef" }}
                      >
                        <StyledTable aria-label="january table">
                          <TableBody>
                            {monthDrugCost.CostDetail.map((drug) => {
                              return (
                                <DrugRow
                                  key={`${monthDrugCost.Month}-${
                                    showRetailCost ? "retail" : "mo"
                                  }-cost-${drug.DrugName}`}
                                  name={drug.DrugName}
                                  tier={drug.Tier}
                                  PA={drug.HasPriorAuthorization ? "Yes" : "No"}
                                  QL={
                                    drug.HasQuantityLimit
                                      ? `${drug.QuantityLimitAmount} / ${drug.QuantityLimitDays} Days`
                                      : "N/A"
                                  }
                                  ST={drug.HasStepTherapy ? "Yes" : "No"}
                                  medicineInfo={Medicines.find(
                                    (medicine) =>
                                      medicine.LabelName === drug.DrugName
                                  )}
                                  costPhase={
                                    showRetailCost
                                      ? drug.RetailCostPhase
                                      : drug.MailOrderCostPhase
                                  }
                                  drugCost={
                                    (showRetailCost
                                      ? drug.RetailCost
                                      : drug.MailOrderCost) ?? null
                                  }
                                />
                              );
                            })}
                          </TableBody>
                        </StyledTable>
                      </TableContainer>
                    </Accordion>
                  </Grid>
                );
              })}
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
