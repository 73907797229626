import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { RudderAnalytics } from "@rudderstack/analytics-js";

import {
  RUDDERSTACK_DATA_PLANE_URL,
  RUDDERSTACK_WRITE_KEY,
} from "../config/global.config";

interface AnalyticsState {
  Analytics: RudderAnalytics | null;
}

const initialState: AnalyticsState = {
  Analytics: null,
};

enum ActionTypes {
  SET_ANALYTICS = "SET_ANALYTICS",
}

interface Action {
  type: ActionTypes;
  payload: any;
}

function reducer(state: AnalyticsState, action: Action): AnalyticsState {
  switch (action.type) {
    case ActionTypes.SET_ANALYTICS:
      return { ...state, Analytics: action.payload };

    default:
      throw new Error();
  }
}

const defaultDispatch: React.Dispatch<Action> = () => initialState;
const AnalyticsContext = createContext({
  state: initialState,
  dispatch: defaultDispatch,
});

interface RootProps {
  children: React.ReactNode;
}
export function AnalyticsWrapper({ children }: RootProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!state.Analytics) {
      const analyticsInstance = new RudderAnalytics();
      analyticsInstance.load(RUDDERSTACK_WRITE_KEY, RUDDERSTACK_DATA_PLANE_URL);

      analyticsInstance.ready(() => {
        console.log("RudderStack Init");
      });

      dispatch({
        type: ActionTypes.SET_ANALYTICS,
        payload: analyticsInstance,
      });
    }
  }, [state.Analytics]);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export function useRudderStackAnalytics() {
  const context = useContext(AnalyticsContext);
  return context.state.Analytics;
}
