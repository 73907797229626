import * as React from "react";
import { useAppSelector } from "../../redux/hooks";

import {
  IAzureMapOptions,
  AuthenticationType,
  AzureMapsProvider,
  AzureMap,
  AzureMapFeature,
  AzureMapDataSourceProvider,
  IAzureMapFeature,
  AzureMapLayerProvider,
  IAzureMapControls,
  AzureMapPopup,
} from "react-azure-maps";
import {
  ControlOptions,
  MapMouseEvent,
  PopupOptions,
  data,
} from "azure-maps-control";

import { Theme } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Skeleton from "@mui/material/Skeleton";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export interface MarkersInfo {
  Markers: MarkerDetails[];
  PageNum: number;
}

export interface MarkerDetails {
  Coordinates: number[];
  MarkerURL?: string;
  Name: string;
  Address: string;
}

/**
 * TODO:
 * 1) Add animation to Markers loading onto map
 * X) Add popup when marker clicked with Location Info
 * 3) Popup: maybe a button to add to Quote inside popup
 * 4) Add option to have custom Marker (AzureMapHtmlMarker)
 */

const renderPoint = (
  marker: MarkerDetails
): React.ReactElement<IAzureMapFeature> => {
  const rendId = Math.random();
  const position: data.Position = marker.Coordinates;

  return (
    <AzureMapFeature
      key={rendId}
      id={rendId.toString()}
      type="Point"
      coordinate={position}
      properties={{
        id: rendId,
        prop: "Provider Location Prop",
        address: marker.Address,
        name: marker.Name,
      }}
    />
  );
};

const controls: IAzureMapControls[] = [
  {
    controlName: "ZoomControl",
    options: { position: "bottom-left" } as ControlOptions,
  },
];

const DEFAULT_RADIUS_OPTIONS = [2, 3, 4, 5, 10, 20, 30, 40, 50];

interface RootProps {
  handleRadius: (event: SelectChangeEvent<number>) => void;
  radius: number;
  markers: MarkersInfo;
  zipCode: string;
  initialCoordinates?: number[];
  radiusOptions?: number[];
}
export default function Map({
  handleRadius,
  radius,
  markers,
  zipCode,
  initialCoordinates,
  radiusOptions,
}: RootProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const AzureMapsKey = useAppSelector((state) => state.Utils.AzureMapsKey);

  const [popupOptions, setPopupOptions] = React.useState<PopupOptions>({});
  const [popupProperties, setPopupProperties] = React.useState<{
    address?: string;
    name?: string;
  }>({});

  const mapOptions: IAzureMapOptions = React.useMemo(() => {
    const generateMapCenter = () => {
      if (markers.Markers[0]) {
        return {
          lng: markers.Markers[0].Coordinates[0],
          lat: markers.Markers[0].Coordinates[1],
        };
      }

      if (initialCoordinates) {
        return {
          lng: initialCoordinates[0],
          lat: initialCoordinates[1],
        };
      }

      return null;
    };

    const coords = generateMapCenter();

    return {
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: AzureMapsKey ?? "",
      },
      center: coords ? [coords.lng, coords.lat] : [0, 0],
      zoom: 11,
      view: "Auto",
      language: "en-US",

      showFeedbackLink: false,
      showTileBoundaries: false,
      showLogo: false,
    };
  }, [initialCoordinates, markers, AzureMapsKey]);

  const memoizedMarkerRender: React.ReactElement<IAzureMapFeature>[] =
    React.useMemo(
      (): React.ReactElement<IAzureMapFeature>[] =>
        markers.Markers.map((marker) => renderPoint(marker)),
      [markers]
    );

  return (
    <AzureMapsProvider>
      <AzureMap
        options={mapOptions}
        controls={controls}
        LoaderComponent={Skeleton}
        styles={{
          height: "100%",
          width: "100%",
          borderRadius: isMobile ? 0 : "15px",
          position: "relative",
        }}
      >
        <Grid
          container
          sx={{
            boxSizing: "border-box",
            border: "1px solid transparent",
            width: { xs: "350px", md: "400px" },
            height: "50px",
            borderRadius: "15px",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
            position: "absolute",
            left: "2%",
            top: 10,
            background: "#EFEFEF",
          }}
          alignItems="center"
        >
          <LocationOnIcon fontSize="large" />
          <Typography variant="h6" marginLeft={2}>
            {zipCode}
          </Typography>
          <Select<number>
            value={radius}
            onChange={handleRadius}
            sx={{
              position: "absolute",
              right: -1,
              height: 50,
              width: "170px",
              backgroundColor: "#C4C4C4",
              borderRadius: "0 15px 15px 0",
              fontWeight: 600,
              fontSize: "1.2rem",
              textAlign: "center",
            }}
            IconComponent={(props) => (
              <KeyboardArrowDownIcon {...props} fontSize="large" />
            )}
          >
            {radiusOptions
              ? radiusOptions.map((radiusOption) => {
                  return (
                    <MenuItem
                      key={`map-radius-option-${radiusOption}`}
                      value={radiusOption}
                    >
                      {radiusOption} Miles
                    </MenuItem>
                  );
                })
              : DEFAULT_RADIUS_OPTIONS.map((radiusOption) => {
                  return (
                    <MenuItem
                      key={`map-radius-option-${radiusOption}`}
                      value={radiusOption}
                    >
                      {radiusOption} Miles
                    </MenuItem>
                  );
                })}
          </Select>
        </Grid>

        <AzureMapDataSourceProvider
          id={"MultiplePoint AzureMapDataSourceProvider"}
        >
          <AzureMapLayerProvider
            id={"MultiplePoint AzureMapLayerProvider"}
            options={{
              iconOptions: {
                image: "pin-red",
                ignorePlacement: true,
                allowOverlap: true,
              },
            }}
            type="SymbolLayer"
            events={{
              mousemove: (e: MapMouseEvent) => {
                if (e.shapes && e.shapes.length > 0) {
                  const prop: any = e.shapes[0];

                  setPopupOptions({
                    position: new data.Position(
                      prop.data.geometry.coordinates[0],
                      prop.data.geometry.coordinates[1]
                    ),
                    pixelOffset: [0, -18],
                  });
                  if (prop.data.properties)
                    setPopupProperties({
                      ...prop.data.properties,
                      dumpProp: "My Popup",
                    });
                }
              },
            }}
          />
          {memoizedMarkerRender}
        </AzureMapDataSourceProvider>

        <AzureMapPopup
          isVisible={true}
          options={popupOptions}
          popupContent={
            <div style={{ width: "100%", height: "100%", padding: "10px" }}>
              <Typography variant="h2" style={{ fontWeight: 600 }}>
                {popupProperties.name}
              </Typography>
              <Typography>{popupProperties.address}</Typography>
            </div>
          }
        />
      </AzureMap>
    </AzureMapsProvider>
  );
}
