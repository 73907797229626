import * as React from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  Input,
  addCompleteStep,
  setInput,
  submitForm,
} from "../../../redux/reducers/Enrollment";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

import CheckboxInput from "../inputs/CheckboxInput";
import TextInput from "../inputs/TextInput";
import RadioInput from "../inputs/RadioInput";
import SelectInput from "../inputs/SelectInput";
import PhoneInput from "../inputs/PhoneInput";

import { STATES, RELATIONSHIP_OPTIONS } from "../../../types/Enrollment.types";
import { CARRIER_NAMES } from "../../../types/Global.types";

import { findCarrier, findPlanType } from "../../../utils/EnrollUtils";
import getCarrierAgreements from "../../../utils/getCarrierAgreements";
import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";

const SUBMITTING_OPTIONS = [
  {
    value: "own",
    label: "I am completing this enrollment form on my own.",
  },
  {
    value: "representative",
    label:
      "I am an authorized representative to act on behalf of the individual listed on this enrollment application.",
  },
];

interface FormInput {
  PersonSubmitting: string;
  RepresentativeFirstName: string;
  RepresentativeLastName: string;
  RepresentativeRelationship: string;
  RepresentativeAddress1: string;
  RepresentativeAddress2?: string;
  RepresentativeCity: string;
  RepresentativeState: string;
  RepresentativeZipCode: string;
  RepresentativePhoneNumber: string;
  Acknowledge: boolean;
  Signature: string;
}

export default function ApplicationSignature() {
  const dispatch = useAppDispatch();
  const analytics = useRudderStackAnalytics();

  const {
    ApplicationID,
    Input: InputtedValues,
    EnrollPlan,
    Carrier,
  } = useAppSelector((state) => state.Enrollment);
  const QuoteID = useAppSelector((state) => state.Quote.QuoteID);

  const State = useAppSelector((state) => state.Quote.Location?.State);

  const methods = useForm<FormInput>({
    defaultValues: {
      PersonSubmitting:
        (InputtedValues["signature"]?.PersonSubmitting as string) ?? "",
      Acknowledge: false,
      Signature: (InputtedValues["signature"]?.Signature as string) ?? "",
    },
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  const { control, handleSubmit, formState, watch } = methods;
  const { errors, isSubmitting } = formState;

  const watchPersonSubmitting = watch("PersonSubmitting");

  React.useEffect(() => {
    if (!analytics || !EnrollPlan || !ApplicationID || !QuoteID) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_STARTED, {
      quote_id: QuoteID,
      application_id: ApplicationID,
      enrollment_type: "INTERNAL",
      contract_id: EnrollPlan?.ContractID,
      plan_id: EnrollPlan?.PlanID,
      segment_id: EnrollPlan?.SegmentID,
      plan_year: EnrollPlan?.Year,
      snp_type: EnrollPlan?.SnpType,
      plan_type: EnrollPlan?.PlanType,
      section_id: "signature",
    });
  }, [ApplicationID, EnrollPlan, QuoteID, analytics]);

  React.useEffect(() => {
    if (!analytics || !EnrollPlan || !ApplicationID || !QuoteID) {
      return;
    }
    const errors = formState.errors;

    if (errors) {
      Object.keys(errors).forEach((i) => {
        const errorMessage = errors[i as keyof FormInput]?.message;

        if (errorMessage) {
          analytics?.track(
            ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_ERROR,
            {
              quote_id: QuoteID,
              application_id: ApplicationID,
              enrollment_type: "INTERNAL",
              contract_id: EnrollPlan?.ContractID,
              plan_id: EnrollPlan?.PlanID,
              segment_id: EnrollPlan?.SegmentID,
              plan_year: EnrollPlan?.Year,
              snp_type: EnrollPlan?.SnpType,
              plan_type: EnrollPlan?.PlanType,
              section_id: "signature",
              error_field: i,
              error_detail: errorMessage,
            }
          );
        }
      });
    }
  }, [formState, analytics, EnrollPlan, ApplicationID, QuoteID]);

  const onSubmit: SubmitHandler<FormInput> = (data) => {
    // TODO: Check if Signature matches representative or enrollee name

    const inputData: Input = {
      signature: { ...data },
    };

    dispatch(setInput(inputData));
    dispatch(addCompleteStep("signature"));

    analytics?.track(ANALYTICS_EVENT.ENROLLMENT_APPLICATION_SECTION_SUBMITTED, {
      quote_id: QuoteID,
      application_id: ApplicationID,
      enrollment_type: "INTERNAL",
      contract_id: EnrollPlan?.ContractID,
      plan_id: EnrollPlan?.PlanID,
      segment_id: EnrollPlan?.SegmentID,
      plan_year: EnrollPlan?.Year,
      snp_type: EnrollPlan?.SnpType,
      plan_type: EnrollPlan?.PlanType,
      section_id: "signature",
    });

    dispatch(submitForm(true));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RadioInput
              name="PersonSubmitting"
              control={control}
              label="Who is submitting this enrollment application?"
              required
              options={SUBMITTING_OPTIONS}
              errorMessage={errors.PersonSubmitting?.message}
              validation={{
                required: {
                  value: true,
                  message: "This field is required",
                },
              }}
            />
          </Grid>

          {watchPersonSubmitting === "representative" && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight={600}>
                  Authorized Representative Information
                </Typography>
              </Grid>

              <Grid item xs={12} sm={5}>
                <TextInput
                  name="RepresentativeFirstName"
                  control={control}
                  errorMessage={errors.RepresentativeFirstName?.message}
                  label="First Name"
                  placeholder="Enter your Representative's First Name"
                  required
                  initialValue={
                    InputtedValues["signature"]
                      ?.RepresentativeFirstName as string
                  }
                  validation={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /^[A-Za-z. ]{1,50}$/,
                      message: "Only letters (No symbols)",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={5}>
                <TextInput
                  name="RepresentativeLastName"
                  control={control}
                  errorMessage={errors.RepresentativeLastName?.message}
                  label="Last Name"
                  placeholder="Enter your Representative's Last Name"
                  required
                  initialValue={
                    InputtedValues["signature"]
                      ?.RepresentativeLastName as string
                  }
                  validation={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /^[A-Za-z. ]{1,50}$/,
                      message: "Only letters (No symbols)",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <RadioInput
                  name="RepresentativeRelationship"
                  control={control}
                  label="Who is submitting this enrollment application?"
                  required
                  options={RELATIONSHIP_OPTIONS}
                  errorMessage={errors.RepresentativeRelationship?.message}
                  initialValue={
                    InputtedValues["signature"]
                      ?.RepresentativeRelationship as string
                  }
                  validation={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /^[A-Za-z. ]{1,50}$/,
                      message: "Only letters (No symbols)",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextInput
                  name="RepresentativeAddress1"
                  control={control}
                  errorMessage={errors.RepresentativeAddress1?.message}
                  label="Address 1"
                  placeholder="Enter First Line of Representative's Address"
                  required
                  initialValue={
                    InputtedValues["signature"]
                      ?.RepresentativeAddress1 as string
                  }
                  validation={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /^[A-Za-z0-9., ]{1,100}$/,
                      message: "Only letters and numbers (No symbols)",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextInput
                  name="RepresentativeAddress2"
                  control={control}
                  errorMessage={errors.RepresentativeAddress2?.message}
                  label="Address 2"
                  placeholder="Enter Second Line of Representative's Address"
                  initialValue={
                    InputtedValues["signature"]
                      ?.RepresentativeAddress2 as string
                  }
                  validation={{
                    pattern: {
                      value: /^[A-Za-z0-9., ]{0,100}$/,
                      message: "Only letters and numbers (No symbols)",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextInput
                  name="RepresentativeCity"
                  control={control}
                  errorMessage={errors.RepresentativeCity?.message}
                  label="City"
                  placeholder="Enter City of Representative's Address"
                  required
                  initialValue={
                    InputtedValues["signature"]?.RepresentativeCity as string
                  }
                  validation={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /^[A-Za-z. ]{1,50}$/,
                      message: "Only letters (No symbols)",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <SelectInput
                  name="RepresentativeState"
                  control={control}
                  errorMessage={errors.RepresentativeState?.message}
                  label="State"
                  required
                  options={STATES}
                  initialValue={
                    InputtedValues["signature"]?.RepresentativeState as string
                  }
                  validation={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /^[A-Za-z. ]{1,50}$/,
                      message: "Only letters (No symbols)",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <TextInput
                  name="RepresentativeZipCode"
                  control={control}
                  errorMessage={errors.RepresentativeZipCode?.message}
                  label="Zip Code"
                  placeholder="Enter ZipCode of Representative's Address"
                  required
                  initialValue={
                    InputtedValues["signature"]?.RepresentativeZipCode as string
                  }
                  validation={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /^\d{5}(?:[-s]\\d{4})?$/,
                      message: "Enter a valid Zip Code",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <PhoneInput
                  name="RepresentativePhoneNumber"
                  control={control}
                  errorMessage={errors.RepresentativePhoneNumber?.message}
                  label="Phone Number"
                  required
                  initialValue={
                    InputtedValues["signature"]
                      ?.RepresentativePhoneNumber as string
                  }
                  validation={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /^\d{10}$/,
                      message: "Enter a valid Phone Number",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  If you have been authorized to complete this application on
                  behalf of the individual listed on this application, under the
                  laws of the state in which this individual resides, you must
                  provide the following information. Upon request, you must be
                  able to present{" "}
                  {CARRIER_NAMES[EnrollPlan ? EnrollPlan.Carrier : ""]} and/or
                  Medicare with documentation of your authority to represent the
                  individual listed on this application.
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12} mt={2} container direction="column" rowGap={1}>
            <Typography variant="h4" fontWeight={600}>
              Agreements
            </Typography>
            {EnrollPlan &&
              getCarrierAgreements(
                findCarrier(Carrier ?? "") || (Carrier as string),
                findPlanType(EnrollPlan.PlanType),
                EnrollPlan.PlanName,
                State
              )}
          </Grid>
          <Grid item xs={12}>
            <CheckboxInput
              name="Acknowledge"
              control={control}
              label="I acknowledge that I have read the disclosures and confirm that all the information in this enrollment application is accurate"
              errorMessage={errors.Acknowledge?.message}
              required
              validation={{
                required: {
                  value: true,
                  message: "You must check this box to submit.",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={7}>
            <TextInput
              name="Signature"
              control={control}
              errorMessage={errors.Signature?.message}
              label="Signature (Type full name)"
              placeholder="Enter your full name"
              required
              validation={{
                required: { value: true, message: "This field is required" },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={isSubmitting}
            >
              Submit Application
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
