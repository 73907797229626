import * as React from "react";
import { Outlet, useLocation, useMatch } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { nextStep, prevStep, setActiveStep } from "../redux/reducers/Quote";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import FactCheckIcon from "@mui/icons-material/FactCheck";

import QuoteStepper from "../components/shared/QuoteStepper";
import QuotePopup from "../components/UserInformation/QuotePopup";
import SEO from "../components/shared/SEO";

import { useRudderStackAnalytics } from "../utils/useRudderStackAnalytics";
import useNavigateParams from "../utils/useNavigateParams";

import { STEPS } from "../types/Global.types";

import { ANALYTICS_EVENT } from "../config/analytics.config";

const STEP_INFORMATION = ["doctors", "medicines", "pharmacy", "compare"];

function findIndexofPathname(pathname: string) {
  const currentURLStep = pathname.split("/")[3];
  const indexOfCurrentStep = STEP_INFORMATION.findIndex(
    (value) => value === currentURLStep
  );
  return indexOfCurrentStep;
}

export default function UserInformation() {
  const navigate = useNavigateParams();
  const dispatch = useAppDispatch();
  const analytics = useRudderStackAnalytics();

  const { pathname } = useLocation();
  const isConfirmPage = useMatch("/:affiliateId/input/confirm");
  const isMedAdvPlanDetailsPage = useMatch(
    "/:affiliateId/input/compare/plan-details/:id"
  );
  const isMedSuppPlanDetailsPage = useMatch(
    "/:affiliateId/input/compare/medSupp/:medSuppKey"
  );
  const isPlanDetailsPage = isMedAdvPlanDetailsPage || isMedSuppPlanDetailsPage;

  const {
    Location: selectedLocation,
    ActiveStep: activeStep,
    TooltipText,
    QuoteID,
  } = useAppSelector((state) => state.Quote);

  const enrollPlan = useAppSelector((state) => state.Enrollment.EnrollPlan);
  const [openQuote, setOpenQuote] = React.useState(false);

  React.useEffect(() => {
    const handleTabClose = (event: BeforeUnloadEvent) => {
      event.preventDefault();

      return (event.returnValue =
        "Are you sure you want to exit? Make sure you keep your QuoteID so continue where you left off.");
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  React.useEffect(() => {
    if (selectedLocation === null && enrollPlan === null) {
      navigate("/");
      return;
    }

    if (isPlanDetailsPage || isConfirmPage) {
      return;
    }

    navigate(`${STEP_INFORMATION[activeStep]}`);
  }, [
    activeStep,
    enrollPlan,
    isConfirmPage,
    isPlanDetailsPage,
    navigate,
    selectedLocation,
  ]);

  React.useEffect(() => {
    if (isPlanDetailsPage || isConfirmPage) {
      return;
    }
    const indexOfCurrentStep = findIndexofPathname(pathname);
    dispatch(setActiveStep(indexOfCurrentStep));
  }, [dispatch, isConfirmPage, isPlanDetailsPage, pathname]);

  React.useEffect(() => {
    if (!QuoteID || !analytics) {
      return;
    }

    analytics.track(ANALYTICS_EVENT.QUOTE_LOADED, {
      quote_id: QuoteID,
    });
  }, [QuoteID, analytics]);

  // React.useEffect(() => {
  //   if (!selectedLocation && !enrollPlan) {
  //     navigate("/");
  //   }
  // }, [enrollPlan, selectedLocation]);

  const handleStepClick = (step: STEPS) => {
    dispatch(setActiveStep(step));

    analytics?.track(ANALYTICS_EVENT.QUOTE_STEP_CLICKED, {
      quote_id: QuoteID,
      step_id: STEP_INFORMATION[step],
      link_url: `/input/${STEP_INFORMATION[step]}`,
    });
  };

  const handleBack = () => {
    if (activeStep > 0) dispatch(prevStep());

    analytics?.track(ANALYTICS_EVENT.QUOTE_PREVIOUS_STEP, {
      quote_id: QuoteID,
      step_id: STEP_INFORMATION[activeStep],
      link_url: `/input/${STEP_INFORMATION[activeStep]}`,
    });
  };

  const handleNext = () => {
    if (activeStep < 3) dispatch(nextStep());

    analytics?.track(ANALYTICS_EVENT.QUOTE_NEXT_STEP, {
      quote_id: QuoteID,
      step_id: STEP_INFORMATION[activeStep],
      link_url: `/input/${STEP_INFORMATION[activeStep]}`,
    });
  };

  const handleQuoteClose = () => {
    setOpenQuote(false);
  };

  const handleQuoteOpen = () => {
    setOpenQuote(true);

    analytics?.track(ANALYTICS_EVENT.QUOTE_VIEW_CLICKED, {
      quote_id: QuoteID,
    });
  };

  const showQuoteStepper = !(isConfirmPage || isPlanDetailsPage);

  return (
    <>
      <SEO
        title="Find Medicare Plans"
        description="Find Medicare Plans in your Area"
        name="YourVirtualEnrollment - Find Plans"
        type="website"
      />
      <Grid container p={{ xs: 0, md: 2 }} gap={2}>
        {showQuoteStepper && (
          <Grid item xs={12}>
            <QuoteStepper
              activeStep={activeStep}
              handleClick={handleStepClick}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid>

      <Tooltip
        open={TooltipText !== null}
        placement="left"
        title={<Typography variant="h6">{TooltipText}</Typography>}
      >
        <Fab
          color="secondary"
          variant="extended"
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2),
            minWidth: 64,
            height: 64,
            color: "#fff",
            zIndex: 98,
          }}
          onClick={() => handleQuoteOpen()}
        >
          <FactCheckIcon fontSize="large" sx={{ mr: { xs: 0, sm: 1 } }} />
          <Typography fontWeight={600} display={{ xs: "none", sm: "block" }}>
            Your Quote
          </Typography>
        </Fab>
      </Tooltip>

      {showQuoteStepper && (
        <>
          <Fab
            color="primary"
            sx={{
              display: { xs: "none", md: "block" },
              position: "fixed",
              bottom: (theme) => theme.spacing(11),
              right: (theme) => theme.spacing(15),
              height: 64,
              width: 64,
              color: "#fff",
              fontWeight: 600,
              zIndex: 98,
            }}
            onClick={() => handleBack()}
            disabled={activeStep === STEPS.DOCTORS}
          >
            Back
          </Fab>

          <Fab
            color="primary"
            sx={{
              display: { xs: "none", md: "block" },
              position: "fixed",
              bottom: (theme) => theme.spacing(11),
              right: (theme) => theme.spacing(5),
              height: 64,
              width: 64,
              color: "#fff",
              fontWeight: 600,
              zIndex: 98,
            }}
            onClick={() => handleNext()}
            disabled={activeStep === STEPS.COMPARE}
          >
            Next
          </Fab>
        </>
      )}

      {openQuote && (
        <QuotePopup open={openQuote} handleClose={handleQuoteClose} />
      )}
    </>
  );
}
