import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../redux/hooks";
import { useGetDoctorsInNetworkQuery } from "../../../redux/services/API";
import { useDebounce } from "use-debounce";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Cancel, Close, Search } from "@mui/icons-material";

import SearchField from "../../shared/SearchField";
import DoctorSelect from "../../UserInformation/Doctors/DoctorSelect";

import { WEBSITE_FILTER_TYPE } from "../../../types/Affiliate.types";
import { SpecialistInformation } from "../../../types/Enrollment.types";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, bgcolor: "primary.main" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <Cancel fontSize="large" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

function parseSpecialistString(str: string): SpecialistInformation[] {
  if (!str) {
    return [];
  }

  return JSON.parse(str);
}

function convertSpecialistObj(specialists: SpecialistInformation[]): string {
  return JSON.stringify(specialists);
}

interface RootProps {
  id: string;
  label: string;
  errorMessage?: string;
  max?: number;
}

export default function SpecialistFinder({
  id,
  label,
  errorMessage,
  max,
}: RootProps) {
  const { setValue, register, getValues } = useFormContext();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  const { Location } = useAppSelector((state) => state.Quote);
  const { [WEBSITE_FILTER_TYPE.PROVIDER]: ProviderFilters } = useAppSelector(
    (state) => state.Affiliate.Filters
  );

  const { ContractID, PlanID, SegmentID, Year } =
    useAppSelector((state) => state.Enrollment.EnrollPlan) ?? {};

  const [open, setOpen] = React.useState(false);
  const [page, setCurrentPage] = React.useState(1);
  const [total, setTotal] = React.useState<number>();
  const [radius, setRadius] = React.useState(5);

  const selectedSpecialists = parseSpecialistString(getValues(id));

  const [doctorSearch, setDoctorSearch] = React.useState("");
  const [debouncedSearchQuery] = useDebounce(doctorSearch, 750);

  const { data, isLoading, isFetching } = useGetDoctorsInNetworkQuery(
    {
      zipCode: (Location?.ZipCode as string) ?? "",
      radiusInMiles: radius,
      page,
      network: `${Year}_${ContractID}_${PlanID}_${SegmentID}`,
      providerName: debouncedSearchQuery,
      coordinates: Location?.Coordinates?.join(",") ?? "",
      filters: ProviderFilters,
      providerType: "SPECIALIST",
    },
    {
      skip: !open || !Location || debouncedSearchQuery.length < 3,
    }
  );

  register(id, {
    value: convertSpecialistObj(selectedSpecialists),
  });

  React.useEffect(() => {
    if (page === 1) {
      setTotal(data?.meta.totalPages);
      return;
    }
  }, [page, data]);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearchQuery]);

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  const handleRadius = (event: SelectChangeEvent<number>) => {
    setRadius(event.target.value as number);
  };

  const handleSelectDoctor = (
    index: number,
    isSelected: boolean,
    locationIndex: number
  ) => {
    if (!data) {
      return;
    }

    const doctor = data.data[index];
    const location = doctor.locations[locationIndex];

    if (isSelected) {
      const selectSpecialIndex = selectedSpecialists.findIndex(
        (i) => i.ProviderID === doctor.providerId
      );

      console.log(selectSpecialIndex);
      handleRemoveSpecialist(selectSpecialIndex);

      return;
    }

    if (max !== undefined && selectedSpecialists.length === max) {
      return;
    }

    const newSpecialist: SpecialistInformation = {
      ProviderID: doctor.providerId,
      LocationID: location.providerLocationId,
      Name: doctor.facilityName
        ? doctor.facilityName
        : `${doctor.firstName} ${doctor.lastName}`,
      PhoneNumber: location.phoneNumber ?? "",
      Address1: location.address,
      Address2: location.address2 ?? "",
      City: location.city,
      State: location.state,
      ZipCode: location.zip,
      NPI: doctor.npi,
    };

    setValue(
      id,
      convertSpecialistObj([...selectedSpecialists, newSpecialist]),
      {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      }
    );
  };

  const handleOpenClick = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleDoctorSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setDoctorSearch(value);
  };

  const handleRemoveSpecialist = (index: number) => {
    if (index === -1) {
      return;
    }

    const temp = [...selectedSpecialists];
    temp.splice(index, 1);

    setValue(id, convertSpecialistObj(temp), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  return (
    <>
      <Grid container p={3} rowGap={2}>
        <Grid item xs={12} container direction="column">
          <Typography fontWeight={500} color="#6a6a6a">
            {label}
            {/* {isRequired ? "*" : "(Optional)"} */}
          </Typography>
          {errorMessage !== undefined && (
            <Typography pl={2} variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleOpenClick}
            endIcon={<Search />}
          >
            Find Specialist
          </Button>
        </Grid>

        <Grid item xs={12} container gap={1} pl={{ xs: 0.5, sm: 4 }}>
          <List
            subheader={
              <Stack>
                <Typography
                  fontWeight={600}
                  color={errorMessage !== undefined ? "error" : "black"}
                >
                  Selected Specialists (Click specialist below to remove):{" "}
                </Typography>
                {max !== undefined && (
                  <Typography variant="body2">
                    You have selected <b>{selectedSpecialists.length}</b> of{" "}
                    <b>{max}</b> Specialists.
                  </Typography>
                )}
                {selectedSpecialists.length === 0 && (
                  <Typography mt={2}>No Specialists Selected</Typography>
                )}
              </Stack>
            }
          >
            {selectedSpecialists.map((i, index) => {
              return (
                <React.Fragment key={`${id}-selected-${i.ProviderID}`}>
                  <ListItemButton onClick={() => handleRemoveSpecialist(index)}>
                    <ListItemIcon>
                      <Close color="error" />
                    </ListItemIcon>
                    <ListItemText
                      primary={i.Name}
                      secondary={`${i.Address1} ${i.Address2} ${i.City}, ${i.State} ${i.ZipCode}`}
                    />
                  </ListItemButton>
                  {index < selectedSpecialists.length - 1 && (
                    <Divider variant="inset" component="li" />
                  )}
                </React.Fragment>
              );
            })}
          </List>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
        fullScreen={isMobile}
      >
        <CustomDialogTitle
          id="specialist-finder-dialog-title"
          onClose={handleDialogClose}
        >
          <Typography variant="h5" color="#fff" fontWeight={600}>
            Specialist Finder
          </Typography>
        </CustomDialogTitle>

        <Grid container rowGap={2} p={3} justifyContent="center">
          <Grid item xs={12}>
            <SearchField
              placeholder="Search by Doctor's Name"
              handleChange={handleDoctorSearch}
              value={doctorSearch}
            />
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6} sm={3}>
              <FormControl fullWidth>
                <InputLabel id="radius-label">Radius</InputLabel>
                <Select<number>
                  labelId="radius-label"
                  label="Radius"
                  fullWidth
                  onChange={handleRadius}
                  value={radius}
                >
                  <MenuItem value={2}>2 Miles</MenuItem>
                  <MenuItem value={3}>3 Miles</MenuItem>
                  <MenuItem value={4}>4 Miles</MenuItem>
                  <MenuItem value={5}>5 Miles</MenuItem>
                  <MenuItem value={10}>10 Miles</MenuItem>
                  <MenuItem value={15}>15 Miles</MenuItem>
                  <MenuItem value={20}>20 Miles</MenuItem>
                  <MenuItem value={30}>30 Miles</MenuItem>
                  <MenuItem value={40}>40 Miles</MenuItem>
                  <MenuItem value={50}>50 Miles</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Stack divider={<Divider flexItem />} alignItems="center">
              <Box alignSelf="flex-start" pb={1}>
                <Typography variant="h5" fontWeight={600}>
                  Search Results
                </Typography>
                {max !== undefined && (
                  <Typography variant="body2">
                    You have selected <b>{selectedSpecialists.length}</b> of{" "}
                    <b>{max}</b> Specialists.
                  </Typography>
                )}
              </Box>

              {!isFetching &&
                data?.data.map((doctor, index) => {
                  const selectedIndex = selectedSpecialists?.findIndex(
                    (i) => i.ProviderID === doctor.providerId
                  );
                  const isSelected = selectedIndex > -1;
                  return (
                    <DoctorSelect
                      key={doctor.providerId}
                      info={doctor}
                      index={index}
                      handleSelect={handleSelectDoctor}
                      selected={isSelected}
                      locationId={
                        isSelected
                          ? selectedSpecialists[selectedIndex].LocationID
                          : undefined
                      }
                    />
                  );
                })}

              {isFetching && (
                <Skeleton
                  variant="rectangular"
                  height={400}
                  sx={{ width: { xs: 350, md: 600 } }}
                />
              )}

              {isLoading ? (
                <Box
                  sx={{
                    display: "inline-flex",
                    columnGap: 3,
                    margin: ".5rem",
                  }}
                >
                  <Skeleton variant="circular" width={32} height={32} />
                  <Skeleton variant="circular" width={32} height={32} />
                  <Skeleton variant="circular" width={32} height={32} />
                  <Skeleton variant="circular" width={32} height={32} />
                </Box>
              ) : (
                data && (
                  <Pagination
                    page={page}
                    count={total}
                    variant="outlined"
                    color="secondary"
                    sx={{ mt: 2 }}
                    onChange={handleChangePage}
                  />
                )
              )}
            </Stack>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
